import React from "react";
import axios from "../../axios/axios";

import message from "../../messages";
import { setToken } from "../../actions/setToken";
import { useDispatch } from "react-redux";

import { Button } from "react-bootstrap";
import "./logout.css";

function Logout(props) {
  let dispatch = useDispatch();

  const confirmLogout = async (ans) => {
    //console.log("ans",ans);
    if (ans === "yes") {
      await axios
        .get("/logout")
        .then((res) => {
          //console.log("res",res.data);
          if (res.data.success === true) {
            message.success("Logged out");
            // window.location.href='/'
            localStorage.removeItem("AuthData");
            localStorage.removeItem("AuthToken");
            dispatch(setToken(""));
          } else {
            message.error("Unable to logout", 3);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      props.history.push("/dashboard");
    }
  };
  return (
    <div>
      <div className="logout_inner_page">
        <div className="logout_text">
          <h2>Do You Want to Logout?</h2>
          <div className="logout_buttons">
            <Button className="yes_but" onClick={() => confirmLogout("yes")}>
              Yes
            </Button>
            <Button className="no_but" onClick={() => confirmLogout("no")}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
