import React, {useCallback, useEffect, useState} from 'react'
import {Button, message} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {Dropdown, Form} from 'react-bootstrap';
import axios from '../../axios/axios';
import "./frames.css"
import {useHistory, useParams} from 'react-router-dom';

const AddFrame = () => {
  const DEFAULTCARDURL = "https://hypeu-dev-api.s3.us-west-2.amazonaws.com/frames/frame16867494496773.png"
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory()
  const {frameId} = useParams()
  const PATHNAME = window.location.pathname
  const [isLoading, setIsLoading] = useState(false)
  const [frameName, setFrameName] = useState("")
  const [blankCard, setBlankCard] = useState(false)
  const [uploadData, setUploadData] = useState({
    common: {
      c: "",
      cBg: "",
      topImg: "",
      previewImg: ""
    },
    rare: {
      c: "",
      cBg: "",
      topImg: "",
      previewImg: ""
    },
    epic: {
      c: "",
      cBg: "",
      topImg: "",
      previewImg: ""
    }
  })

  // useEffect(() => {
  //   setUploadData((prevState) => ({
  //     ...prevState,
  //     ["common"]: {
  //       ...prevState["common"],
  //       ["c"]: blankCard ? DEFAULTCARDURL : "",
  //     },
  //     ["rare"]: {
  //       ...prevState["rare"],
  //       ["c"]: blankCard ? DEFAULTCARDURL : "",
  //     },
  //     ["epic"]: {
  //       ...prevState["epic"],
  //       ["c"]: blankCard ? DEFAULTCARDURL : "",
  //     },
  //   }));
  // }, [blankCard])

  const [loading, setLoading] = useState({
    common: {
      c: false,
      cBg: false,
      topImg: false,
      previewImg: false
    },
    rare: {
      c: false,
      cBg: false,
      topImg: false,
      previewImg: false
    },
    epic: {
      c: false,
      cBg: false,
      topImg: false,
      previewImg: false
    }
  })
  const [orientation, setOrientation] = useState("")

  const getFrameDetails = useCallback(async () => {
    const frame = await axios.get(`/get-frame/${frameId}`)

    if (frame.data.success) {
      const result = frame.data.frame
      setFrameName(result.name)
      setOrientation(result.orientation)
      setUploadData({...uploadData, common: result.common, rare: result.rare, epic: result.epic})
      setBlankCard(result.blankCard)
    }
  }, [frameId])

  useEffect(() => {
    if (frameId) {
      getFrameDetails()
    }
  }, [frameId, getFrameDetails])

  const addFrame = async () => {
    const errors = (
      !frameName && "Name is required" ||
      !orientation && "Orientation is required" ||
      !uploadData.common.c && "Common card image is required" ||
      !uploadData.rare.c && "Legendary card image is required" ||
      !uploadData.epic.c && "Epic card image is required"
    );

    if (errors) {
      message.open({
        type: "error",
        content: errors
      })
      return
    } else {
      setIsLoading(true)
      const data = {
        name: frameName,
        orientation: orientation.toLowerCase(),
        common: uploadData.common,
        rare: uploadData.rare,
        epic: uploadData.epic
      }

      let addFrame;

      if (frameId) {
        addFrame = await axios.put(`/update-frame/${frameId}`, data);
      } else {
        addFrame = await axios.post(`/add-frame?blankCard=${blankCard}`, data);
      }
      if (!blankCard) {
        if (addFrame.data.success && frameId) {
          history.push(`/frame-design/${frameId}?blankCard=${blankCard}`)
        } else {
          history.replace(`/frame-design/${addFrame.data.frame._id}?blankCard=${blankCard}`)
        }
      } else {
        history.replace("/frames")
      }
      setIsLoading(false)
      messageApi.open({
        type: 'success',
        content: "Frame added successfully",
      });
    }
  }

  const checkResolution = async (image) => {
    try {
      const img = new Image();
      img.src = URL.createObjectURL(image);

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const {naturalWidth: width, naturalHeight: height} = img;
      const landscapeWidth = 972;
      const landscapeHeight = 1458;
      const portraitWidth = 1458;
      const portraitHeight = 972;

      let isValid = false;

      if ((width === landscapeWidth && height === landscapeHeight) ||
        (width === portraitWidth && height === portraitHeight)) {
        isValid = true;
      }

      return isValid;
    } catch (error) {
      // Handle image loading or validation errors
      console.error('Error validating image resolution:', error);
      return false;
    }
  };

  const uploadImage = async (e, type, orientation) => {
    try {
      const isValid = await checkResolution(e.target.files[0])
      if (!isValid) {
        message.open({
          type: "error",
          content: "Image resolution does not match"
        })
      } else {
        setLoading((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            [orientation]: true,
          },
        }))
        const data = new FormData()

        data.append("lightImage", e.target.files[0])
        const upload = await axios.post("/upload-frame", data)
        const frameUrl = upload.data.frameUrl;

        if (type !== "topImg") {
          setUploadData((prevState) => ({
            ...prevState,
            [type]: {
              ...prevState[type],
              [orientation]: frameUrl,
            },
          }));
          if (blankCard && orientation === "topImg") {
            if (uploadData[type]["c"] === DEFAULTCARDURL && frameUrl) {
              setUploadData((prevState) => ({
                ...prevState,
                [type]: {
                  ...prevState[type],
                  ["c"]: frameUrl,
                },
              }));
            } else {
              setUploadData((prevState) => ({
                ...prevState,
                [type]: {
                  ...prevState[type],
                  ["c"]: DEFAULTCARDURL,
                },
              }));
            }
          }
        } else {
          setUploadData((prevState) => ({
            ...prevState,
            common: {
              ...prevState["common"],
              [orientation]: frameUrl,
            },
            rare: {
              ...prevState["rare"],
              [orientation]: frameUrl,
            },
            epic: {
              ...prevState["epic"],
              [orientation]: frameUrl,
            },
          }));
        }
      }
      setLoading((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          [orientation]: false,
        },
      }))
    } catch (error) {
      console.log(error, "<<-- Error in file upload")
      setLoading((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          [orientation]: false,
        },
      }))
    }
  }

  const handleOrientation = (eKey, e) => {
    setOrientation(e.target.innerText)
  }

  const handleBlankCheck = (e) => {
    setBlankCard(e.target.checked)
  }

  return (
    <>
      <div className="add-frame_header">
        <ArrowLeftOutlined key="arrow-left" style={{marginRight: "10px"}} onClick={() => history.goBack()}/>
        {frameId && PATHNAME.includes("card-details") ? "Card Details" : frameId ? "Edit Card" : "Add Card"}
      </div>
      <div className='addframe_body_partts'>
        <div className="add-frame addframe_body">
          <p style={{color: "#0dcaf0"}}>*** Image size should be 972x1458 for portrait and 1458x972 for landscape
            ***</p>
          <div className="choose_field choose_filder">
            <div className={"inputField"}>
              <Form.Label htmlFor="inputText">Card Name*</Form.Label>
              <input
                type="text"
                name="frames"
                id="inputText"
                disabled={PATHNAME.includes("card-details")}
                className="add_frame_txt text_box text_box1"
                value={frameName}
                placeholder="Card Name"
                onChange={(e) => {
                  setFrameName(e.target.value);
                }}
              />
            </div>
            <div className={"dropdownField"}>
              <Form.Label aria-required={"true"} htmlFor="inputText">Card Orientation*</Form.Label>
              <Dropdown onSelect={handleOrientation}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className={"dropdownBtn"}>
                  {orientation?.toUpperCase() || "Card Orientation"}
                </Dropdown.Toggle>
                {!PATHNAME.includes("card-details") &&
                  <Dropdown.Menu>
                    <Dropdown.Item>Portrait</Dropdown.Item>
                    <Dropdown.Item>Landscape</Dropdown.Item>
                  </Dropdown.Menu>
                }
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="check_blank_card">
          <Form.Check
            disabled={PATHNAME.includes("card-details")}
            type={"checkbox"}
            id={`default-checkbox`}
            label={`Is it a blank card?`}
            checked={blankCard}
            onChange={handleBlankCheck}
          />
        </div>

        <div className={"add-frame addframe_body"}>
          <h5 style={{color: "white"}}>Preview Card Image</h5>
          <div className="frame_image">
            <p className='frame-type'>{orientation?.toUpperCase() || "Portrait"}</p>
            <div className='typed_image typed_image_partts'>
              <div className='upload_image'>
                <h4>Common</h4>
                {!PATHNAME.includes("card-details") &&
                  <div className="upload_btn_wrapper">
                    <button disabled={loading.common.previewImg}
                            className="btn">{loading.common.previewImg ? "Loading..." : "Upload"}</button>
                    <input
                      disabled={loading.common.previewImg}
                      type="file"
                      accept='image/png, image/jpg, image/jpeg'
                      name="darkfile"
                      style={{cursor: "pointer"}}
                      onChange={(e) => {
                        uploadImage(e, "common", "previewImg")
                      }}
                    />
                  </div>
                }
                {uploadData.common.previewImg &&
                  <img loading={"lazy"} src={uploadData.common.previewImg} alt="Example"
                       className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
              </div>
              <div className='upload_image'>
                <h4>Epic</h4>
                {!PATHNAME.includes("card-details") &&
                  <div className="upload_btn_wrapper">
                    <button disabled={loading.epic.previewImg}
                            className="btn">{loading.epic.previewImg ? "Loading..." : "Upload"}</button>
                    <input
                      disabled={loading.epic.previewImg}
                      type="file"
                      accept='image/png, image/jpg, image/jpeg'
                      name="darkfile"
                      style={{cursor: "pointer"}}
                      onChange={(e) => {
                        uploadImage(e, "epic", "previewImg")
                      }}
                    />
                  </div>
                }
                <div className='text-center'>{uploadData.epic.previewImg &&
                  <img loading={"lazy"} src={uploadData.epic.previewImg} alt="Example"
                       className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}</div>
              </div>
              <div className='upload_image'>
                <h4>Legendary</h4>
                {!PATHNAME.includes("card-details") &&
                  <div className="upload_btn_wrapper">
                    <button disabled={loading.rare.previewImg}
                            className="btn">{loading.rare.previewImg ? "Loading..." : "Upload"}</button>
                    <input
                      disabled={loading.rare.previewImg}
                      type="file"
                      accept='image/png, image/jpg, image/jpeg'
                      name="darkfile"
                      style={{cursor: "pointer"}}
                      onChange={(e) => {
                        uploadImage(e, "rare", "previewImg")
                      }}
                    />
                  </div>
                }
                {uploadData.rare.previewImg &&
                  <img loading={"lazy"} src={uploadData.rare.previewImg} alt="Example"
                       className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
              </div>
            </div>
          </div>
        </div>

        <div className={"add-frame addframe_body"}>
          <h5 style={{color: "white"}}>Card Image</h5>
          {!blankCard ?
            <div className="frame_image">
              <p className='frame-type' style={{marginTop: "30px"}}>(1st layer) Background <small>(optional)</small></p>
              <div className='typed_image typed_image_partts'>
                <div className='upload_image'>
                  <h4>Common</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.common.cBg}
                              className="btn">{loading.common.cBg ? "Loading..." : "Upload"}</button>
                      <input
                        disabled={loading.common.cBg}
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "common", "cBg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.common.cBg &&
                    <img loading={"lazy"} src={uploadData.common.cBg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>

                <div className='upload_image'>
                  <h4>Epic</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.epic.cBg}
                              className="btn">{loading.epic.cBg ? "Loading..." : "Upload"}</button>
                      <input
                        disabled={loading.epic.cBg}
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "epic", "cBg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.epic.cBg &&
                    <img loading={"lazy"} src={uploadData.epic.cBg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>

                <div className='upload_image'>
                  <h4>Legendary</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.rare.cBg}
                              className="btn">{loading.rare.cBg ? "Loading..." : "Upload"}</button>
                      <input
                        disabled={loading.rare.cBg}
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "rare", "cBg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.rare.cBg &&
                    <img loading={"lazy"} src={uploadData.rare.cBg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
              </div>

              <p className='frame-type' style={{margin: "30px 0"}}>(2nd layer) Original Image</p>

              <h5 style={{color: "white", marginTop: "20px"}}>(3rd layer) Frame layer*</h5>
              <p className='frame-type'>{orientation?.toUpperCase() || "Portrait"}</p>
              <div className='typed_image typed_image_partts'>
                <div className='upload_image'>
                  <h4>Common*</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.common.c}
                              className="btn">{loading.common.c ? "Loading..." : "Upload"}</button>
                      <input
                        disabled={loading.common.c}
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "common", "c")
                        }}
                      />
                    </div>
                  }
                  {uploadData.common.c &&
                    <img loading={"lazy"} src={uploadData.common.c} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
                <div className='upload_image'>
                  <h4>Epic*</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.epic.c}
                              className="btn">{loading.epic.c ? "Loading..." : "Upload"}</button>
                      <input
                        disabled={loading.epic.c}
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "epic", "c")
                        }}
                      />
                    </div>
                  }
                  <div className='text-center'>{uploadData.epic.c &&
                    <img loading={"lazy"} src={uploadData.epic.c} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}</div>
                </div>
                <div className='upload_image'>
                  <h4>Legendary*</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.rare.c}
                              className="btn">{loading.rare.c ? "Loading..." : "Upload"}</button>
                      <input
                        disabled={loading.rare.c}
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "rare", "c")
                        }}
                      />
                    </div>
                  }
                  {uploadData.rare.c &&
                    <img loading={"lazy"} src={uploadData.rare.c} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
              </div>

              <p className='frame-type'>(4th layer) Set Text Co-ordinates <small>(Complete all the mandatory fields to
                continue)</small></p>
              <div className="up_submit_but">
                <Button
                  loading={isLoading}
                  className="upload_submit"
                  type="button"
                  disabled={isLoading}
                  onClick={() => PATHNAME.includes("card-details") ? history.push(`/frame-design/${frameId}`) : addFrame()}
                >
                  Set Text Co-ordinates
                </Button>
              </div>

              <p className='frame-type'>(5th layer) Top Image <small>(optional)</small></p>
              <div className='typed_image typed_image_partts'>
                <div className='upload_image'>
                  <h4>Common</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.common.topImg}
                              className="btn">{loading.common.topImg ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "common", "topImg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.common.topImg &&
                    <img loading={"lazy"} src={uploadData.common.topImg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
                <div className='upload_image'>
                  <h4>Epic</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.epic.topImg}
                              className="btn">{loading.epic.topImg ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "epic", "topImg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.epic.topImg &&
                    <img loading={"lazy"} src={uploadData.epic.topImg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
                <div className='upload_image'>
                  <h4>Legendary</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.rare.topImg}
                              className="btn">{loading.rare.topImg ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "rare", "topImg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.rare.topImg &&
                    <img loading={"lazy"} src={uploadData.rare.topImg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
              </div>
            </div>
            : <div className="frame_image">
              <h5 style={{color: "white"}}>(1st layer) Original Image</h5>
              <h5 style={{color: "white", marginTop: "20px"}}>(2nd layer) Frame layer</h5>
              <p className='frame-type'>{orientation?.toUpperCase() || "Portrait"}</p>
              <div className='typed_image typed_image_partts'>
                <div className='upload_image'>
                  <h4>Common*</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.common.c}
                              className="btn">{loading.common.c ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "common", "c")
                        }}
                      />
                    </div>
                  }
                  {uploadData.common.c &&
                    <img  loading={"lazy"} src={uploadData.common.c} alt="Example" className={`uploaded_image ${orientation.toLowerCase()==="landscape" && "landscape_image" }`}/>}
                </div>
                <div className='upload_image'>
                  <h4>Epic*</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.epic.c}
                              className="btn">{loading.epic.c ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "epic", "c")
                        }}
                      />
                    </div>
                  }
                  <div className='text-center'>{uploadData.epic.c &&
                    <img  loading={"lazy"} src={uploadData.epic.c} alt="Example" className={`uploaded_image ${orientation.toLowerCase()==="landscape" && "landscape_image" }`}/>}</div>
                </div>
                <div className='upload_image'>
                  <h4>Legendary*</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.rare.c}
                              className="btn">{loading.rare.c ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        accept='image/png, image/jpg, image/jpeg'
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "rare", "c")
                        }}
                      />
                    </div>
                  }
                  {uploadData.rare.c &&
                    <img  loading={"lazy"} src={uploadData.rare.c} alt="Example" className={`uploaded_image ${orientation.toLowerCase()==="landscape" && "landscape_image" }`}/>}
                </div>
              </div>

              {/*<p className='frame-type'>(5th layer) Top Image <small>(optional)</small></p>*/}
              {/* <h5 style={{color: "white", marginTop: "50px"}}>(2nd layer) Top Image <small>(optional)</small></h5>
              <div className='typed_image typed_image_partts'>
                <div className='upload_image'>
                  <h4>Common</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.common.topImg}
                              className="btn">{loading.common.topImg ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "common", "topImg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.common.topImg &&
                    <img loading={"lazy"} src={uploadData.common.topImg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
                <div className='upload_image'>
                  <h4>Epic</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.epic.topImg}
                              className="btn">{loading.epic.topImg ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "epic", "topImg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.epic.topImg &&
                    <img loading={"lazy"} src={uploadData.epic.topImg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
                <div className='upload_image'>
                  <h4>Legendary</h4>
                  {!PATHNAME.includes("card-details") &&
                    <div className="upload_btn_wrapper">
                      <button disabled={loading.rare.topImg}
                              className="btn">{loading.rare.topImg ? "Loading..." : "Upload"}</button>
                      <input
                        type="file"
                        name="darkfile"
                        style={{cursor: "pointer"}}
                        onChange={(e) => {
                          uploadImage(e, "rare", "topImg")
                        }}
                      />
                    </div>
                  }
                  {uploadData.rare.topImg &&
                    <img loading={"lazy"} src={uploadData.rare.topImg} alt="Example"
                         className={`uploaded_image ${orientation.toLowerCase() === "landscape" && "landscape_image"}`}/>}
                </div>
              </div> */}

              <p className='frame-type'>{!blankCard && "(3rd layer) Set Text Co-ordinates"}</p>
              <div className="up_submit_but">
                <Button
                  loading={isLoading}
                  className="upload_submit"
                  type="button"
                  disabled={isLoading}
                  onClick={() => PATHNAME.includes("card-details") ? history.push(`/frame-design/${frameId}?blankCard=${blankCard}`) : addFrame()}
                >
                  {!blankCard ? "Set Text Co-ordinates" : !PATHNAME.includes("card-details") && "Submit"}
                </Button>
              </div>
            </div>}
        </div>
      </div>
      {
        contextHolder
      }
    </>
  )
}

export default AddFrame