import React, { useEffect, useState } from "react";
//import useDidMountEffect from '../../CustomHooks/useDidMountEffect';
import { useParams } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import Switch from "react-switch";

import { No_ProfImg, User_Icon_Img } from "../../assets/images";
import "./useredit.css";
import axios from "../../axios/axios";

import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";
// import Select from "react-dropdown-select";
import { countries } from "../../callingCode";

function UserEditPage() {
  const { userId } = useParams();

  //states.....
  const [user, setUser] = useState({});
  const [states, setStates] = useState([]);
  const [err, setErr] = useState({});
  const [passErr, setPassErr] = useState({});
  const [password, setPassword] = useState({ password: "", confPassword: "" });
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState({ email: "", phone: "true" });
  const [isPrivate, setIsPrivate] = useState();

  // const [phoneerr, setPhoneerr] = useState("")

  const getStates = async () => {
    setLoading(true);
    await axios
      .get(`/field-list/states`)
      .then((res) => {
        if (res.data.success === true) {
          console.log("res", res.data.user);
          setStates(res.data.data.states)
          setLoading(false);
        } else {
          console.log("err", res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getStates err", err);
      });
  };
  const getUserDetails = async () => {
    setLoading(true);
    await axios
      .get(`/get-user-by-id/${userId}`)
      .then((res) => {
        console.log("getUserDetails res", res.data);
        if (res.data.success === true) {
          console.log("res", res.data.user);
          let userData = res.data.user;

          userData.callingCode = userData?.callingCode?.includes("+") ? `${userData?.callingCode}` : `+${userData?.callingCode}`
          setUser(userData);
          setProfile(res.data.user.userProfile);
          // setNum(re)
          setIsPrivate(res.data.user.userProfile.isPrivate);

          if (res.data.user.emailVerifyed === false) {
            setReadOnly({ ...readOnly, email: false });
          }
          if (res.data.user.phoneVerifyed === false) {
            setReadOnly({ ...readOnly, phone: false });
          }

          setLoading(false);
        } else {
          console.log("err", res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getUserDetails err", err);
      });
  };

  const submitDetails = async () => {
    console.log("profile", profile);
    let error = {};

    const data = {
      ...profile,
      email: user.email,
      phone: user.phone,
      callingCode: user.callingCode,
    };
    console.log(data);

    await axios
      .post(`/edit-user-profile-by-id/${userId}`, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.success === true) {
          setErr({});
          //console.log(res.data.data);
          message.success("Profile changed successfully");
        } else if (res.data.success === false) {
          res.data.errors?.map((item) => {
            error[item.param] = item.msg;
          });
          setErr(error);
        }
      })
      .catch((err) => {
        console.log("edit details", err);
      });
  };

  const savePassword = async () => {
    let error = {};
    await axios
      .patch(`/change-user-password/${userId}`, password)
      .then((res) => {
        //console.log(res.data);
        if (res.data.success === true) {
          setPassErr({});
          setPassword({ password: "", confPassword: "" });
          message.success("Password changed successfully");
        } else if (res.data.success === false) {
          res.data.errors?.map((item) => {
            error[item.param] = item.msg;
          });
          console.log(error);
          setPassErr(error);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // Make Profile Private function hide
  // const handleProfilePrivacy = async () => {
  //   const privacy = isPrivate;
  //   setIsPrivate(!isPrivate);
  //   await axios
  //     .patch(`/change-profile-privacy-by-user-id/${userId}`, {
  //       isPrivate: !privacy,
  //     })
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         console.log("success");
  //         message.success("Profile privacy updated");
  //       } else {
  //         message.error("Failed to update privacy");
  //         setIsPrivate(privacy);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("handleProfilePrivacy err", err);
  //       message.error("Server error");
  //       setIsPrivate(privacy);
  //     });
  // };
  useEffect(() => {
    getStates()
    getUserDetails();
  }, []);

  return (
    <div>
      <div className="dash_partts">
        <div className="settings_partts">
          <h2>Settings</h2>
          <div className="settings_inner">
            <div className="settings_inner_left">
              <LoadingOverlay active={loading} spinner>
                <div className="settings_user">
                  <img
                    src={profile?.thumbnailUrl ? profile.thumbnailUrl : No_ProfImg}
                    alt="No Img"
                  />
                  {/* <div className="edit_field">
                                    <div className="upload-btn-wrapper">
                                        <button className="btn"><img src={Edit_Icon_Img}/></button>
                                        <input type="file" name="myfile" />
                                    </div>
                                </div> */}
                </div>
              </LoadingOverlay>
              <div className="user_name_edit">
                <h3>
                  {user?.name}
                  <span className="user_iconnc">
                    <img src={User_Icon_Img} alt="No Img" />
                  </span>
                </h3>
                <h6>@{user?.userName}</h6>
                {/* <div className="user_badge_partts">
                                    <span><img src={Badge_11_Img} /></span>
                                    <span><img src={Badge_12_Img} /></span>
                                    <span><img src={Badge_13_Img} /></span>
                                    <span><img src={Badge_14_Img} /></span>
                                    <a href="#" className="edit_buttonn">Edit</a>
                                </div> */}
              </div>
            </div>
            {/* Make Profile Private button hide */}
            {/* <div className="settings_inner_right">
              <h3>Make Profile Private</h3>
              <Switch
                onChange={handleProfilePrivacy}
                //onChange={setIsPrivate(e)}
                checked={isPrivate}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={13}
                width={30}
                className="react-switch"
                id="material-switch"
              />
            </div> */}
          </div>
          <div className="pdetails">
            <Row>
              <Col lg="7" md="6" sm="12">
                <div className="pdetails_left">
                  <h3>Personal Details</h3>
                  <LoadingOverlay
                    active={loading}
                    spinner
                    text="please wait..."
                  >
                    <div className="pdetails_left_inner">
                      <Form>
                        <Row>
                          <Col lg="12" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <div className="icon_field">
                                <Form.Control
                                  type="email"
                                  placeholder=""
                                  className="text_field_parttss"
                                  value={user?.email}
                                  onChange={(e) =>
                                    setUser({ ...user, email: e.target.value })
                                  }
                                  readOnly={readOnly?.email}
                                />
                                {readOnly?.email ? (
                                  <span className="user_iconnc">
                                    <img src={User_Icon_Img} alt="No Img" />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <p style={{ color: "#ff4d4f" }}>{err.email}</p>
                            </Form.Group>
                          </Col>
                          <Col lg="12" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Phone</Form.Label>
                              <div className="icon_field">
                                <Row>
                                  <Col lg="4" md="6">
                                    <select
                                      className="text_field_parttss callCode"
                                      value={user.callingCode}
                                      onChange={(e) =>
                                        setUser({
                                          ...user,
                                          callingCode: e.target.value,
                                        })
                                      }
                                      disabled={readOnly?.phone}
                                    >
                                      {countries?.map((country) => (
                                        <option
                                          key={country.name}
                                          value={country.code}
                                          selected={
                                            user?.callingCode === country.code
                                              ? true
                                              : false
                                          }
                                        >
                                          {country.name} {country.code}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>
                                  <Col lg="8" md="6">
                                    <Form.Control
                                      type="tel"
                                      placeholder=""
                                      className="text_field_parttss phone_parts"
                                      value={user?.phone}
                                      onChange={(e) =>
                                        setUser({
                                          ...user,
                                          phone: e.target.value,
                                        })
                                      }
                                      readOnly={readOnly.phone}
                                    />
                                    {readOnly.phone ? (
                                      <span className="user_iconnc">
                                        <img src={User_Icon_Img} alt="No Img" />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              </div>
                              <p style={{ color: "#ff4d4f" }}>{err.phone}</p>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col lg="6" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Height</Form.Label>
                              <Row>
                                <Col lg="6" md="12">
                                  <Form.Control
                                    type="number"
                                    placeholder="feet"
                                    className="text_field_parttss"
                                    value={profile?.measurements?.height?.feet}
                                    onChange={(e) =>
                                      setProfile({
                                        ...profile,
                                        measurements: {
                                          ...profile.measurements,
                                          height: {
                                            ...profile.measurements?.height,
                                            feet: e.target.valueAsNumber,
                                          },
                                        },
                                      })
                                    }
                                  />
                                  <p style={{ color: "#ff4d4f" }}>
                                    {err["measurements.height.feet"]}
                                  </p>
                                </Col>
                                <Col lg="6" md="12">
                                  <Form.Control
                                    type="number"
                                    placeholder="inches"
                                    className="text_field_parttss"
                                    value={profile?.measurements?.height?.inch}
                                    onChange={(e) =>
                                      setProfile({
                                        ...profile,
                                        measurements: {
                                          ...profile.measurements,
                                          height: {
                                            ...profile.measurements.height,
                                            inch: e.target.valueAsNumber,
                                          },
                                        },
                                      })
                                    }
                                  />
                                  <p style={{ color: "#ff4d4f" }}>
                                    {err["measurements.height.inch"]}
                                  </p>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                          <Col lg="6" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Weight (lbs)</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder=""
                                className="text_field_parttss"
                                value={profile.measurements?.weight}
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    measurements: {
                                      ...profile.measurements,
                                      weight: e.target.valueAsNumber,
                                    },
                                  })
                                }
                              />
                            </Form.Group>
                            <p style={{ color: "#ff4d4f" }}>
                              {err["measurements.weight"]}
                            </p>
                          </Col>
                        </Row> */}

                        <Row>
                          <Col lg="6" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                className="text_field_parttss"
                                value={profile.address?.city}
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    address: {
                                      ...profile.address,
                                      city: e.target.value,
                                    },
                                  })
                                }
                              />
                            </Form.Group>
                            <p style={{ color: "#ff4d4f" }}>
                              {err["address.city"]}
                            </p>
                          </Col>
                          <Col lg="6" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>State</Form.Label>
                              <Form.Select
                                aria-label="Default select example form-control"
                                className="text_field_parttss callCode"
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    address: {
                                      ...profile.address,
                                      state: e.target.value,
                                    },
                                  })
                                }
                              >
                                <option value="">Select</option>
                                {states.map((s)=>{
                                  return <option value={s._id} selected={s._id==profile.address?.state}>{s.State}</option>

                                })}

                              </Form.Select>
                              {/* <Form.Control
                                type="text"
                                placeholder=""
                                className="text_field_parttss"
                                value={profile.address?.state}
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    address: {
                                      ...profile.address,
                                      state: e.target.value,
                                    },
                                  })
                                }
                              /> */}
                            </Form.Group>
                            <p style={{ color: "#ff4d4f" }}>
                              {err["address.state"]}
                            </p>
                          </Col>
                        </Row>

                        <Row className="align-items-center">
                          <Col lg="6" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                className="text_field_parttss"
                                value={profile.address?.country}
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    address: {
                                      ...profile.address,
                                      country: e.target.value,
                                    },
                                  })
                                }
                              />
                            </Form.Group>
                            <p style={{ color: "#ff4d4f" }}>
                              {err["address.country"]}
                            </p>
                          </Col>
                          <Col lg="6" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds "
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Gender </Form.Label>
                              <div className="custom_radio gender_partts">
                                <p>
                                  <input
                                    type="radio"
                                    id="test1"
                                    name="radio-group"
                                    checked={
                                      user.gender === "male" ? true : false
                                    }
                                  />
                                  <label for="test1">Male</label>
                                </p>
                                <p>
                                  <input
                                    type="radio"
                                    id="test2"
                                    name="radio-group"
                                    checked={
                                      user.gender === "female" ? true : false
                                    }
                                  />
                                  <label for="test2">Female</label>
                                </p>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" md="12">
                            <Form.Group
                              className="mb-3 edit_text_fielddds"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Bio</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="user_text_area"
                                value={profile.description}
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <p style={{ color: "#ff4d4f" }}>
                              {err.description}
                            </p>
                          </Col>
                        </Row>
                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" label="Check me out" />
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button> */}
                        <div className="save_changes">
                          <Button
                            variant="primary"
                            className="save_changes_but mt-2"
                            onClick={submitDetails}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </LoadingOverlay>
                </div>
              </Col>
              <Col lg="5" md="6" sm="12">
                <div className="text_password">
                  <h3>Change Password</h3>
                  <div className="change_pass_inner">
                    <Form.Group
                      className="mb-3 edit_text_fielddds"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Enter New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder=""
                        className="text_field_parttss"
                        value={password.password}
                        onChange={(e) =>
                          setPassword({ ...password, password: e.target.value })
                        }
                      />
                      <p style={{ color: "#ff4d4f" }}>{passErr.password}</p>
                    </Form.Group>

                    <Form.Group
                      className="mb-3 edit_text_fielddds"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder=""
                        className="text_field_parttss"
                        value={password.confPassword}
                        onChange={(e) =>
                          setPassword({
                            ...password,
                            confPassword: e.target.value,
                          })
                        }
                      />
                      <p style={{ color: "#ff4d4f" }}>{passErr.confPassword}</p>
                    </Form.Group>
                    <div className="save_changes">
                      <Button
                        variant="primary"
                        className="save_changes_but mt-2"
                        onClick={savePassword}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEditPage;
