import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";

import { Row, Col, Form, Dropdown } from "react-bootstrap";
import "./pushNotification.css";

import {
    Tusers1_img,
    Tusers2_img,
    Managesearch_img,
    Maneye_img,
    Threedots_img,
    Edit_img,
    Delete_img,
    Profw2_Img,
  } from "../../assets/images";

//   /push-notification

const PushNotification = () => {
    // const [search, setSearch] = useState("");
    const [allNotification, setAllNotification] = useState([]);
    const [search, setSearch] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState({ counts: false, dtable: false });
    
    const CustomLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>Loading...</div>
        </div>
      );
      
    const EmptyDataLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>No data found...</div>
        </div>
      );
      
      const getNotification = async() => {
        
        setLoading((prevState) => ({ ...prevState, dtable: true }));
        await axios.get(`/push-notification/?limit=${limit}&page=${page}&search=${search}`)
        .then((response) => {
          console.log("Push Notification data....", response.data);
          
          setLoading((prevState) => ({ ...prevState, dtable: false }));
            setAllNotification(response.data.getNotification);
            setTotalRows(response.data.pagination.totalRows);
        })
        .catch((error) => {
            console.log(error);
        })
    }
    
    useEffect(() => {
        getNotification();
    }, [page, limit, search]);
      
      const getUsers = () => {
        let pushNotification = [];
        allNotification.map((item, i) => {
          pushNotification.push({
            // id: item.id,
            title: (
              <div key={i+"A"} className="management_list_name">
                <div className="notification_alignment">{item.name.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>
              </div>
            ),
            // subject: <div className="notification_alignment">{item.subject}</div>,
            subject: <div key={i+"B"} className="notification_alignment">{item.subject.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</div>,
            // string.charAt(0).toUpperCase() + string.slice(1)
            // message: <div className="man_email" dangerouslySetInnerHTML={{__html: item.content}} ></div>,
            useKey: <div key={i+"C"} className="notification_alignment">{item.useKey.map((s,i)=> <div key={i} className="useKeyStyle"> {s}</div>)}</div>,
            action: (
              <div key={i+"D"} className="three_dotss">
                <Link to={`/edit-notification/${item._id}`} style={{color: "white"}} className="edit_icon_design">
                  <img alt="No Img" src={Edit_img} className="editMailStyle" />
                </Link>
              </div>
            ),
          });
        });
        return pushNotification;
      };
      
      
      const handlePageChange = (page) => {
        //console.log(page)
        setPage(page);
      };
      
      const handlePerRowsChange = (newPerPage, page) => {
        console.log(newPerPage, page);
        setLimit(newPerPage);
        setPage(page);
      };
  return (
    <React.Fragment>
        <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Notification Template List</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div>
              </div>

              <div className="manage_table">
                {/* <div className="loader_1"><BounceLoader loading={loading.dtable} size={60} /></div> */}
                <div className="table-responsive">
                  <DataTable
                  className="hypTable1"
                    columns={columns}
                    data={getUsers()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      // reorder: true,
      width: "250px",
    },
    // {
    //   name: "Message",
    //   selector: (row) => row.message,
    //   sortable: true,
      // reorder: true,
    //   width: "370px",
    // },
    {
      name: "Use Key",
      selector: (row) => row.useKey,
      width: "325px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      // reorder: true,
      width: "100px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        //minHeight: '72px',
        backgroundColor: "transparent",
        color: "#fff",
        paddingLeft: "10px", // override the row height
      },
    },
    headCells: {
      style: {
        // paddingLeft: "10px",
        paddingLeft: "25px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "16px",
      },
    },
    cells: {
      style: {
        paddingLeft: "4px", // override the cell padding for data cells
        paddingRight: "2px",
        paddingTop: "25px",
        // paddingTop: "5px,
        paddingBottom: "25px",
        // paddingBottom: "5px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "14px",
      },
    },
  };

export default PushNotification