import React, { useEffect, useState } from "react";

import { Row, Col, Dropdown, Button, Form } from "react-bootstrap";
import { findIndex } from "lodash";
import "./manageproduct.css";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import axios from "../../../axios/axios";
import InputControl from "../InputControl";

const columns = [
  {
    name: "Sl.",
    selector: (row) => row.sl,
  },
  {
    name: "Item",
    selector: (row) => row.item,
  },
  {
    name: "Size",
    selector: (row) => row.size,
  },
  {
    name: "Color",
    selector: (row) => row.color,
  },
  {
    name: "Quantity",
    selector: (row) => row.quantity,
  },
  {
    name: "Price",
    selector: (row) => row.price,
  },
  // {
  //   name: "Image",
  //   selector: (row) => row.image,
  // },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

function ManageProductInventory({ product, setKey }) {
  const [loading, setLoading] = useState(true);
  const [items, setItem] = useState([]);
  const [qty, setQty] = useState(0);
  const [prc, setPrc] = useState(0);
  const [target, setTarget] = useState(0);
  const token = useSelector((state) => state.setToken);
  // let productId = "63479f8529f4bc4602f8b574";
  let productId = product.productId;
  console.log("inventory prod before", productId);
  const [inventory, setInventory] = useState([]);
  const getInventory = async () => {
    console.log("inventory prod", productId);
    let data = {
      productId: productId,
    };
    await axios
      .post("/list-of-varierties-of-product", data, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("inventory res....", res.data);
        if (res.data.success === true) {
          setInventory(res.data.data);
          // setItem(res.data.data);
          //   setPagination(res.data.pagination);
          //   setNfts(res.data.allFundRaisers);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleItemChange = (id, e, name) => {
    setLoading(true);
    setTarget(id);
    console.log("inv._id == id", id);
    let index = findIndex(inventory, { _id: id });
    console.log("inventory[index]", e.target.value);
    if (name === "price") {
      inventory[index].price = Number(e.target.value);
      setPrc(e.target.value);
    } else {
      inventory[index].quantity = Number(e.target.value);
      setQty(e.target.value);
    }
    setLoading(false);
    setInventory(inventory);
    // items.push(inventory[index]);
    // setItem(items);

    console.log("inventory[index]", inventory[index]);

    // console.log("allUser",allUsers)
    // axios
    //   .post(`/toggle-status/${id}`, { item })
    //   .then((res) => {
    //     //console.log("res",res.data)
    //     if (res.data.success === true) {
    //       fetchUsers();
    //       getUsersCount();
    //     } else {
    //       console.log("error");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };
  const showState = () => {
    let items = inventory;
    console.log("item==", items);
    // console.log("allUser",allUsers)
    axios
      .post(`/add-product-details`, { items })
      .then((res) => {
        console.log("res", res.data);
        if (res.data.success === true) {
          // getInventory();
          setKey("finish");
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const data = () => {
    // console.log("inventory", inventory);
    let allInventory = [];
    inventory.length > 0 &&
      inventory.map((inv, index) => {
        let id = inv._id;
        // console.log("inventory", index);
        // console.log("inventory[id].quantity", inventory[index]);
        allInventory.push({
          sl: index + 1,
          item: inv.itemId,
          size: inv.variety.size,
          color: (
            <div
              style={{
                "background-color": inv.variety.color,
                width: "20px",
                height: "20px",
                "border-radius": "50px",
                margin: "0 auto",
              }}
            ></div>
          ),
          quantity: (
            // <InputControl
            //   value={qty}
            //   inv={inv}
            //   handleItemChange={handleItemChange}
            //   id={inv._id}
            // />
            <input
              type="number"
              value={target === inv._id ? qty : inv.quantity}
              // value={qty}
              className="tag"
              onChange={(e) => handleItemChange(inv._id, e, "quantity")}
            />
            /* {inv.quantity}
            </input> */
          ),
          price: (
            <input
              type="number"
              value={target === inv._id ? prc : inv.price}
              // value={inventory[index].price}
              className="tag"
              onChange={(e) => handleItemChange(inv._id, e, "price")}
            />
            /* ${inv.price}
            </div> */
          ),
          // image: (
          //   <label class="custom-file-upload">
          //     {/* <Button onClick={showState}></Button> */}
          //     <input type="file" />
          //     Choose file
          //   </label>
          // ),
        });
      });
    return allInventory;
  };

  useEffect(() => {
    getInventory();
  }, [loading]);
  return (
    <div>
      {console.log("inventory list", inventory)}
      <div className="dash_partts">
        <Row className="justify-content-center">
          <Col lg="12" md="12" sm="12" className="ps-0 pe-0">
            <DataTable
              className="hypTable1"
              columns={columns}
              data={data()}
              pagination
              responsive
              progressPending={loading}
              customStyles={customStyles}
              noDataComponent={<EmptyDataLoader />}
              progressComponent={<CustomLoader />}
            />
          </Col>
          <Col>
            <Button onClick={showState}>Save</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);

export default ManageProductInventory;
