import React, { useState } from "react";
import { Form, Modal, Row, Col, Button } from "react-bootstrap";
import message from "../../messages";
import axios from "../../axios/axios";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FundRaiserResumeDate = () => {
    
  const [show, setShow] = useState(false);

  const [resetDate, setResetDate] = useState({
    date: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  
  const validation = () => {
    let isValid = [];
    
    ////////// date
    // if (mailTemplateData.name === "") {
    //   setError((prevState) => ({...prevState, nameErr: "Name is required"}));
    //   isValid.push(false);
    // }else {
    //   setError((prevState) => ({...prevState, nameErr: ""}));
    //   isValid.push(true);
    // }
    
    if(isValid.includes(false))
        {
            return false
        }
        else
        {
            return true;
        }
  };
  
  const errorClearData = () => {
    setError({
      dateErr: "",
    })
  };
  
  const handleClose = () => {
    setShow(false);
    errorClearData();
  };
  const handleShow = () => setShow(true);
  
  
  const sendResumeDate = async (e) => {
    e.preventDefault();
    let validate = validation();
    console.log("Entered date and time: ", resetDate)
    
    console.log("check validation........", validate);
    
    if (validate) {
      setLoading(true);
      let dataurl = `/#`;
      axios.post(dataurl, resetDate)
      .then((res) =>{
        if(res.data.success === true){
          console.log("send-date response successfully...", resetDate);
          message.success("Create new date.");
          setShow(false);
          setLoading(false);
        } else {
          console.log("error");
          message.error("New Date Creation Failed");
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error("New Date send fail");
        console.log(error);
        setLoading(false);
      });
    }
  };
  
  const setStartDate = (startDate) => {
    setResetDate({
        ...resetDate,
        date: startDate
    })
  }

  return (
    <React.Fragment>
      
      <Modal show={show} onHide={handleClose} size="md">
        
      <div className="mail_container">
            <Modal.Header closeButton>
                <Modal.Title className="modal-title w-100 text-center">
                    Reset Fund Raisers Expiry Date
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>New Expiry Date</Form.Label>
                {/* <Form.Control
                    type="date"
                    name="name"
                    placeholder="Enter New Date"
                    className="text_box"
                    // onChange={(evt) => {
                    //   setMailTemplateData({
                    //   ...mailTemplateData,
                    //   name: evt.target.value,
                    // })
                    // if(!evt.target.value){
                    //   error.nameErr = "Name is required"
                    // }else{
                    //   error.nameErr = "";
                    // }
                    // setError(error);
                    // }}
                    
                /> */}
                <DatePicker
                    className="text_box"
                    selected={resetDate.date}
                    onChange={setStartDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="Set Time"
                    dateFormat="d MMMM yyyy, h:mm aa"
                />
                <p className="errorMessage">{error.nameErr}</p>
                </Form.Group>

                <div className="mailLoder">
                  <LoadingOverlay
                    active={loading}
                    spinner={
                      <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
                    }
                  >
                    <Button
                        type="submit"
                        onClick={sendResumeDate}
                        className="upload_submit border"
                        >
                        Submit
                    </Button>
                </LoadingOverlay>
                </div>
            </Form>
            </Modal.Body>
        </div>
      </Modal>

      <div>
        <button className="resume-date-border" onClick={handleShow}>
          Resume
        </button>
      </div>
      
    </React.Fragment>
  );
};

export default FundRaiserResumeDate;
