
// import moment from "moment-timezone";

let datteTimerender = (date)=>{
    let now = new Date(date)
    return now.getHours() + ':' + now.getMinutes() + ':' + (now.getSeconds().toString()).padStart(2,"0")
}

export {
    datteTimerender
}