import React from "react";
import { Navbar, Nav, Container, Form } from "react-bootstrap";
import { Link ,useHistory} from "react-router-dom";
import "./footer.css";
import axios from "../../../axios/axios";

const Footer = () => {

  return (
    <div className="footer_partttss">
        <div className="footer_inner_partts">
            <p>&copy; information goes here. All rights reserved.</p>
            <ul>
              {/* <Link className="link_agreement" to="/Privacy-Policy">Privacy Policy</Link> */}
              {/* <Link className="link_agreement" to="/terms-and-condition">Terms & Conditions</Link> */}
              <Link className="link_agreement" to="/about">About Us</Link>
              <Link className="link_agreement" to="/privacy">Privacy Policy</Link>
              <Link className="link_agreement" to="/terms-conditions">Terms & Conditions</Link>
              <Link className="link_agreement" to="/faq-list">FAQ</Link>
            </ul>
        </div>
    </div>
  );
};
export default Footer;
