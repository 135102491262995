import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../axios/axios";
import { BounceLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

import { Maneye_img, Love_s_Img, Date_Cal_Img } from "../../assets/images";
import "./storiesmore.css";
import DescriptionModal from "../modals/DescriptionModal";
import moment from 'moment-timezone';

function UserStories() {
  const { userId } = useParams();

  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStoriesByUser = async () => {
    setLoading(true);
    await axios
      .get(`/get-stories-by-user-id/${userId}`)
      .then((res) => {
        //console.log(res.data);
        if (res.data.success === true) {
          console.log(res.data.allStories);
          setStories(res.data.allStories);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getStoriesByUser err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getStoriesByUser();
  }, []);

  return (
    <div>
      <div className="dash_partts">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Stories</h2>
          </div>
        </div>
        {/* <LoadingOverlay active={loading} spinner={<BounceLoader color="white"/>}> */}
        <div className="nft_collect_body">
          <LoadingOverlay
            active={loading}
            spinner={<BounceLoader color="white" />}
          >
            <div className="nft_collect_inner_body">
              {stories?.map((item,index) => {
                return (
                  
                    <div className="nft_collct_inner_body_part" key={index}>
                      <div className="nft_collect_inner_partts">
                        <div className="nft_collect_top_img">
                          <img src={item?.images[0].imageUrl} alt="No Img" />
                        </div>
                        <div className="nft_collect_bottom_details story_bottom">
                          <div className="nft_collect_details_left">
                            <div className="football_datee">
                              <div className="love_football">
                                {/* <img src={Love_s_Img} alt="No Img" /> */}
                                {/* <span>{item?.reactions[1]?.totalReacts}</span> */}
                              </div>

                              <div className="love_football">
                                {/* <img src={Date_Cal_Img} alt="No Img" />
                                <span>{item?.date}</span> */}
                              </div>
                            </div>
                            <div className="story_title">
                              <h5>Title: {" "}{item?.name}</h5>
                            </div>
                            <h6>{item?.tournament}</h6>
                            <p>
                              Description:{" "}
                              {item?.description?.length > 60 ?
                                <React.Fragment>
                                  {item?.description?.substring(0, 60)}
                                  <DescriptionModal sendDescription={item?.description} />
                                </React.Fragment>
                                : item?.description}
                            </p>
                            
                              <div className="view_but">
                                <Link to={`/storydetails/${item._id}`}>
                                  <img src={Maneye_img} alt="No Img" />
                                  View
                                </Link>
                              </div>
                            
                          </div>

                          {/*             Features under development              */}
                          {/* <div className="view_but story_but">
                            <a href="#"  title="Under development" style={{opacity:0.6,cursor:"not-allowed"}} >
                              <img src={Maneye_img} alt="No Img" />
                              View
                            </a>
                          </div> */}
                          <div className="user_create_date">
                            <span>Created at: {moment(item?.createdAt).format("MM-DD-YYYY")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                );
              })}
            </div>
          </LoadingOverlay>
        </div>
        {/* </LoadingOverlay> */}
      </div>
    </div>
  );
}

export default UserStories;
