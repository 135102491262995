import { ArrowLeftOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../axios/axios';

const AddWallet = () => {
	const history = useHistory()
	const location = useLocation()
	const [messageApi, contextHolder] = message.useMessage();

	const [wallet, setWallet] = useState({})

	useEffect(() => {
		setWallet(location.state)
	}, [location])

	const handleChange = (e) => {
		setWallet({ ...wallet, [e.target.name]: e.target.value })
	}

	const handleClick = (name, value) => {
		setWallet({ ...wallet, [name]: value })
	}

	const addWallet = async () => {
		if (!wallet?.name || !wallet?.id || !wallet?.mode || !wallet?.type || !wallet?.wallet_address) {
			message.open({
				type: "error",
				content: "Fields marked with asterisks are mandatory."
			})
		} else {
			let newWallet;

			if (wallet?._id) {
				let url = `/update-wallet/${wallet?._id}`
				newWallet = await axios.put(url, wallet)
			} else {
				let url = "/create-wallet"
				newWallet = await axios.post(url, wallet)
			}

			if (newWallet.data.success) {
				history.goBack()
			} else {
				message.open({
					type: "error",
					content: "Wallet create failed."
				})
			}
		}
	}

	return (
		<>
			<div className="add-frame_header">
				<ArrowLeftOutlined key="arrow-left" style={{ marginRight: "10px" }} onClick={() => history.goBack()} />
				{wallet?._id ? "Edit blockchain wallet" : "Add blockchain wallet"}
			</div>
			<div className='addframe_body_partts'>
				<div className="add-frame addframe_body">
					<div className="choose_field choose_filder">
						<div className={"inputField"}>
							<Form.Label htmlFor="inputText">Wallet Name*</Form.Label>
							<input
								required
								type="text"
								name="name"
								id="inputText"
								className="add_frame_txt text_box text_box1"
								value={wallet?.name}
								placeholder="Wallet Name"
								onChange={handleChange}
							/>
						</div>

						<div className={"inputField"}>
							<Form.Label htmlFor="inputText">Wallet id*</Form.Label>
							<input
								required
								type="number"
								name="id"
								id="inputText"
								className="add_frame_txt text_box text_box1"
								value={wallet?.id}
								placeholder="Wallet id"
								onChange={handleChange}
							/>
						</div>
					</div>

					<div className="choose_field choose_filder">
						<div className={"inputField"}>
							<Form.Label htmlFor="inputText">Type*</Form.Label>
							<input
								required
								type="number"
								name="type"
								id="inputText"
								className="add_frame_txt text_box text_box1"
								value={wallet?.type}
								placeholder="Wallet type"
								onChange={handleChange}
							/>
						</div>

						<div className={"inputField"}>
							<Form.Label htmlFor="inputText">Wallet address*</Form.Label>
							<input
								required
								type="text"
								name="wallet_address"
								id="inputText"
								className="add_frame_txt text_box text_box1"
								value={wallet?.wallet_address}
								placeholder="Wallet address"
								onChange={handleChange}
							/>
						</div>
					</div>

					<div className="choose_field choose_filder">
						<div className={"inputField"}>
							<Form.Label htmlFor="inputText">Mode*</Form.Label>
							<DropdownButton className='dropdown-btn' title={wallet?.mode || "Mode"} id="dropdown-button-dark-example2" variant="secondary">
								<Dropdown.Item onClick={() => handleClick("mode", "sandbox")}>Sandbox</Dropdown.Item>
								<Dropdown.Item onClick={() => handleClick("mode", "production")}>Production</Dropdown.Item>
							</DropdownButton>
						</div>
						<div className={"inputField"}>
							<Form.Label htmlFor="inputText">Status</Form.Label>
							<div className="dropdown">
								<DropdownButton className='dropdown-btn' title={wallet?.status || "Status"} id="dropdown-button-dark-example2" variant="secondary">
									<Dropdown.Item onClick={() => handleClick("status", "old-wallet")}>Old Wallet</Dropdown.Item>
									<Dropdown.Item onClick={() => handleClick("status", "new-wallet")}>New Wallet</Dropdown.Item>
									<Dropdown.Item onClick={() => handleClick("status", "test-wallet")}>Test Wallet</Dropdown.Item>
								</DropdownButton>
							</div>
						</div>
					</div>
					<div className="up_submit_but">
						<Button
							// loading={isLoading}
							className="upload_submit"
							type="button"
							// disabled={isLoading}
							onClick={addWallet}
						>
							{"Submit"}
						</Button>
					</div>
				</div>

			</div>
			{
				contextHolder
			}
		</>
	)
}

export default AddWallet