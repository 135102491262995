import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import message from "../../messages";
import "./pushNotification.css";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../axios/axios";

const NotificationTemplate = (props) => {
    const { notificationId } = useParams();
    const history = useHistory();

    const [notificationTemplate, setNotificationTemplate] = React.useState({
        name: "",
        content: "",
        subject: "",
        useKey: "",
    });

    const handleClick = (evt) => {
        setNotificationTemplate({
            ...notificationTemplate,
            [evt.target.name]: evt.target.value,
        });
    };

    // get notification by id
    const getPushNotification = async () => {
        await axios
            .get(`/push-notification-by-id/${notificationId}`)
            .then((res) => {
                console.log("notification details......", res.data.viewNotification);
                setNotificationTemplate(res.data.viewNotification)
            })
            .catch((err) => {
                console.log("get-Push-Notification err", err);
            });
    };

    // update notification by id
    const updatePushNotification = async () => {
        let dataurl = `/push-notification/${notificationId}`;
        await axios.patch(dataurl, notificationTemplate)
            .then((resp) => {
                message.success(resp.data.message);
                history.push("/pushnotification")
            })
            .catch((error) => {
                console.log("notification-update error.....", error);
                message.error("edit notification fail");
            });
        console.log("updated data....", notificationTemplate)
    }

    useEffect(() => {
        getPushNotification()
    }, []);

    return (
        <React.Fragment>
            <div className='user_inner_partts'>
                <div className="sand_box_partts">
                    <h2>Edit Notification</h2>
                    <div className="setting_right_partts_inner">
                        <div className="notification_container">

                            <div className="notification_container stripe">
                                <div>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Name" className="notification_input_field" name="name" value={notificationTemplate.name} onChange={handleClick} />
                                </div>
                            </div>

                            <div className="notification_container stripe">
                                <div>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" placeholder="Title" className="notification_input_field" name="subject" value={notificationTemplate.subject} onChange={handleClick} />
                                </div>
                            </div>

                            <div className="notification_container stripe">
                                <div>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control type="text" placeholder="Message" className="notification_input_field" name="content" value={notificationTemplate.content} onChange={handleClick}></Form.Control>
                                </div>
                            </div>

                            <div className="notification_container stripe">
                                <div>
                                    <Form.Label>Use Key</Form.Label>
                                    <Form.Control type="text" placeholder="useKey" className="notification_input_field" readOnly name="useKey" value={notificationTemplate.useKey} onChange={handleClick} />
                                </div>
                            </div>

                            <div className='email-button-container'>
                                <div>
                                    <button type="submit" className="notification_update_button email-button" onClick={updatePushNotification}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default NotificationTemplate;