import React, { useEffect, useState } from "react";

import { Dropdown } from "react-bootstrap";
import Switch from "react-switch";

// import "./storyfeeds.css";

import {
  Story_User2_Img,
  Threedots_img,
  Delete_img,
  Maneye_img,
  Like1_Img,
  Noimg_Img,
} from "../../../assets/images";
import "./banner.css";
import axios from "../../../axios/axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStories } from "../../../actions/setStories";
import { findIndex } from "lodash";
import DataTable from "react-data-table-component";
import moment from "moment";

const Banner = () => {
  const [page, setPage] = useState("1");
  const [pageNew, setPageNew] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [totalRows, setTotalRows] = useState(0);
  const [banners, setBanners] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalPage: 7,
    totalRows: 0,
  });
  const [count, setCount] = useState("0");
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(true);

  let dispatch = useDispatch();

  const token = useSelector((state) => state.setToken);
  const fetchBanners = async () => {
    // console.log("page row chng", pageNew, pagination.limit);
    await axios
      .get(
        `/get-banners/?limit=${limit}&page=${pageNew}`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("get-banners res...", res.data);
        if (res.data.success === true) {
          setBanners(res.data.banners);
          setPagination(res.data.pagination);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchBanners();
  }, [pageNew, loading, limit, loadingCount]);
  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);
    setLimit(newPerPage);
    setPageNew(page);
  };

  const handleStatusChange = (id, status) => {
    setLoading(true);
    console.log("userId", id, status);
    let index = findIndex(banners, { _id: id });
    console.log("index", index);
    banners[index].active = !status;
    setBanners(banners);

    console.log("allUser", banners);
    axios
      .post("/toggle-banner-status", { id: id, status: status })
      .then((res) => {
        console.log("res", res.data);
        if (res.data.success === true) {
          //   fetchBanners();
          setLoading(false);
          //   getUsersCount();
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const deleteBanner = async (id) => {
    setLoading(true);
    // console.log("delete story=", id);
    let data = { id };
    await axios
      .post("/delete-banner", data, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("baner deleted", res.data);
        if (res.data.success === true) {
          setLoading(false);
          fetchBanners();
          //   fetchStoriesCount();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getUsersStories = () => {
    let userStories = [];
    banners.length > -1 &&
      banners.map((item, i) => {
        console.log("......", item);
        userStories.push({
          id: i,
          title: (
            <div className="management_list_name">
              <div className="man_user story_user">
                <img
                  alt={Noimg_Img}
                  src={item.imageUrl ? item.imageUrl : Noimg_Img}
                />
              </div>
              <div className="man_text story_text">
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
            </div>
          ),
          datecreated: [
            <div className="man_email">
              {" "}
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </div>,
          ],
          like: (
            <Switch
              checked={item.active}
              onChange={() => handleStatusChange(item._id, item.active)}
              onColor="#2e7b7f"
              onHandleColor="#01C1C1"
              handleDiameter={12}
              //uncheckedIcon={item.status}
              //checkedIcon={true}
              // checkedHandleIcon={true}
              // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={9}
              width={19}
              className="react-switch"
              id="material-switch"
            />
          ),
          // );
          // }),
          // action: [
          //   <div className="three_dotss">
          //     <Dropdown className="drop_partts">
          //       <Dropdown.Toggle variant="" id="dropdown-basic">
          //         <img src={Threedots_img} alt={Noimg_Img} />
          //       </Dropdown.Toggle>

          //       <Dropdown.Menu>
          //         {/* <Dropdown.Item href="#/action-1">
          //         <img src={Edit_img} /> Edit
          //       </Dropdown.Item> */}
          //         {/* <Dropdown.Item>
          //           <Link to={`/storydetails/${item._id}`}>
          //             <img src={Maneye_img} alt={Noimg_Img} />
          //             View
          //           </Link>
          //           {/* <img src={Maneye_img} /> View */}
          //         {/* </Dropdown.Item> */}
          //         <Dropdown.Item
          //           href="#/action-2"
          //           onClick={() => deleteBanner(item._id)}
          //         >
          //           <img src={Delete_img} alt={Noimg_Img} /> Delete
          //           {/* </button> */}
          //         </Dropdown.Item>
          //       </Dropdown.Menu>
          //     </Dropdown>
          //   </div>,
          // ],
        });
      });
    return userStories;
  };
  const changePage = (newPage) => {
    // console.log("newPage", newPage);
    setLoading(true);
    setPageNew(newPage);
  };

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );
  return (
    <div className="user_inner_partts email_partts">
      {/* <h3>Banners List</h3>
      <button className="add_button_set_align new_banner_btn">
        <Link to={`/add-banner`} style={{color:"#fff"}}>Add New Banner</Link>
      </button> */}
      
      {/* <div className="user_inner_partts">
        <div className="total_users_report">
          <div className="total_users_left">
            <h3>Banners List</h3>
          </div>
          <div className="total_users_right">
          <button className="add_button_set_align new_banner_btn">
            <Link to={`/add-banner`} style={{color:"#fff"}}>Add New Banner</Link>
          </button>
          </div>
        </div>
      </div> */}
      
      <div className="manage_heading_search">
        <h2>Banners List</h2>
        {/* <div className="add_new_promo"> */}
          <button className="add_button_set_align new_banner_btn">
            <Link to={`/add-banner`} style={{color:"#fff"}}>Add New Banner</Link>
          </button>
        {/* </div> */}
      </div>
      
      <div className="manage_table">
        <DataTable
        className="hypTable1"
          columns={columns}
          data={getUsersStories()}
          pagination
          responsive
          customStyles={customStyles}
          defaultSortField="id"
          defaultSortAsc={false}
          paginationDefaultPage={page}
          paginationServer
          paginationTotalRows={pagination.totalRows}
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[10, 20, 40]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={changePage}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent={<EmptyDataLoader />}
        />
        
        {/* <DataTable
        className="hypTable1"
          columns={columns}
          data={getUsersStories()}
          responsive
          customStyles={customStyles}
          defaultSortField="id"
          defaultSortAsc={false}
          noDataComponent={<EmptyDataLoader />}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          // paginationDefaultPage={page}
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[10, 20, 40]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={changePage}
          progressPending={loading}
          progressComponent={<CustomLoader />}
        /> */}
        
      </div>
    </div>
  );
};

const columns = [
  {
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    reorder: true,
    width: "400px",
  },
  {
    name: "Date Created",
    selector: (row) => row.datecreated,
    sortable: true,
    reorder: true,
    width: "250px",
  },

  {
    name: "Status",
    selector: (row) => row.like,
    sortable: true,
    reorder: true,
    width: "100px",
  },
  // {
  //   name: "Action",
  //   selector: (row) => row.action,
  //   sortable: true,
  //   reorder: true,
  //   width: "70px",
  // },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

export default Banner;
