/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import axios from "../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Switch from "react-switch";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { message } from "antd";

import {
  Managesearch_img,
  Man1_img,
  Maneye_img,
  Threedots_img,
  Edit_img,
  Delete_img,
  Man2_img,
  Man3_img,
  timeline,
} from "../../assets/images";
import ReportDownload from "../subComponents/ReportDownload";
import Card from "./Card";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);

const NftPart = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [nfts, setNfts] = useState([]);
  const [loadingCount, setLoadingCount] = useState(true);
  const [pagination, setPagination] = useState({ limit: 5, page: 1, totalPage: 7, totalRows: 0, });
  const [count, setCount] = useState({ totalSeller: 0, weeklyNfts: 0, totalBuyer: 0, });
  const [loading, setLoading] = useState(true);

  const token = useSelector((state) => state.setToken);

  const fetchNfts = async () => {
    await axios.get(`/all-nfts/?limit=${limit}&page=${page}&search=${search}`, { headers: { Authorization: token, }, }).then((res) => {
      if (res.data.success === true) {
        setPagination(res.data.pagination);
        setNfts(res.data.allNfts);
        setTotalRows(res.data.pagination.totalRows);
        setLoading(false);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  const fetchCount = async () => {
    await axios.get("/nft-count", { headers: { Authorization: token, }, }).then((res) => {
      if (res.data.success === true) {
        setCount(res.data);
        setLoadingCount(false);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  const data = {
    labels: ["Newly Created NFTs (weekly)", "Total Buyer Profile", "Total Seller Profile",],
    datasets: [
      {
        label: "# of Votes",
        data: [count.weeklyNfts, count.totalBuyer, count.totalSeller],
        backgroundColor: ["#30FFA8", "#FF4F4F", "#37ABFF", "#EEDD44"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const deleteNft = async (id) => {
    console.log("delete nft=", id);
    let data = { id };
    await axios.post("/delete-nft", data, { headers: { Authorization: token, }, }).then((res) => {
      if (res.data.success === true) {
        fetchNfts();
        fetchCount();
        message.success("NFT Deleted Successfully");
      } else {
        message.error("NFT Deletion Failed");
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  useEffect(() => {
    // console.log("page chng ue", page, limit);
    // if (!storiesCount) {
    fetchNfts();
    fetchCount();
    // } else {
    //   setLoading(false);
    // }
  }, [page, limit, search]);

  const data50 = () => {
    let allNfts = [];
    nfts && nfts.map((nft, index) => {
      return allNfts.push({
        id: index,
        image:(
          <div className="man_user">
              <img src={nft.fileUrl} alt="" />
            </div>
        ),
        name: (
          <div key={index+"A"} className="management_list_name">
            <div className="man_text">{nft.title!=""? nft.title : nft.creator.nick_name}</div>
          </div>
        ),
        author: [<div key={index+"B"} className="man_email">{nft.creator ? nft.creator.name : ""}
        <p><small>@{nft.creator ? nft.creator.userName : ""}</small></p>
        </div>],
        // create: (
        //   <div className="man_email">
        //     {" "}
        //     {moment(nft.updatedAt).format("YYYY/MM/DD")}
        //   </div>
        // ),
        create: (
          <div key={index+"C"} className="man_email">
            {" "}
            {moment(nft.createdAt).format("MM-DD-YYYY")}
          </div>
        ),
        // amount: (
        //   <div className="man_pricee" style={{marginLeft: "2.5rem"}}>
        //     ${" "}{nft.prices && nft.prices.amount}
        //   </div>
        // ),
        status: (
          <div key={index+"D"} className="man_pricee">
            {nft.activeStatus ? "Active" : "Inactive"}
          </div>
        ),
        // edition: <div className="man_email">{nft.editions}</div>,
        edition: (
          <div key={index+"E"} className="man_pricee" style={{ marginLeft: "4px" }}>{nft.editionTotal} </div>
          // <Switch
          //   checked={nft.activeStatus}
          //   //onChange={this.handleChange}
          //   onColor="#2e7b7f"
          //   onHandleColor="#01C1C1"
          //   handleDiameter={12}
          //   uncheckedIcon={false}
          //   checkedIcon={false}
          //   // checkedHandleIcon={true}
          //   // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          //   // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          //   height={9}
          //   width={19}
          //   className="react-switch"
          //   id="material-switch"
          // />
        ),
        action: (
          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item href="#/action-1">
                    <img src={Edit_img} /> Edit
                  </Dropdown.Item> */}
                <Link to={`/nftdetails/${nft._id}/${nft.NFT_identity}`}>
                  <img src={Maneye_img} alt="" /> View
                </Link>
                <Link to={{ pathname: `/timeline`, state: { NFT_identity: nft.NFT_identity, data: nft } }}>
                  <img src={timeline} alt="" />
                  <i className="fa-timeline"></i> View Timeline
                </Link>
                {/* <Dropdown.Item href="#/action-1">
                    <img src={Maneye_img} /> View
                  </Dropdown.Item> */}
                <Dropdown.Item onClick={() => deleteNft(nft._id)}>
                  <img src={Delete_img} alt="" /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      });
    });
    return allNfts;
  };

  const changePage = (page) => {
    setLoading(true);
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);
    setLimit(newPerPage);
    setPage(page);
  };

  return (
    <>
      <div className="nft_user_top_partts">
        <div className="nft_user_top_heading">
          <h2>NFTs</h2>
          <div className="nft_drop">
            <ReportDownload totalNftCount={totalRows} type="nft" />
            {/* Csvfields={["title", "name", "amount", "editions"]} */}
          </div>
        </div>
        <div className="nft_profiless" id="nftPDF">
          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="new_created_prf">
                <Card loading={loadingCount} title="Newly Created NFTs (weekly)" count={count.weeklyNfts} />
                {/* <Card title="Buyer" loading={loadingCount} count={count.totalBuyer} />
                <Card title="Seller" loading={loadingCount} count={count.totalSeller} /> */}

                <Card title="Total Nft Minted" loading={loadingCount} count={count.totalCardMinted} />
                <Card title="Total Editions Minted" loading={loadingCount} count={count.editionsMinted} />
                
                {/* <div className="created_inner_prf">
                      <div className="created_inner_inner_prf">
                        <h2>New Created Profile</h2>
                        <label>130</label>
                      </div>
                    </div>
                    <div className="created_inner_prf">
                      <div className="created_inner_inner_prf">
                        <h2>Deleted Profile</h2>
                        <label>30</label>
                      </div>
                    </div>
                    <div className="created_inner_prf">
                      <div className="created_inner_inner_prf">
                        <h2>Buy NFTs Profle</h2>
                        <label>120</label>
                      </div>
                    </div> */}
                {/* <div className="created_inner_prf">
                      <div className="created_inner_inner_prf">
                        <h2>Selling NFTs Profile</h2>
                        <label>100</label>
                      </div>
                    </div> */}
              </div>
            </Col>
            {/* <Col lg="6" md="12" sm="12">
              <div className="piechart_js">
                <div className="nft_pie">
                  <Pie data={data} options={options} />
                </div>
                <div className="nft_pie_right">
                  <div className="nft_iner_pie">
                    <div className="rounded_pie color1pie"></div>
                    <div className="inner_pie_text">
                      <h2>Newly Created NFTs (Weekly)</h2>
                      <label>{count.weeklyNfts}</label>
                    </div>
                  </div>
             

                 
                <div className="nft_iner_pie">
                    <div className="rounded_pie color2pie"></div>
                    <div className="inner_pie_text">
                      <h2>Total Nft Minted</h2>
                      <label>{count.totalCardMinted}</label>
                    </div>
                  </div>

                  <div className="nft_iner_pie">
                    <div className="rounded_pie color3pie"></div>
                    <div className="inner_pie_text">
                      <h2>Total Editions Minted</h2>
                      <label>{count.editionsMinted}</label>
                    </div>
                  </div>
                </div>


              </div>
            </Col> */}
          </Row>
        </div>
      </div>
      <div className="user_management_list">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>NFTs Lists</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    onChange={(e) => setSearch(e.target.value)}
                    className="manage_search_boxxx"
                  />
                  <span className="search_imgg_manage">
                    <img src={Managesearch_img} alt="" />
                  </span>
                </div>
              </div>
              <div className="manage_table table-responsive">
                <DataTable
                  className="hypTable1"
                  columns={columns}
                  data={data50()}
                  responsive
                  customStyles={customStyles}
                  defaultSortField="id"
                  defaultSortAsc={false}
                  noDataComponent={<EmptyDataLoader />}
                  pagination
                  paginationServer
                  // paginationTotalRows={count.totalNfts}
                  paginationTotalRows={totalRows}
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={[10, 20, 40]}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={changePage}
                  progressPending={loading}
                  progressComponent={<CustomLoader />}
                // paginationDefaultPage={page}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* </div> */}
    </>
  );
};

export default NftPart;

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      align: "start",
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
};

const columns = [
  {
    name: "",
    selector: (row) => row.image,
    // reorder: true,
    width: "50px",
  },
  {
    name: "Name of NFTs",
    selector: (row) => row.name,
    // reorder: true,
    width: "200px",
  },
  {
    name: "Author of NFTs",
    selector: (row) => row.author,
    // reorder: true,
    width: "200px",
  },

  {
    name: "Created Date",
    selector: (row) => row.create,
    // reorder: true,
    width: "125px",
  },
  // {
  //   name: "Amount of NFTs",
  //   selector: (row) => row.amount,
  //   // reorder: true,
  //   width: "150px",
  // },
  {
    name: "Status",
    selector: row => row.status,
    width: "125px"
  },
  {
    name: "Edition",
    selector: (row) => row.edition,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "100px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "16px",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "14px",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
