/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";

import { Row, Col, Form, Dropdown } from "react-bootstrap";
import "./emailTemplate.css";

import { Edit_img, Managesearch_img } from "../../assets/images";

import EmailTemplateModel from '../modals/EmailTemplateModel';

const EmailList = () => {

  // const [search, setSearch] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );

  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const getMail = async () => {
    await axios.get(`/mail-template/?limit=${limit}&page=${page}&search=${search}`).then((response) => {
      setAllEmail(response.data.getEmailTemplete);
      setTotalRows(response.data.pagination.totalRows)
      setLoading(false)
    })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    getMail();
  }, [page, limit, loading, search]);

  const getUsers = () => {
    let emailListData = [];
    allEmail.map((item, i) => {
      return emailListData.push({
        name: (
          <div key={i+"A"} className="management_list_name">
            <div className="notification_alignment">{item.name}</div>
          </div>
        ),
        subject: <div className="notification_alignment">{item.subject}</div>,
        content: <div className="notification_alignment" dangerouslySetInnerHTML={{ __html: item.content }}></div>,
        // useKey: <div className="notification_alignment">{item?.useKey}</div>,
        useKey: <div key={i+"B"} className="man_text">
          {
            item?.useKey.map((data, i) => {
              return (
                <div key={i} className='useKeyStyle'>{data}</div>
              )
            })
          }
        </div>,

        action: (
          <div key={i+"C"} className="three_dotss">
            <Link to={`/email-template/${item._id}`} style={{ color: "white" }} className="edit_icon_design">
              <img alt="No Img" src={Edit_img} className="editMailStyle" />
            </Link>
          </div>
        ),
      });
    });
    return emailListData;
  };


  const handlePageChange = (page) => {
    //console.log(page)
    setLoading(true)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLoading(true)
    setLimit(newPerPage);
    setPage(page);
  };
  return (
    <React.Fragment>
      <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">

              <div className="emailModel">
                {/* <EmailTemplateModel /> */}
              </div>

              <div className="manage_heading_search">
                <h2>Email Template List</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div>
              </div>

              <div className="manage_table">
                {/* <div className="loader_1"><BounceLoader loading={loading.dtable} size={60} /></div> */}
                <div className="table-responsive">
                  <DataTable
                    className="hypTable1"
                    columns={columns}
                    data={getUsers()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    // reorder: true,
    width: "175px",
  },
  {
    name: "Subject",
    selector: (row) => row.subject,
    // reorder: true,
    width: "175px",
  },
  {
    name: "Content",
    selector: (row) => row.content,
    // reorder: true,
    width: "350px",
  },

  {
    name: "UseKey",
    selector: (row) => row.useKey,
    // reorder: true,
    width: "145px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "95px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: "10px",
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
    },
  },
};

export default EmailList;