import React from "react";
import "./Nft.css";
import NftPart from "./NftPart";

const NFTPage = () => {
  return (
    <div className="home_new">
      {/* <NFTPart /> */}
      <NftPart />
    </div>
  );
};
export default NFTPage;
