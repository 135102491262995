import React, { useCallback, useEffect, useState } from 'react'
import WalletHeader from './WalletHeader'
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router-dom'
import axios from '../../axios/axios'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import chiaWallet from "../../assets/images/svg_images/chia.svg"
import LoadingOverlay from 'react-loading-overlay'
import { PulseLoader } from 'react-spinners'

const BlockchainWallet = () => {
	const history = useHistory()
	const [wallet, setWallet] = useState([])
	let [loading, setLoading] = useState(false);
	const [mode, setMode] = useState("");

	const CustomLoader = () => (
		<div
			style={{
				padding: "15px",
				background: "#495A71",
				color: "#fff",
				width: "100%",
				fontSize: "40px",
				textAlign: "center",
				minHeight: "350px",
				lineHeight: "400px",
			}}
		>
			<div>Loading...</div>
		</div>
	);

	const EmptyDataLoader = () => (
		<div style={{
			padding: '15px',
			background: '#495A71',
			color: '#fff',
			width: '100%',
			fontSize: '40px',
			textAlign: 'center',
			minHeight: '350px',
			lineHeight: '400px'
		}}>
			<div>No data found...</div>
		</div>
	);

	const getMode = async () => {
		setLoading(true)
		try {
			const setting = await axios.get(`/get-mode-details`)
			setMode(setting.data.details.mode)
			getWallets(setting.data.details.mode)
		} catch (error) {
			console.error(error, "<<-- Error in getMode")
		}
	}

	const getWallets = async (mode) => {
		// setLoading(true)
		try {
			const wallets = await axios.get(`/get-wallets?mode=${mode}`)
			if (wallets.data.success) {
				setWallet(wallets.data.wallets)
			}
		} catch (error) {
			console.error(error, "<<-- Error in get wallets")
		}
		setLoading(false)
	}

	useEffect(() => {
		getMode()
	}, [])

	const handleStatusChange = async (wallet) => {
		try {
			const bodyData = {
				activeStatus: !wallet.activeStatus,
				mode: wallet.mode
			}

			const result = await axios.put(`/update-wallet-status/${wallet._id}`, bodyData)

			if (result.data.success) {
				getWallets()
			}
		} catch (error) {
			console.error(error, "<<-- Error in wallet status change")
		}
	}

	const walletList = () => {
		let allWallets = []

		wallet && wallet.map((wallet, idx) => {
			allWallets.push({
				id: idx + 1,
				name: (
					<div className="management_list_name">
						<div className="man_user" style={{ position: "relative" }}>
							<img src={chiaWallet} height={30} width={30} alt="Card icon" style={{ objectFit: "contain", borderRadius: 0, position: "absolute" }} loading={"lazy"} />
						</div>
						<div className="man_text" style={{ width: "100%" }}>{wallet.name}</div>
					</div>
				),
				type: <div style={{ width: "100%" }}>{wallet.type}</div>,
				walletId: <div style={{ width: "100%" }}>{wallet.id}</div>,
				walletAddress: <div style={{ width: "80%" }}>{wallet.wallet_address}</div>,
				mode: <div style={{ width: "100%" }}>{wallet.mode}</div>,
				critical_error: <div style={{ width: "100%", color: wallet.critical_issue && "#ff8e8e", fontWeight: wallet.critical_issue && 800 }}>{wallet.critical_issue?.toString()}</div>,
				createdAt: <div style={{ width: "100%" }}>{moment(wallet.createdAt).format("L")}</div>,
				walletStatus: <div style={{ width: "100%" }}>{wallet.status || "--"}</div>,
				action: (
					<div className="action_btns-wallet">
						<Button variant="info" onClick={() => history.push(`/wallet-details/${wallet._id}?mode=${wallet.mode}`)}>View</Button>
						<Button variant="info" onClick={() => {
							// history.push(`/edit-wallet/${wallet._id}?mode=${wallet.mode}`)
							history.push({
								pathname: `/edit-wallet/${wallet._id}?mode=${wallet.mode}`,
								state: wallet
							})
						}}>Edit</Button>
						<Button variant={"success"} onClick={() => handleStatusChange(wallet)} style={{ width: !wallet.activeStatus && "5.4rem", visibility: wallet.activeStatus && "hidden" }}>Activate</Button>
						{/* <Button variant="danger">Delete</Button> */}
					</div>
				)
			})
		})
		return allWallets
	}

	return (
		<>
			<WalletHeader handleClick={(data) => {
				setMode(data)
				getWallets(data)
			}} mode={mode} onClick={() => {
				history.push("/add-wallet")
			}} />
			<div className="manage_table table-responsive framelist_table">
				<LoadingOverlay
					active={loading}
					spinner={<PulseLoader color="white" size={10} />}
				>
					<DataTable
						className="hypTable1"
						columns={columns}
						data={walletList()}
						responsive
						customStyles={customStyles}
						defaultSortField="_id"
						defaultSortAsc={false}
						noDataComponent={<EmptyDataLoader />}
						progressComponent={<CustomLoader />}
						progressPending={loading}
					/>
				</LoadingOverlay>
			</div>
		</>
	)
}

const columns = [
	{
		name: "Name of wallet",
		selector: (row) => row.name,
		reorder: true,
		width: "150px",
	},
	{
		name: "Type",
		selector: (row) => row.type,
		reorder: true,
		width: "80px",
	},
	{
		name: "Wallet ID",
		selector: (row) => row.walletId,
		reorder: true,
		width: "100px",
	},
	{
		name: "Wallet address",
		selector: (row) => row.walletAddress,
		reorder: true,
		width: "280px",
	},
	{
		name: "Mode",
		selector: (row) => row.mode,
		reorder: true,
		width: "120px",
	},
	{
		name: "Critical error",
		selector: (row) => row.critical_error,
		reorder: true,
		width: "120px",
	},
	{
		name: "Created at",
		selector: (row) => row.createdAt,
		reorder: true,
		width: "140px",
	},
	{
		name: "Wallet Status",
		selector: (row) => row.walletStatus,
		reorder: true,
		width: "130px",
	},
	{
		name: "Action",
		selector: (row) => row.action,
		// reorder: true,
		width: "280px",
	},
];

const customStyles = {
	rows: {
		style: {
			//minHeight: '72px',
			backgroundColor: "transparent",
			color: "#fff",
			paddingLeft: "10px", // override the row height
		},
	},
	headCells: {
		style: {
			paddingLeft: "10px", // override the cell padding for head cells
			paddingRight: "2px",
			backgroundColor: "transparent",
			fontSize: "16px",
			color: "#fff",
		},
	},
	cells: {
		style: {
			paddingLeft: "4px", // override the cell padding for data cells
			paddingRight: "2px",
			paddingTop: "8px",
			paddingBottom: "8px",
			backgroundColor: "transparent",
			fontSize: "14px",
			color: "#fff",
		},
	},
};

export default BlockchainWallet