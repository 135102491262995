import React, { useState } from 'react';
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { Maneye_img } from '../../assets/images';

const DescriptionModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    const handleShow = () => {
      setShow(true);
    };
    
  return (
    <React.Fragment>
    <Modal show={show} onHide={handleClose} size="lg" className="mail_modal">
        <div className="mail_template_container">
            <Modal.Header closeButton>
                <Modal.Title className="modal-title w-100 text-center">
                    Full Description
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
                >
                <Form.Label>Description</Form.Label>
                <Form.Control
                    className="text_box_textarea"
                    as="textarea"
                    rows={4}
                    name="comment"
                    placeholder='read full comments'
                    readOnly
                    value={props.sendDescription ? props.sendDescription : "Till Now Not Description..."}
                />
                {/* <p>{props.sendComment ? props.sendComment : "Till Now Not Commented..."}</p> */}
                </Form.Group>
            </Form>
            </Modal.Body>
        </div>
      </Modal>
    
      {/* <img alt="read more" className="manEyeStyle" onClick={handleShow} /> more... */}
      <span onClick={handleShow} className="fixMargin">{" "}more...</span>
    </React.Fragment>
  )
}

export default DescriptionModal;