import { useState } from "react";

import { Col, Container, Form, Row } from "react-bootstrap"
import FormInput from "./formInput"
import FormButton from "./formButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FilterByMonthYear(props) {
    let { filterSelected, filterHandale, filterSubmit } = props
    //  const currentdate = new Date();  
    //  console.log(currentdate.Year());
    const [date, setDate] = useState(new Date());

    return (
        <Container>
            <Row>
                <Col md={12}>
                    {/* <Form> */}
                    <Row>
                        <Col md={4}>
                            <label className="form-label" for="formBasicEmail">Month Year</label>
                            <DatePicker
                                className="dateRangebox form-control"
                                selected={filterSelected?.monthYear ?? ""}
                                onChange={(e) => filterHandale("monthYear", e)}
                                placeholderText="MMM-YYYY"
                                showMonthYearPicker
                                dateFormat={"MMM-yyyy"}
                            />
                            {/* <FormInput type="month" name="datepic" label="Start Date" value={date}
                onChange={(e) => setDate(e.target.value)}/>  */}
                        </Col>
                        <Col md="2">
                            <div className="mb-3">
                                <label className="form-label">&nbsp;</label>
                                <button className="form-control filter_submit btn" onClick={() => filterSubmit("monthYear")}>Filter</button>
                            </div>
                            {/* <FormButton addClass="filter_submit" type="submit" name="Filter" /> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <small style={{ color: "red" }}>{filterSelected?.monthYearErr}</small>
                        </Col>
                    </Row>
                    {/* </Form> */}
                </Col>
            </Row>
        </Container>
    )
}

export default FilterByMonthYear