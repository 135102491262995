import React from "react";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';

const DashboardCountCard = ({ imgSrc, title, count, loading }) => {
  return (
    <div className="active_inner_inner_partts">
      <LoadingOverlay
        active={loading}
        spinner={<PulseLoader color="white" size={10} />}
      >
        <div className="active_inner_list">
          <h2>{title}</h2>
          <div className="active_image_text">
            <div className="active_image">
              <img src={imgSrc} alt="No Img" />
            </div>
            <div className="active_text">{count || 0}</div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default DashboardCountCard;
