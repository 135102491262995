import React, { useState } from "react";

import { Row, Col, Form, Modal, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./tagstyle.css";
import "./manageproduct.css";
import axios from "../../../axios/axios";
// import {COUNTRIES} from './countries';
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function ManageAttributes({ values, setKey, setProduct, product }) {
  const [isActive, setActive] = useState([]);
  const token = useSelector((state) => state.setToken);
  const ToggleClass = (color) => {
    console.log("toggle", color);
    let arr = isActive;
    let id = color.id;
    if (isActive.includes(id)) {
      arr = arr.filter((i) => {
        return i != id;
      });
    } else {
      arr.push(id);
    }

    setActive(arr);
    console.log(id, arr);
  };
  let placeholder = "Add size";
  let [prodDT, setProdDT] = useState({
    attributes: "",
    type: "",

    selectedAttributes: [],
    tagAtt: [],
  });
  
  const [attributesErr, setAttributesErr] = useState({});
  
  const validate = () => {
    let isValid = [];
    
    // Attribute Name Error
    if(prodDT.attributes === ""){
      setAttributesErr((prevState) => ({...prevState, attributeErr: "Attribute Name is Required"}));
        isValid.push(false);
    }else {
        setAttributesErr((prevState) => ({...prevState, attributeErr: ""}));
        isValid.push(true);
    }
    
    // Attribute Value Error
    if(prodDT.type === ""){
      setAttributesErr((prevState) => ({...prevState, typeErr: "Attribute Value is Required"}));
        isValid.push(false);
    }else {
        setAttributesErr((prevState) => ({...prevState, typeErr: ""}));
        isValid.push(true);
    }
    
    if(isValid.includes(false))
    {
        return false
    }
    else
    {
        return true;
    }
  }
  
  console.log("display data of tag-attribute....", prodDT.tagAtt);

  const [tags, setTags] = React.useState([
    { id: "Thailand", text: "Thailand" },
    { id: "India", text: "India" },
    { id: "Vietnam", text: "Vietnam" },
  ]);
  // let suggestions = [
  //   { id: "Thailand", text: "Thailand" },
  //   { id: "India", text: "India" },
  //   { id: "Vietnam", text: "Vietnam" },
  // ];
  let suggestions = tags;
  console.log("product in att", product);
  const [sizeTags, setSizeTags] = React.useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleSizeDelete = (i) => {
    setSizeTags(sizeTags.filter((tag, index) => index !== i));
  };

  const handleTagChange = (e) => {
    setProdDT((prevState) => ({
      ...prevState,
      attributes: e.target.value,
    }));
    console.log("getSelctedColor", values);
    let getSelctedColor = values.filter(
      (value) => value.name === e.target.value
    );
    console.log("getSelctedColor", getSelctedColor[0]);
    let colorTag = [];
    let colorActiveTag = [];
    getSelctedColor[0].dataset.map((color, idx) => {
      colorTag.push({ id: color, text: color });
      colorActiveTag.push(color);
    });
    setTags(colorTag);
    setActive(colorActiveTag);
    console.log(isActive);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleSizeAddition = (tag) => {
    setSizeTags([...sizeTags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  const setAttributes = (e) => {
    e.preventDefault();
    let valid = validate();
    // setProduct((prevState) => ({
    //   ...prevState,
    //   attributes: prodDT.selectedAttributes,
    // }));
    // setKey("inventory");
    
    if(valid){
      console.log("attribute validate.... if part execute......");
      createInventory();
    }else{
      console.log("attribute validation error..... else part execute....");
    }
  };

  const createInventory = async () => {
    console.log("inventory prod", product);
    await axios
      .post(
        `/add-product`,
        product,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("inbentory res....", res.data);
        if (res.data.success === true) {
          setProduct((prevState) => ({
            ...prevState,

            productId: res.data.data.product_id,
          }));
          setKey("inventory");
          // setKey("attributes");
          // setOptions(res.data.data);
          //   setPagination(res.data.pagination);
          //   setNfts(res.data.allFundRaisers);
          //   setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const addAttribute = () => {
    console.log("prodDT.selectedAttributes", prodDT);

    let attributess = prodDT.selectedAttributes;
    let getSelctedAtt = attributess.filter(
      (value) => value.type === prodDT.attributes
    );
    let TagAtt = prodDT.tagAtt;
    console.log("getSelctedAtt", getSelctedAtt[0]);
    if (!getSelctedAtt[0]) {
      attributess.push({
        type: prodDT.attributes,
        value: prodDT.type,
      });
      TagAtt.push({
        id: prodDT.attributes,
        text: prodDT.type,
      });
      console.log(TagAtt);

      setProdDT((prev) => ({
        ...prev,
        tagAtt: TagAtt,
        selectedAttributes: attributess,
      }));
      setProduct((prevState) => ({
        ...prevState,
        attributes: attributess,
      }));

      // setTags(TagAtt);
    } else {
      console.log("already present");
    }
    // let colorTag = [];
    // let colorActiveTag = [];
    // getSelctedColor[0].dataset.map((color, idx) => {
    //   colorTag.push({ id: color, text: color });
    //   colorActiveTag.push(color);
    // });
    // setTags(colorTag);
    // setActive(colorActiveTag);
    // console.log(isActive)
  };

  return (
    <div>
      <Form.Label>You can add multiple Attributes</Form.Label>
      <div className="dash_partts">
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Attribute Name</Form.Label>
                  <div className="d-flex">
                    <Form.Select
                      aria-label="Default select example"
                      className="text_box"
                      value={prodDT.attributes}
                      onChange={(e) => handleTagChange(e)}
                    >
                      <option>Select</option>
                      {values &&
                        values.map((color, idx) => {
                          // console.log("size", values[0].size);
                          return <option key={idx}>{color.name}</option>;
                        })}
                    </Form.Select>
                  </div>
                  <p className="errorMessage">{attributesErr.attributeErr}</p>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Attribute Value</Form.Label>
                  <div className="d-flex">
                    <Form.Select
                      aria-label="Default select example"
                      className="text_box add_attribute"
                      value={prodDT.type}
                      onChange={(e) =>
                        setProdDT((prevState) => ({
                          ...prevState,
                          type: e.target.value,
                        }))
                      }
                      // onChange={(e) => handleTagChange(e)}
                    >
                      <option>Select</option>
                      {tags &&
                        tags.length &&
                        tags.map((color, idx) => {
                          // console.log("size", values[0].size);
                          return <option key={idx}>{color.text}</option>;
                        })}
                    </Form.Select>
                    <Button
                      variant="primary"
                      className="add_button"
                      onClick={addAttribute}
                    >
                      Add
                    </Button>
                  </div>
                  <p className="errorMessage">{attributesErr.typeErr}</p>
                </Form.Group>

                <ReactTags
                  tags={prodDT.tagAtt}
                  suggestions={tags}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  // inputFieldPosition="bottom"
                  placeholder={placeholder}
                  autocomplete
                />
                
                <hr />
              </Col>
            </Row>

            {/* <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Color</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    value={prodDT.color}
                    onChange={(e) => handleTagChange(e, "color")}
                  >
                    <option>Select</option>
                    {values.length > 0 &&
                      values[2].color &&
                      values[2].color.map((color, idx) => {
                        // console.log("size", values[0].size);
                        return <option key={idx}>{color.desc}</option>;
                      })}
                  </Form.Select>
                  <div className="color_box_wrapper">
                    {tags.map((color, id) => {
                      {
                        // console.log("tags", color);
                        console.log("isActive", isActive);
                      }

                      return (
                        <div
                          id={id}
                          style={{ "background-color": color.text }}
                          className={
                            isActive.includes(color.id)
                              ? "color_box active"
                              : "color_box"
                          }
                          onClick={() => {
                            ToggleClass(color);
                          }}
                        ></div>
                      );
                    })}
                    {/* <div
                      id="1"
                      style={{ "background-color": "red" }}
                      className={
                        isActive.includes("1")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="2"
                      style={{ "background-color": "yellow" }}
                      className={
                        isActive.includes("2")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="3"
                      style={{ "background-color": "blue" }}
                      className={
                        isActive.includes("3")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div> */}
            {/* </div>
                </Form.Group>
              </Col>
            </Row> */}

            {/* <ReactTags
              tags={tags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              placeholder={placeholder}
              autocomplete
            /> */}

            {/* <hr />

            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Size</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    value={prodDT.size}
                    onChange={(e) => handleTagChange(e, "size")}
                    // onChange={(e) =>
                    //   setProdDT((prevState) => ({
                    //     ...prevState,
                    //     size: e.target.value,
                    //   }))
                    // }
                  >
                    <option>Select</option>

                    {values.length > 0 &&
                      values[2].size &&
                      values[2].size.map((size, idx) => {
                        // console.log("size", values[0].size);
                        return (
                          <option
                            key={idx}
                            // onChange={setProdDT((prevState) => ({
                            //   ...prevState,
                            //   selectedSize: size.datasets,
                            // }))}
                          >
                            {size.desc}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <ReactTags
              tags={sizeTags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleSizeDelete}
              handleAddition={handleSizeAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              placeholder={placeholder}
              autocomplete
            /> */}
            {/* <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Color</Form.Label>
                  <div className="color_box_wrapper">
                    {console.log(isActive.includes("1"))}
                    <div
                      id="1"
                      style={{ "background-color": "red" }}
                      className={
                        isActive.includes("1")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="2"
                      style={{ "background-color": "yellow" }}
                      className={
                        isActive.includes("2")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="3"
                      style={{ "background-color": "blue" }}
                      className={
                        isActive.includes("3")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                  </div>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col md={12} className="text-center">
                <Form.Group
                  className="mb-3 pt-5 pb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Button
                    variant="primary"
                    onClick={setAttributes}
                    className="my_button"
                  >
                    Next
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ManageAttributes;
