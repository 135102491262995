import React, { useState } from 'react';
import DataTable from "react-data-table-component";
import { Maneye_img, Profw2_Img } from '../../assets/images';
import axios from "../../axios/axios";

const MakeOfferedDetails = () => {
    const [offerData, setOfferData] = useState([
        {
            offer_provider: "Hello World !",
            offer_price: "$ 120",
            status: "Accepted"
        }
    ])
    
    const showOfferDetails = () => {
        let offerDetails = [];
        offerData.map((data, index) => {
            offerDetails.push({
                offer_provider: (
                    <div className="management_list_name">
                    <div className="man_user">
                      <img
                        alt={Profw2_Img}
                        src={Maneye_img}
                      />
                    </div>
                    <div className="man_text">{data.offer_provider}</div>
                  </div>
                )
            })
        });
        return offerDetails;
    }
    
  return (
    <React.Fragment>
    <div className="manage_table">
        <div className="table-responsive">
            <DataTable
            className="hypTable1"
                columns={columns}
                data={showOfferDetails()}
                responsive
                customStyles={customStyles}
            />
        </div>
    </div>
    </React.Fragment>
  );
};

const columns = [
    {
        name: "Offer_Provider",
        selector: (row) => row.offer_provider,
        sortable: true,
        reorder: true,
        width: "250px"
    },
    {
        name: "Offered_Price",
        selector: (row) => row.offer_price,
        sortable: true,
        reorder: true,
        width: "200px"
    },
    {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        reorder: true,
        width: "150px"
    },
];

const customStyles = {
    rows: {
      style: {
        //minHeight: '72px',
        backgroundColor: "transparent",
        color: "#fff",
        paddingLeft: "10px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "transparent",
        color: "#fff",
      },
    },
    cells: {
      style: {
        paddingLeft: "4px", // override the cell padding for data cells
        paddingRight: "2px",
        paddingTop: "25px",
        paddingBottom: "25px",
        backgroundColor: "transparent",
        color: "#fff",
      },
    },
  };

export default MakeOfferedDetails;