import React, { useEffect, useState } from "react";
import CountCard from "../../subComponents/CountCard";
import ReportDownload from "../../subComponents/ReportDownload";
import { Row, Col, Dropdown, Table } from "react-bootstrap";
import { find } from "lodash";
import "./orders.css";

import {
  Story_User_Img,
  Story_User2_Img,
  Story_Man1_Img,
  Threedots_img,
  Edit_img,
  Delete_img,
  Maneye_img,
  Like1_Img,
  Dislike_Img,
  Story_Man2_Img,
  Story_Man3_Img,
  Story_Man4_Img,
  Noimg_Img,
} from "../../../assets/images";
import axios from "../../../axios/axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import moment from "moment";
import { message } from "antd";

const Orders = () => {
  const [page, setPage] = useState(1);
  // const [pageNew, setPageNew] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [stories, setStories] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalPage: 7,
    totalRows: 0,
  });
  console.log("....pagination",pagination)
  // const [count, setCount] = useState("0");
  const [loading, setLoading] = useState(true);
  // const [loadingCount, setLoadingCount] = useState(true);

  let dispatch = useDispatch();

  const token = useSelector((state) => state.setToken);
  const fetchStories = async () => {
    // console.log("page row chng", pageNew, pagination.limit);
    await axios
      .get(
        `/get-orders/?limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("Ordered List response...", res.data);
        if (res.data.success === true) {
          setStories(res.data.allOrders);
          setPagination(res.data.pagination);
          setTotalRows(res.data.pagination.totalRows)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchStories();
  }, [page, loading, limit]);
  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);

    setLimit(newPerPage);

    setPage(page);
  };
  
  ///////////////  Order deletion is working properly, but currently hidden
  // const deleteOrder = (orderId) => {
  //   console.log("order_Id....", orderId);
    
  //   let dataurl = `delete-merchandise-order/${orderId}`;
  //   axios.delete(dataurl)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         fetchStories();
  //         message.success("Order deleted successfully");
  //       } else {
  //         message.error("Order deletion failed");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Order deletion error...", err);
  //     });
  // };

  const getUsersStories = () => {
    let userStories = [];
    stories.map((item, i) => {
      userStories.push({
        id: i,
        orderId: (
          <div className="management_list_name">
            <div className="orderCell">{item.orderId}</div>
          </div>
        ),
        name: [
          <div>
            <h4>{item.user_id.name}</h4>
          </div>,
        ],
        amount: <div>{item.amount}</div>,
        quantity: <div>{item.quantity}</div>,
        // );
        // }),
        action: [
          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt={Noimg_Img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                  <Link to={`/order-details/${item._id}`}>
                    <img src={Maneye_img} alt={Noimg_Img} />
                    View
                  </Link>
                  
                  {/*  Order deletion is working, but temperory hidden this part  */}
                {/* <Dropdown.Item onClick={() => deleteOrder(item._id)} >
                  <img src={Delete_img} alt={Noimg_Img} /> Delete
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>,
        ],
      });
    });
    return userStories;
  };
  const changePage = (newPage) => {
    // console.log("newPage", newPage);
    setLoading(true);
    setPage(newPage);
    // // if (pageNew + 1 < pagination.totalPage) {
    // //   setPageNew(pageNew + 1);
    // // } else if (pageNew + 1 == pagination.totalPage) {
    // //   setPageNew(pagination.totalPage);
    // // } else {
    // //   setPageNew(pagination.totalPage);
    // // }
  };

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );
  return (
    <div>
      {/* <Leftpanel panelName="dashboard"/> */}
      {/* <div className="right_panel"> */}
      {/* <HeaderPart /> */}
      <div className="user_inner_partts">
        <div className="total_users_report">
          <div className="total_users_left">
            {/* <h2>Orders</h2> */}
          </div>
          <div className="total_users_right">
            <ReportDownload
              Csvfields={["orderId", "name", "amount", "quantity"]}
              totalOrderCount={totalRows}
              type="merchandiseOrder"
            />
          </div>
        </div>
      </div>

      <div className="user_management_list">
        <h3>Orders List</h3>
        <div className="manage_table">
          <DataTable
            className="hypTable1"
            columns={columns}
            //data={data45}
            data={getUsersStories()}
            pagination
            responsive
            customStyles={customStyles}
            defaultSortField="id"
            defaultSortAsc={false}
            paginationDefaultPage={page}
            paginationServer
            paginationTotalRows={pagination.totalRows}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={changePage}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            noDataComponent={<EmptyDataLoader />}
          />
        </div>
      </div>
    </div>
  );
};

const columns = [
  {
    name: "Order Id",
    selector: (row) => row.orderId,
    reorder: true,
    width: "250px",
  },
  {
    name: "Person Name",
    selector: (row) => row.name,
    reorder: true,
    width: "250px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    reorder: true,
    width: "200px",
  },

  {
    name: "Quantity",
    selector: (row) => row.quantity,
    reorder: true,
    width: "200px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    reorder: true,
    width: "100px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "15px",
      paddingBottom: "15px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

export default Orders;
