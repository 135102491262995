import React, { useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import message from "../../messages";
import axios from "../../axios/axios";

const SettingModal = () => {
  const [show, setShow] = useState(false);
  // const [mailDetails, setMailDetails] = useState("");
  const [creds, setCreds] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const [credsError, setCredsError] = useState({
    emailerr: "",
    subjecterr: "",
    messageerr: "",
  });
  
  // email validator
  const emailValidatior = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (creds.email.trim() === "") {
      setCredsError((prevState) => ({
        ...prevState,
        emailerr: "Email is required",
      }));
    } else if (reg.test(creds.email) === false) {
      setCredsError((prevState) => ({
        ...prevState,
        emailerr: "Email is invalid",
      }));
    } else {
      setCredsError((prevState) => ({ ...prevState, emailerr: "" }));
    }
  };

  // subject Validator
  const subjectValidator = () => {
    creds.subject.trim() === ""
      ? setCredsError((prevState) => ({
          ...prevState,
          subjecterr: "Subject is required",
        }))
      : setCredsError((prevState) => ({ ...prevState, subjecterr: "" }));
  };

  // message Validator
  const messageValidator = () => {
    creds.message.trim() === ""
      ? setCredsError((prevState) => ({
          ...prevState,
          messageerr: "message is required",
        }))
      : setCredsError((prevState) => ({ ...prevState, messageerr: "" }));
  };

  const handleChange = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    emailValidatior();
    subjectValidator();
    messageValidator();

    // console.log(".....", credsError);

    if ( credsError.emailerr === "" && credsError.subjecterr === "" && credsError.messageerr === "" )
    {
      await axios
        .post("/test-mail", creds)
        .then((res) => {
          // setMailDetails(res.data);
          console.log("send-mail response...", res.data);
          message.success("Mail send successfully.");
        })
        .catch((error) => {
          message.error("mail send fail");
          console.log(error);
        });
    }
  };

  // clear data on 'handleClose'
  const clearData = () => {
    setCreds({
      email: "",
      subject: "",
      message: "",
    });
  };
  const errorClearData = () => {
    setCredsError({
      emailerr: "",
      subjecterr: "",
      messageerr: "",
    });
  };

  const handleClose = () => {
    clearData();
    errorClearData();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg" className="mail_modal">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title w-100 text-center">
            Test Mail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>To Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={creds.email}
                onChange={handleChange}
                onBlur={emailValidatior}
                className="text_box"
                placeholder="name@example.com"
              />
              {/* {creds.email !== "" ? <p style={{ color: "red" }}>{credsError.emailerr}</p> : ""} */}
              <p style={{ color: "red" }}>{credsError.emailerr}</p>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={subjectValidator}
                // onTouch={subjectValidator}
                name="subject"
                value={creds.subject}
                className="text_box"
                placeholder="Subject"
              />
              <p style={{ color: "red" }}>{credsError.subjecterr}</p>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                className="text_box_textarea"
                as="textarea"
                rows={3}
                onChange={handleChange}
                onBlur={messageValidator}
                // onTouchEnd={messageValidator}
                name="message"
                value={creds.message}
              />
              <p style={{ color: "red" }}>{credsError.messageerr}</p>
            </Form.Group>

            <div className="up_submit_but">
              <Button
                className="upload_submit"
                type="submit"
                onClick={sendMail}
              >
                Send
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="addnew_modal">
        <button className="mail_modal_button" onClick={handleShow}>
          Test Mail
        </button>
      </div>
    </div>
  );
};

export default SettingModal;
