/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { Row, Col, Form, Dropdown, Modal, DropdownButton } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Switch from "react-switch";
import { findIndex } from "lodash";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import "./user.css";
import {
  Tusers1_img,
  Tusers2_img,
  Managesearch_img,
  Maneye_img,
  Threedots_img,
  Edit_img,
  // Delete_img,
  // Profw2_Img,
  level_setting,
  Icon5_img,
  No_ProfImg,
  // Noimg_Img
} from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../../actions/setUsers";
import { setUsersCount } from "../../actions/setUsersCount";
import { Link } from "react-router-dom";
import { message } from "antd";
import ReportDownload from "../subComponents/ReportDownload";
ChartJS.register(ArcElement, Tooltip, Legend);

function User() {
  let dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [active, setActive] = useState("");
  const [inActive, setInactive] = useState("");
  let [loading, setLoading] = useState({ counts: false, dtable: false });
  // const [loadingCount, setLoadingCount] = useState(true);
  const [show, setShow] = useState(false);
  // const [user_id, setUser_id] = useState("");
  const [userFilter, setUserFilter] = useState("")


  const fetchUsers = async (userFilter) => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));

    let url = `/get-users/?limit=${limit}&page=${page}&search=${search}`

    if (userFilter) {
      if (userFilter === "Active") {
        url = `${url}&status=active`
      } else if (userFilter === "Inactive") {
        url = `${url}&status=inactive`
      }
    }

    await axios
      .get(url)
      .then((res) => {
        console.log("all users data =======", res.data);
        // console.log(res.data.pagination);
        if (res.data.success === true) {
          dispatch(setUsers(res.data.users));
          setLoading((prevState) => ({ ...prevState, dtable: false }));
          setTotalRows(res.data.pagination.totalRows);
          setAllUsers(res.data.users);
        } else {
          console.log("error");
          setLoading((prevState) => ({ ...prevState, dtable: false }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getUsersCount = async () => {
    setLoading((prevState) => ({ ...prevState, counts: true }));
    await axios
      .get("/get-users-count")
      .then((res) => {
        if (res.data.success === true) {
          //console.log(res.data.count)
          dispatch(setUsersCount(res.data.count));
          setLoading((prevState) => ({ ...prevState, counts: false }));
        } else {
          console.log("error");
          setLoading((prevState) => ({ ...prevState, counts: false }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getAllUsersCount = async () => {
    setLoading((prevState) => ({ ...prevState, counts: true }));
    await axios
      .get("/get-all-count")
      .then((res) => {
        if (res.data.success === true) {
          //console.log(res.data.count)
          setActive(res.data.count?.totalActiveUsers);
          setInactive(res.data.count?.totalInActiveUsers);
          setLoading((prevState) => ({ ...prevState, counts: false }));
          // setLoadingCount(false);
        } else {
          console.log("error");
          setLoading((prevState) => ({ ...prevState, counts: false }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const deleteUser = async (e, userId) => {
  //   // setLoading((prevState) => ({ ...prevState, dtable: true }));
  //   //console.log(userId);
  //   e.preventDefault()
  //   await axios
  //     .delete(`delete-user-by-id/${userId}`)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         fetchUsers();
  //         getUsersCount();
  //         getAllUsersCount();
  //         // handleClose();
  //         // setLoadingCount(false);
  //         message.success("User deleted successfully");
  //         setShow(false);
  //       } else {
  //         message.error("User deletion failed");
  //         setLoading((prevState) => ({ ...prevState, dtable: false }));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("deleteUser err", err);
  //     });
  // };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };

  // const handleShow = (id) => {
  //   console.log("display current id...", id);
  //   // setShow(true);
  //   setUser_id(id);
  // }

  //let users = useSelector((state) => state.setUsers);
  let counts = useSelector((state) => state.setUsersCount);

  const getUsers = () => {
    let userDetails = [];
    allUsers.map((item, i) => {
      return userDetails.push({
        id: item.id,
        name: (
          <div className="management_list_name">
            <div className="man_user story_user division_image">
              <img src={item.userProfile?.thumbnailUrl ? item.userProfile?.thumbnailUrl : No_ProfImg} alt="" />
            </div>
            <div className="man_text story_text" style={{ marginLeft: "15px" }}>{item.name}</div>
          </div>
        ),
        username: <div className="man_username">{item.userName}</div>,
        email: <div>{item.email}</div>,
        phone: <div>{item.phone}</div>,
        action: (
          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt="No Img" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="DropdownMenu">
                <Link to={`/userdetails/${item.id}`}>
                  <img alt="No Img" src={Maneye_img} />
                  <span className="userActionAlign">View</span>
                </Link>
                {item.level !== null &&
                  <Link to={`UserLevelDetails/${item.id}`}>
                    <img alt="No Img" src={level_setting} />
                    View Level
                  </Link>}
                <Link to={`/view-wallet/${item.id}`}>
                  <img alt="No Img" src={Icon5_img} />
                  View Wallet
                </Link>
                <Link to={`/nfts-transaction/${item._id}`}>
                  <img alt="No Img" src={Maneye_img} />
                  <span className="userActionAlign">Nfts Transactions</span>
                </Link>
                <Link to={`/user-edit/${item.id}`}>
                  <img alt="No Img" src={Edit_img} />
                  <span className="userActionAlign">Edit</span>
                </Link>
                {/* <Dropdown.Item onClick={() => { setShow(true); handleShow(item.id) }}>
                  <img src={Delete_img} alt={Noimg_Img} />Delete
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        active: (
          <Switch
            checked={item.status}
            uncheckedIcon={item.status}
            onChange={() => handleStatusChange(item.id, item.status)}
            onColor="#2e7b7f"
            onHandleColor="#01C1C1"
            handleDiameter={12}
            //uncheckedIcon={item.status}
            //checkedIcon={true}
            // checkedHandleIcon={true}
            // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={9}
            width={19}
            className="react-switch"
            id="material-switch"
          />
        ),
      });
    });
    return userDetails;
  };
  const data = {
    labels: ["Active %", "Inactive %"],
    datasets: [
      {
        label: "% of Votes",
        radius: "100%",
        // cutout: 74,
        //data: [60,40],
        data: counts
          // ? [counts.activeUsersPercent, counts.inactiveUsersPercent]
          ? [(active / (active + inActive) * 100).toFixed(2), (inActive / (active + inActive) * 100).toFixed(2)]
          : [0, 0],
        backgroundColor: ["#01C1C1", "#FD9216"],
        borderColor: ["#01C1C1", "#FD9216"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    fetchUsers(userFilter);
    getUsersCount();
    getAllUsersCount();
    //getUsers()
  }, [page, limit, search, userFilter]);

  const handleStatusChange = (id, status) => {
    //console.log("userId",id,status);
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    let index = findIndex(allUsers, { id: id });
    allUsers[index].status = !status;
    setLoading((prevState) => ({ ...prevState, dtable: false }));
    setAllUsers(allUsers);
    // console.log("allUser",allUsers)
    axios
      .post(`/toggle-status/${id}`, { status: status })
      .then((res) => {
        //console.log("res",res.data)
        if (res.data.success === true) {
          message.success("Status changed successfully")
          fetchUsers();
          getUsersCount();
          getAllUsersCount();
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };
  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const handleUserFilter = (status) => {
    setUserFilter(status)
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="md">
        <div className="mail_container">
          <Modal.Header closeButton onClick={handleClose}>
            <Modal.Title className="modal-title w-100 text-center">
              Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                {/* <Form.Label>Alert</Form.Label> */}
                <p className="alert_p">Do you want to delete the user's records? </p>
              </Form.Group>

              <div className="deleteLoader d-flex justify-content-end">
                <LoadingOverlay
                  // active={loading}
                  spinner={
                    <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
                  }
                >
                  <div className='email-button-container'>
                    <div className="mx-3">
                      {/* <button type="submit" className="alert-button ml-0" onClick={(e) => deleteUser(e, user_id)}>Delete</button> */}
                    </div>
                    <div>
                      {/* <button type="submit" className="notification_update_button email-button" onClick={updateEmailDetail}>Update</button> */}
                      <button className="notification_update_button email-button" onClick={handleClose}>Cancel</button>
                    </div>
                  </div>
                </LoadingOverlay>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      {/* <div className='right_panel'> */}
      <div className="user_inner_partts">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="text-white">Users</h2>
          <div className="download_report">
            <Dropdown className="drop_partts121">
              <ReportDownload
                Csvfields={["name", "email", "userName", "phone", "status"]}
                totalUsercount={totalRows}
                totalSearch={search}
                type="user"
              />
            </Dropdown>
          </div>
        </div>
        <div className="total_users_report total_users_report_box">
          <div className="total_users_left">
            <div className="total_and_new_user fix_box" id="userPDF">
              <LoadingOverlay
                className="t_userers_adjust block_items margin_left"
                active={loading.counts}
                spinner={<PulseLoader color="white" size={10} />}
              >
                <div className="t_userers_size">
                  <div className="tusers_heading">
                    <h2>Active Users</h2>
                    <h4>{active ? active : 0}</h4>
                  </div>
                  <span>
                    <img alt="No Img" src={Tusers1_img} />
                  </span>
                </div>
              </LoadingOverlay>
              <LoadingOverlay
                className="t_userers_adjust"
                active={loading.counts}
                spinner={<PulseLoader color="white" size={10} />}
              >
                <div className="t_userers_size">
                  <div className="tusers_heading">
                    <h2>Newly Add Users</h2>
                    <h4>
                      {counts ? counts.monthlyUsers : 0}
                      <sub>(Monthly)</sub>
                    </h4>
                  </div>
                  <span>
                    <img alt="No Img" src={Tusers2_img} />
                  </span>
                </div>
              </LoadingOverlay>
              <LoadingOverlay
                className="t_userers_adjust"
                active={loading.counts}
                spinner={<PulseLoader color="white" size={10} />}
              >
                <div className="usermaageleft" id="userPDFChart">
                  {/* <div className="manage_heading_search">
                  <h2>User Count</h2>
                </div> */}
                  <div className="dognut_partts dognut_partt ">
                    <div className="show_header">
                      <h4>User Count</h4>
                    </div>
                    <Doughnut data={data} options={options} />
                    <div className="data_counts">
                      {active ? (active / (active + inActive) * 100).toFixed(0) : 0}%
                      {/* {active ? active : 0}% */}
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>

          </div>
          <div className="total_users_right">
          </div>
        </div>
      </div>

      <div className="user_management_list">
        <Row>
          <Col lg="12" md="12" sm="12">
            {/* <Col lg="8" md="12" sm="12"></Col> */}
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Users List</h2>
                <div className="d-flex gap-3">
                  <div className="userFilter">
                    <DropdownButton id="userFilter" variant="secondary" title={!userFilter ? "All" : userFilter} onSelect={handleUserFilter}>
                      <Dropdown.Item eventKey={"All"}>All</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey={"Active"}>Active</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey={"Inactive"}>Inactive</Dropdown.Item>
                    </DropdownButton>
                  </div>

                  <div className="manage_texfieldt">
                    <Form.Control
                      type="text"
                      placeholder="Search here.."
                      className="manage_search_boxxx"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="search_imgg_manage">
                      <img alt="No Img" src={Managesearch_img} />
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="manage_table manage_table_name"> */}
              {/* {
                allUsers.length > 0 ?
                <React.Fragment> */}
              <div className="manage_table">
                {/* <div className="loader_1"><BounceLoader loading={loading.dtable} size={60} /></div> */}
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    className="hypTable"
                    //data={data45}
                    data={getUsers()}
                    //data={allUsers}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
              {/* </React.Fragment> : ""
              } */}

            </div>
          </Col>
          {/* <Col lg="4" md="12" sm="12">
            <div className="usermaageleft" id="userPDFChart">
              <div className="manage_heading_search">
                <h2>User Count</h2>
              </div>
              <div className="dognut_partts">
                <Doughnut data={data} options={options} />
                <div className="data_count">
                  {counts ? counts.activeUsersPercent : 0}%
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
    // </div>
  );
}

export const options = {
  // responsive: true,
  padding: "0px",
  responsive: false,
  maintainAspectRatio: false,
  defaultFontSize: "14px",
  width: "400",
  height: "400",
  plugins: {
    legend: {
      labels: {
        color: ["#fff"],
      },
      weight: "20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
};

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    // reorder: true,
    width: "200px",
  },
  {
    name: "Username",
    selector: (row) => row.username,
    // reorder: true,
    width: "150px",
  },
  {
    name: "Email",
    selector: (row) => row.email,
    // reorder: true,
    width: "225px",
  },

  {
    name: "Phone No.",
    selector: (row) => row.phone,
    // reorder: true,
    width: "150px",
  },

  {
    name: "Active",
    selector: (row) => row.active,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "100px",
  },
];
const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px"
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px"
    },
  },
};

export default User;
