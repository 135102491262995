import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import { setDashboardCount } from "../../actions/setDashboardCount";

import "./dashboard.css";

import DashBoardPart from "./DashBoardPart";
import axios from "../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { setStoriesCount } from "../../actions/setStoriesCount";
import { setLineChart } from "../../actions/setLineChart";



import { setToken } from "../../actions/setToken";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const DashboardPage = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [topNft, setNft] = useState([]);
  const token = useSelector((state) => state.setToken);
  console.log(token, localStorage.getItem("AuthToken"));
  const [count, setCount] = useState({
    totalNftsNotSold: 0,
    totalNftsSold: 0,
  });

  const [userCount, setUserCount] = useState({
    totalUsers: 0,
    activeUsers: 0,
    inactiveUsers: 0,
  });

  const fetchDashbooardCount = async () => {
    await axios
      .get("/get-nft-count", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("dashboard nft count res", res.data);
        if (res.data.success === true) {
          setCount(res.data.counts);
          setLoading(false);
        } else {
          dispatch(setToken(""));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    await axios
      .get("/get-users-count", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("dashboard nft count res", res.data);
        if (res.data.success === true) {
          setUserCount(res.data.count);
          dispatch(setDashboardCount(res.data.count));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchStoriesCount = async () => {
    await axios
      .get("/all-story-count", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("stories count res", res.data);
        if (res.data.success === true) {
          dispatch(setStoriesCount(res.data.allStories));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchTopNfts = async () => {
    await axios
      .get("/top-nft", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log("topnft res", res.data);
        if (res.data.success === true) {
          dispatch(setNft(res.data.topNfts));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const monthlyRevenueChart = async () => {
    await axios
      .get("/monthly-revenue-chart", {
        headers: {
          Authorization: token,
        },
      })
      .then((res)=>{
        // console.log("res data chart",res.data);
        if(res.data.success === true) {
            // setLineChart(res.data);
            dispatch(setLineChart(res.data.data));
        }
    })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    fetchDashbooardCount();
    fetchStoriesCount();
    fetchTopNfts();
    monthlyRevenueChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="home_new">
      <DashBoardPart
        counts={count}
        userCount={userCount}
        loading={loading}
        topNfts={topNft}
      />
    </div>
  );
};
export default DashboardPage;
// class DashboardPart extends Component {
//     render() {
//         return (

//             );
//         }
//       }

export const options = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
  scales: {
    y: {
      ticks: { color: "#fff", beginAtZero: true },
    },
    x: {
      ticks: { color: "#fff", beginAtZero: true },
    },
  },
};

export const data2 = {
  labels: ["Active %", "Inactive %"],
  datasets: [
    {
      label: "# of Votes",
      data: [60, 40],
      backgroundColor: ["#01C1C1", "#FD9216"],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 0,
    },
  ],
};

export const options2 = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
  // scales: {
  //     y: {
  //       ticks: { color: '#fff', beginAtZero: true }
  //     },
  //     x: {
  //       ticks: { color: '#fff', beginAtZero: true }
  //     }
  // },
};
