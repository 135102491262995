import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import EasyCropper from 'react-easy-crop';
import getCroppedImg, { readFile } from '../../helpers/cropImage';
import "./ImageCroper.css"
import Minus from "../.../../../assets/images/minus.png"
import Plus from "../.../../../assets/images/plus.png"


const ImageCroper = (props) => {


    // const [image, setImage] = useState('https://hypeu-staging-api.s3.us-west-2.amazonaws.com/aboutUs/Mask%20Group%2048.png');
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);



    const handleZoomIn = () => {
        if (zoom < 3) {
            setZoom(zoom + 0.1 * 2);
        }
    };

    const handleZoomOut = () => {
        if (zoom > 1) {
            setZoom(zoom - 0.1 * 2);
        }
    };
    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    // useEffect(()=>{
    //   getProcessedImage()
    // },[croppedAreaPixels])


    const handleFileChange = async ({ target: { files } }) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
    };


    const getProcessedImage = async () => {
        // if (image && croppedAreaPixels) {
        if (image && croppedAreaPixels) {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
            const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
                type: 'image/png'
            });
            // console.log(imageFile)
            // const imageDataUrl = await readFile(imageFile);
            //     setImage(imageDataUrl);
            props.setImageFile(imageFile)
        }
    };

    return (<>
        <div className="text-center relative">
        {/* <input type="file" onChange={handleFileChange} style={{ margin: "30px", minWidth: "500px" }} /> */}
            <label for="file-upload" className="custom-file-upload">Upload Image</label>
            <input type="file" id="file-upload" className="input_upload_image typFile" onChange={handleFileChange} style={{width: "272px" }} />
            <div className="border border-dashed border-gray-200 p-6 rounded-lg">
                <div className="flex justify-center">
                    <div className="crop-container mb-4">
                        <EasyCropper
                            image={image || undefined}
                            crop={crop}
                            zoom={zoom}
                            // rotation={rotation}
                            cropShape="rect"
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            // onZoomChange={setZoom}
                            // setRotation={setRotation}
                            showGrid={false}
                            cropSize={{ width: 272, height: 408, "border-radius": "10px" }}
                            style={{
                                containerStyle: {
                                    width: 272,
                                    height: 408,
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='d-flex items-center justify-content-center gap-2 mb-3'>
                    <button className="p-1 plus_minus" onClick={handleZoomOut} >
                    <img src={Minus} className="minus"/>    
                    {/* <<i className="text-gray-400 w-4" >-</i>> */}
                    </button>
                    <input
                        type="range"
                        name="volju"
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={e => {
                            setZoom(Number(e.target.value));
                        }}
                    />
                    <button className="p-1 plus_minus" onClick={handleZoomIn}>
                        {/* <i className="text-gray-400 w-4" >+</i> */}
                        <img src={Plus} className="plus"/> 
                    </button>
                </div>


                <Button className="mb-2 w-full" variant="primary"  onClick={getProcessedImage}>
                    Crop & Select
                </Button>
            </div>
        </div>

    </>)

}

export default ImageCroper;