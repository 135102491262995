import React, { useEffect, useState } from "react";
import CountCard from "../../subComponents/CountCard";
import ReportDownload from "../../subComponents/ReportDownload";
import { Row, Col, Dropdown, Table } from "react-bootstrap";
import { find } from "lodash";
import "../../storyfeeds/storyfeeds.css";
import "./manageproduct.css";

import {
  Story_User_Img,
  Story_User2_Img,
  Story_Man1_Img,
  Threedots_img,
  Edit_img,
  Delete_img,
  Maneye_img,
  Like1_Img,
  Dislike_Img,
  Story_Man2_Img,
  Story_Man3_Img,
  Story_Man4_Img,
  Noimg_Img,
} from "../../../assets/images";
import axios from "../../../axios/axios";
import { Link, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import moment from "moment";

const Products = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  // const [pageNew, setPageNew] = useState(1);
  const [limit, setLimit] = useState(10);
  const [stories, setStories] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalPage: 7,
    totalRows: 0,
  });
  const [count, setCount] = useState("0");
  const [loading, setLoading] = useState(true);
  // const [loadingCount, setLoadingCount] = useState(true);

  let dispatch = useDispatch();

  const token = useSelector((state) => state.setToken);
  const fetchStories = async () => {
    // console.log("page row chng", pageNew, pagination.limit);
    await axios
      .get(
        `/all-products/?limit=${limit}&page=${page}`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("products res...", res.data);
        if (res.data.success === true) {
          setStories(res.data.data);
          setPagination(res.data.pagination);
          setCount(res.data.pagination.totalRows);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchStories();
  }, [page, limit, loading]);
  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);

    setLimit(newPerPage);

    setPage(page);
  };

  const getUsersStories = () => {
    let userStories = [];
    stories &&
      stories.map((item, i) => {
        userStories.push({
          id: i,
          title: (
            <div className="management_list_name">
              <div>{item.product_id}</div>
            </div>
          ),
          userName: [
            <div className="man_text story_text allign">
              <h4>{item.name}</h4>
            </div>,
          ],
          desc: [
            <div className="man_text story_text">
              <h4>{item.description}</h4>
            </div>,
          ],
          customer_gender: [
            <div className="man_text story_text">
              <h4>{item.customer_gender}</h4>
            </div>,
          ],
          // datecreated: [<div className="man_user story_user">{item.amount}</div>],
          // like: <div className="man_user story_user">{item.quantity}</div>,
          // );
          // }),
          //   action: [
          //     <div className="three_dotss">
          //       <Dropdown className="drop_partts">
          //         <Dropdown.Toggle variant="" id="dropdown-basic">
          //           <img src={Threedots_img} alt={Noimg_Img} />
          //         </Dropdown.Toggle>

          //         <Dropdown.Menu>
          //           {/* <Dropdown.Item href="#/action-1">
          //           <img src={Edit_img} /> Edit
          //         </Dropdown.Item> */}
          //           <Dropdown.Item>
          //             <Link to={`/order-details/${item._id}`}>
          //               <img src={Maneye_img} alt={Noimg_Img} />
          //               View
          //             </Link>
          //             {/* <img src={Maneye_img} /> View */}
          //           </Dropdown.Item>
          //           <Dropdown.Item
          //             href="#/action-2"
          //             //   onClick={() => deleteStory(item._id)}
          //           >
          //             <img src={Delete_img} alt={Noimg_Img} /> Delete
          //             {/* </button> */}
          //           </Dropdown.Item>
          //         </Dropdown.Menu>
          //       </Dropdown>
          //     </div>,
          //   ],
        });
      });
    return userStories;
  };
  const changePage = (newPage) => {
    // console.log("newPage", newPage);
    setLoading(true);
    setPage(newPage);
    // // if (pageNew + 1 < pagination.totalPage) {
    // //   setPageNew(pageNew + 1);
    // // } else if (pageNew + 1 == pagination.totalPage) {
    // //   setPageNew(pagination.totalPage);
    // // } else {
    // //   setPageNew(pagination.totalPage);
    // // }
  };

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );
  
  const goManageProduct = () => {
    history.push('/merchandiseproduct')
  }
  
  return (
    <div>
      {/* <Leftpanel panelName="dashboard"/> */}
      {/* <div className="right_panel"> */}
      {/* <HeaderPart /> */}
      <div className="user_inner_partts">
        <div className="total_users_report">
          <div className="total_users_left">
            <h2>Products List</h2>
          </div>
          <div className="total_users_right product-list-alignment">
            <ReportDownload
              listData={stories}
              Csvfields={[ "product_id", "name", "description", "customer_gender", ]}
              totalProductCount={count}
              type="merchandiseProduct"
            />
          </div>
        </div>
      </div>
      
      {/* <div className="add-button-align">
        <button className="add_button_set_align">
          <Link to={`/merchandiseproduct`} style={{ color: "#ffff" }}>
            Add New Product
          </Link>
        </button>
      </div> */}
      
      <div className="user_management_list">
      <div className="add-button-align">
        <button className="add_button_set_align" onClick={goManageProduct}>
          {/* <Link to={`/merchandiseproduct`} style={{ color: "#ffff" }}>
            Add New Product
          </Link> */}
          Add New Product
        </button>
      </div>
        <div className="manage_table">
          {/* <div className="table-responsive"> */}
            <DataTable
              className="hypTable1"
              columns={columns}
              data={getUsersStories()}
              pagination
              responsive
              customStyles={customStyles}
              defaultSortField="id"
              defaultSortAsc={false}
              paginationDefaultPage={page}
              paginationServer
              paginationTotalRows={pagination.totalRows}
              paginationPerPage={limit}
              paginationRowsPerPageOptions={[10, 20, 40]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={changePage}
              progressPending={loading}
              progressComponent={<CustomLoader />}
              noDataComponent={<EmptyDataLoader />}
            />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

const columns = [
  {
    name: "Product Id",
    selector: (row) => row.title,
    reorder: true,
    width: "300px",
  },
  {
    name: "Name",
    selector: (row) => row.userName,
    reorder: true,
    width: "250px",
  },
  {
    name: "Description",
    selector: (row) => row.desc,
    reorder: true,
    width: "250px",
  },

  {
    name: "Customer Gender",
    selector: (row) => row.customer_gender,
    reorder: true,
    width: "200px",
  },
  // {
  //   name: "Action",
  //   selector: (row) => row.action,
  //   reorder: true,
  //   width: "100px",
  // },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

export default Products;
