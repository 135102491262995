/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import message from "../../messages";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { No_ProfImg } from "../../assets/images";
import "./badges.css";
import BadgesModal from "../modals/BadgesModal";
import { BounceLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import ReportDownload from "../subComponents/ReportDownload";

function BadgesPage() {
  const [details, setDetails] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [badges, setBadges] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [render, setRender] = useState(false);
  let [loading, setLoading] = useState({ badges: false, dtable: false });

  const getUserDetails = async () => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    await axios.get(`/get-badges/?limit=${limit}&page=${page}`).then((res) => {
      if (res.data.success === true) {
        setTotalRows(res.data.pagination.totalRows);
        setDetails(res.data.userDetails);
        setLoading((prevState) => ({ ...prevState, dtable: false }));
      } else {
        setLoading((prevState) => ({ ...prevState, dtable: false }));
      }
    }).catch((err) => {
      console.log("getUserDetails err", err);
    });
  };

  const getAllBadges = async () => {
    setLoading((prevState) => ({ ...prevState, badges: true }));
    await axios.get("/get-all-badges").then((res) => {
      if (res.data.success === true) {
        setBadges(res.data.allBadges);
        setLoading((prevState) => ({ ...prevState, badges: false }));
        setRender(false);
      } else {
        setLoading((prevState) => ({ ...prevState, badges: false }));
        setRender(false);
      }
    }).catch((err) => {
      console.log(err);
      setLoading((prevState) => ({ ...prevState, badges: false }));
    });
  };

  const dataTableData = () => {
    let data = [];
    details?.map((item) => {
      return data.push({
        id: item?._id,
        name: (
          <div className="management_list_name">
            <div className="man_user story_user division_image">
              <img src={item?.userProfile?.thumbnailUrl ? item.userProfile.thumbnailUrl : No_ProfImg} alt="" />
            </div>
            <div className="man_text story_text division_text" style={{ marginLeft: "15px" }}>
              <h4>
                {item?.name}
              </h4>
            </div>
          </div>
        ),
        sport: (<div className="man_email division_email" style={{ marginLeft: "2rem" }}>{item?.sportsBadges}</div>),
        division: (<div className="man_email division_email" style={{ marginLeft: "4.5rem" }}>{item?.divisionsBadges}</div>),
        location: (<div className="man_email division_email" style={{ marginLeft: "2.5rem" }}>  {item?.locationsBadges}</div>),
        speciality: <div className="man_email division_email" style={{ marginLeft: "2.5rem" }}>0</div>,
        view: (
          <div className="action_view">
            <Link to={{ pathname: `/user-badges/${item?._id}`, state: { pic: item?.userProfile?.thumbnailUrl ? item.userProfile.thumbnailUrl : No_ProfImg, name: item?.name, }, }} style={{ color: "white" }} className="view_hover">
              {/* <img src={Maneye_img} /> */}
              <span style={{ fontSize: "14px" }}>View</span>
            </Link>
          </div>
        ),
      });
    });
    return data;
  };

  const renderBadgePage = (val) => {
    // console.log(val);
    setRender(val);
    message.success("new badge added");
  };

  useEffect(() => {
    getUserDetails();
    getAllBadges();
  }, [page, limit, render]);

  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };

  const CustomLoader = () => (
    <div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px", }}>
      <div>Loading...</div>
    </div>
  );

  const EmptyDataLoader = () => (
    <div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px", }}>
      <div>No data found...</div>
    </div>
  );

  return (
    <div>
      <div className="dash_partts">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Badges</h2>
          </div>
          <div className="nft_drop">
            <ReportDownload totalRows={totalRows} Csvfields={["name", "sportsBadges", "locationsBadges", "divisionsBadges",]} type="badges" />
          </div>
        </div>

        <div className="badge_listss">
          <div className="badges_list_heading nft_profiless">
            <h3>HypeU Badges List</h3>
            <BadgesModal renderBadgePage={renderBadgePage} />
          </div>
          {/* modal part code */}
          <LoadingOverlay active={loading.badges} spinner={<BounceLoader color="white" />}>
            <div className="badges_inner_lists">
              <ul>
                {
                  badges?.map((item) => (
                      <li>
                        <div className="badge_listtting">
                          <img src={item?.lightBadges ? item?.lightBadges : No_ProfImg} alt={item?.name} className="text-white" />
                        </div>
                      </li>
                  ))
                }
              </ul>
            </div>
          </LoadingOverlay>
        </div>
      </div>
      <div className="user_management_list">
        <h3>Badge Listing</h3>
        <div className="manage_table division_table">
          <DataTable
            className="hypTable1"
            columns={columns}
            data={dataTableData()}
            responsive
            customStyles={customStyles}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={loading.dtable}
            noDataComponent={<EmptyDataLoader />}
            progressComponent={<CustomLoader />}
          />
        </div>
      </div>
    </div>
  );
}

const columns = [
  {
    name: "User Name",
    selector: (row) => row.name,
    // reorder: true,
    width: "200px",
  },
  {
    name: "Sport Badges",
    selector: (row) => row.sport,
    // reorder: true,
    width: "120px",
  },

  {
    name: "Division and Team Badges",
    selector: (row) => row.division,
    // reorder: true,
    width: "215px",
  },
  {
    name: "Location Badge",
    selector: (row) => row.location,
    // reorder: true,
    width: "140px",
  },

  {
    name: "Speciality Badge",
    selector: (row) => row.speciality,
    // reorder: true,
    width: "140px",
  },

  {
    name: "Action",
    selector: (row) => row.view,
    // reorder: true,
    width: "95px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
    },
  },
};
export default BadgesPage;