import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../axios/axios";
import message from "../../messages";
import {
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";
import "./levelSetting.css";
import MintBalance from "./MintBalance";

const LevelSetting = () => {
    const { levelId } = useParams();
    const history = useHistory();

    const [setting, setSetting] = useState({});
    const [mint, setMint] = useState("");
    const [levelError, setLevelError] = useState({
        commonErr: "",
        rareErr: "",
        epicErr: "",
        editionErr: "",
        sale_1PercentErr: "",
        salePercentErr: "",
        sale_1HypeuErr: "",
        sale_1SellerErr: "",
        saleHypeuErr: "",
        saleMintorErr: "",
        saleSellerErr: "",
        leveljumperr: "",
        reset_type_err: "",
        monthly_reset_seq_err: "",
        monthly_reset_day_err: "",
        quarterly_reset_day_err: "",
        yearly_reset_day_err: "",

        common_reset_day:"",
        rare_reset_day:"",
        epic_reset_day:"",
        common_reset_sequence:"",
        rare_reset_sequence:"",
        epic_reset_sequence:"",
    });
    
    const levelValidation = () => {
        console.log("validation check",setting.editions)
        let isValid=[]
        if(mint?.common?.total === ""){
            setLevelError((prevState) => ({...prevState, commonErr: "Common Total allowed 0 or greater than 0"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, commonErr: ""}));
            isValid.push(true);
        }

        ////////////////////////////////////////
        if(mint?.rare?.total <0){
            setLevelError((prevState) => ({...prevState, rareErr: "Legendary Total allowed 0 or greater than 0"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, rareErr: ""}));
            isValid.push(true);
        }

        /////////////////////////////////
        if(mint?.epic?.total === ""){
            setLevelError((prevState) => ({...prevState, epicErr: "Epic Total allowed 0 or greater than 0"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, epicErr: ""}));
            isValid.push(true);
        }

        ///////////////////////
        if(setting.editions === ""){
            setLevelError((prevState) => ({...prevState, editionErr: "Edition is required"}));
            isValid.push(false);
        }

        else
        {
            setLevelError((prevState) => ({...prevState, editionErr: ""}));
            isValid.push(true);
        }

        ////////////////////////
        if(setting.sale_1?.hypeu === ""){
            setLevelError((prevState) => ({...prevState, sale_1HypeuErr: "Hypeu is required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, sale_1HypeuErr: ""}));
            isValid.push(true);
        }

        ////////////////
        if(setting.sale_1?.seller === ""){
            setLevelError((prevState) => ({...prevState, sale_1SellerErr: "Seller is required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, sale_1SellerErr: ""}));
            isValid.push(true);
        }

        //////////////////
        if(setting.sale?.hypeu === ""){
            setLevelError((prevState) => ({...prevState, saleHypeuErr: "Hypeu is required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, saleHypeuErr: ""}));
            isValid.push(true);
        }

        /////////////////////
        if(setting.sale?.mintor === ""){
            setLevelError((prevState) => ({...prevState, saleMintorErr: "Mintor is required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, saleMintorErr: ""}));
            isValid.push(true);
        }

        /////////////////////
        if(setting.sale?.seller === ""){
            setLevelError((prevState) => ({...prevState, saleSellerErr: "Seller is required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, saleSellerErr: ""}));
            isValid.push(true);
        }

        /////////////////////

        if(setting.nextLevel?.total === ""){
            setLevelError((prevState) => ({...prevState, leveljumperr: "Field is required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, leveljumperr: ""}));
            isValid.push(true);
        }

        /////////////////////
       
        if(mint.common.reset.type === "monthly" && mint.common?.reset?.sequence === ""){
            setLevelError((prevState) => ({...prevState, common_reset_sequence: "required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, common_reset_sequence: ""}));
            isValid.push(true);
        }

        //////////////////////
        if(mint.rare.reset.type === "monthly" && mint.rare?.reset?.sequence === ""){
            setLevelError((prevState) => ({...prevState, rare_reset_sequence: "required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, rare_reset_sequence: ""}));
            isValid.push(true);
        }

        ////////////////////////////
        if(mint.epic.reset.type === "monthly" && mint.epic?.reset?.sequence === ""){
            setLevelError((prevState) => ({...prevState, epic_reset_sequence: "required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, epic_reset_sequence: ""}));
            isValid.push(true);
        }
        

        /////////////////////
     
        
        if(mint.common?.reset?.day === ""){
            setLevelError((prevState) => ({...prevState, common_reset_day: "required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, common_reset_day: ""}));
            isValid.push(true);
        }

        /////////////////////
        if(mint.rare?.reset?.day === ""){
            setLevelError((prevState) => ({...prevState, rare_reset_day: "required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, rare_reset_day: ""}));
            isValid.push(true);
        }

        /////////////////////
        if( mint.epic?.reset?.day === ""){
            setLevelError((prevState) => ({...prevState, epic_reset_day: "required"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, epic_reset_day: ""}));
            isValid.push(true);
        }

        /////////////////////
        
        if((parseFloat(setting.sale_1?.hypeu )+ parseFloat(setting.sale_1?.seller)) !== 100){
            setLevelError((prevState) => ({...prevState, sale_1PercentErr: "First Sale Over all % should be 100"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, sale_1PercentErr: ""}));
            isValid.push(true);
        }

        /////////////////////
        if((parseFloat(setting.sale?.hypeu) + parseFloat(setting.sale?.mintor )+ parseFloat(setting.sale?.seller)) !== 100){
            setLevelError((prevState) => ({...prevState, salePercentErr: "Sale Over all % should be 100"}));
            isValid.push(false);
        }
        else
        {
            setLevelError((prevState) => ({...prevState, salePercentErr: ""}));
            isValid.push(true);
        }

        /////////////////////
 
        if(isValid.includes(false))
        {
            return false
        }
        else
        {
            return true;
        }
        
       
    };
    
    const clearError = () =>{
        setLevelError({
            commonErr: "", rareErr: "", epicErr: "", editionErr: "", sale_1HypeuErr: "", sale_1SellerErr: "", saleHypeuErr: "",
            saleMintorErr: "", sale_1PercentErr: "", salePercentErr: "", saleSellerErr: "", leveljumperr: "", reset_type_err: "",
            monthly_reset_seq_err: "", monthly_reset_day_err: "", quarterly_reset_day_err: "", yearly_reset_day_err: "", 
            common_reset_day:"",
            rare_reset_day:"",
            epic_reset_day:"",
            common_reset_sequence:"",
            rare_reset_sequence:"",
            epic_reset_sequence:""
        })
    }
      
    // get level-setting by id
    const getLevelSetting = async () => {
        await axios
          .get(`/get-level-setting-by-id/${levelId}`)
          .then((res) => {
            console.log("setting-level details", res.data.data);
            setSetting(res.data.data);
            setMint(res.data.data.mint);
          })
          .catch((err) => {
            console.log("setting-level err", err);
          });
      };
      
      useEffect(() => {
        clearError();
        getLevelSetting();
      }, []);
    //   useEffect(() => {
    //     levelValidation()
    //   }, [setting?.editions]);
      
      const updateLevelSetting = async (e) => {
        e.preventDefault();
        setSetting({...setting, mint});
        clearError();
        let valid = levelValidation();
        
        console.log("check validation....", valid);
        // return 
        
        if(valid){
            let dataurl = `/edit-level-setting-by-id/${levelId}`
            await axios.patch(dataurl, setting)
            .then((res) => {
                if(res.data.success === true) {
                    console.log("level-setting response...", res.data);
                    message.success("Update successfully.");
                    history.push('/level-setting');
                } else {
                    console.log("error");
                    message.error("Update fail.");
                }
            })
            .catch((error) => {
                console.log("level-setting update error", error);
            });
        }
        
        // console.log("After updation data...", setting);
      };
      
      
    return (
        <div>
            <div className="dash_partts">
                <Row>
                    <Col lg="12" md="12" sm="12" className="ps-0 pe-0">
                    <div className="setting_left_inner_partts">
                    <h2>Level: {setting?.level}</h2>
                        <div className="levelsetting">
                            <h5>Mint Balance</h5>
                            <Row className="mb-2  pt-3 pb-3">
                                <Col md={3}></Col>
                                <Col md={3} className="text-center main_head">Balance</Col>
                                <Col md={6} className="text-center main_head">Rest</Col>
                            </Row>
                            <Form>
                                <Row className="mail-first-row">
                                    <Col md={3}>
                                        <br/><br/>
                                        <label className="main_head">Common</label>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Total</Form.Label>
                                            <Form.Control
                                            type="number"
                                            className="text_box percentageInput"
                                            placeholder="total common"
                                            name="total"
                                            // value={mint?.common?.total>=0 ? mint?.common?.total : parseInt(0)}
                                            value={mint?.common?.total>=0 ? mint?.common?.total : mint?.common?.total === undefined ? 0 : parseInt()}
                                            required
                                            onChange={(e) =>{ 
                                                let {common} = mint;
                                                // common.total = (e.target.value>=0)? parseInt(e.target.value): parseInt(0);
                                                common.total = common.total === 0 || (e.target.value>0) ? parseInt(e.target.value) : parseInt(0);
                                                setMint({
                                                    ...mint,
                                                    common
                                                })
                                                setSetting({
                                                    ...setting,
                                                    mint
                                                })
                                                levelValidation()
                                                }
                                            }
                                        />
                                        {/* {mint.common?.total === "" ? <p className="errorMessage">{levelError.commonErr}</p> : ""} */}
                                        <p className="errorMessage">{levelError.commonErr}</p>
                                        </Form.Group>
                                    </Col>
                                    
                                    {mint?.common?.reset  && ( 
                                    <MintBalance sendMint={mint.common.reset}  // changes
                                        sequnceErr={levelError.common_reset_sequence}
                                        dayErr={levelError.common_reset_day}
                                    // receive change data from 'MintBalance'
                                        changeData = {(data) =>{ 
                                            console.log("data.....", data);
                                            let {common} = mint;
                                            common.reset = data;
                                            setMint({
                                                ...mint,
                                                common
                                            })
                                            setSetting({
                                                ...setting,
                                                mint
                                            })
                                            levelValidation()
                                            }
                                        }
                                    />
                                    )}
                                </Row>
                                
                                <Row>
                                    <Col md={3}>
                                        <br/><br/>
                                        <label className="main_head">Legendary</label>
                                        
                                        
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <label className="mb_2"></label>
                                            <Form.Control
                                                type="number"
                                                className="text_box percentageInput"
                                                placeholder="total Legendary"
                                                name="total"
                                                // value={mint?.rare?.total>=0 ? mint?.rare?.total : parseInt(0)}
                                                value={mint?.rare?.total>=0 ? mint?.rare?.total : mint?.rare?.total === undefined ? 0 : parseInt()}
                                                required
                                                onChange={(e) =>{ 
                                                    let {rare} = mint;
                                                    // rare.total = (e.target.value>=0)? parseInt(e.target.value): parseInt(0);
                                                    rare.total = rare.total === 0 || (e.target.value>0) ? parseInt(e.target.value) : parseInt(0);
                                                    setMint({
                                                        ...mint,
                                                        rare
                                                    })
                                                    setSetting({
                                                        ...setting,
                                                        mint
                                                    })
                                                    levelValidation()
                                                    }
                                                }
                                            />
                                            <p className="errorMessage">{levelError.rareErr}</p>
                                        </Form.Group>
                                    </Col>
                                    
                                    {mint?.rare?.reset  && ( 
                                    <MintBalance sendMint={mint.rare.reset}
                                        sequnceErr={levelError.rare_reset_sequence}
                                        dayErr={levelError.rare_reset_day}
                                    
                                        changeData = {(data) =>{ 
                                            let {rare} = mint;
                                            rare.reset = data;
                                            setMint({
                                                ...mint,
                                                rare
                                            })
                                            setSetting({
                                                ...setting,
                                                mint
                                            })
                                            levelValidation()
                                            }
                                        }
                                    />
                                    )}
                                </Row>
                                
                                <Row>
                                    <Col md={3}>
                                        <br/><br/>
                                        <label className="main_head">Epic</label>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <label className="mb_2"></label>
                                            <Form.Control
                                                type="number"
                                                className="text_box percentageInput"
                                                placeholder="total epic"
                                                name="total"
                                                value={mint?.epic?.total>=0 ? mint?.epic?.total : mint?.epic?.total === undefined ? 0 : parseInt()}
                                                required
                                                onChange={(e) =>{ 
                                                    let {epic} = mint;
                                                    epic.total = epic.total === 0 || (e.target.value>0) ? parseInt(e.target.value) : parseInt(0);
                                                    // if(e.target.value){
                                                    //  // epic?.total.remove()
                                                    //  // epic?.total.shift()
                                                    //  epic.total = (e.target.value>0)? parseInt(e.target.value): parseInt(0);
                                                    // }
                                                    // epic.total = (e.target.value>=0) ? parseInt(e.target.value) : mint?.epic?.total === 0 ? parseInt(e.target.value) : parseInt();
                                                    setMint({
                                                        ...mint,
                                                        epic
                                                    })
                                                    setSetting({
                                                        ...setting,
                                                        mint
                                                    })
                                                    levelValidation()
                                                    }
                                                }
                                            />
                                            <p className="errorMessage">{levelError.epicErr}</p>
                                        </Form.Group>
                                    </Col>
                                    {mint?.epic?.reset  && ( 
                                    <MintBalance sendMint={mint.epic.reset}
                                        sequnceErr={levelError.epic_reset_sequence}
                                        dayErr={levelError.epic_reset_day}
                                    
                                        changeData = {(data) =>{ 
                                            let {epic} = mint;
                                            epic.reset = data;
                                            setMint({
                                                ...mint,
                                                epic
                                            })
                                            setSetting({
                                                ...setting,
                                                mint
                                            })
                                            levelValidation()
                                            }
                                        }
                                    />
                                    )}
                                </Row>
                                
                                <div className="seperate_section">
                                    <Row>
                                        <Col md={3}>
                                            <br/>
                                            <label className="main_head">Edition</label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control 
                                                type="number" 
                                                placeholder="editions" 
                                                name="editions" 
                                                value={setting?.editions}
                                                required
                                                onChange={(e) => {
                                                    let editions = (e.target.value>0)? parseInt(e.target.value) : "";
                                                    console.log(editions)
                                                    setSetting({
                                                        ...setting,
                                                        editions
                                                    })
                                                   setTimeout(() => {
                                                    levelValidation()
                                                   }, 1000); 
                                                    
                                                    
                                                }
                                                }
                                                className="text_box percentageInput" />
                                                <p className="errorMessage">{levelError.editionErr}</p>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </div>
                                
                                <Row className="mb-2 pt-3 pb-3">
                                    <Col md={3}></Col>
                                    <Col md={9} className="text-center main_head">commission</Col>
                                </Row>

                                <Row>
                                    <Col md={3}>
                                        <br/>
                                        <label className="main_head">First Sale</label>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>HypeU</Form.Label>
                                            <Form.Control
                                                type="number"
                                                className="text_box parsentage_box percentageInput"
                                                placeholder="first hypeu"
                                                name="hypeu"
                                                value={setting?.sale_1?.hypeu}
                                                required
                                                onChange={(e) =>{ 
                                                    let {sale_1} = setting;
                                                    
                                                    // let {value} = e.target;
                                                    // if(value && value <= 100) {
                                                    //     sale_1.hypeu = value;
                                                    //     sale_1.seller = (100 - value);
                                                    // }
                                                    // else{
                                                    //     sale_1.hypeu = "";
                                                    //     sale_1.seller =100
                                                    // }
                                                    sale_1.hypeu =(e.target.value>0)? parseFloat(e.target.value): "";
                                                    // sale_1.seller = value <= 100 ?  100 - value : "";
                                                    setSetting({
                                                        ...setting,
                                                        sale_1
                                                    })
                                                    levelValidation()
                                                    }
                                                }
                                            />
                                            <p className="errorMessage">{levelError.sale_1HypeuErr}</p>
                                            <p className="errorMessage">{levelError.sale_1PercentErr}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Seller</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="text_box parsentage_box percentageInput"
                                                        placeholder="first seller"
                                                        name="seller"
                                                        value={setting?.sale_1?.seller}
                                                        required
                                                        onChange={(e) => {
                                                            let {sale_1} = setting;
                                                            // let {value} = e.target;
                                                            
                                                            // sale_1.seller = e.target.value;
                                                            sale_1.seller = (e.target.value>0)? parseFloat(e.target.value): "";
                                                            // sale_1.hypeu = value <= 100 ? 100 - value : "";
                                                            setSetting({
                                                                ...setting,
                                                                sale_1
                                                            })
                                                            levelValidation()
                                                        }
                                                            
                                                        }
                                                    />
                                                    <p className="errorMessage">{levelError.sale_1SellerErr}</p>
                                                    <p className="errorMessage">{levelError.sale_1PercentErr}</p>
                                                </Form.Group>
                                            </Col>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={3}>
                                        <br/>
                                        <label className="main_head">Sale</label>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>HypeU</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step="any"
                                                placeholder="HypeU_Sale"
                                                className="text_box parsentage_box percentageInput"
                                                name="hypeu"
                                                value={setting?.sale?.hypeu}
                                                required
                                                onChange={(e) =>{
                                                        let {sale} = setting;
                                                        sale.hypeu = (e.target.value>0)? parseFloat(e.target.value): "";
                                                        setSetting({
                                                        ...setting,
                                                        sale
                                                    })
                                                    levelValidation()
                                                    }
                                                }
                                            />
                                            <p className="errorMessage">{levelError.saleHypeuErr}</p>
                                            <p className="errorMessage">{levelError.salePercentErr}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Mintor</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        step="any"
                                                        placeholder="Mintor_Sale"
                                                        name="mintor"
                                                        value={setting?.sale?.mintor}
                                                        required
                                                        className="text_box parsentage_box percentageInput"
                                                        onChange={(e) =>{
                                                            let {sale} = setting;
                                                            sale.mintor = (e.target.value>0)? parseFloat(e.target.value): "";
                                                            setSetting({
                                                            ...setting,
                                                            sale
                                                        })
                                                        levelValidation()
                                                    }
                                                    }
                                                    />
                                                    <p className="errorMessage">{levelError.saleMintorErr}</p>
                                                    <p className="errorMessage">{levelError.salePercentErr}</p>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Seller</Form.Label>
                                                    <Form.Control
                                                    type="number"
                                                    step="any"
                                                    placeholder="Seller_Sell"
                                                    className="text_box parsentage_box percentageInput"
                                                    name="seller"
                                                    value={setting?.sale?.seller}
                                                    required
                                                    onChange={(e) =>{
                                                        let {sale} = setting;
                                                        sale.seller = (e.target.value>0)? parseFloat(e.target.value): "";
                                                        setSetting({
                                                            ...setting,
                                                            sale
                                                        })
                                                        levelValidation()
                                                    }
                                                    }
                                                />
                                                <p className="errorMessage">{levelError.saleSellerErr}</p>
                                                <p className="errorMessage">{levelError.salePercentErr}</p>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                </Row>

                                <div className="seperate_section">
                                    <Row>
                                        <Col md={3}>
                                            <br/>
                                            <label className="main_head">Next Level</label>
                                        </Col>
                                        <Col md={9}>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Type</Form.Label>
                                                            <Form.Select aria-label="Default select example"
                                                                onChange={(e) => {
                                                                    let {nextLevel} = setting;
                                                                    nextLevel.type = e.target.value;
                                                                    setSetting({
                                                                        ...setting,
                                                                        nextLevel
                                                                    })}
                                                                }
                                                                className="text_box">
                                                                <option value="">Select</option>
                                                                <option value="sellNft" selected={setting?.nextLevel?.type==="sellNft"}>sellNft</option>
                                                                <option value="sellNftPrice" selected={setting?.nextLevel?.type==="sellNftPrice"}>sellNftPrice</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                        
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    {setting?.nextLevel?.type==="sellNft" ? <Form.Label>Nft Count</Form.Label> : setting?.nextLevel?.type==="sellNftPrice" ? <Form.Label>Total $ Earning</Form.Label> : <Form.Label>Nft Count / Total $ Earning</Form.Label>}
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="level jump by Nft Count or $ Earn"
                                                            className="text_box"
                                                            name="total"
                                                            value={setting?.nextLevel?.total}
                                                            required
                                                            onChange={(e) =>{
                                                                let {nextLevel} = setting;
                                                                nextLevel.total = e.target.value;
                                                                setSetting({
                                                                    ...setting,
                                                                    nextLevel
                                                                })}
                                                            }
                                                    
                                                            // onChange={(e) => 
                                                            //     setSetting({
                                                            //         ...setting,
                                                            //         nextLevel: {
                                                            //             total: e.target.value
                                                            //         },
                                                            //     })
                                                            // }
                                                        />
                                                        <p className="errorMessage">{levelError.leveljumperr}</p>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                <Form.Group className="mb-3 pt-5 pb-2" controlId="exampleForm.ControlInput1">
                                    <Button variant="primary" type="submit" className="my_button" onClick={updateLevelSetting}>
                                        Update
                                    </Button>
                                </Form.Group>
                                
                            </Form>
                        </div>
                    </div>
                    </Col>
                </Row>
            </div>
        </div>
        );
    }
    
export default LevelSetting;