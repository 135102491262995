import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function ViewVideo({ show, handleClose, details }) {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Review video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <video src={details.videoUrl} alt="" autoPlay controls style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
            </Modal.Footer>
        </Modal>
    )
}
