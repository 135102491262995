import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Line, Doughnut } from "react-chartjs-2";
import moment from "moment";
import axios from "../../axios/axios";

import "./dashboard.css";
import {
  Active1_Img,
  Active2_Img,
  Active3_Img,
  Active4_Img,
  Active5_Img,
  Active6_Img,
  Sold1_Img,
  Sold2_Img,
  // Badge1_Img,
  // Common_3_Img,
} from "../../assets/images";
import ReportDownload from "../subComponents/ReportDownload";

import DashboardCountCard from "../subComponents/DashboardCountCard";
import { useSelector } from "react-redux";
import { CurrencyFormating } from "../../helpers/currencyHelper";
const DashBoardPart = ({ counts, loading, topNfts }) => {
  const dashboardCount = useSelector((state) => state.setDashboardCount);
  const storiesCount = useSelector((state) => state.setStoriesCount);
  const revenueLineChart = useSelector((state) => state.setLineChart);
  const [active, setActive] = useState("");
  const [inActive, setInactive] = useState("");

  const getAllUsersCount = async () => {
    await axios
      .get("/get-all-count")
      .then((res) => {
        if (res.data.success === true) {
          //console.log(res.data.count)
          setActive(res.data.count?.totalActiveUsers);
          setInactive(res.data.count?.totalInActiveUsers);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getAllUsersCount();
  }, []);

  console.log("....revenueLineChart details....", revenueLineChart);
  console.log("dashboardCount", dashboardCount);

  console.log("storiesCount", storiesCount);
  const data2 = {
    labels: ["Active %", "Inactive %"],
    datasets: [
      {
        label: "% of Votes",
        radius: "100%",
        data: dashboardCount
          ? [
            // dashboardCount.activeUsersPercent,
            // dashboardCount.inactiveUsersPercent,
            // active, inActive
            (active / (active + inActive) * 100).toFixed(2), (inActive / (active + inActive) * 100).toFixed(2)
          ]
          : [0, 0],
        backgroundColor: ["#01C1C1", "#FD9216"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };
  const getChartData = () => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
    // var months = ["Jan,23","Feb,23","Mar,23","Apr,23","May,23","Jun,23","Jul,23","Aug,23","Sep,23","Oct,23","Nov,23","Dec,23",];

    const revenueModified = [...revenueLineChart];
    const monthExist = revenueLineChart.map((item) => {
      return item._id?.month;
    });

    // for (let i = 1; i <= 12; i++) {
    //   if (!monthExist.includes(i)) {
    //     const monthObj = {
    //       _id: {
    //         month: i,
    //         year: 2022,
    //       },
    //       revenue: 0,
    //     };
    //     revenueModified.push(monthObj);
    //   }
    // }

    for (let i = 0; i < revenueModified.length; i++) {
      let data = revenueModified[i];
      let createdAt = `01-${data._id.month}-${data._id.year}`;
      data.createdAt = createdAt;
      revenueModified[i] = data;
    }
    revenueModified.sort((a, b) =>
      // a._id?.month > b._id?.month ? 1 : b._id?.month > a._id?.month ? -1 : 0
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    revenueModified.reverse();

    const labels = revenueModified.map(function (item) {
      let month = months[item._id?.month - 1];
      month += `, ${moment(item.createdAt).format("YY")}`;
      return month;

    });
    console.log("revenueModified,,,,", revenueModified);
    console.log("lebel", labels);
    const ChartData = {
      labels,
      datasets: [
        {
          label: "Monthly Revenue",
          data: revenueModified.map((item) => item.revenue),
          fill: true,
          backgroundColor: "rgba(47, 218, 255, 0.5)",
          borderColor: "rgba(47, 218, 255, 0.5)",
        },
      ],
    };
    return ChartData;
  };

  return (
    <>
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Dashboard</h2>
            <p>A quick data overview of the Application.</p>
          </div>
          <div className="nft_drop">
            <ReportDownload type="dashboard" />
          </div>
        </div>
      </div>

      <div id="dashPDF">
        <div className="dash_partts_inner">

          {/* <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Dashboard</h2>
            <p>A quick data overview of the Application.</p>
          </div>
          <div className="nft_drop">
            <ReportDownload type="dashboard" />
          </div>
        </div> */}

          <div className="active_accnt_partts" id="dashboardCount">
            <div className="active_accnt_inner_partts">
              <DashboardCountCard
                loading={loading}
                title="Active Users"
                // count={dashboardCount.activeUsers}
                count={active}
                imgSrc={Active1_Img}
              />
              <DashboardCountCard
                loading={loading}
                title="InActive Users"
                // count={dashboardCount.inactiveUsers}
                count={inActive}
                imgSrc={Active2_Img}
              />
              <DashboardCountCard
                loading={loading}
                title="Stories"
                count={storiesCount}
                imgSrc={Active6_Img}
              />
              <DashboardCountCard
                loading={loading}
                title="Nft Editions"
                count={counts?.totalNfts}
                imgSrc={Active6_Img}
              />
              <DashboardCountCard
                loading={loading}
                title="Buyers"
                count={counts?.totalBuyer}
                imgSrc={Active3_Img}
              />
              <DashboardCountCard
                loading={loading}
                title="Sellers"
                count={counts?.totalSeller}
                imgSrc={Active4_Img}
              />
              <DashboardCountCard
                loading={loading}
                title="Mintors"
                count={counts?.totalMintor}
                imgSrc={Active5_Img}
              />

              {/* <div className="active_inner_inner_partts">
              <div className="active_inner_list">
                <h2>NFT Creators</h2>
                <div className="active_image_text">
                  <div className="active_image">
                    <img src={Active5_Img} />
                  </div>
                  <div className="active_text">3400</div>
                </div>
              </div>
            </div> */}

              {/* <div className="active_inner_inner_partts">
              <div className="active_inner_list">
                <h2>Update Storys</h2>
                <div className="active_image_text">
                  <div className="active_image">
                    <img src={Active6_Img} />
                  </div>
                  <div className="active_text">{storiesCount.allStories}</div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        <div className="user_management_list">
          <Row>
            <Col lg="8" md="12" sm="12">
              <div className="dash_chart_left">
                <h2>Monthly Revenue Chart</h2>
                <div className="monthly_revenue_chart">
                  <Line data={getChartData()} options={options} />
                </div>
              </div>
            </Col>
            <Col lg="4" md="12" sm="12">
              <div className="dash_chart_right">
                <h2>User Count</h2>
                <div className="monthly_revenue_chart">
                  <div className="dog_chart">
                    <Doughnut data={data2} options={options2} />
                    <div className="count_inner">
                      {active ? (active / (active + inActive) * 100).toFixed(0) : 0}%
                      {/* {dashboardCount ? dashboardCount.activeUsersPercent : 0}% */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="nfts_sold">
            <Row>
              <Col lg="6" md="12" sm="12">
                <div className="sold_nft_inner">
                  <div className="sold_nft_leftpp">
                    <div className="sold_box">
                      <img src={Sold1_Img} alt="No Img" />
                    </div>
                    <h2>NFT’s are sold</h2>
                  </div>
                  <div className="sold_nft_rightpp">{counts?.totalNftsSold}</div>
                </div>
                <div className="sold_nft_inner">
                  <div className="sold_nft_leftpp">
                    <div className="sold_box">
                      <img src={Sold2_Img} alt="No Img" />
                    </div>
                    <h2>NFT’s are not sold</h2>
                  </div>
                  <div className="sold_nft_rightpp">
                    {counts?.totalNftsNotSold}
                  </div>
                </div>
              </Col>
              <Col lg="6" md="12" sm="12">
                <div className="top_three_nfts top_nft">
                  <h2>Top 3 NFT’s</h2>

                  {topNfts &&
                    topNfts.map((nft,index) => {
                      return (
                        <div className="nfts_user_partts nfts_user_list" key={index}>
                          <div className="nft_user_left">
                            <div className="stry_man nft_user_img">
                              <img src={nft?.fileUrl} alt="No Img" />
                            </div>
                            <div className="storyman_texttt">
                              <h2>{nft.title ?? `${nft.creator.nickName.fName} ${nft.creator.nickName.lName}`}</h2>
                              <p>{CurrencyFormating (nft.prices.amount)}</p>
                            </div>
                          </div>

                          {/* <div className="nft_user_middle">
                            {nft.sports.lightBadges && (
                              <span className="badges">
                                <img
                                  src={nft.sports.lightBadges}
                                  alt="No Img"
                                  className="img-fluid"
                                />
                              </span>
                            )}
                            {nft.division.lightBadges && (
                              <span className="badges">
                                <img
                                  src={nft.division.lightBadges}
                                  alt="No Img"
                                  className="img-fluid"
                                />
                              </span>
                            )}
                            {nft.state.lightBadges && (
                              <span className="badges">
                                <img
                                  src={nft.state.lightBadges}
                                  alt="No Img"
                                  className="img-fluid"
                                />
                              </span>
                            )}
                          </div> */}
                          <div className="nft_user_right">
                            {nft.nftType.imageUrl && (
                              <img
                                src={nft.nftType.imageUrl}
                                alt="No Img"
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}

                  {/* <div className="nfts_user_partts">
                  <div className="nft_user_left">
                    <div className="stry_man">
                      <img src={Story_Man2_Img} />
                    </div>
                    <div className="storyman_texttt">
                      <h2>Carl Brandt</h2>
                      <p>$1000.00</p>
                    </div>
                  </div>
                  <div className="nft_user_middle">
                    <span>
                      <img src={Badge1_Img} />
                    </span>
                    <span>
                      <img src={Badge4_Img} />
                    </span>
                    <span>
                      <img src={Badge5_Img} />
                    </span>
                  </div>
                  <div className="nft_user_right">
                    <img src={Common_Img} />
                  </div>
                </div>

                <div className="nfts_user_partts">
                  <div className="nft_user_left">
                    <div className="stry_man">
                      <img src={Story_Man3_Img} />
                    </div>
                    <div className="storyman_texttt">
                      <h2>Kevin Trapp</h2>
                      <p>$500.00</p>
                    </div>
                  </div>
                  <div className="nft_user_middle">
                    <span>
                      <img src={Badge6_Img} />
                    </span>
                    <span>
                      <img src={Badge2_Img} />
                    </span>
                    <span>
                      <img src={Badge7_Img} />
                    </span>
                  </div>
                  <div className="nft_user_right">
                    <img src={Common_2_Img} />
                  </div>
                </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardPart;
// const data = {
//   labels: [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ],
//   datasets: [
//     {
//       label: "",
//       data: [33, 53, 85, 41, 44, 65, 45, 54, 30, 23, 25, 62],
//       fill: true,
//       backgroundColor: "rgba(47, 218, 255, 0.5)",
//       borderColor: "rgba(47, 218, 255, 0.5)",
//     },
//     //   {
//     //     label: "Second dataset",
//     //     data: [33, 25, 35, 51, 54, 76],
//     //     fill: false,
//     //     borderColor: "#742774"
//     //   }
//   ],
// };

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
  scales: {
    y: {
      ticks: { color: "#fff", beginAtZero: true },
    },
    x: {
      ticks: { color: "#fff", beginAtZero: true },
    },
  },
};

export const options2 = {
  // responsive: true,
  // padding:"0px",
  // responsive:false,
  // maintainAspectRatio:false,
  // defaultFontSize:"14px",
  // width:"400",
  // height:"800",
  plugins: {
    legend: {
      labels: {
        color: ["#fff"],
      },
      weight: "20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
  // scales: {
  //     y: {
  //       ticks: { color: '#fff', beginAtZero: true }
  //     },
  //     x: {
  //       ticks: { color: '#fff', beginAtZero: true }
  //     }
  // },
};
