// import axios from "../axios/axios";

// //  fetch Nft_Details request
// export const FETCH_NFT_DETAILS_REQUEST = "FETCH_NFT_DETAILS_REQUEST";
// export const FETCH_NFT_DETAILS_SUCCESS = "FETCH_NFT_DETAILS_SUCCESS";
// export const FETCH_NFT_DETAILS_FAILURE = "FETCH_NFT_DETAILS_FAILURE";

// export const fetchNftDetails = (nftId) => {
//     return async (dispatch) => {
//         try{
//             dispatch({ type: FETCH_NFT_DETAILS_REQUEST });
//             let dataurl = `/nft-details/${nftId}`;
//             let response = await axios.get(dataurl);
            
//             // console.log("...URL: ", response.data.nftEditions);
            
//             // dispatch an action if request got successful
//             dispatch({ type: FETCH_NFT_DETAILS_SUCCESS, payload: response.data });
//         }catch(error){
//             console.log(error);
//             dispatch({
//                 type: FETCH_NFT_DETAILS_FAILURE,
//                 payload: { error: error },
//             });
//         }
//     };
// };


export const setNftDetails = (data) =>{
    return { type:"SET_NFT_DETAILS", payload: data };
}