import React, { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import { CKEditor } from 'ckeditor4-react';

import { useParams, useHistory  } from "react-router-dom";
import axios from "../../axios/axios";

const NotificationLogTemplate = () => {
    const { notificationLogId } = useParams();
    const history = useHistory();
    
    const [notificationLogDetails, setNotificationLogDetails] = useState({
      name: "",
      title: "",
      content: "",
      status: "",
    })

    const [editorContent,setEditorContent] = useState("")
    
    const handleChange = (event, notificationLogDetails) => {
        setEditorContent(event.editor.getData());
    }
    
    const goBack = () => {
      history.push('/notification-log')
    }
    
    // get notification-log by id
    const getNotificationDetails = async () => {
        await axios
          .get(`/get-notification-logs-by-id/${notificationLogId}`)
          .then((res) => {
            console.log(".......", res.data);
            setNotificationLogDetails(res.data.getNotification)
            setEditorContent(res.data.getNotification.content)
          })
          .catch((err) => {
            console.log("getNotificationDetails err", err);
          });
      };
    
      useEffect(() => {
        getNotificationDetails()
      },[]);
    
  return (
    <React.Fragment>
    <div className='user_inner_partts'>
    <div className="sand_box_partts">
        <h2>Notification-Log Template</h2>
        <div className="setting_right_partts_inner">
            <div className="notification_container">
                
                <div className="notification_container stripe">
                    <div>
                        <Form.Label>Receiver Name</Form.Label>
                        <Form.Control type="text" placeholder="Receiver Name" className="notification_input_field" name= "name" value={notificationLogDetails?.receiver} readOnly />
                    </div>
                </div>
                
                <div className="notification_container stripe">
                    <div>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" className="notification_input_field" name="title" value={notificationLogDetails?.title} readOnly />
                    </div>
                </div>
                
                <div className="notification_container stripe">
                    <Form.Label>Messsage</Form.Label>
                    {editorContent!="" && (
                        <CKEditor
                        initData ={editorContent}
                        onChange={(event)=>{handleChange(event,notificationLogDetails)}}
                        />
                    )}
                    
                </div>
                
                <div className="notification_container stripe">
                    <div>
                        <Form.Label>Status</Form.Label>
                        <Form.Control type="text" placeholder="Status" className="notification_input_field" name="status" readOnly value={notificationLogDetails.status} />
                    </div>
                </div>
                
                <div className='email-button-container'>
                    <div>  
                        <button type="submit" className="notification_update_button email-button" onClick={goBack}>Previous</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
        
    </React.Fragment>
  )
}

export default NotificationLogTemplate;