import React, { useState } from "react";

import { Row, Col, Form, Modal, Button, Tabs, Tab } from "react-bootstrap";

import "./tagstyle.css";
import "./manageproduct.css";

// import {COUNTRIES} from './countries';
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function ManageProductVarity({ values, setKey, setProduct }) {
  const [isActive, setActive] = useState([]);

  const ToggleClass = (color) => {
    let items = prodDT.variety.filter((tag, index) => tag.type == "color");
    let index = prodDT.variety.indexOf(items[0]);
    let items2 = items[0].dataset.filter((tag, index) => tag !== color.id);
    console.log("toggle", color);
    let arr = isActive;
    let id = color.id;
    if (isActive.includes(id)) {
      arr = arr.filter((i) => {
        return i != id;
      });
    } else {
      arr.push(id);
    }
    prodDT.variety.splice(index, 1);
    let newVariety = prodDT.variety;
    newVariety.push({ type: "color", dataset: arr });
    // newVariety.push({ type: "color", dataset: arr });
    console.log("newVariety", newVariety);
    setProdDT((prev) => ({ ...prev, variety: newVariety }));
    // console.log(prodDT.variety);
    setActive(arr);
    console.log(id, arr);
  };
  let placeholder = "Add size";
  let [prodDT, setProdDT] = useState({
    size: "",
    color: "",
    variety: [],
  });

  const [varityErr, setVarityErr] = useState({});
  
  const validate = () => {
    let isValid = [];
    
    // color error
    if(prodDT.color === ""){
      setVarityErr((prevState) => ({...prevState, colorErr: "Color is Required"}));
        isValid.push(false);
    }else if(prodDT.color === "Select"){
      setVarityErr((prevState) => ({...prevState, colorErr: "Color is Required"}));
      isValid.push(false);
    }else {
      setVarityErr((prevState) => ({...prevState, colorErr: ""}));
      isValid.push(true);
    }
  
  // size error
  if(prodDT.size === ""){
    setVarityErr((prevState) => ({...prevState, sizeErr: "Size is Required"}));
      isValid.push(false);
  }else if(prodDT.size == "Select"){
    setVarityErr((prevState) => ({...prevState, sizeErr: "Size is Required"}));
    isValid.push(false);
  }else {
    setVarityErr((prevState) => ({...prevState, sizeErr: ""}));
    isValid.push(true);
  }
  
  // size tag error
  if(sizeTags === ""){
    setVarityErr((prevState) => ({...prevState, sizeTagErr: "Size is Required"}));
      isValid.push(false);
  }else {
    setVarityErr((prevState) => ({...prevState, sizeTagErr: ""}));
    isValid.push(true);
  }
  
  if(isValid.includes(false))
    {
        return false
    }
    else
    {
        return true;
    }
}

  let suggestions = [
    { id: "Thailand", text: "Thailand" },
    { id: "India", text: "India" },
    { id: "Vietnam", text: "Vietnam" },
  ];
  const [tags, setTags] = React.useState([
    { id: "Thailand", text: "Thailand" },
    { id: "India", text: "India" },
    { id: "Vietnam", text: "Vietnam" },
  ]);
  const [sizeTags, setSizeTags] = React.useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleSizeDelete = (i) => {
    let itemDltd = sizeTags.filter((tag, index) => index == i);
    setSizeTags(sizeTags.filter((tag, index) => index !== i));

    setSelectedAttribute(itemDltd[0]);
  };
  const setSelectedAttribute = (item) => {
    console.log("item to be deleted", item);
    console.log("prodDT.variety", prodDT.variety);
    let items = prodDT.variety.filter((tag, index) => tag.type == "size");
    let index = prodDT.variety.indexOf(items[0]);
    console.log("item to be deleted index", index);
    let items2 = items[0].dataset.filter((tag, index) => tag !== item.id);

    prodDT.variety.splice(index, 1);
    let newVariety = prodDT.variety;
    newVariety.push({ type: "size", dataset: items2 });
    console.log("newVariety", newVariety);
    setProdDT((prev) => ({ ...prev, variety: newVariety }));
    // console.log("prodDT.variety", prodDT.variety);
    // setSizeTags(sizeTags.filter((tag, index) => index !== i));
  };

  const handleTagChange = (e, mode) => {
    switch (mode) {
      case "color":
        let ifAlready = prodDT.variety.filter((color) => color.type == "color");
        let getSelctedColor = values[2].color.filter(
          (value) => value.desc === e.target.value
        );
        // console.log("getSelctedColor", getSelctedColor[0]);
        let colorTag = [];
        let varietyColor = prodDT.variety;

        let colorActiveTag = [];
        getSelctedColor[0].datasets.map((color, idx) => {
          colorTag.push({ id: color, text: color });

          colorActiveTag.push(color);
        });
        if (ifAlready) {
          let index = prodDT.variety.indexOf(ifAlready[0]);
          prodDT.variety.splice(index, 1);
          varietyColor.push({
            type: "color",
            dataset: getSelctedColor[0].datasets,
          });
          setProdDT((prevState) => ({
            ...prevState,
            color: e.target.value,
            variety: varietyColor,
          }));
        } else {
          varietyColor.push({
            type: "color",
            dataset: getSelctedColor[0].datasets,
          });
          setProdDT((prevState) => ({
            ...prevState,
            color: e.target.value,
            variety: varietyColor,
          }));
        }

        // setProdDT((prevState) => ({
        //   ...prevState,
        //   color: e.target.value,
        //   variety: varietyColor,
        // }));
        console.log("prodDT", prodDT);
        setTags(colorTag);
        setActive(colorActiveTag);
        // console.log(isActive);
        break;
      case "size":
        let ifAlreadysize = prodDT.variety.filter(
          (color) => color.type == "size"
        );
        // console.log("getSelctedColor", values[2].color);
        let getSelctedSize = values[2].size.filter(
          (value) => value.desc == e.target.value
        );
        // console.log("getSelctedColor", getSelctedSize[0]);
        let sizeTag = [];
        getSelctedSize[0].datasets.map((color, idx) => {
          sizeTag.push({ id: color, text: color });
        });
        let varietySize = prodDT.variety;
        console.log(ifAlreadysize);
        // if (ifAlreadysize) {
        //   let index = prodDT.variety.indexOf(ifAlreadysize[0]);
        //   prodDT.variety.splice(index, 1);
        //   varietySize.push({
        //     type: "size",
        //     dataset: getSelctedSize[0].datasets,
        //   });
        //   setProdDT((prevState) => ({
        //     ...prevState,
        //     size: e.target.value,
        //     variety: varietySize,
        //   }));
        // } else {
        varietySize.push({
          type: "size",
          dataset: getSelctedSize[0].datasets,
        });
        setProdDT((prevState) => ({
          ...prevState,
          size: e.target.value,
          variety: varietySize,
        }));
        // }
        // setProdDT((prevState) => ({
        //   ...prevState,
        //   size: e.target.value,
        //   variety: varietySize,
        // }));
        setSizeTags(sizeTag);
        break;

      default:
        break;
    }
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleSizeAddition = (tag) => {
    setSizeTags([...sizeTags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  const showState = (e) => {
    e.preventDefault();
    
    let valid = validate();
    console.log("prodDT", prodDT);
    // setLoading((prevState) => ({ ...prevState, profile: true }));
    if(valid){
      setProduct((prevState) => ({
        ...prevState,
        variety: prodDT.variety,
      }));
      setKey("attributes");
    }else{
      console.log("product verity.... else part error......")
    }
  };

  return (
    <div>
      <div className="dash_partts">
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
            {/* <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex">
                    <Form.Select
                      aria-label="Default select example"
                      className="text_box add_attribute"
                    >
                      <option>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                    <Button
                      variant="primary"
                      type="Save"
                      className="add_button"
                    >
                      Add
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Color</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    value={prodDT.color}
                    onChange={(e) => handleTagChange(e, "color")}
                  >
                    <option>Select</option>
                    {values.length > 0 &&
                      values[2].color &&
                      values[2].color.map((color, idx) => {
                        // console.log("size", values[0].size);
                        return <option key={idx}>{color.desc}</option>;
                      })}
                  </Form.Select>
                  <p className="errorMessage">{varityErr.colorErr}</p>
                  <div className="color_box_wrapper">
                    {tags.map((color, id) => {
                      return (
                        <div
                          id={id}
                          style={{ "background-color": color.text }}
                          className={
                            isActive.includes(color.id)
                              ? "color_box active"
                              : "color_box"
                          }
                          onClick={() => {
                            ToggleClass(color);
                          }}
                        ></div>
                      );
                    })}
                    {/* <div
                      id="1"
                      style={{ "background-color": "red" }}
                      className={
                        isActive.includes("1")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="2"
                      style={{ "background-color": "yellow" }}
                      className={
                        isActive.includes("2")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="3"
                      style={{ "background-color": "blue" }}
                      className={
                        isActive.includes("3")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div> */}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            {/* <ReactTags
              tags={tags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              placeholder={placeholder}
              autocomplete
            /> */}

            <hr />

            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Size</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    value={prodDT.size}
                    onChange={(e) => handleTagChange(e, "size")}
                    // onChange={(e) =>
                    //   setProdDT((prevState) => ({
                    //     ...prevState,
                    //     size: e.target.value,
                    //   }))
                    // }
                  >
                    <option>Select</option>

                    {values.length > 0 &&
                      values[2].size &&
                      values[2].size.map((size, idx) => {
                        // console.log("size", values[0].size);
                        return (
                          <option
                            key={idx}
                            // onChange={setProdDT((prevState) => ({
                            //   ...prevState,
                            //   selectedSize: size.datasets,
                            // }))}
                          >
                            {size.desc}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <p className="errorMessage">{varityErr.sizeErr}</p>
                </Form.Group>
              </Col>
            </Row>

            <ReactTags
              tags={sizeTags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleSizeDelete}
              handleAddition={handleSizeAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              placeholder={placeholder}
              autocomplete
            />
            {/* <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Color</Form.Label>
                  <div className="color_box_wrapper">
                    {console.log(isActive.includes("1"))}
                    <div
                      id="1"
                      style={{ "background-color": "red" }}
                      className={
                        isActive.includes("1")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="2"
                      style={{ "background-color": "yellow" }}
                      className={
                        isActive.includes("2")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                    <div
                      id="3"
                      style={{ "background-color": "blue" }}
                      className={
                        isActive.includes("3")
                          ? "color_box active"
                          : "color_box"
                      }
                      onClick={ToggleClass}
                    ></div>
                  </div>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col md={12} className="text-center">
                <Form.Group
                  className="mb-3 pt-5 pb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Button
                    variant="primary"
                    className="my_button"
                    onClick={showState}
                  >
                    Next
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ManageProductVarity;
