import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "./userPayout.css";
import {
  Managesearch_img,
  Maneye_img,
} from "../../assets/images";
import { capitalizeFirstLetter } from "../../helpers/currencyHelper";

const UserPayout = () => {
  
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [usersPayout, setUsersPayout] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  let [loading, setLoading] = useState({ counts: false, dtable: false });
  
  const token = useSelector((state) => state.setToken);
  
  const getUsersPayout = async() => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    await axios.get(`/get-all-payouts/?limit=${limit}&page=${page}&search=${search}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => {
        console.log("Users Payout Data......", res.data);
        if(res.data.success === true) {
          setUsersPayout(res.data.data);
          setTotalRows(res.data.pagination.totalRows);
          setLoading(prevState => ({...prevState, dtable:false}));
        }
      })
      .catch((err) => {
        console.log("Users-Payout-error", err);
      });
  };
  
  useEffect(() => {
    getUsersPayout();
  }, [page, limit, search]);
    
  const usersPayoutData = () => {
    let payoutDetails = [];
    usersPayout?.map((item, index) => {
      payoutDetails.push({
        id: index,
        userName: <div className="man_text">{item.user_id?.name}</div>,
        account_holder: <div className="man_text">{item.bankId?.account_holder_name}</div>,
        payoutId: <div className="man_text">{item.payoutId}</div>,
        bankName: <div className="man_text">{item.bankId?.bank_name}</div>,
        accountId: <div className="man_text">{item.account_id?.acc_id}</div>,
        // amountWithdraw: <div className="man_ph">&#x24; {item.amount}</div>,
        amountWithdraw: <div className="man_text" style={{marginLeft: '0.4rem'}}>${" "}{item.amount}</div>,
        // transactionId: <div className="man_ph">{item.payoutId}</div>,
        status: <div className="man_text">{capitalizeFirstLetter(item.status)}</div>,
        view: (
          <div className="three_dotss">
            <Link to={`/payout-details/${item._id}`} style={{marginLeft: "0.3rem", color: "white"}} className="view_hover">
            {/* <img src={Maneye_img} alt="No Img" className="manEyeStyle view" /> */}
            <span style={{fontSize: "14px"}}>View</span>
            </Link>
          </div>
        ),
      });
    });
    return payoutDetails;
  };

  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };
 
  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );
  
  return (
    <React.Fragment>
         <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>User's Payout List</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div>
              </div>

              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                  className="hypTable1"
                    columns={columns}
                    data={usersPayoutData()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}


const columns = [
  {
    name: "Name",
    selector: (row) => row.userName,
    // reorder: true,
    width: "150px",
  },
  {
    name: "Bank Name",
    selector: (row) => row.bankName,
    // reorder: true,
    width: "135px",
  },
  {
    name: "Account Holder",
    selector: (row) => row.account_holder,
    // reorder: true,
    width: "150px",
  },
  {
    name: "Account ID",
    selector: (row) => row.accountId,
    // reorder: true,
    width: "150px",
  },
  {
    name: "Payout",
    selector: (row) => row.amountWithdraw,
    // reorder: true,
    width: "80px",
  },
  {
    name: "Ref ID",
    selector: (row) => row.payoutId,
    // reorder: true,
    width: "150px",
  },
  // {
  //   name: "Transaction ID",
  //   selector: (row) => row.transactionId,
    // reorder: true,
  //   width: "225px",
  // },
  {
    name: "Status",
    selector: (row) => row.status,
    // reorder: true,
    width: "90px",
  },
  {
    name: "Action",
    selector: (row) => row.view,
    // reorder: true,
    width: "100px"
  },
];
const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "17px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
    //   paddingTop: "5px",
      paddingTop: "25px",
    //   paddingBottom: "5px",
      paddingBottom: "25px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
    },
  },
};

export default UserPayout;
