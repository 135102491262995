import React, { useState, useEffect } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import axios from "../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Switch from "react-switch";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';
import { message } from "antd";
import {
  Managesearch_img,
  Man1_img,
  Maneye_img,
  Threedots_img,
  Edit_img,
  Delete_img,
  Man2_img,
  Man3_img,
  restart_img,
  calender_img,
  closed_img,
} from "../../assets/images";
import ReportDownload from "../subComponents/ReportDownload";
import Card from "../Nft/Card";
import moment from "moment";
import FundRaiserDate from "../modals/FundRaiserDate";
// import Progressbar from "react-linear-progressbar";

ChartJS.register(ArcElement, Tooltip, Legend);

const FundRaiser = () => {
  const [pageNew, setPageNew] = useState(1);
  const [limit, setLimit] = useState(10);
  const [nfts, setNfts] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingCount, setLoadingCount] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalPage: 7,
    totalRows: 0,
  });
  const [countPercentage, setCountPercentage] = useState({
    totalClosedFundRaisers: 0,
    totalCompleteFundRaisers: 0,
    totalOngoingFundRaisers: 0,
  });
  const [count, setCount] = useState({
    totalClosedFundRaisers: 0,
    totalCompleteFundRaisers: 0,
    totalOngoingFundRaisers: 0,
  });
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);

  let dispatch = useDispatch();
  let today = new Date();

  const token = useSelector((state) => state.setToken);
  const fetchNfts = async () => {
    console.log("page row chng", pageNew, pagination.limit);
    await axios
      .get(
        `/fund-raisers/?limit=${limit}&page=${pageNew}&search=${search}`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("fundraiser res....", res.data);
        if (res.data.success === true) {
          setPagination(res.data.pagination);
          setNfts(res.data.allFundRaisers);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchCount = async () => {
    await axios
      .get("/fund-raisers-count", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        console.log(" count res...", res.data);
        if (res.data.success === true) {
          // dispatch(setStoriesCount(res.data.allStories));
        

          let totalFR = Number(res.data.totalClosedFundRaisers) + Number(res.data.totalCompleteFundRaisers);
          let totalClosedFundRaisers = Number((res.data.totalClosedFundRaisers / (totalFR/100)).toFixed(2))
          let totalCompleteFundRaisers = Number((res.data.totalCompleteFundRaisers / (totalFR/100)).toFixed(2))
        
          setCountPercentage({
            totalClosedFundRaisers,
            totalCompleteFundRaisers,
            totalOngoingFundRaisers:res.data.totalOngoingFundRaisers
          });
          setCount(res.data)
          //   setTotalRows(res.data.totalNfts);
          setLoadingCount(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const data = {
    labels: ["Fund Raise Closed Percentage", "Fund Raise Complete Perentage"],
    datasets: [
      {
        label: "Fund Raisers Status",
        data: [countPercentage.totalClosedFundRaisers, countPercentage.totalCompleteFundRaisers],
        backgroundColor: ["#FF4F4F", "#30FFA8"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  // const deleteNft = async (id) => {
  //   console.log("delete nft=", id);
  //   let data = { id };
  //   await axios
  //     .post("/delete-nft", data, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     })
  //     .then((res) => {
  //       console.log("nft deleted", res.data);
  //       if (res.data.success === true) {
  //         fetchNfts();
  //         fetchCount();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  const closedFundRaiser = (id) => {
    console.log("userId......", id);
    const {userId} = nfts;
    console.log("............userId status: ", userId);
    axios
      .patch(`/fund-raiser-status/${id}`)
      .then((res) => {
        if (res.data.success === true) {
          fetchNfts();
          message.success("fund raiser closed");
        } else {
          message.error("fund raiser close failed");
        }
      })
      .catch((err) => {
        console.log("fund raiser close err", err);
      });
  };

  useEffect(() => {
    console.log("page chng ue", pageNew, limit);
    // if (!storiesCount) {
      // deleteFundRaiser();
    fetchNfts();
    fetchCount();
  }, [pageNew, loading, limit, search]);
  
  const data50 = () => {
    let allNfts = [];
    nfts &&
      nfts.map((nft, index) => {
        console.log(".........Today...", moment(today).format("DD/MM/YYYY") <= moment(nft.expireDate).format("DD/MM/YYYY"))
        let config = {
          containerStyle: {},
          progressStyle: {},
          stripe: false,
          animate: false,
          showLabel: true,
          width: 40,
          label: nft.percentEarned + "%",
        };
        allNfts.push({
          id: index,

          author: [
            <div className="man_email">{nft.userId && nft.userId.name}</div>,
          ],
          reason: [<div className="man_email">{nft.reason}</div>],

          create: (
            <div className="man_email">
              {" "}
              {moment(nft.createdAt).format("DD/MM/YYYY")}
            </div>
          ),
          expireDate: (
            <div className="man_email">
              {" "}
              {moment(nft.expireDate).format("DD/MM/YYYY")}
            </div>
          ),
          amount: <div className="man_priceeee">$ {nft.amount}</div>,

          percentEarned: (
            <div className="man_text">
              
        
              {/* <ProgressBar className="fixProgressBar" now={nft.percentEarned} label={`${nft.percentEarned}%`} /> */}
              <div className="progress_label">
                <ProgressBar className="fixProgressBar" now={nft.percentEarned} />
                <span className='progress_new_label'>{`${nft.percentEarned}%`}</span>
              </div>
           
              {/* {nft.percentEarned} % */}
              {/* <Progressbar config={config} /> */}
            </div>
          ),
          status:( <div className="man_email">
            {
              nft.status === "closed" ? <Badge bg="danger">{nft.status}</Badge> :
              /* moment(today).format("DD/MM/YYYY") >= moment(nft.expireDate).format("DD/MM/YYYY") ? <Badge bg="danger">{nft.status}</Badge> : */
              <>
                {
                  nft.status === "completed" ?
                  <Badge bg="primary">{nft.status}</Badge> :
                  <Badge bg="success">{nft.status}</Badge>
                }
              </>
              
            }
            
            </div>),

          action: (
            <div className="three_dotss">
              <Dropdown className="drop_partts">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <img src={Threedots_img} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to={`/fund-raiser-details/${nft._id}`}>
                    <img src={Maneye_img} />
                    <span style={{marginLeft: "0.3rem"}}>View</span>
                  </Link>
                  {/* <Dropdown.Item>
                    <img src={calender_img} /> <FundRaiserDate />
                  </Dropdown.Item> */}
                  
                  <Dropdown.Item onClick={() => closedFundRaiser(nft._id)}>
                    <img src={closed_img} /> Close
                  </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ),
        });
      });
    return allNfts;
  };
  const changePage = (newPage) => {
    console.log("newPage", newPage);
    setLoading(true);
    setPageNew(newPage);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);

    setLimit(newPerPage);

    setPageNew(page);
  };
  return (
    <>
    
      {/* <Leftpanel panelName="dashboard"/> */}
      {/* <div> */}
      {/* <HeaderPart /> */}
      <div className="nft_user_top_partts">
        <div className="nft_user_top_heading">
          <h2>Fund Raisers</h2>
          <div className="nft_drop">
            <ReportDownload
              //  Csvfields={["name", "reason", "createdAt", "expireDate", "amount", "status"]}
              totalFundRaisersCount={pagination.totalRows}
              type="fundRaisers"
            />
          </div>
        </div>
        <div className="nft_profiless" id="fundRaisersPdf">
          <Row>
            <Col lg="6" md="12" sm="12">
              <div className="piechart_js">
                <div className="nft_pie">
                  <Pie data={data} options={options} />
                </div>
                <div className="nft_pie_right">
                  {/* <div className="nft_iner_pie">
                    <div className="rounded_pie color1pie"></div>
                    <div className="inner_pie_text">
                      <h2>New Created Profile(Weekly)</h2>
                      {/* <label>{count.weeklyNfts}</label> */}
                  {/* </div>
                  </div> */}
                  {/* <div className="nft_iner_pie">
                <div className="rounded_pie color2pie"></div>
                <div className="inner_pie_text">
                  <h2>Deleted Profile</h2>
                  <label>30</label>
                </div>
              </div> */}

                  {/* <div className="nft_iner_pie">
                    <div className="rounded_pie color2pie"></div>
                    <div className="inner_pie_text">
                      <h2>Total Completed fundraiser</h2>
                      <label>{count.totalBuyer}</label>
                    </div>
                  </div> */}

                  {/* <div className="nft_iner_pie">
                    <div className="rounded_pie color3pie"></div>
                    <div className="inner_pie_text">
                      <h2>Total closed fundraiser</h2>
                      <label>{count.totalSeller}</label>
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col lg="2" md="6" sm="6">
            <div className="active_inner_list" style={{maxHeight:"150px"}}>
                <h2>Fundraise Completed</h2>
                <div className="active_image_text" >
                  <div className="active_image">
                    {/* <img src={imgSrc} alt="No Img" /> */}
                  </div>
                  <div className="active_text">{count.totalCompleteFundRaisers}</div>
                </div>
              </div>
            </Col>
            <Col lg="2" md="6" sm="6"> 
            <div className="active_inner_list" style={{maxHeight:"150px"}}>
                <h2>Fundraise Closed</h2>
                <div className="active_image_text">
                  <div className="active_image">
                    {/* <img src={imgSrc} alt="No Img" /> */}
                  </div>
                  <div className="active_text">{count.totalClosedFundRaisers}</div>
                </div>
              </div>
            </Col>
            <Col lg="2" md="6" sm="6">
            <div className="active_inner_list" style={{maxHeight:"150px"}}>
                <h2>Fundraise Ongoing</h2>
                <div className="active_image_text">
                  <div className="active_image">
                    {/* <img src={imgSrc} alt="No Img" /> */}
                  </div>
                  <div className="active_text">{count.totalOngoingFundRaisers}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="user_management_list">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Fund Raisers Lists</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    onChange={(e) => setSearch(e.target.value)}
                    className="manage_search_boxxx"
                  />
                  <span className="search_imgg_manage">
                    <img src={Managesearch_img} />
                  </span>
                </div>
              </div>
              <div className="manage_table table-responsive">
                <DataTable
                className="hypTable1"
                  columns={columns}
                  data={data50()}
                  pagination
                  responsive
                  customStyles={customStyles}
                  // defaultSortField="id"
                  paginationDefaultPage={pageNew}
                  paginationServer
                  paginationTotalRows={pagination.totalRows}
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={[10, 20, 40]}
                  defaultSortAsc={false}
                  progressPending={loading}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={changePage}
                  noDataComponent={<EmptyDataLoader />}
                  progressComponent={<CustomLoader />}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* </div> */}
    </>
  );
};

const columns = [
  {
    name: "Creator",
    selector: (row) => row.author,
    reorder: true,
    width: "150px",
  },
  {
    name: "Reason",
    selector: (row) => row.reason,
    reorder: true,
    width: "150px",
  },

  {
    name: "Created Date",
    selector: (row) => row.create,
    reorder: true,
    width: "150px",
  },
  {
    name: "Expire Date",
    selector: (row) => row.expireDate,
    reorder: true,
    width: "150px",
  },
  {
    name: "Target Amount",
    selector: (row) => row.amount,
    reorder: true,
    width: "150px",
  },
  {
    name: "Percent Earned",
    selector: (row) => row.percentEarned,
    reorder: true,
    width: "350px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    reorder: true,
    width: "125px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    reorder: true,
    width: "125px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      // align: "start",
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
};

export default FundRaiser;
