import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import {
    Container,
    Row,
    Col,
    Modal,
    Button,
    Form,
    Dropdown,
    Table,
} from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import {
    Nft_Purchase2_Img,
    Man1_img,
  Maneye_img,
  Managesearch_img,
  Man2_img,
  Man3_img,
  Noimg_Img,
  Prof_Img,
  } from "../../assets/images";
  import Switch from "react-switch";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    //BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  import "./nftpurchase.css";
  import 'chart.js/auto';
  import moment from "moment";
import ReportDownload from "../subComponents/ReportDownload";
import { CurrencyFormating } from "../../helpers/currencyHelper";
  
// const { faker } = require('@faker-js/faker');

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);
  

  function NftPurchase() {

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [nfts, setNfts] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [count, setCount] = useState({revenue:0, nfts:0});
    const [barCount, setBarCount] = useState({monthlyRevenueData:[], monthlyNftData:[]});
    const [search, setSearch] = useState("");
    let [loading, setLoading] = useState({counts:false, dtable:false});

    const getNftPurchaseDetails = async() =>{

        setLoading(prevState => ({...prevState, dtable:true}));

        await axios.get(`/get-nft-purchase-details/?limit=${limit}&page=${page}&search=${search}`)
        .then( res => {
            // console.log(res.data)
            if(res.data.success === true) {
                console.log("......nft purchase details....", res.data);
                setNfts(res.data.NftPurchased);
                setTotalRows(res.data.pagination.totalRows);
                setLoading(prevState => ({...prevState, dtable:false}));
            } else {
                console.log("err");
                setLoading(prevState => ({...prevState, dtable:false}));
            }
        })
        .catch(err => {
            console.log("getNftPurchaseDetails err", err);
            setLoading(prevState => ({...prevState, dtable:false}));
        })
    }

    const getRevenueData = async() => {
        await axios.get('/get-nft-purchase-revenue-data')
        .then((res)=>{
            // console.log(res.data);
            if(res.data.success === true) {
                let {revenue, nfts} = res.data.data;
                let { monthlyRevenueData, monthlyNftData } = res.data.data;
                setCount({revenue, nfts});
                setBarCount({monthlyRevenueData, monthlyNftData});
            }
        })
        .catch(err=>{
            console.log(err);
        })
    }
    
    let formatter = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "2-digit"
      });

    const getNfts = () =>{
        const allNfts = []
        nfts?.map((item,index)=> 
        { 
            // console.log(item)
            allNfts.push({
                id: item._id,
                image:(
                        <div key={index+'A'} className="man_user"><img src={item.nft.fileUrl}/></div>
                ),
                name:  [
                        <div key={index+'B'} className="management_list_name nft_title">
                        <div className="man_text">{item.nft.title!=""? item.nft.title : `${item.creatorDetails.nickName.fName} ${item.creatorDetails.nickName.lName}`}</div>
                        </div>
                        ],
                invoiceNo: [<div key={index+'C'} className="man_email">{item?.payment?.paymentId}</div>],
                nftprice: [<div key={index+'D'} className="man_email" style={{marginLeft: '1rem'}}>{CurrencyFormating(item.price)}</div>],
                refNo: [<div key={index+'E'} className="man_email">{
                    item?.payment?.payment_method === "CARD" ? item?.payment?.charge_id : item?.payment?.payment_method === "WALLET" ? item?.payment?.wallet : ""
                }</div>],
                // <div className="man_email">{item?.payment?.charge_id}</div>
                // nftdate: [<div className="man_ph">{moment(item.createdAt).format("DD/MM/YYYY")}</div>],
                payment_method: [<div key={index+'F'} className="man_ph text_center">{item?.payment?.payment_method}</div>],
                nftdate: [<div key={index+'G'} className="man_ph">{moment(item.createdAt).format("MM-DD-YYYY")}</div>],
                whosellnft: <div key={index+'H'} className="man_ph buy_nftts">{item.sellerDetails?.name}<p><small>@{item.sellerDetails?.userName}</small></p></div>,
                editions: <div key={index+'I'} className="man_ph buy_nftts" style={{marginLeft: "1rem"}}>{item.nft.editions}</div>,
                whobuynft: <div key={index+'J'} className="man_ph buy_nftts">{item.buyerDetails?.name}<p><small>@{item.buyerDetails?.userName}</small></p></div>,
                view: <Link to={{
                    pathname: `/purchase-details/${item._id}`,
                    state:{ data:item }
                    }} style={{marginLeft: "0.1rem", color: "white"}} className="view_hover">
                        <span style={{fontSize: "14px"}}>View</span>
                    </Link>, 
                    });
        });
        return allNfts;
    }

    const getBarData = () => {
        // console.log(barCount);
        const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const BarData = {
            labels,
            datasets: [
            {
                label: 'NFT Purchase Revenue',
                data: barCount?.monthlyRevenueData,
                backgroundColor: '#01C1C1',
                nfts: barCount?.monthlyNftData
            },
            // {
            //     label: 'NFT Purchased',
            //     data: barCount?.monthlyNftData,
            //     backgroundColor: '#01C1C1',
            // },
            ],
        }
        return BarData;
    }

    useEffect(()=>{

        getNftPurchaseDetails();
        getRevenueData();
    
    },[limit,page,search]);

    const handlePageChange = (page) =>{
        //console.log(page)
        setPage(page);
    }

    const handlePerRowsChange = (newPerPage, page) =>{
        console.log(newPerPage, page);
        setLimit(newPerPage);
        setPage(page);
    }

    const CustomLoader = () => (
        <div style={{ padding: '15px', background: '#495A71', color:'#fff', width:'100%', fontSize:'40px', textAlign:'center', minHeight:'350px', lineHeight:'400px' }}>
        		<div>Loading...</div>
        	</div>
    );
    const EmptyDataLoader = () => (
        <div style={{ padding: '15px', background: '#495A71', color:'#fff', width:'100%', fontSize:'40px', textAlign:'center', minHeight:'350px', lineHeight:'400px' }}>
        		<div>No data found...</div>
        	</div>
    );
    
    return (
        <div >
                <div className="dash_partts">
                    <div className="dash_part_heading">
                            <div className="dash_part_heading_left">
                                <h2>NFT Purchase</h2>
                            </div>
                            <div className="nft_drop">
                                <ReportDownload 
                                    // Csvfields={["title", "paymentId", "price", "sellerDetails", "buyerDetails.name", "mode"]}
                                    type="nftPurchase"
                                    totalNftPurchasedCount={totalRows}
                                    totalSearch={search}
                                />
                            </div>
                        </div>
                        <div className="nft_profiless" id="nftPurchasePDF">
                            <Row>
                                <Col lg="6" md="6" sm="12">
                                    <div className="active_inner_list active_inner_height inner_height_first">
                                        <div className="active_flex_box">
                                            <div className="active_heading_parttts">
                                                <h2>NFT Purchase Revenue</h2>
                                                <div className="active_image_text">
                                                    <div className="active_text active_label">{CurrencyFormating(count?.revenue)}</div>
                                                </div>
                                            </div>
                                            <div className="active_image_parttts">
                                                <img src={Nft_Purchase2_Img}  className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                    <div className="active_inner_list active_inner_height">
                                        <Bar 
                                        // data={} 
                                            data={getBarData()} 
                                            options={options200}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                </div>

                <div className="user_management_list">
                    <div className='manage_heading_search'>
                        <h2>NFT Purchase List</h2>
                        <div className='manage_texfieldt'>
                            <Form.Control type="text" placeholder="Search here.."  className='manage_search_boxxx' onChange={(e)=>setSearch(e.target.value)}/>
                            <span className='search_imgg_manage'><img src={Managesearch_img} /></span>
                        </div>
                    </div>
                <div className="manage_table">
                    <div className="table-responsive">
                        <DataTable
                        className="hypTable1"
                            columns={columns}
                            data={getNfts()}
                            responsive
                            customStyles={customStyles}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            noDataComponent={<EmptyDataLoader/>}
                            progressComponent={<CustomLoader />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={limit}
                            paginationRowsPerPageOptions={[10, 20, 40]}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressPending={loading.dtable}
                        />
                    </div>
                    </div>
                </div>
        </div>
        );
    };

    
export default NftPurchase;


export const options200 = {
  responsive: true,
  plugins: {
    tooltip: {
        callbacks: {

            label: function(context) {
                let label = [];
                let rev = context.dataset.label || '';
                if (rev) {
                    rev += ': ';
                }
                if (context.parsed.y !== null) {
                    rev += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                label.push(rev); 
                if (context.parsed.y !== null) {
                    label.push(`Nfts: ${context.dataset.nfts[context.parsed.x]}`)
                }  
                
                // console.log(label);

                return label;

                }
        }
    },
    legend: {
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
  scales: {
    y: {
      ticks: { color: "#fff", beginAtZero: true },
    },
    x: {
      ticks: { color: "#fff", beginAtZero: true },
    },
  },
};

// const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// export const data200 = {
//   labels,
//   datasets: [
//     {
//       label: 'NFT Purchase Revenue',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#01C1C1',
//     },
//   ],
// };

const columns = [
    {
        name: '',
        selector: row => row.image,
        // reorder: true,
        width: "50px",
    },
    {
        name: 'NFT Title',
        selector: row => row.name,
        // reorder: true,
        width: "170px",
    },
    {
        name: 'Invoice no.',
        selector: row => row.invoiceNo,
        // reorder: true,
        width: "200px",
    },
    {
        name: 'NFT Price',
        selector: row => row.nftprice,
        // reorder: true,
        width: "100px",
    },
    {
        name: 'Reference no.',
        selector: row => row.refNo,
        // reorder: true,
        width: "190px",
    },
    {
        name: 'Payment Method',
        selector: row => row.payment_method,
        // reorder: true,
        width: "150px",
    },
    {
        name: 'Date',
        selector: row => row.nftdate,
            // reorder: true,
            width: "100px",
    },

    {
        name: 'Seller',
        selector: row => row.whosellnft,
        // reorder: true,
        width: "110px",
    },
    {
        name: 'Edition',
        selector: row => row.editions,
        // reorder: true,
        width: "75px",
    },

    {
        name: 'Buyer',
        selector: row => row.whobuynft,
        // reorder: true,
        width: "110px",
    },

    {
        name: 'Action',
        selector: row => row.view,
        // reorder: true,
        width: "100px",
    },
    
    ];
const customStyles = {
    rows: {
        style: {
            //minHeight: '72px',
            backgroundColor: 'transparent',
            color:'#fff',
            paddingLeft: '10px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '15px', // override the cell padding for head cells
            paddingRight: '2px',
            backgroundColor: 'transparent',
            color:'#fff',
            fontSize: "16px",
        },
    },
    cells: {
        style: {
            paddingLeft: '5px', // override the cell padding for data cells
            paddingRight: '2px',
            paddingTop: '5px',
            paddingBottom: '5px',
            backgroundColor: 'transparent',
            color:'#fff',
            fontSize: "14px",
        },
    },
};



