import React, { useEffect, useState } from 'react';
import "./faq.css";
import Accordion from 'react-bootstrap/Accordion';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import axios from "../../axios/axios";
import { message } from "antd";
import { Delete_img, Edit_img, Noimg_Img } from '../../assets/images';
import { Form, Modal } from 'react-bootstrap';

const Faq = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [faqData, setFaqData] = useState({});
    const [faqId, setFaqId] = useState("");
    const [show, setShow] = useState(false);
    let count = 0;
    
    const getFaqList = async () => {
        setLoading(true);
        await axios
        .get("/get-faqs")
        .then((res) => {
            console.log("get faq list response...", res);
            if(res.data.success === true){
                setFaqData(res.data)
            }
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }
    
    useEffect(() =>{
        getFaqList();
    }, [])
    
    const editFaq = (id) => {
        history.push(`/edit-faq/${id}`);
    }
    
    const deleteFaq = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        console.log("Delete data...  /delete-faq/{faqId}");
        console.log("Deleted faq id... ", id);
        await axios
        .delete(`/delete-faq/${id}`)
        .then((res) => {
            if (res.data.success === true) {
                getFaqList();
                message.error("FAQ Deleted successfully");
                setLoading(false);
                setShow(false);
            } else {
                message.error("FAQ deletion failed");
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log("error", error);
            setLoading(false);
        })
    }
    
    const addNewFaq = () => {
        history.push('/add-faq');
    };
    
    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
    };

  return (
    <React.Fragment>
        <Modal show={show} onHide={handleClose} size="md">
            <div className="mail_container">
                <Modal.Header closeButton onClick={handleClose}>
                    <Modal.Title className="modal-title w-100 text-center">
                        Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <p className="alert_p">Do you want to delete the faq's records?</p>
                        </Form.Group>
                        
                        <div className="deleteLoader d-flex justify-content-end">
                            <LoadingOverlay
                            spinner={
                                <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
                            }
                            >
                                <div className='email-button-container'>
                                    <div className="mx-3">
                                        <button type="submit" className="alert-button ml-0" onClick={(e) => deleteFaq(e, faqId)}>Delete</button>
                                    </div>
                                    <div>
                                        <button className="notification_update_button email-button" onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>
        <div className="container user_inner_partts email_partts">
            <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
            
            <div className="manage_heading_search mb-4">
                <h2>FAQ List</h2>
                <button className="add_new_promo" onClick={addNewFaq}>
                    {/* <Link to={`/add-new-promo-code`} className="link_style">
                        Add New Faq
                    </Link> */}
                    Add New FAQ
                </button>
              </div>
              
              <Accordion defaultActiveKey="0" className="faq_cntn">
              {
                faqData?.faqs && faqData?.faqs?.map((item, ind) => {
                    count += 1;
                    return(
                        <React.Fragment>
                            <Accordion.Item eventKey={count} style={{backgroundColor: "transparent"}} keys={ind}>
                                <Accordion.Header>
                                <span> {item.question} </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{__html: item.answer}} />
                                        <div className="faq_edit_sec">
                                            <div className="fes_edit" onClick={() => editFaq(item._id)}>
                                                <img alt="No Img" src={Edit_img} />Edit
                                            </div>
                                            <div className="fes_delete" onClick={() =>{setShow(true); setFaqId(item._id)}}>
                                                <img src={Delete_img} alt={Noimg_Img} />Delete
                                            </div>
                                        </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </React.Fragment>
                    )
                })
              }
                
            </Accordion>
            </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Faq;