/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { No_ProfImg } from "../../assets/images";
import './timelineNew.css'

import { Row, Col, Form } from "react-bootstrap";
import axios from "../../axios/axios";
import { CurrencyFormating } from "../../helpers/currencyHelper";

// import {
//   marchindise_about,
//   marchindise_variety,
//   marchindise_atributes,
//   marchindise_inventory,
//   marchindise_finish,
// } from "../../assets/images";

// import ManageColorImages from "./ManageColorImages";
//import marchindise_about from "https://picsum.photos/164";


// let imgs = [
//     'https://picsum.photos/164',
// ];



function Timeline() {
    const [timelineAll, setTimelineAll] = useState([]);
    const [timeline, setTimeline] = useState([]);
    const [editions, setEditions] = useState([]);
    const [ed, setSelected] = useState(0);
    // let [loading, setLoading] = useState(false);
    const history = useHistory();
    const { state } = useLocation();
    const NFT_identity = state?.NFT_identity;
    const profile = state?.data;

    let selectEditionWise = (edition) => {
        console.log("edition", edition)
        let d = timelineAll.filter(s => s.nft.editions == edition)
        setTimeline(d)
    }

    useEffect(()=>{
        selectEditionWise(1)
        setSelected(1)
    },[editions])


    //   const NFT_identity =1;
    const getTimeline = async () => {
        // setLoading(true);
        await axios.get(`/get-nft-timeline/${NFT_identity}`).then((response) => {
            console.log("timeline", response.data);
            // setLoading(false);
            setTimelineAll(response.data.data);
            Promise.all(response.data.data.map((s) => s.nft.editions)).then((data) => {
                data = [...new Set(data)]
                setEditions(data)
               
                
            })

        }).catch((error) => {
            console.log(error);
            // setLoading(false);
        })
    }
    useEffect(() => {
        if (NFT_identity) {
            getTimeline();
        }
        else {
            console.log(NFT_identity, "redirect")
            history.push('/nfts');
        }

    }, [NFT_identity]);

    return (
        <div>
            <div className="dash_partts">
                <div className="kevin_bio">
                    <div className="kevin_inner_bio">
                        <div className="kevin_inner_image">
                            <img src={profile?.fileUrl} alt="No Img" />
                        </div>
                        <div className="kevin_inner_text">
                            <div className="kevin_inner_title">
                                <p>
                                    <span className="minted">
                                        Minted: {moment(profile?.createdAt).format("MM-DD-YYYY")}
                                    </span>{" "}
                                </p>
                            </div>
                            <div className="kevin_inner_heading">
                                <h3>Title : {profile?.title != "" ? profile?.title : `${profile?.creator.nickName.fName}  ${profile?.creator.nickName.lName}`}</h3>
                                <h4 style={{ color: profile?.nftHolder?.name ? "rgba(255, 255, 255, 0.5)" : "#ff7575" }}>Holder : {profile?.nftHolder?.name ?? "DELETED USER"}<p><small>@{profile.nftHolder.userName}</small></p></h4>
                            </div>


                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="4" md="4" sm="12" className="mt-3 mb-3">

                        {editions.length>0 &&(
                            <Form.Group
                                className="mb-3 edit_text_fielddds"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Editions</Form.Label>
                                <Form.Select
                                    aria-label="Default select example form-control"
                                    className="text_field_parttss callCode"
                                    onChange={(e) => {
                                        setSelected(e.target.value)
                                        selectEditionWise(e.target.value)
                                    }}
                                    defaultValue={ed}
                                >
                                    <option value="">Select</option>
                                    {editions.map((s, i) => {
                                        return <option key={i} value={s}>{`Edition ${s}`}</option>

                                    })}

                                </Form.Select>
                            </Form.Group>
                        )}
                    </Col>
                    <Col lg="12" md="12" sm="12" className="ps-0 pe-0">


                        <section className="timelineSec">
                            <div className="container py-5">
                                <div className="row">

                                    <div className="col-md-12">


                                        <div className="time_line">
                                            <ul className="timelineUL">
                                                {timeline.length > 0 && (
                                                    timeline.map((item, index) => {
                                                        return (<>
                                                            {index === 0 && (
                                                                <li key={index + "B"}>
                                                                    <div className="timeline_item">
                                                                        <div className="number"><span><img src={item?.sellerProfile?.thumbnailUrl != "" ? item?.sellerProfile?.thumbnailUrl : No_ProfImg} className="img img-fluid" alt="" /></span></div>
                                                                        <div className="timeline_content">
                                                                            <h4>{item?.seller?.name}<p><small>@{item?.seller?.userName}</small>
                                                                                {item?.seller?.nickName && (
                                                                                    <small> {' | '}{`${item?.seller?.nickName?.fName} ${item?.seller?.nickName?.lName}`}</small>
                                                                                )}
                                                                            </p>
                                                                            </h4>

                                                                            <p>{CurrencyFormating(item.price)}</p>
                                                                            <small>{moment(item.createdAt).format("MM-DD-YYYY")}</small>

                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}

                                                            <li key={index}>
                                                                <div className="timeline_item">
                                                                    <div className="number"><span><img src={item?.buyerProfile?.thumbnailUrl != "" ? item?.buyerProfile?.thumbnailUrl : No_ProfImg} className="img img-fluid" alt="" /></span></div>
                                                                    <div className="timeline_content">
                                                                        <h4>{item?.buyer?.name}<p><small>@{item?.buyer?.userName}</small>
                                                                            {item?.buyer?.nickName && (
                                                                                <small> {' | '}{`${item?.buyer?.nickName?.fName} ${item?.buyer?.nickName?.lName}`}</small>
                                                                            )}
                                                                        </p>
                                                                        </h4>

                                                                        <p>{CurrencyFormating(item.price)}</p>
                                                                        <small>{moment(item.createdAt).format("MM-DD-YYYY")}</small>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </>)
                                                    }))}
                                                {/* <li>
                                                         <div className="timeline_item">
                                                             <div className="number"><span>2</span></div>
                                                             <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                 tempor incididunt ut labore et dolore magna aliqua.</div>
                                                         </div>
                                                     </li> */}
                                                {/* <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>3</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>4</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>5</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>6</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>7</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>8</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>9</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>10</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>11</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="timeline_item">
                                                            <div className="number"><span>12</span></div>
                                                            <div className="timeline_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua.</div>
                                                        </div>
                                                    </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>



                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Timeline;
