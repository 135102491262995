import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import ForgotPassword from '../modals/forgotPassword';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./resetPassword.css";
import { LoginLeft_img, Logo_img, Eye_img } from "../../assets/images";
import Alert from 'react-bootstrap/Alert';
import axios from '../../axios/axios';
import { useParams } from 'react-router-dom';
import {message} from "antd"

export default function ResetPassword() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
    };

    const {token} = useParams();
   
    //states declararions.....
    const [creds, setCreds] = useState({otp:"",password:"",confirm_password:""});
    const [credsError, setCredsError] = useState({passworderr:"",passwordConfErr:""});
    const [mask, setMask] = useState(true)
    //const [showModal, setshow]

    const passwordValidator =()=>{
        if(creds.password.trim() === ''){
            setCredsError({...credsError, passworderr:"Password is required"})
            return false;
        } else if (creds.password.length<6){
            setCredsError({...credsError, passworderr:"Minimum password length is 6"})
            return false;
        } else {
            setCredsError({...credsError, passworderr:""});
            return true;
        }
    }

    const passwordConfirmValidator =()=>{
        if(creds.confirm_password.trim() === ''){
            setCredsError({...credsError, passwordConfErr:"Password is required"})
            return false;
        } else if (creds.confirm_password.length<6){
            setCredsError({...credsError, passwordConfErr:"Minimum password length is 6"})
            return false;
        } else if (creds.confirm_password !== creds.password){
            setCredsError({...credsError, passwordConfErr:"Confirm Password should be same as Password"})
            return false;
        } else {
            setCredsError({...credsError, passwordConfErr:""});
            return true;
        }
    }

    const toggleMasking =()=>{
        setMask(!mask); 
    }

    const handleChange =(e)=>{
        setCreds({
            ...creds, 
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async(e)=>{ 
        e.preventDefault();
        let data = {...creds, otp: token}; 
        if(passwordValidator() && passwordConfirmValidator())
            {
                await axios.post("/reset-password-confirmation",data)
                .then((res)=>{
                    //console.log("res",res.data);
                    if(res.data.success === true){
                       message.success("Password changes")   
                    } else {
                        message.error("Error")
                    }
                })
                .catch((err)=>{
                    console.log("err",err)
                })
            } else {
            // setShow({alert:false,success:false,inputerr:true})
        }
    }
    
    return (
      <>
        <div className="login_box">
          <div className="login_left">
            <div className="login_left_inner">
              <div>
                <div className="login_logo text-center">
                  <img src={Logo_img} />
                </div>
                <div className="login_text">
                  <Carousel
                    responsive={responsive}
                    centerMode={false}
                    className="slider__text"
                    infinite="false"
                    renderDotsOutside={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    showDots={true}
                    arrows={false}
                  >
                    <div className="slider_text_part">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                      </p>
                    </div>
                    <div className="slider_text_part">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                      </p>
                    </div>
                    <div className="slider_text_part">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                      </p>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="login_right">
            <div className="login_into_dashboard">
              <h2>Reset Password</h2>
              {/* <Alert key={"danger"} variant={"danger"} show={show.alert}>Otp expired</Alert>
              <Alert key={"success"} variant={"success"} show={show.success}>Alas!! Password changed</Alert> */}
              {/* <Alert key={"danger"} variant={"danger"} show={show.inputerr}>Invalid Input</Alert> */}
              <div className="login__right_form">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicOtp">
                    <Form.Label>OTP</Form.Label>
                    <div className="field_img">
                      <Form.Control
                        name="otp" 
                        type="text" 
                        value={token}
                        readOnly={true}
                        className="field_part" 
                        autoComplete='off'/>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <div className="field_img">
                      <Form.Control 
                        name="password"
                        type={mask?"password":"text"}
                        value={creds.password}
                        onChange={handleChange}
                        onBlur={passwordValidator}
                        className="field_part" 
                        autoComplete='off' />

                      <span className="eye_img">
                        <img src={Eye_img} onClick={toggleMasking}/>
                      </span>
                      <p style={{"color":"red"}}>{credsError.passworderr}</p>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="field_img">
                      <Form.Control 
                        name="confirm_password"
                        type={mask?"password":"text"}
                        value={creds.passwordConf}
                        onChange={handleChange}
                        onBlur={passwordConfirmValidator}
                        className="field_part" 
                        autoComplete='off'/>

                      <span className="eye_img">
                        <img src={Eye_img} onClick={toggleMasking}/>
                      </span>
                      <p style={{"color":"red"}}>{credsError.passwordConfErr}</p>
                    </div>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="dash_login_but"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

