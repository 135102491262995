import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

const FrameHeader = (props) => {
	return (
		<div className="nft_user_top_heading frame_header">
			<h2>Cards</h2>
			<div className="addnew_badge_partts">
				<button className="new_badge1" onClick={props.onClick}>
					Add New Card
				</button>
			</div>
		</div>
	)
}

export default FrameHeader