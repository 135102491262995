import React, { useEffect } from 'react';
import {Col, Row, Form} from "react-bootstrap";

const MintBalance = (props) => {
    const [resetType, setResetType] = React.useState({
        type: "",
        sequence: "",
        day: ""
    });
    
    const getProps = () => {
        setResetType(props.sendMint);
        // setPrevState(props.sendMint);
    };

    
        
    useEffect(() => {
        getProps();
        // console.log("useEffect consolelog", props.sendMint);
      }, [props.sendMint]);  // #Changes
      
  return (
    <React.Fragment>
        <Col md={6}>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Type</Form.Label>
                        <Form.Select aria-label="Default select example"
                            name='type'
                            onChange={(evt) => {
                                let {value} = evt.target;
                                
                                
                                resetType.type = value;
                                
                                // if(value === props.sendPrevMint.type){
                                //     resetType.day = props.sendPrevMint.day
                                    
                                // }
                                
                                // console.log("Onchange value..", value, prevState.type)
                                resetType.day = "";
                                if(value === "quarterly" || value === "yearly"){
                                    // resetType.sequence = "";
                                    delete resetType["sequence"]
                                }
                                setResetType({
                                    resetType
                                })
                                
                                // setResetType({...resetType, type: evt.target.value})
                                props.changeData({...resetType, type: evt.target.value})
                            }}
                            className="text_box"
                        >
                            <option value="">Select</option>
                            <option value="monthly" selected={props.sendMint?.type==="monthly"}>Monthly</option>
                            <option value="quarterly" selected={props.sendMint?.type==="quarterly"}>Quarterly</option>
                            <option value="yearly" selected={props.sendMint?.type==="yearly"}>Yearly</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                
            {
                props.sendMint?.type === "monthly" ?
                <React.Fragment>
                        {
                            props.sendMint?.type === "monthly" && (
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Sequence</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            name="sequence"
                                            value={resetType.sequence}
                                            required
                                            className="text_box"
                                            onChange={(evt) => {
                                                setResetType({...resetType, sequence: evt.target.value})
                                                props.changeData({
                                                    ...resetType,
                                                    sequence: evt.target.value
                                                })
                                            }}
                                        >
                                        <option value="">Select</option>
                                        <option value="1" selected={props.sendMint?.sequence==="1"}>1</option>
                                        <option value="2" selected={props.sendMint?.sequence==="2"}>2</option>
                                        <option value="3" selected={props.sendMint?.sequence==="3"}>3</option>
                                        <option value="4" selected={props.sendMint?.sequence==="4"}>4</option>
                                    </Form.Select>
                                    {/* {props.sendMint?.type === "monthly" && resetType.sequence === ""
                                            ? <p className="errorMessage">required</p> : ""
                                    } */}
                                    {/* {props.sendMint?.type === "quarterly" && resetType.sequence === ""
                                            ? <p className="errorMessage">required</p> : ""
                                    } */}
                                    
                                    <p className="errorMessage">{props.sequnceErr}</p>
                                </Form.Group>
                            </Col>
                            )
                        }
                    <Col md={3}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Day</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="day"
                                value={resetType.day}
                                required
                                className="text_box"
                                onChange={(evt) => {
                                    setResetType({...resetType, day: evt.target.value})
                                    props.changeData({...resetType, day: evt.target.value})
                                }}
                            >
                            <option value="">Select</option>
                            <option value="Monday" selected={props.sendMint?.day==="Monday"}>Monday</option>
                            <option value="Tuesday" selected={props.sendMint?.day==="Tuesday"}>Tuesday</option>
                            <option value="Wednesday" selected={props.sendMint?.day==="Wednesday"}>Wednesday</option>
                            <option value="Thursday" selected={props.sendMint?.day==="Thursday"}>Thursday</option>
                            <option value="Friday" selected={props.sendMint?.day==="Friday"}>Friday</option>
                            <option value="Saturday" selected={props.sendMint?.day==="Saturday"}>Saturday</option>
                            <option value="Sunday" selected={props.sendMint?.day==="Sunday"}>Sunday</option>
                        </Form.Select>
                            {/* {props.sendMint?.type === "monthly" && resetType.day === ""
                                ? <p className="errorMessage">required</p> : ""
                            } */}
                            <p className="errorMessage">{props.dayErr}</p>
                        </Form.Group>
                    </Col>
                </React.Fragment> :
                props.sendMint?.type==="quarterly" ?
                <React.Fragment>
                {
                    props.sendMint?.type === "quarterly" && (
                        <Col md={3}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Select Day</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    name="day"
                                    value={resetType.day}
                                    required
                                    className="text_box"
                                    onChange={(evt) => {
                                        setResetType({...resetType, day: evt.target.value})
                                        props.changeData({...resetType, day: evt.target.value})
                                    }}
                                >
                                    <option value="">Select</option>
                                    <option value="1" selected={props.sendMint?.day==="1"}>1</option>
                                        <option value="2" selected={props.sendMint?.day==="2"}>2</option>
                                        <option value="3" selected={props.sendMint?.day==="3"}>3</option>
                                        <option value="4" selected={props.sendMint?.day==="4"}>4</option>
                                        <option value="5" selected={props.sendMint?.day==="5"}>5</option>
                                        <option value="6" selected={props.sendMint?.day==="6"}>6</option>
                                        <option value="7" selected={props.sendMint?.day==="7"}>7</option>
                                        <option value="8" selected={props.sendMint?.day==="8"}>8</option>
                                        <option value="9" selected={props.sendMint?.day==="9"}>9</option>
                                        <option value="10" selected={props.sendMint?.day==="10"}>10</option>
                                        <option value="11" selected={props.sendMint?.day==="11"}>11</option>
                                        <option value="12" selected={props.sendMint?.day==="12"}>12</option>
                                        <option value="13" selected={props.sendMint?.day==="13"}>13</option>
                                        <option value="14" selected={props.sendMint?.day==="14"}>14</option>
                                        <option value="15" selected={props.sendMint?.day==="15"}>15</option>
                                        <option value="16" selected={props.sendMint?.day==="16"}>16</option>
                                        <option value="17" selected={props.sendMint?.day==="17"}>17</option>
                                        <option value="18" selected={props.sendMint?.day==="18"}>18</option>
                                        <option value="19" selected={props.sendMint?.day==="19"}>19</option>
                                        <option value="20" selected={props.sendMint?.day==="20"}>20</option>
                                        <option value="21" selected={props.sendMint?.day==="21"}>21</option>
                                        <option value="22" selected={props.sendMint?.day==="22"}>22</option>
                                        <option value="23" selected={props.sendMint?.day==="23"}>23</option>
                                        <option value="24" selected={props.sendMint?.day==="24"}>24</option>
                                        <option value="25" selected={props.sendMint?.day==="25"}>25</option>
                                        <option value="26" selected={props.sendMint?.day==="26"}>26</option>
                                        <option value="27" selected={props.sendMint?.day==="27"}>27</option>
                                        <option value="28" selected={props.sendMint?.day==="28"}>28</option>
                                
                                </Form.Select>
                                {/* {props.sendMint?.type === "quarterly" && resetType.day === ""
                                    ? <p className="errorMessage">required</p> : ""
                                } */}
                                <p className="errorMessage">{props.dayErr}</p>
                            </Form.Group>
                        </Col>
                    )
                }
                </React.Fragment>
                
                 : props.sendMint?.type==="yearly" ?
                <React.Fragment>
                    {
                        props.sendMint?.type === "yearly" && (
                            <Col md={3}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Select Day</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        name="day"
                                        value={resetType.day}
                                        required
                                        className="text_box"
                                        onChange={(evt) => {
                                            setResetType({...resetType, day: evt.target.value})
                                            props.changeData({...resetType, day: evt.target.value})
                                        }}
                                    >
                                        <option value="">Select</option>
                                        <option value="1" selected={props.sendMint?.day==="1"}>1</option>
                                        <option value="2" selected={props.sendMint?.day==="2"}>2</option>
                                        <option value="3" selected={props.sendMint?.day==="3"}>3</option>
                                        <option value="4" selected={props.sendMint?.day==="4"}>4</option>
                                        <option value="5" selected={props.sendMint?.day==="5"}>5</option>
                                        <option value="6" selected={props.sendMint?.day==="6"}>6</option>
                                        <option value="7" selected={props.sendMint?.day==="7"}>7</option>
                                        <option value="8" selected={props.sendMint?.day==="8"}>8</option>
                                        <option value="9" selected={props.sendMint?.day==="9"}>9</option>
                                        <option value="10" selected={props.sendMint?.day==="10"}>10</option>
                                        <option value="11" selected={props.sendMint?.day==="11"}>11</option>
                                        <option value="12" selected={props.sendMint?.day==="12"}>12</option>
                                        <option value="13" selected={props.sendMint?.day==="13"}>13</option>
                                        <option value="14" selected={props.sendMint?.day==="14"}>14</option>
                                        <option value="15" selected={props.sendMint?.day==="15"}>15</option>
                                        <option value="16" selected={props.sendMint?.day==="16"}>16</option>
                                        <option value="17" selected={props.sendMint?.day==="17"}>17</option>
                                        <option value="18" selected={props.sendMint?.day==="18"}>18</option>
                                        <option value="19" selected={props.sendMint?.day==="19"}>19</option>
                                        <option value="20" selected={props.sendMint?.day==="20"}>20</option>
                                        <option value="21" selected={props.sendMint?.day==="21"}>21</option>
                                        <option value="22" selected={props.sendMint?.day==="22"}>22</option>
                                        <option value="23" selected={props.sendMint?.day==="23"}>23</option>
                                        <option value="24" selected={props.sendMint?.day==="24"}>24</option>
                                        <option value="25" selected={props.sendMint?.day==="25"}>25</option>
                                        <option value="26" selected={props.sendMint?.day==="26"}>26</option>
                                        <option value="27" selected={props.sendMint?.day==="27"}>27</option>
                                        <option value="28" selected={props.sendMint?.day==="28"}>28</option>
                                    </Form.Select>
                                    {/* {props.sendMint?.type === "yearly" && resetType.day === ""
                                        ? <p className="errorMessage">required</p> : ""
                                    } */}
                                    <p className="errorMessage">{props.dayErr}</p>
                                </Form.Group>
                            </Col>
                        )
                    }
                </React.Fragment>
                : ""
            }
            </Row>
        </Col>
    </React.Fragment>
  )
}
export default MintBalance;