import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import "./emailTemplate.css";
import message from "../../messages";
import axios from "../../axios/axios";
import {PulseLoader} from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const TestMail = (props) => {
  const [show, setShow] = useState(false);
  const [creds, setCreds] = useState({
    email: "",
    subject: "",
    message: "",
    testMail_id: ""
  });
  const [loading, setLoading] = useState(false);

  const [credsError, setCredsError] = useState({
    emailerr: "",
  });
  
  
  const getTemplateId = () => {
    setCreds({
      testMail_id: props.sendMailId,
    })
  }
  const getMailDetails = () => {
    // setCreds(props.sendMailDetail)
    setCreds({
      subject: props.sendMailDetail?.subject,
      message: props.sendMailDetail?.content
    })
  }
  
  useEffect(() =>{
    getTemplateId();
    getMailDetails();
    }, [creds.testMail_id, props.sendMailDetail]);
  
  // email validator
  const emailValidatior = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (creds.email.trim() === "") {
      setCredsError((prevState) => ({
        ...prevState,
        emailerr: "Email is required",
      }));
    } else if (reg.test(creds.email) === false) {
      setCredsError((prevState) => ({
        ...prevState,
        emailerr: "Email is invalid",
      }));
    } else {
      setCredsError((prevState) => ({ ...prevState, emailerr: "" }));
    }
  };

  const handleChange = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    emailValidatior();
    setLoading(true);
      await axios
        .post("/test-mail", creds)
        .then((res) => {
          console.log("check entered credentials..........", creds);
          message.success("Mail send successfully.");
          setShow(false);         // close popup
          clearData();           // clear data
          setLoading(false);    // loading off
        })
        .catch((error) => {
          message.error("mail send fail");
          console.log(error);
          setLoading(false);
        });
  };

  // clear data on 'handleClose'
  const clearData = () => {
    setCreds({
      email: "",
      testMail_id: "",
    });
  };
  
 // clear error on 'handleClose'
  const errorClearData = () => {
    setCredsError({
      emailerr: "",
    });
  };

  const handleClose = () => {
    clearData();
    errorClearData();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="md">
        
      <div className="mail_container">
            <Modal.Header closeButton>
            <Modal.Title className="modal-title w-100 text-center">
                Test Mail
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>To Email address</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={creds.email}
                    onChange={handleChange}
                    onBlur={emailValidatior}
                    className="text_box"
                    placeholder="name@example.com"
                />
                {/* {creds.email !== "" ? <p style={{ color: "red" }}>{credsError.emailerr}</p> : ""} */}
                <p style={{ color: "red" }}>{credsError.emailerr}</p>
                </Form.Group>

                <div className="mailLoder">
                  <LoadingOverlay
                    active={loading}
                    spinner={
                      <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
                    }
                  >
                  <button
                      className="email-button ml-0"
                      type="submit"
                      onClick={sendMail}
                  >
                    send
                  </button>
                </LoadingOverlay>
                </div>
            </Form>
            </Modal.Body>
        </div>
      </Modal>

      <div className="addnew_modal">
        <button className="email-button test-button" onClick={handleShow}>
          Test Mail
        </button>
      </div>
    </div>
  );
};

export default TestMail;
