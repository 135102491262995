import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import {
  Choice_Img,
  Kevin_Common_Img,
  Edition1_Img,
  Edition2_Img,
  Edition3_Img,
  Edition4_Img,
} from "../../assets/images";
import axios from "../../axios/axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import NftEdition from "./NftEdition";
import { setNftDetails } from "../../actions/setNftDetails";

import "./NftDetails.css";
import NftOffers from "./NftOffers";
const NFTDetailsPage = () => {
  const { nftId } = useParams();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [likes, setLikes] = useState(0)
  const [nftCount, setNftCount] = useState({ minted: 0, notMinted: 0 })

  const getNftDetails = async () => {
    console.log("getNftDetails");
    // setLoading(prevState => ({...prevState, profile:true}));
    await axios
      .get(`/nft-details/${nftId}`)
      .then((res) => {
        if (res.data.success === true) {
          console.log(".....getNftDetails", res.data);

          if (res.data.nftDetail !== null) {
            setProfile(res.data.nftDetail);
            setLikes(res.data.nftLikes)
            setNftCount({minted: res.data.mintedNftCount, notMinted: res.data.notMintedNftCount})
            dispatch(setNftDetails(res.data.nftEditions));

            setLoading(false);
          } else {
            setProfile(null);
          }
        } else {
          message.error("Server error");
        }
      })
      .catch((err) => {
        console.log("nftDetail err", err);
      });

    // dispatch(nftDetailsAction.fetchNftDetails(nftId))
  };

  let nftDetailsInfo = useSelector((state) => state.setNftDetails);

  useEffect(() => {
    getNftDetails();
  }, []);

  return (
    <div>
      <LoadingOverlay
        className="z-3"
        active={loading}
        spinner={<PulseLoader color="white" size={50} />}
      >
        <div className="kevin_bio">
          <div className="kevin_inner_bio">
            <div className="kevin_inner_image">
              <img src={profile.fileUrl} alt="No Img" />
            </div>
            <div className="kevin_inner_text">
              <div className="kevin_inner_title">
                <p>
                  <span className="minted">
                    Minted: {moment(profile.createdAt).format("MM-DD-YYYY")}
                  </span>{" "}
                  {profile.team!="" &&(<>
                  <span className="devider_minted">|</span>{" "}
                  <span className="minted">{profile.team}</span>
                  </>)}

                  {profile.position!="" &&(<>
                  <span className="devider_minted">|</span>{" "}
                  <span className="minted">{profile.position  }</span>
                  </>)}
                </p>
                {/* <div className="choice_sign">
                  <img src={Choice_Img} alt="No Img" />
                  <span className="kevin_number">{likes ?? 0}</span>
                </div> */}
              </div>
              <div className="kevin_inner_heading">
                <div>
                  {/* <h3>Current Holder {" "}: {" "} <span>{profile.title}</span> </h3> */}
                  <h3>Title {" "}: {" "} <span>{profile.title != ""? profile.title: `${profile.creator.nickName.fName} ${profile.creator.nickName.lName}`}</span> </h3>
                </div>
                <div>
                  {/* <h3>Current Holder {" "}: {" "} <span>{profile.title}</span> </h3> */}
                  {/* <h5 style={{ color: "white" }}>Current Holder {" "}: {" "} <span>{profile.nftHolder?.name}</span> </h5> */}
                </div>
                <div>
                  <h6 style={{ color: "rgba(255, 255, 255, 0.5)" }}>Author {" "}: {" "} <span>{profile.creator?.name}</span><p><small>@{profile?.creator?.userName}</small></p></h6>
                </div>
              </div>
              <div className="kevin_badges">
                {/* <ul>
                  <li>
                    <img
                      alt="No Img"
                      width="32px"
                      src={profile.state && profile.state.lightBadges}
                    />
                  </li>
                  <li>
                    <img
                      width="32px"
                      alt="No Img"
                      src={profile.sports && profile.sports.lightBadges}
                    />
                  </li>
                  <li>
                    <img
                      alt="No Img"
                      width="32px"
                      src={profile.division && profile.division.lightBadges}
                    />
                  </li>
                </ul> */}
                <div className="kevin_common">
                  <img src={profile.nftType?.imageUrl} alt="No Img" />
                </div>
              </div>
              <div className="kevin_inner_para">
                <p>{profile.description}</p>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="edition_kevin">
          <Row>
            <Col lg="7" md="12" className="edition_leftts">
              <div className="edition_inner">
                <div className="edition_inner_header">
                  <h2>Editions: {nftDetailsInfo.length}</h2>
                  <h2>Minted: {nftCount.minted}</h2>
                  <h2>Not Minted: {nftCount.notMinted}</h2>
                </div>
                <div className="edition_inner_inner">
                  <NftEdition />

                  {/* <div className="edition_inner_inner_part">
                  <div className="edition_inner_center">
                    <div className="edition_center_left">
                      <div className="edition_user">
                        <img src={Edition1_Img} />
                      </div>
                      <div className="edition_user_text">1.1 supersally20</div>
                    </div>
                  </div>
                  <div className="edition_inner_center">
                    <div className="edition_center_middle">
                      by George Hermes
                    </div>
                  </div>
                  <div className="edition_inner_center">
                    <div className="edition_center_right">$25</div>
                  </div>
                </div> */}

                  {/* <NftEdition sendNftDetails={profile} /> */}

                </div>
              </div>
            </Col>
            <Col lg="5" md="12" className="edition_leftts">
              <div className="edition_inner">
                <div className="edition_inner_header">
                  <h2>Offers</h2>
                  {/* <a href="#/action-1" className="make_offer">
                    Make Offer
                  </a> */}
                </div>
                <div className="edition_inner_inner">
                  <NftOffers />
                  {/* <div className="edition_inner_inner_part">
                    <div className="edition_inner_center">
                      <div className="edition_center_left">
                        <div className="edition_user">
                          <img src={Edition1_Img} alt="No Img" />
                        </div>
                        <div className="edition_user_text">baracuda4u</div>
                      </div>
                    </div>
                    <div className="edition_inner_center">
                      <div className="edition_center_right">$25</div>
                    </div>
                  </div>
                  <div className="edition_inner_inner_part">
                    <div className="edition_inner_center">
                      <div className="edition_center_left">
                        <div className="edition_user">
                          <img src={Edition2_Img} alt="No Img" />
                        </div>
                        <div className="edition_user_text">hellokitty88</div>
                      </div>
                    </div>
                    <div className="edition_inner_center">
                      <div className="edition_center_right">$53</div>
                    </div>
                  </div>
                  <div className="edition_inner_inner_part">
                    <div className="edition_inner_center">
                      <div className="edition_center_left">
                        <div className="edition_user">
                          <img src={Edition3_Img} alt="No Img" />
                        </div>
                        <div className="edition_user_text">fatfoot44</div>
                      </div>
                    </div>
                    <div className="edition_inner_center">
                      <div className="edition_center_right">$49</div>
                    </div>
                  </div>
                  <div className="edition_inner_inner_part">
                    <div className="edition_inner_center">
                      <div className="edition_center_left">
                        <div className="edition_user">
                          <img src={Edition4_Img} alt="No Img" />
                        </div>
                        <div className="edition_user_text">fatfoot44</div>
                      </div>
                    </div>
                    <div className="edition_inner_center">
                      <div className="edition_center_right">$42</div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default NFTDetailsPage;
