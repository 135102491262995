import React, {useCallback, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import axios from '../../axios/axios'
import {Card, Modal, message} from 'antd';
import "./frames.css"
import FrameHeader from './FrameHeader';
import {Button, Dropdown} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom';
import cardIcon from "../../assets/images/svg_images/cardicon.png"
import {
  Maneye_img,
  Delete_img,
  timeline,
} from "../../assets/images";
import moment from 'moment';

const {Meta} = Card;

const Frames = () => {
  const history = useHistory()
  const [frames, setFrames] = useState([])
  // const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [frameId, setFrameId] = useState("")
  const [totalRows, setTotalRows] = useState(0)
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  let [loading, setLoading] = useState({counts: false, dtable: false});

  const getFrames = useCallback(async () => {
    setLoading(prevState => ({...prevState, dtable: true}));
    const frames = await axios.get(`/get-frames?limit=${limit}&page=${page}`).catch(err => console.error(err, "<<-- Error in get frames"))
    if (frames.data.success) {
      setFrames(frames.data.frames)
      setTotalRows(frames.data.pagination.totalRows)
    }
    setLoading(prevState => ({...prevState, dtable: false}));
  }, [limit, page])

  useEffect(() => {
    getFrames()
  }, [getFrames])

  const handleDelete = async (item) => {
    setFrameId(item._id)
    setVisible(true)
  }

  const EmptyData = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );

  const EmptyDataLoader = () => (
    <div style={{
      padding: '15px',
      background: '#495A71',
      color: '#fff',
      width: '100%',
      fontSize: '40px',
      textAlign: 'center',
      minHeight: '350px',
      lineHeight: '400px'
    }}>
      <div>No data found...</div>
    </div>
  );

  const handleChangeStatus = async (frame) => {
    const data = {
      active: !frame.active
    }
    const statusChange = await axios.put(`/change-frame-status/${frame._id}`, data)

    if (statusChange.data.success) {
      message.open({
        type: "success",
        content: statusChange.data.message
      })
      await getFrames()
    } else {
      message.open({
        type: "error",
        content: statusChange.data.message
      })
    }
  }

  const frameList = () => {
    let allFrames = [];
    frames &&
    frames.map((frame, index) => {
      allFrames.push({
        id: index,
        name: (
          <div className="management_list_name">
            <div className="man_user" style={{position: "relative"}}>
              <img src={frame.epic.c || cardIcon} alt="Card icon"
                   style={{objectFit: "contain", borderRadius: 0, position: "absolute"}} loading={"lazy"}/>
              {frame.epic.cBg &&
                <img src={frame.epic.cBg} alt="Card icon"
                     style={{objectFit: "contain", borderRadius: 0}} loading={"lazy"}/>
              }
            </div>
            <div className="man_text" style={{width: "100%"}}>{frame.name}</div>
          </div>
        ),
        status: (
          <div className="status">{frame.active ? "Active" : "Inactive"}</div>
        ),
        createdDate: <div className="createdAt">{moment(frame.createdAt).format("MMM Do YY")}</div>,
        createdTime: <div className="createdAt">{moment(frame.createdAt).format("LT")}</div>,
        action: (
          <div className="action_btns">
            <Button variant="info" onClick={() => history.push(`/card-details/${frame._id}`)}>View</Button>
            <Button variant="info" onClick={() => history.push(`/edit-frame/${frame._id}`)}>Edit</Button>
            <Button variant={frame.active ? "warning" : "success"} style={{width: !frame.active && "5.4rem"}}
                    onClick={() => handleChangeStatus(frame)}>{frame.active ? "Inactive" : "Active"}</Button>
            <Button variant="danger" onClick={() => handleDelete(frame)}>Delete</Button>
          </div>
        ),
      });
    });
    return allFrames;
  };

  // Function to handle modal confirmation
  const handleOk = async () => {
    await axios.delete(`/delete-frame/${frameId}`)
      .then(res => {
        message.open({
          type: "success",
          content: "Frame deleted successfully"
        })
        setVisible(false);
      }).catch(err => console.error(err, "<<-- Error in card delete"))
    const newFrames = frames.filter((frame) => frame._id !== frameId)
    setFrames(newFrames)
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    setVisible(false);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  }

  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  }

  return (
    <>
      <FrameHeader onClick={() => {
        history.push("/add-frame")
      }}/>
      <div className="manage_table table-responsive framelist_table">
        {/*<DataTable
          className="hypTable1"
          columns={columns}
          data={frameList()}
          responsive
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<CustomLoader/>}
        />*/}

        <DataTable
          className="hypTable1"
          columns={columns}
          data={frameList()}
          responsive
          customStyles={customStyles}
          defaultSortField="id"
          defaultSortAsc={false}
          noDataComponent={<EmptyDataLoader/>}
          progressComponent={<CustomLoader/>}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[10, 20, 40]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressPending={loading.dtable}
        />
      </div>

      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{backgroundColor: '#455060'}}
        footer={[
          <Button variant='danger' key="back" onClick={handleCancel}>
            No
          </Button>,
          <Button variant="info" key="submit" style={{marginLeft: "10px"}} loading={loading} onClick={handleOk}>
            Yes
          </Button>,
        ]}
      >
        <p style={{fontSize: "18px"}}>Are you sure you want to delete?</p>
      </Modal>
    </>
  )
}

const columns = [
  {
    name: "Name of Card",
    selector: (row) => row.name,
    reorder: true,
    width: "270px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    reorder: true,
    width: "150px",
  },
  {
    name: "Created Date",
    selector: (row) => row.createdDate,
    reorder: true,
    width: "180px",
  }, {
    name: "Created Time",
    selector: (row) => row.createdTime,
    reorder: true,
    width: "180px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "350px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "16px",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "14px",
      color: "#fff",
    },
  },
};

export default Frames