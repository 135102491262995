import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { Maneye_img } from "../../assets/images";
import axios from "../../axios/axios";
import message from "../../messages";
import "./UserNft.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CurrencyFormating } from "../../helpers/currencyHelper";

function UserNft() {
  const [nftData, setNftData] = useState([]);
  const [loader, setLoader] = useState(false);

  const { userId } = useParams();
  const abc = useSelector((state) => state.setNftByUser);
  console.log("abc", abc);
  console.log("nftData",nftData)

  const getNftData = async () => {
    setLoader(true);

    await axios.get(`/get-collectables-of-user/${userId}`).then((res) => {
      if (res.data.success === true) {
        // console.log("============", res.data.nfts);
        let array = []
        const map = new Map()
        res.data.nfts.forEach(value => {
          let key = value.title;
          if (!map.has(key)) {
            array.push(value);
            map.set(key, value);
          }
        })
        // setNftData(res.data.nfts);
        setNftData(array);
        setLoader(false);
      } else {
        setLoader(false);
        message.warning("Oops! Server error");
      }
    });
  };
  useEffect(() => {
    getNftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="dash_partts">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>NFT's Collectables</h2>
          </div>
          {/* <div className="nft_drop">
                            <Dropdown className="drop_partts121">
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                Download Report
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
        </div>
        <div className="nft_collect_body">
          <LoadingOverlay
            active={loader}
            spinner={<BounceLoader color="white" />}
          >
            <div className="nft_collect_inner_body">
              {nftData.map((nftItem, index) => {
                return (
                  <div key={index} className="nft_collct_inner_body_part">
                    <div className="nft_collect_inner_partts">
                      <div className="nft_collect_top_img">
                        <img src={nftItem.fileUrl} alt="No Img" />
                      </div>
                      <div className="nft_collect_bottom_details">
                        <div className="nft_collect_details_left">
                          <h2>{nftItem?.title}</h2>
                          <h5><small><i>Price</i></small>: {CurrencyFormating(nftItem.prices?.amount ? nftItem.prices?.amount : 0)}</h5>
                          <h5><small><i>Author</i></small>: {nftItem.creator?.name}</h5>
                          <h5><small><i>Current Holder</i></small>: {nftItem.nftHolder?.name}</h5>
                        </div>
                        <div className="nft_collect_details_right">
                          {/* <div> */}
                            <div className="view_but">
                              {/* <a href="#/action-1">
                                <img src={Maneye_img} alt="No Img" /> View
                              </a> */}
                              {/* md working */}
                              <Link to={`/nftdetails/${nftItem._id}/${nftItem.NFT_identity}`}>
                              <img src={Maneye_img} alt="No Img"/>
                              View
                            </Link>
                            </div>
                            <div className="common_img">
                              <img
                                src={nftItem?.nftType?.imageUrl}
                                alt="No Img"
                              />
                            </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
}

export default UserNft;
