import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";

import { Row, Col  } from "react-bootstrap";
import "./levelSetting.css";

import { Edit_img } from "../../assets/images";

const LevelSetting = () => {
    const [levelSetting, setLevelSetting] = useState();
    const [loading, setLoading] = useState({ counts: false, dtable: false });
    
    const CustomLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>Loading...</div>
        </div>
      );
    const EmptyDataLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>No data found...</div>
        </div>
      );
      
      const getLevelSetting = async() => {
        setLoading((prevState) => ({ ...prevState, dtable: true }));
        await axios.get(`/get-all-level-setting`)
        .then((response) => {
            console.log("get all level setting....", response.data);
            setLoading((prevState) => ({ ...prevState, dtable: false }));
            setLevelSetting(response.data.levelSettingData);
        })
        .catch((error) => {
            console.log(error);
        })
    }
    
    useEffect(() => {
        getLevelSetting();
    }, []);
      
      const getLevel = () => {
        let levelSettingData = [];
        levelSetting?.map((item, i) => {
          levelSettingData.push({
            // id: item.id,
            level: <div className="man_username">Level {item?.level}</div>,
            mint_rarity: (
                <div className="man_email">
                    
                    {
                        item?.mint.common.total > 0 ? <div className="man_email"> {item.mint?.common?.total} Commons {item.mint?.common?.reset?.type} </div> : <div></div>
                    }
                    {
                        item?.mint.rare.total > 0 ? <div className="man_email"> {item.mint?.rare?.total} Legendary {item.mint?.rare?.reset?.type} </div> : <div></div>
                    }
                    {
                        item?.mint.epic.total > 0 ? <div className="man_email"> {item.mint?.epic?.total} Epic {item.mint?.epic?.reset?.type} </div> : <div></div>
                    }
                    
                </div>
            ),
            edition_limit: <div className="man_ph notification_key">{item.editions} Editions per Mint</div>,
            first_sale: <div className="man_ph notification_key">{item.sale_1?.seller} % Seller Fee <br/> {item.sale_1?.hypeu} % Hypeu Fee</div>,
            sale: <div className="man_ph notification_key">{item.sale?.seller} % Seller Fee <br/> {item.sale?.hypeu} % Hypeu Fee <br/> {item.sale?.mintor} % Mintor Fee </div>,
            // level_jump: <div className="man_ph notification_key">Sell {item.nextLevel?.total} NFTs to reach next level</div>,
            level_jump: <div className="man_ph notification_key">
            {
                item.nextLevel?.type === "sellNft" ?
                <div className="man_ph notification_key">Sell {item.nextLevel?.total} NFTs to reach next level</div> :
                <div className="man_ph notification_key">Earn $ {item.nextLevel?.total} to reach next level</div>}</div>,
            action: (
              <div className="three_dotss">
                <Link to={`/edit-level-setting/${item._id}`} style={{color: "white", marginLeft: "0.5rem"}} className="edit_icon_design">
                  <img alt="No Img" src={Edit_img} className="editMailStyle" />
                </Link>
              </div>
            ),
          });
        });
        return levelSettingData;
      };
      
  return (
    <React.Fragment>
        <div className="user_inner_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Level Setting</h2>
              </div>

              <div className="manage_table">
                {/* <div className="loader_1"><BounceLoader loading={loading.dtable} size={60} /></div> */}
                <div className="table-responsive">
                  <DataTable
                  className="hypTable1"
                    columns={columns}
                    data={getLevel()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const columns = [
    {
      name: "Level",
      selector: (row) => row.level,
      // reorder: true,
      width: "100px",
    },
    {
      name: "Mint & Rarity",
      selector: (row) => row.mint_rarity,
      // reorder: true,
      width: "175px",
    },
    {
      name: "Editions Limit",
      selector: (row) => row.edition_limit,
      // reorder: true,
      width: "170px",
    },
    {
      name: "First Sale",
      selector: (row) => row.first_sale,
      // reorder: true,
      width: "150px",
    },
    {
        name: "Sale",
        selector: (row) => row.sale,

        // reorder: true,
        width: "150px",
    },
    {
        name: "Level Jump",
        selector: (row) => row.level_jump,

        // reorder: true,
        width: "200px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      // reorder: true,
      width: "100px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        backgroundColor: "transparent",
        color: "#fff",
        paddingLeft: "10px", // override the row height
      },
    },
    headCells: {
      style: {
        // paddingLeft: "10px",
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "16px",
      },
    },
    cells: {
      style: {
        paddingLeft: "4px", // override the cell padding for data cells
        paddingRight: "2px",
        paddingTop: "30px",
        // paddingTop: "5px,
        paddingBottom: "30px",
        // paddingBottom: "5px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "14px",
      },
    },
  };

export default LevelSetting;