import { Container, Col, Card, Button, Form, Row } from 'react-bootstrap'
import FilterByDateRange from './filter-by-date-range'
import { useEffect, useState } from 'react'
import FilterByDate from './filter-by-date'
import FilterByMonthYear from './filter-by-month-and-year'
import './filter.css'



function Filter(props) {
    let {filterSelected, filterHandale, filterSubmit,setFilterSelected} = props
    const [filterTyepe, setfilterType] = useState('Date Range Filter')
    const radioCLick = (e)=>{
        setfilterType(e.target.value)
    }
    useEffect(()=>{
        setFilterSelected({})
    },[filterTyepe])

  return (

        <Row>
            <Col md={12}>
                <Card className='filter_card'>
                    <Card.Header>
                            <Form>
                                <div className="d-flex gap-1 gap-md-5 flex-wrap">
                                    {['Date Range Filter', 'Date Filter', 'Month & Year Filter'].map((type, index) => (
                                        <div key={index} className="radio_item" onClick={radioCLick}>
                                            <Form.Check checked={filterTyepe==type ? true : false} type='radio' id={`radio-${index}`} label={`${type}`} name="filter" value={type} />

                                            {/* {index = 0 ? "checked" : ""} */}
                                        </div>
                                    ))}
                                </div>
                            </Form>
                    </Card.Header>
                    <Card.Body>
                            { filterTyepe == 'Date Range Filter' ? <FilterByDateRange filterSelected={filterSelected} filterHandale={filterHandale} filterSubmit={filterSubmit}/> : "" } 
                            { filterTyepe == 'Date Filter' ? <FilterByDate filterSelected={filterSelected} filterHandale={filterHandale} filterSubmit={filterSubmit}/> : "" } 
                            { filterTyepe == 'Month & Year Filter' ? <FilterByMonthYear filterSelected={filterSelected} filterHandale={filterHandale} filterSubmit={filterSubmit}/> : "" }       
                    </Card.Body>
                </Card>
            </Col>
        </Row>

  )
}

export default Filter