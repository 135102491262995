import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import "./userPayout.css";
import axios from "../../axios/axios";
import { useParams } from 'react-router-dom';
import { message } from "antd";


const UserPayoutDetails = () => {
    const { payoutId } = useParams();
    const [payoutDetails, setPayoutDetails] = useState({});
    const [loading, setLoading] = useState({ counts: false, dtable: false });
    
    const getPayoutDetails = async() => {
        // setLoading((prevState) => ({...prevState, dtable: true}));
        // let dataurl = `/view-payouts-by-id/${payoutId}`;
        await axios.get(`/view-payouts-by-id/${payoutId}`)
        .then((res) => {
            console.log("Payout Details....", res.data);
            if(res.data.success === true){
                setPayoutDetails(res.data.data);
                setLoading((prevState) => ({ ...prevState, stories: false }));
            }
        })
        .catch((err) => {
            console.log("Payout Details Error...", err);
        })
    }
    
    useEffect(() => {
        getPayoutDetails();
    }, []);
    
  return (
    <React.Fragment>
        <div className="dash_partts">
            <Row>
                <Col lg="12" md="12" sm="12" className="ps-0 pe-0">
                <div className="setting_left_inner_partts">
                <h2>Payout Details</h2>
                {
                    payoutDetails?.status === 'paid' ?
                    <React.Fragment>
                        <div className="payout_status_success">
                            <h5>
                                <span></span>
                                Payment Successful
                            </h5>
                        </div>
                    </React.Fragment> : payoutDetails?.status === 'pending' ?
                    <React.Fragment>
                        <div className="payout_status_warning">
                            <h5>
                                <span></span>
                                Payment Pending
                            </h5>
                        </div>
                    </React.Fragment> :
                    <React.Fragment>
                        <div className="payout_status_danger">
                            <h5>
                                <span></span>
                                Payment Failed
                            </h5>
                        </div>
                    </React.Fragment>
                }
                    
                    <div className="levelsetting">
                        <Row className="mb-2  pt-3 pb-3 d-flex text-center">
                            <Col sm={5} md={5} className="main_head">User Details</Col>
                            <Col sm={6} md={6} className="main_head">Amount & Status</Col>
                        </Row>
                        <Form>
                            <Row className="mail-first-row">
                                <Col md={6}>
                                    <Row>
                                        <Col md={4}>
                                            <label className="main_head"><strong>Name : </strong></label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className='state_parttsss'>
                                                <h2> {payoutDetails?.user_id?.name} </h2>
                                            </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <label className="main_head"><strong>User Id : </strong></label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className='state_parttsss'>
                                                <h2> {payoutDetails?.user_id?._id} </h2>
                                            </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                
                                <Col md={6}>
                                    <Row>
                                        <Col md={4}>
                                            <label className="main_head"><strong className='amount-color'>Amount :</strong></label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className='state_parttsss amount-color'>
                                                <h2>&#36; {payoutDetails?.amount} </h2>
                                            </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <label className="main_head"><strong>Status :</strong></label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className='state_parttsss'>
                                                <h2> {payoutDetails?.status} </h2>
                                            </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                            
                            <div className="seperate_section">
                                <Row className="mb-2  pt-3 pb-3 d-flex text-center">
                                    <Col sm={5} md={5} className="main_head">Bank Details</Col>
                                    <Col sm={6} md={6} className="main_head">Account Details</Col>
                                </Row>
                                <Row className="mail-first-row">
                                    <Col md={6}>
                                        <Row>
                                            <Col md={5}>
                                                <label className="main_head"><strong>Account Holder : </strong></label>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className='state_parttsss'>
                                                    <h2> {payoutDetails?.bankId?.account_holder_name} </h2>
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={5}>
                                                <label className="main_head"><strong>Bank Name : </strong></label>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className='state_parttsss'>
                                                    <h2> {payoutDetails?.bankId?.bank_name} </h2>
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={5}>
                                                <label className="main_head"><strong>Account Holder Type : </strong></label>
                                            </Col>
                                            <Col md={7}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className='state_parttsss'>
                                                    <h2> {payoutDetails?.bankId?.account_holder_type} </h2>
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col md={6}>
                                        <Row>
                                            <Col md={4}>
                                                <label className="main_head"><strong>Account Id : </strong></label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className='state_parttsss'>
                                                    <h2> {payoutDetails?.account_id?.acc_id} </h2>
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <label className="main_head"><strong>Personal Id : </strong></label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className='state_parttsss'>
                                                    <h2> {payoutDetails?.account_id?.person_id} </h2>
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <label className="main_head"><strong>Status : </strong></label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className='state_parttsss'>
                                                    <h2> {payoutDetails?.account_id?.status} </h2>
                                                </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            
                            <Row className="mb-2  pt-4 pb-3 d-flex">
                                <Col md={3} className="main_head">Stripe Details</Col>
                            </Row>
                            <Row className="mail-first-row">
                                <Col md={6}>
                                    <Row>
                                        <Col md={5}>
                                            <label className="main_head"><strong>Stripe Id :</strong></label>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className='state_parttsss'>
                                                <h2> {payoutDetails?.payoutId} </h2>
                                            </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                </Col>
            </Row>
        </div>
    </React.Fragment>
  )
}

export default UserPayoutDetails;