import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Layout from "./Components/layout/Layout";
import Login from "./Components/Login/login";
import User from "./Components/user/user";
import Userdetails from "./Components/UserDetails/Userdetails";
import UserEditPage from "./Components/Useredit/Useredit";
import StoryFeedsPage from "./Components/storyfeeds/storyfeeds";

import NFTPage from "./Components/Nft/Nft";
import NFTDetailsPage from "./Components/NftDetails/NftDetails";
import NftPurchaseDetails from "./Components/NftPurchaseDetails/NftPurchaseDetails";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import UserBadges from "./Components/UserBadges/UserBadges";
import Leftpanel from "./Components/leftpanel/Leftpanel";
import Logout from "./Components/logout/logout";
import BadgesPage from "./Components/Badges/Badges";
import NftPurchase from "./Components/NftPurchase/NftPurchase";
import StoryDetailsPage from "./Components/StoryDetails/StoryDetails";
import UserNft from "./Components/UserNft/UserNft";
import UserStories from "./Components/UserStories/UserStories";
import HeaderPart from "./Components/layout/header/Header";
import DashboardPage from "./Components/Dashboard/dashboard";
import Setting from "./Components/Setting/SiteSettings";
import FundRaiser from "./Components/FundRaiser/FundRaiser";
import FundRaiserDetails from "./Components/FundRaiserDetails/FundRaiserDetails";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./actions/setToken";
import ManageProduct from "./Components/Merchandise/ManageProduct/ManageProduct";
import Banner from "./Components/Merchandise/Banner/Banner";
import Varieties from "./Components/Merchandise/Varietys/Varietys";
import Attributes from "./Components/Merchandise/Attributes/Attributes";
import Orders from "./Components/Merchandise/Orders/Orders";

import PushNotification from "./Components/Notification/PushNotification";
import EmailList from "./Components/Email Template/EmailList";
import EmailTemplate from "./Components/Email Template/EmailTemplate";
import NotificationTemplate from "./Components/Notification/NotificationTemplate";
import NotificationLog from "./Components/NotificationLog/NotificationLog";
import NftNotification from "./Components/NftNotification/NftNotification";
// import OfferList from "./Components/Offer List/MakeOffered";
import Footer from "./Components/layout/Footer/Footer";
// import TermsAndConditions from "./Components/layout/TermsConditions/TermsConditions";
// import privacyPolicy from "./Components/layout/privacyPolicy/privacyPolicy";
import Management from "./Components/Management/Management";
import LevelSetting from "./Components/Level Setting/LevelSetting"; ///////////
import EditLevelSetting from "./Components/Level Setting/EditLevelSetting";
import NotificationLogTemplate from "./Components/NotificationLog/NotificationLogTemplate";
import FeedBack from "./Components/Feedback/Feedback";
import UserPayout from "./Components/UsersPayout/UserPayout";
import UserLevel from "./Components/UserLevel/UserLevel";
import UserLevelDetails from "./Components/UserLevelDetails/UserLevelDetails";
import OrderDetails from "./Components/Merchandise/OrderDetails/OrderDetails";
import PromoCode from "./Components/PromoCode/PromoCode";
import AddPromoCode from "./Components/PromoCode/AddPromoCode";
import Products from "./Components/Merchandise/ManageProduct/Products";
import UserUsagesPromoCode from "./Components/UserUsagesPromoCode/UserUsagesPromoCode";
import UsagesPromoCodeDetails from './Components/UsagesPromoCodeDetails/UsagesPromoCodeDetails';
import AddBanner from './Components/Merchandise/AddBanner/AddBanner';


import Timeline from "./Components/timeline/Timeline";
import EditPromoCode from "./Components/PromoCode/EditPromoCode";
import UserPayoutDetails from "./Components/UsersPayout/UserPayoutDetails";
import ViewWallet from "./Components/View Wallet/ViewWallet";
import MakeOffered from "./Components/Offer List/MakeOffered";
import MakeOfferedDetails from "./Components/Offer List/MakeOfferedDetails";
import GlobalSearchResult from "./Components/Global Search Result/GlobalSearchResult";
import Privacy from "./Components/Privacy/Privacy";
import AboutUs from "./Components/AboutUs/AboutUs";
import TermsAndConditions from "./Components/Terms and Conditions/TermsAndConditions";
import Faq from "./Components/Faq/Faq";
import AddFaq from "./Components/Faq/AddFaq";
import EditFaq from "./Components/Faq/EditFaq";
import FaqList from "./Components/Faq/FaqList";
import Frames from "./Components/Frames/Frames";
import AddFrame from "./Components/Frames/AddFrame";
import ViewTransactions from "./Components/View Wallet/ViewTransactions";
import FrameDesign from "./Components/Frames/FrameDesign";
import BlockchainWallet from "./Components/BlockchainWallet/BlockchainWallet";
import BlockchainWalletDetails from "./Components/BlockchainWallet/BlockchainWalletDetails";
import AddWallet from "./Components/BlockchainWallet/AddWallet";
import NewAnnouncement from "./Components/New and Announcement/NewAnnouncement";
import HypeuTotals from "./Components/HypeuTotals/HypeuTotals";
import RemoveAccount from "./Components/RemoveAccount/RemoveAccount";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <>
      {/* <Leftpanel panelName="dashboard" /> */}
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("AuthToken") ? (
            <Layout {...props}>
              <div className="user_partts">
                <Leftpanel panelName="dashboard" />
                <div className="right_panel">
                  <HeaderPart />
                  <Component {...props} />
                  <Footer />
                </div>
              </div>
            </Layout>
          ) : (
            <>
              {" "}
              <Redirect to="/" />
            </>
          )
        }
      />
    </>
  );
}
function App() {
  let dispatch = useDispatch();
  const token = useSelector((state) => state.setToken);
  useEffect(() => {
    if (localStorage.getItem("AuthToken")) {
      dispatch(setToken(localStorage.getItem("AuthToken")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {token ? (
            <RouteWrapper
              path="/"
              component={DashboardPage}
              layout={Layout}
              exact
            />
          ) : (
            <Route path="/" component={Login} exact />
          )}
          {/* <Route
            path="/terms-and-condition"
            component={TermsAndConditions}
            exact
          /> */}
          <Route path="/remove-account" component={RemoveAccount} exact />
          <RouteWrapper path="/user" component={User} layout={Layout} exact />
          <RouteWrapper
              path="/hypeu-totals"
              component={HypeuTotals}
              layout={Layout}
              exact
            />
          <RouteWrapper
            path="/userdetails/:userId"
            component={Userdetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/fund-raiser-details/:fr_id"
            component={FundRaiserDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/storyfeeds"
            component={StoryFeedsPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/fund-raisers"
            component={FundRaiser}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/storydetails/:id"
            // path="/storydetails"
            component={StoryDetailsPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/nfts"
            component={NFTPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/dashboard"
            component={DashboardPage}
            layout={Layout}
            exact
          />
          <Route path="/reset-password/:token" component={ResetPassword} />

          <RouteWrapper
            path="/user-edit/:userId"
            component={UserEditPage}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/badges"
            component={BadgesPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/user-badges/:userId"
            component={UserBadges}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/nftdetails/:nftId/:NFT_identity"
            component={NFTDetailsPage}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/nftpurchase"
            component={NftPurchase}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/purchase-details/:purchaseId"
            component={NftPurchaseDetails}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/user-stories/:userId"
            component={UserStories}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/user-nft/:userId"
            component={UserNft}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/users-payout"
            component={UserPayout}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/news-announcement"
            component={NewAnnouncement}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/logout"
            component={Logout}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/setting"
            component={Setting}
            layout={Layout}
            exact
          />

          {/* Wallet route start*/}
          <RouteWrapper
            path="/blockchain-wallet"
            component={BlockchainWallet}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/wallet-details/:walletId"
            component={BlockchainWalletDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-wallet"
            component={AddWallet}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/edit-wallet/:walletId"
            component={AddWallet}
            layout={Layout}
            exact
          />
          {/* Wallet route end */}

          <RouteWrapper
            path="/management"
            component={Management}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/Feedback"
            component={FeedBack}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/email-list"
            component={EmailList}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/email-template/:mailId"
            component={EmailTemplate}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/pushnotification"
            component={PushNotification}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/edit-notification/:notificationId"
            component={NotificationTemplate}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/nft-notification"
            component={NftNotification}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/notification-log"
            component={NotificationLog}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/frames"
            component={Frames}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-frame"
            component={AddFrame}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/frame-design/:frameId"
            component={FrameDesign}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/edit-frame/:frameId"
            component={AddFrame}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/card-details/:frameId"
            component={AddFrame}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/notification-log-template/:notificationLogId"
            component={NotificationLogTemplate}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/offer-list"
            component={MakeOffered}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/offer-details"
            component={MakeOfferedDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/management"
            component={Management}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/level-setting"
            component={LevelSetting}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/edit-level-setting/:levelId"
            component={EditLevelSetting}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/userlevel"
            component={UserLevel}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/UserLevelDetails/:userId"
            component={UserLevelDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/UsagesPromoCodeDetails/:id"
            component={UsagesPromoCodeDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/merchandiseproduct"
            component={ManageProduct}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/banners"
            component={Banner}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-banner"
            component={AddBanner}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-varieties"
            component={Varieties}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/orders"
            component={Orders}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/products"
            component={Products}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-attributes"
            component={Attributes}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/order-details/:id"
            // path="/storydetails"
            component={OrderDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/management"
            component={Management}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/users-payout"
            component={UserPayout}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/promo-code-list"
            component={PromoCode}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/usages-promo-code"
            component={UserUsagesPromoCode}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-new-promo-code"
            component={AddPromoCode}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/edit-promo-code/:promoId"
            component={EditPromoCode}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/payout-details/:payoutId"
            component={UserPayoutDetails}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/view-wallet/:walletId"
            component={ViewWallet}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/nfts-transaction/:userId"
            component={ViewTransactions}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/global-search/:search"
            component={GlobalSearchResult}
            layout={Layout}
            exact
          />
          {/* <Route path="/privacy-policy" component={privacyPolicy} exact /> */}
          {/* <Route path="/privacy" component={Privacy} layout={Layout} exact /> */}
          <Route
            path="/privacy"
            component={Privacy}
            layout={Layout}
            exact
          />
          <Route
            path="/about"
            component={AboutUs}
            layout={Layout}
            exact
          />
          <Route
            path="/terms-conditions"
            component={TermsAndConditions}
            layout={Layout}
            exact
          />
          <Route
            path="/faq-list"
            component={FaqList}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/faq"
            component={Faq}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/add-faq"
            component={AddFaq}
            layout={Layout}
            exact
          />
          <RouteWrapper
            path="/edit-faq/:faqId"
            component={EditFaq}
            layout={Layout}
            exact
          />

          <RouteWrapper
            path="/timeline"
            component={Timeline}
            layout={Layout}
            exact
          />


        </Switch>
      </BrowserRouter>
    </div>
  );
}
export default App;
