import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
} from "react-bootstrap";
import "./userLevelDetails.css";
import axios from "../../axios/axios";
import { useParams, Link, useHistory } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { No_ProfImg } from "../../assets/images";



import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const UserLevelDetails = () => {
  const { userId } = useParams();
  const history = useHistory();

  const [userLevel, setUserLevel] = useState({});
  const [loading, setLoading] = useState(true);
  console.log(".......///userLevel", userLevel);

  const getUserLevelCounts = async () => {
    await axios
      .get(`/get-level-by-user-id/${userId}`)
      .then((resp) => {
        setUserLevel(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(" err", err);
        setLoading(false);
      });
  };
  const data = {
    labels: ["Complete %", "Incomplete %"],
    datasets: [
      {
        label: '% of Votes',
        radius: "100%",
        data: userLevel
          ? [userLevel.levelPercentage, 100 - userLevel.levelPercentage]
          : [0],
        backgroundColor: [
          'rgba(43, 250, 250, 0.8)',
          'rgba(43, 250, 250, 0.2)',
        ],
        borderColor: [
          'rgba(43, 250, 250, 1)',
          'rgba(43, 250, 250, 0.8 )',
        ],
        borderWidth: 1,
      },
    ],
  };



  // const options2={
  //   padding:"0px",
  //   responsive:false,
  //   maintainAspectRatio:false,
  //   defaultFontSize:"14px",
  //   width:"400",
  //   height:"400",
  //   legend:{
  //       display:false,
  //   },
  //   plugins:{
  //       datalabels: {
  //           color:'#000000',
  //           anchor: "start",
  //           align:"end",
  //           formatter: function(value, context) {
  //                   return context.chart.data.labels[context.dataIndex];
  // }
  //       }
  //   } 
  // }
  const options2 = {
    padding: "0px",
    responsive: false,
    maintainAspectRatio: false,
    defaultFontSize: "14px",
    width: "400",
    height: "400",
    plugins: {
      legend: {
        labels: {
          color: ["#fff"],
        },
        weight: "20"
      },
    },
  };


  useEffect(() => {
    getUserLevelCounts();
  }, []);

  return (
    <div className="user_details_top_partts">
      {
        userLevel === null ? <React.Fragment>
          <h2>Sorry ! There is No Any Level To Display.</h2>
          {history.push('/user')}
        </React.Fragment> : <React.Fragment>
          <div className="nft_top">
            <div className="nft_inner">
              <div className="nft_inner_inner">
                <h3>Level</h3>
                <h2>{userLevel?.level}</h2>
              </div>
            </div>

            <div className="nft_inner">
              <div className="nft_inner_inner">
                <h3>Common Balance</h3>
                <h2>{userLevel?.mintBalance?.common}</h2>
              </div>
            </div>

            <div className="nft_inner">
              <div className="nft_inner_inner">
                <h3>Epic Balance</h3>
                <h2>{userLevel?.mintBalance?.epic ?? 0}</h2>
              </div>
            </div>

            <div className="nft_inner">
              <div className="nft_inner_inner">
                <h3>Legendary Balance</h3>
                <h2>{userLevel?.mintBalance?.rare ?? 0}</h2>
              </div>
            </div>
            <div className="nft_inner">
              <div className="nft_inner_inner">
                <h3>Nft Sale Count</h3>
                <h2>{userLevel?.nftSaleCount}</h2>
              </div>
            </div>

            <div className="nft_inner">
              <div className="nft_inner_inner">
                <h3>Nft Sale Earn</h3>
                <h2>${" "}{userLevel?.nftSaleEarn?.toFixed(2)}</h2>
              </div>
            </div>
          </div>
          <div>
            <div className="nft_bottom">
              <Row>
                {/* <Col lg="4" md="12" sm="12">
                <div className="usermaageleft circularbar">
                  <h2 style={{ color: "#2BFAFA", position: "relative" }}>
                    Level Percentage
                  </h2>
                  <CircularProgressbar
                    styles={buildStyles({
                      textColor: "#2BFAFA",
                      textSize: "10px",
                    })}
                    value={userLevel?.levelPercentage}
                    text={`${
                      userLevel?.levelPercentage
                        ? userLevel?.levelPercentage
                        : "loading....."
                    }%`}
                  />
                </div>
              </Col> */}
                <Col lg="8" md="12" sm="12" className="mt-5">
                  <LoadingOverlay
                    active={loading}
                    spinner={<PulseLoader color="white" size={10} />}
                  >
                    <div className="hermis_top fix_design d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="george_hermis_user">
                          <div className="hermis_user_left">
                            <div className="hermis_user_image">
                              <img
                                src={
                                  userLevel?.userProfile?.thumbnailUrl
                                    ? userLevel.userProfile.thumbnailUrl
                                    : No_ProfImg
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="state_parttsss">
                          <h2><strong>Name:</strong> {userLevel?.user_id?.name}</h2>
                          <h2><strong>User Name:</strong> {userLevel?.user_id?.userName}</h2>
                          <h2><strong>Nick Name:</strong> {`${userLevel?.user_id?.nickName.fName} ${userLevel?.user_id?.nickName.lName}`}</h2>
                          <h2><strong>Email:</strong> {userLevel?.user_id?.email}</h2>
                        </div>
                      </div>
                    </div>
                  </LoadingOverlay>
                </Col>
                <Col lg="4" md="12" sm="12">
                  <div className="usermaageleft">
                    <div className="manage_heading_search">
                      <h5>Users Level %</h5>
                    </div>
                    <div className="dognut_partts">
                      <div className="pro_gress">
                        <Doughnut data={data} options={options2} />
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <div className="manage_heading_search">
                <h5>Users Level %</h5>
              </div>
              <div className="pro_gress">
                  <Doughnut data={data} options={options2} />
              </div> */}
              </Row>
            </div>
          </div>
        </React.Fragment>
      }

    </div>
  );
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        color: ["#fff"],
      },
      // weight:"20"
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
};

export default UserLevelDetails;
