import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../axios/axios";
import message from "../../messages";

const BadgesModal = ({ renderBadgePage }) => {
  const [show, setShow] = useState(false);
  const [btn, setBtn] = useState(false);
  const [darkImg, setDarkImg] = useState("");
  const [err, setErr] = useState({ lightImg: "", darkImg: "", category: "", badgeName: "" });
  // const [setErr, setsetErr] = useState({ lightImg: "", darkImg: "" });
  const [lightImg, setLightImg] = useState("");
  const [category, setCategory] = useState("");
  const [badgeName, setBadgeName] = useState("");

  const imgValidation = () => {
    if (!lightImg) {
      setErr((prevState) => ({
        ...prevState,
        lightImg: "file is required",
      }));
    } else if (!lightImg?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setErr((prevState) => ({
        ...prevState,
        lightImg: "file not an image",
      }));
    } else {
      setErr((prevState) => ({ ...prevState, lightImg: "" }));
    }

    if (!darkImg) {
      setErr((prevState) => ({ ...prevState, darkImg: "file is required" }));
    } else if (!darkImg?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setErr((prevState) => ({
        ...prevState,
        darkImg: "file not an image",
      }));
    } else {
      setErr((prevState) => ({ ...prevState, darkImg: "" }));
    }
    if (!category) {
      setErr((prevState) => ({
        ...prevState,
        category: "Category is required",
      }));
    } else {
      setErr((prevState) => ({ ...prevState, category: "" }));
    }
    if (!badgeName) {
      setErr((prevState) => ({
        ...prevState,
        badgeName: "Badge Name is required",
      }));
    } else {
      setErr((prevState) => ({ ...prevState, badgeName: "" }));
    }
  }

  const uploadBadge = async () => {
    
    imgValidation(); 
    let error = {};

    let data = new FormData();
    if (err.darkImg === "" && err.lightImg === "" && err.category === "" && err.badgeName === "") {
      data.append("badgeName", badgeName);
      data.append("category", category);
      data.append("darkImage", darkImg);
      data.append("lightImage", lightImg);
      console.log(...data);

      const hideMessage = message.loading("Please wait ...", 0);
      setBtn(true);
      await axios.post(`/add-new-badge`,data)
      .then( res => {
        
        if(res.data.success === true) {
          hideMessage();
          // message.success("new badge added")
          console.log(res.data.message);
          console.log(".......", res.data);
          // setErr({});
          setShow(false);
          renderBadgePage(true);
          setBtn(false);
          setDarkImg("");
          setLightImg("");
          setCategory("");
          setBadgeName("");
        } else {
          hideMessage();
          setBtn(false)
          message.error(res.data.message)
          res.data.errors?.map( item =>{
            error[item.param] = item.msg;
          })
          // setErr(error);
        }
      
      })
      .catch(err => {
        console.log(err);
        hideMessage();
        setBtn(false)
      })
    } else {
      console.log("error");
    }
  };
  const errorClearData = () => {
    setErr({
      lightImg: "",
      darkImg: "",
      category: "",
      badgeName: "",
    })
  };

  const handleClose = () => {
    setShow(false);
    errorClearData();
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg" className="content_modal">
        <div className="mail_template_container">
          <Modal.Header closeButton>
            <Modal.Title>Add New Badge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addnew_badge_body">
              <form>
                <div className="addnew_badge_body">
                  <div className="two_image_upload">
                    <div className="left_image_upload up_img">
                      <h2>Dark Image Upload</h2>
                      <div className="upload_btn_wrapper">
                        <button className="btn">Upload</button>
                        <input
                          type="file"
                          name="darkfile"
                          onChange={(e) => {
                            setDarkImg(e.target.files[0]);
                          }}
                        />
                      </div>
                      {!err?.darkImg ? (
                        <p>{darkImg?.name}</p>
                      ) : (
                        <p style={{ color: "#ff4d4f"  }}>{err.darkImg}</p>
                      )}
                    </div>
                    <div className="left_image_upload up_img">
                      <h2>Light Image Upload</h2>
                      <div className="upload_btn_wrapper">
                        <button className="btn">Upload</button>
                        <input
                          type="file"
                          name="lightfile"
                          onChange={(e) => setLightImg(e.target.files[0])}
                        />
                      </div>
                      {!err?.lightImg ? (
                        <p>{lightImg?.name}</p>
                      ) : (
                        <p style={{ color: "#ff4d4f"  }}>{err.lightImg}</p>
                      )}
                    </div>
                  </div>
                  <div className="choose_field">
                    <label>Choose Category</label>
                    <select
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      className="select_box"
                    >
                      <option value="" selected="selected">
                        Select
                      </option>
                      <option value="sport">Sport</option>
                      <option value="division">Division</option>
                      <option value="state">State</option>
                    </select>
                  </div>
                  <p style={{ color: "#ff4d4f" }}>{err.category}</p>

                  <div className="choose_field">
                    <Form.Label htmlFor="inputText">Badge Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="badges"
                      id="inputText"
                      className="text_box"
                      value={badgeName}
                      placeholder="Badge Name"
                      onChange={(e) => {
                        setBadgeName(e.target.value);
                      }}
                    />
                  </div>
                  <p style={{ color: "#ff4d4f" }}>{err.badgeName}</p>
                  <div className="up_submit_but">
                    <Button
                      className="upload_submit"
                      type="button"
                      disabled={btn}
                      onClick={uploadBadge}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button>
                </Modal.Footer> */}
          </div>
      </Modal>
      <div className="addnew_badge_partts">
        <button className="new_badge1" onClick={handleShow}>
          Add New Badge
        </button>
      </div>
    </div>
  );
};

export default BadgesModal;
