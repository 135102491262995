import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import ForgotPassword from "../modals/forgotPassword";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./login.css";
import { LoginLeft_img, Logo_img, Eye_img } from "../../assets/images";
import Alert from "react-bootstrap/Alert";
import axios from "../../axios/axios";
import { useDispatch } from "react-redux";
import { setToken } from "../../actions/setToken";
import message from "../../messages";

const Login = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [creds, setCreds] = useState({ email: "", password: "" });
  const [credsError, setCredsError] = useState({
    emailerr: "",
    passworderr: "",
  });
  const [show, setShow] = useState({ alert: false, forgetPass:false });
  const [mask, setMask] = useState(true);
  //const [error, setError] = useState("")

  const emailValidatior = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (creds.email.trim() === "") {
      setCredsError({ emailerr: "Email is required" });
      return false;
    } else if (reg.test(creds.email) === false) {
      setCredsError({ emailerr: "Email is invalid" });
      return false;
    } else {
      setCredsError({ emailerr: "" });
      return true;
    }
  };

  const passwordValidator = () => {
    if (creds.password.trim() === "") {
      setCredsError({ passworderr: "Password is required" });
      return false;
    } else if (creds.password.length < 6) {
      setCredsError({ passworderr: "Minimum password length is 6" });
      return false;
    } else {
      setCredsError({ passworderr: "" });
      return true;
    }
  };

  const toggleMasking = () => {
    setMask(!mask);
  };

  const forgetPassword = (e) => {
    e.preventDefault();
    setShow({ ...show, forgetPass: !show.forgetPass });
  };

  const handleChange = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };
  let dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (emailValidatior() && passwordValidator())
      await axios
        .post("/login", creds)
        .then((res) => {
          console.log("res", res.data);
          if (res.data.success === true) {
            setShow({ ...show, alert: false });
            const data = {
              userDetails: res.data.data,
              token: res.data.token,
            };
            message.success('Successfully logged in')
            dispatch(setToken("Bearer " + res.data.token));
            localStorage.setItem("AuthData", JSON.stringify(data));
            localStorage.setItem("AuthToken", res.data.token);
          } else {
            message.error("Invalid credentials")
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
  };

  return (
    <>
      <ForgotPassword show={show.forgetPass} handleClose={forgetPassword} />
      <div className="login_box">
        <div className="login_left">
          <div className="login_left_inner">
            <div>
              <div className="login_logo text-center">
                <img src={Logo_img} alt="img" />
              </div>
              <div className="login_text">
                <Carousel
                  responsive={responsive}
                  centerMode={false}
                  className="slider__text"
                  infinite="false"
                  renderDotsOutside={true}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  showDots={true}
                  arrows={false}
                >
                  <div className="slider_text_part">
                    <p>
                    Discover the power of NFTs and blockchain on HypeU - the one-stop centralized marketplace for buying, selling, and trading digital assets.
                    </p>
                  </div>
                  <div className="slider_text_part">
                    <p>
                    Create, sell, and buy NFTs seamlessly with HypeU's wallet feature and transfer your earnings straight to your personal bank account.
                    </p>
                  </div>
                  {/* <div className="slider_text_part">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam
                    </p>
                  </div> */}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="login_right">
          <div className="login_into_dashboard">
            <h2>Login Into Dashboard</h2>
            {/* <Alert key={"danger"} variant={"danger"} show={show.alert}>
              Wrong Credentials
            </Alert> */}
            <div className="login__right_form">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email or User Name</Form.Label>
                  <div className="field_img">
                    <Form.Control
                      name="email"
                      type="email"
                      value={creds.email}
                      //onChange={(e)=>setCreds({...creds, email:e.target.value})}
                      onChange={handleChange}
                      onBlur={emailValidatior}
                      className="field_part"
                    />
                  </div>
                  <p style={{ color: "red" }}>{credsError.emailerr}</p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <div className="field_img">
                    <Form.Control
                      name="password"
                      type={mask ? "password" : "text"}
                      value={creds.password}
                      onChange={handleChange}
                      onBlur={passwordValidator}
                      className="field_part"
                    />

                    <span className="eye_img">
                      <img src={Eye_img} onClick={toggleMasking} />
                    </span>
                    <p style={{ color: "red" }}>{credsError.passworderr}</p>
                  </div>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="dash_login_but"
                  onClick={handleLogin}
                >
                  Login
                </Button>
                <div className="remeber_forgot mt-3">
                  {/* <Form.Group className="" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group> */}
                  <button className="forgot_pass" style={{border:'none',background:'none'}} onClick={forgetPassword}>
                    Forgot Password?
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
