import React, {useCallback, useEffect, useRef, useState} from 'react'
import "./frames.css"
import {Button, Dropdown, Form} from 'react-bootstrap'
import axios from '../../axios/axios'
import {useHistory, useParams} from 'react-router-dom'
import {ArrowLeftOutlined} from "@ant-design/icons";
import {message} from 'antd'

const FrameDesign = () => {
  const canvasRef = useRef(null)
  const {frameId} = useParams()
  const history = useHistory()
  const FONTS = ["Muro", "FuturaMedium", "FuturaLight", "InterLight", "InterBold", "Chingolo", "MontHeavy", "BebasNeue", "kimberley-bl", "Montserrat-BlackItalic", "Montserrat-MediumItalic", "KOHNE-MAKINA", "Reticulum", "Muroslant", "Sofachrome-Book-Italic", "Fahkwang-Regular", "MrDafoe-Regular", "Gotham-Black", "Avenir-Next", "Etobicoke", "Copperplate", "Bebas-Regular", "Mizike", "DIN-Bold", "Stencil"]

  const [getTxtLoading, setGetTxtLoading] = useState(false)
  const [checkTxtLoading, setCheckTxtLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showImage, setShowImage] = useState("")
  const [orientation, setOrientation] = useState("")
  const [data, setData] = useState({
    sFile: "", fName: "MICHEL", lName: "JORDAN", cBg: ""
  })

  const [cardType, setCardType] = useState("")

  const [cCoOrdinate, setCCoOrdinate] = useState({
    fNameX: 100,
    fNameY: 1335,
    lNameX: 100,
    lNameY: 1456,
    fNameColor: "#2847a4",
    lNameColor: "#ffffff",
    fNameAngle: "0",
    lNameAngle: "0",
    fNameTxtAlign: "left",
    lNameTxtAlign: "left",
    fNamefontFamily: "Futura",
    lNamefontFamily: "Futura",
    fNamefontSize: "60",
    lNamefontSize: "100",
    fNameTxtMaxWidth: 0,
    lNameTxtMaxWidth: 0,
    maxfNameLength: 0,
    maxlNameLength: 0,
    fNameLetterSpacing: 0,
    lNameLetterSpacing: 0
  })

  const [commonCCoOrdinate, setCommonCCoOrdinate] = useState({
    fNameX: 100,
    fNameY: 1335,
    lNameX: 100,
    lNameY: 1456,
    fNameColor: "#2847a4",
    lNameColor: "#ffffff",
    fNameAngle: "0",
    lNameAngle: "0",
    fNameTxtAlign: "left",
    lNameTxtAlign: "left",
    fNamefontFamily: "Futura",
    lNamefontFamily: "Futura",
    fNamefontSize: "60",
    lNamefontSize: "100",
    fNameTxtMaxWidth: 0,
    lNameTxtMaxWidth: 0,
    maxfNameLength: 0,
    maxlNameLength: 0,
    fNameLetterSpacing: 0,
    lNameLetterSpacing: 0
  })
  const [epicCCoOrdinate, setEpicCCoOrdinate] = useState({
    fNameX: 100,
    fNameY: 1335,
    lNameX: 100,
    lNameY: 1456,
    fNameColor: "#2847a4",
    lNameColor: "#ffffff",
    fNameAngle: "0",
    lNameAngle: "0",
    fNameTxtAlign: "left",
    lNameTxtAlign: "left",
    fNamefontFamily: "Futura",
    lNamefontFamily: "Futura",
    fNamefontSize: "60",
    lNamefontSize: "100",
    fNameTxtMaxWidth: 0,
    lNameTxtMaxWidth: 0,
    maxfNameLength: 0,
    maxlNameLength: 0,
    fNameLetterSpacing: 0,
    lNameLetterSpacing: 0
  })
  const [rareCCoOrdinate, setRareCCoOrdinate] = useState({
    fNameX: 100,
    fNameY: 1335,
    lNameX: 100,
    lNameY: 1456,
    fNameColor: "#2847a4",
    lNameColor: "#ffffff",
    fNameAngle: "0",
    lNameAngle: "0",
    fNameTxtAlign: "left",
    lNameTxtAlign: "left",
    fNamefontFamily: "Futura",
    lNamefontFamily: "Futura",
    fNamefontSize: "60",
    lNamefontSize: "100",
    fNameTxtMaxWidth: 0,
    lNameTxtMaxWidth: 0,
    maxfNameLength: 0,
    maxlNameLength: 0,
    fNameLetterSpacing: 0,
    lNameLetterSpacing: 0
  })

  const [maxNameWidth, setMaxNameWidth] = useState({
    fName: "", lName: ""
  })

  const [maxWidth, setMaxWidth] = useState({
    fNameWidth: 10, lNameWidth: 10
  })

  const coordinateLookup = {
    common: commonCCoOrdinate, rare: rareCCoOrdinate, epic: epicCCoOrdinate,
  };

  const coordinate = coordinateLookup[cardType] ?? cCoOrdinate;
  const fNameX = coordinate.fNameX;
  const fNameY = coordinate.fNameY;
  const lNameX = coordinate.lNameX;
  const lNameY = coordinate.lNameY;
  const fNameColor = coordinate.fNameColor;
  const lNameColor = coordinate.lNameColor;
  const fNameAngle = coordinate.fNameAngle;
  const lNameAngle = coordinate.lNameAngle;
  const fNameTxtAlign = coordinate.fNameTxtAlign;
  const lNameTxtAlign = coordinate.lNameTxtAlign;
  const fNamefontFamily = coordinate.fNamefontFamily;
  const lNamefontFamily = coordinate.lNamefontFamily;
  const fNamefontSize = coordinate.fNamefontSize;
  const lNamefontSize = coordinate.lNamefontSize;
  const fNameTxtMaxWidth = coordinate.fNameTxtMaxWidth;
  const lNameTxtMaxWidth = coordinate.lNameTxtMaxWidth;
  const maxfNameLength = coordinate.maxfNameLength;
  const maxlNameLength = coordinate.maxlNameLength;
  const fNameLetterSpacing = coordinate.fNameLetterSpacing;
  const lNameLetterSpacing = coordinate.lNameLetterSpacing;

  const getFrameDetails = useCallback(async () => {
    setShowImage("")
    const frame = await axios.get(`/get-frame/${frameId}`)

    if (frame.data.success) {
      const result = frame.data.frame
      setOrientation(result.orientation)
      if (cardType !== "applyToAll" && !!cardType) {
        setData({...data, sFile: result[cardType].c, cBg: result[cardType].cBg})
        if (result[cardType]?.cCoOrdinate?.length > 0) {
          const coordinateLookup = {
            common: setCommonCCoOrdinate, rare: setRareCCoOrdinate, epic: setEpicCCoOrdinate,
          };

          const setCoordinates = coordinateLookup[cardType];
          const coOrdinates = JSON.parse(result[cardType].cCoOrdinate);
          if (setCoordinates) {
            setCoordinates((prevState) => ({...prevState, ...coOrdinates}));
          }
          setCCoOrdinate({...cCoOrdinate, ...coOrdinates})
          setMaxNameWidth({
            ...maxNameWidth,
            fName: generateAlphabets(coOrdinates.maxfNameLength),
            lName: generateAlphabets(coOrdinates.maxlNameLength)
          })
        }
      } else {
        setData({...data, sFile: result["epic"].c, cBg: result["epic"].cBg})
        if (result["epic"]?.cCoOrdinate?.length > 0) {
          let coOrdinates = JSON.parse(result["epic"].cCoOrdinate)
          setCCoOrdinate({...cCoOrdinate, ...coOrdinates})
          setMaxNameWidth({
            ...maxNameWidth,
            fName: generateAlphabets(coOrdinates.maxfNameLength),
            lName: generateAlphabets(coOrdinates.maxlNameLength)
          })
        }
      }
    }
  }, [frameId, cardType])

  function generateAlphabets(count) {
    const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';

    for (let i = 0; i < count; i++) {
      const alphabet = alphabets.charAt(i);
      result += alphabet;
    }

    return result;
  }

  const handleTxtLength = (e) => {
    const alphabets = generateAlphabets(e.target.value)
    setMaxNameWidth({...maxNameWidth, [e.target.name]: alphabets})
    if (cardType === "common") {
      setCommonCCoOrdinate({
        ...commonCCoOrdinate, [e.target.name === "fName" ? "maxfNameLength" : "maxlNameLength"]: e.target.value
      })
    } else if (cardType === "rare") {
      setRareCCoOrdinate({
        ...rareCCoOrdinate, [e.target.name === "fName" ? "maxfNameLength" : "maxlNameLength"]: e.target.value
      })
    } else if (cardType === "epic") {
      setEpicCCoOrdinate({
        ...epicCCoOrdinate, [e.target.name === "fName" ? "maxfNameLength" : "maxlNameLength"]: e.target.value
      })
    }
    setCCoOrdinate({...cCoOrdinate, [e.target.name === "fName" ? "maxfNameLength" : "maxlNameLength"]: e.target.value})
  }

  useEffect(() => {
    getFrameDetails()
  }, [getFrameDetails])

  const handleChange = (e) => {
    let {name, value} = e.target
    if (name === "fNameAngle" || name === "lNameAngle") {
      let normalizedAngle = parseFloat(e.target.value) % 360;
      if (normalizedAngle < -360) {
        normalizedAngle += 720;
      } else if (normalizedAngle > 360) {
        normalizedAngle -= 720;
      }
      // Round the angle value to two decimal places
      value = normalizedAngle
    }
    if (cardType === "common") {
      setCommonCCoOrdinate({...commonCCoOrdinate, [name]: value})
    } else if (cardType === "epic") {
      setEpicCCoOrdinate({...epicCCoOrdinate, [name]: value})
    } else if (cardType === "rare") {
      setRareCCoOrdinate({...rareCCoOrdinate, [name]: value})
    }
    setCCoOrdinate({...cCoOrdinate, [name]: value})
  }

  const handleFontChange = (eKey, e, name) => {
    setCCoOrdinate({...cCoOrdinate, [name]: e.target.innerText})
  }

  const handleSubmit = async () => {
    setGetTxtLoading(true)
    const payload = {
      "p": data.sFile, "fName": data.fName, "lName": data.lName, ...cCoOrdinate
    }
    const result = await axios.post("/frame-modify", payload)
    setShowImage(result.data.buffer)
    setGetTxtLoading(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    let payload = {
      // cCoOrdinate: JSON.stringify(cCoOrdinate)
    }
    if (cardType === "common") {
      payload.commonCCoOrdinate = JSON.stringify(commonCCoOrdinate)
    } else if (cardType === "rare") {
      payload.rareCCoOrdinate = JSON.stringify(rareCCoOrdinate)
    } else if (cardType === "epic") {
      payload.epicCCoOrdinate = JSON.stringify(epicCCoOrdinate)
    } else {
      payload.cCoOrdinate = JSON.stringify(cCoOrdinate)
    }
    const frameSave = await axios.put(`/change-frame-points/${frameId}`, payload)

    if (frameSave.data.success) {
      message.open({
        type: "success", content: "Text co-ordinates saved successfully"
      })
      setTimeout(() => {
        history.push("/frames")
      }, [500])
    }
    setIsLoading(false)
  }

  const handleCheckWidth = async () => {
    const errors = (!maxNameWidth.fName && "First name text length is required" || !maxNameWidth.lName && "Last name text length is required")
    if (errors) {
      message.open({
        type: "error", content: errors
      })
      return
    }
    setCheckTxtLoading(true)
    const payload = {
      "p": data.sFile, "fName": maxNameWidth.fName, "lName": maxNameWidth.lName, ...cCoOrdinate
    }
    const result = await axios.post("/get-text-width", payload)
    setShowImage(result.data.buffer)
    setMaxWidth(result.data.textWidth)
    setCheckTxtLoading(false)
  }

  const handleSetWidth = () => {
    setCCoOrdinate({
      ...cCoOrdinate, fNameTxtMaxWidth: maxWidth.fNameWidth.toFixed(2), lNameTxtMaxWidth: maxWidth.lNameWidth.toFixed(2)
    })
    message.open({
      type: "success", content: "Max text width set successfully"
    })
  }

  const handleSelect = (eKey, e) => {
    console.log(eKey, "<<-- ekey")
    setCardType(eKey)
  }

  return (<>
    <div className={"frame_design_header"}>
      <div className="add-frame_header">
        <ArrowLeftOutlined key="arrow-left" style={{marginRight: "10px"}} onClick={() => history.goBack()}/>
        Card Design
      </div>
      <div className="choose_field select_type_dd">
        <div className={"dropdownField"}>
          {/*<Form.Label aria-required={"true"} htmlFor="inputText">Card type*</Form.Label>*/}
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className={"dropdownBtn"}>
              {cardType !== "" ? `Card Type(${cardType})` : "Card Type(Apply to all)"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={"applyToAll"}>Apply to all</Dropdown.Item>
              <Dropdown.Item eventKey={"common"}>Common</Dropdown.Item>
              <Dropdown.Item eventKey={"epic"}>Epic</Dropdown.Item>
              <Dropdown.Item eventKey={"rare"}>Legendary</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
    <div className='image-container'>
      <div className="image_section">
        <img src={!showImage ? `${data?.sFile}` : `data:image/jpeg;base64,${showImage}`}
             style={{height: "25rem", width: "auto", position: orientation === 'portrait' ? "absolute" : "relative"}}
             alt='Hello' loading={"lazy"}/>
        {orientation === 'portrait' &&
          <img src={data.cBg} style={{height: "25rem", width: "auto"}} alt='Hello' loading={"lazy"}/>}
        <div className='x_axis_direction'><span>X Axis</span></div>
        <div className='y_axis_direction'><span>Y Axis</span></div>
        <h6 style={{color: "#0d6efd"}}><strong>***Remove extra characters and set a max length for card
          text***</strong></h6>
        <div className='add_max_widthhh'>
          <Form.Group className="mb-3 text_form" controlId="formBasicEmail">
            <Form.Label>Max text length for first name</Form.Label>
            <Form.Control required name="fName" type="number" placeholder="" value={maxfNameLength}
                          onChange={handleTxtLength}/>
          </Form.Group>

          <Form.Group className="mb-3 text_form" controlId="formBasicPassword">
            <Form.Label>Max text length for last name</Form.Label>
            <Form.Control required name="lName" type="number" placeholder="" value={maxlNameLength}
                          onChange={handleTxtLength}/>
          </Form.Group>
        </div>
        <div className='add_max_widthhh'>
          <Form.Group className="mb-3 text_form" controlId="formBasicEmail">
            <Form.Label>Maximum character for first name</Form.Label>
            <Form.Control readOnly required type="text" value={maxNameWidth.fName} placeholder=""/>
          </Form.Group>

          <Form.Group className="mb-3 text_form" controlId="formBasicPassword">
            <Form.Label>Maximum character for last name</Form.Label>
            <Form.Control readOnly required type="text" value={maxNameWidth.lName} placeholder=""/>
          </Form.Group>
        </div>
        <div className="text_width_btns">
          <Button disabled={checkTxtLoading} variant="primary" className='form_submittt' type="submit"
                  onClick={handleCheckWidth}>
            {checkTxtLoading ? "Loading..." : "Check max width"}
          </Button>
          <Button variant="primary" className='form_submittt' type="submit" onClick={handleSetWidth}>
            Set max width
          </Button>
        </div>
      </div>

      <div className='image-right'>
        <div className="inputfields">
          <div className="first_name">
            <div className='formfield'>
              <label htmlFor='xAxis'>First name</label>
              <input className="text_box" type="text" name="fNameX" id="TXAxis"
                     onChange={e => setData({...data, fName: e.target.value})}
                     value={data.fName}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name x axis</label>
              <input className="text_box" type="number" name="fNameX" id="TXAxis"
                     onChange={handleChange}
                     value={fNameX}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name y axis</label>
              <input className="text_box" type="number" name="fNameY" id="TYAxis"
                     onChange={handleChange}
                     value={fNameY}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name color</label>
              <input className="text_box" type="color" name="fNameColor" id="TColor"
                     onChange={handleChange}
                     value={fNameColor}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name text angle(In degree)</label>
              <input className="text_box" type="number" name="fNameAngle" id="angle"
                     onChange={handleChange} min={'-360'} max={'360'} step={'1'}
                     value={fNameAngle}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name text align</label>
              {/*<input className="text_box" type="text" name="fNameTxtAlign" id="angle" onChange={handleChange}
                       value={fNameTxtAlign}/>*/}

              <Dropdown onSelect={(key, e) => handleFontChange(key, e, "fNameTxtAlign")}
                        style={{width: "100%"}}
                        className={"fontFamily"}>

                <Dropdown.Toggle variant="success" id="dropdown-basic" className={"dropdownBtn"}>
                  {fNameTxtAlign.toUpperCase() || "Font Family"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>left</Dropdown.Item>
                  <Dropdown.Item>center</Dropdown.Item>
                  <Dropdown.Item>right</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </div>
            <div className='formfield fontslist'>
              <label htmlFor='xAxis'>First name font family</label>
              <Dropdown onSelect={(key, e) => handleFontChange(key, e, "fNamefontFamily")}
                        style={{width: "100%"}}
                        className={"fontFamily"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className={"dropdownBtn"}>
                  <span className={"fontfamilyTxt"}>{fNamefontFamily.toUpperCase() || "Font Family"}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {FONTS?.length > 0 && FONTS.map((font, idx) => {
                    return (<Dropdown.Item key={idx}>{font}</Dropdown.Item>)
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name font size</label>
              <input className="text_box" type="number" name="fNamefontSize" id="fontSize"
                     onChange={handleChange}
                     value={fNamefontSize}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name letter spacing</label>
              <input className="text_box" type="number" name="fNameLetterSpacing" id="fNameLetterSpacing"
                     onChange={handleChange} min={'0'} step={'1'}
                     value={fNameLetterSpacing}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>First name text max width</label>
              <input readOnly className="text_box" type="number" name="fNameTxtMaxWidth" id="fNameTxtMaxWidth"
                     onChange={handleChange}
                     value={fNameTxtMaxWidth}/>
            </div>
          </div>
          <div className="last_name">
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name</label>
              <input className="text_box" type="text" name="fNameX" id="TXAxis"
                     onChange={e => setData({...data, lName: e.target.value})}
                     value={data.lName}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name x axis</label>
              <input className="text_box" type="number" name="lNameX" id="SXAxis"
                     onChange={handleChange}
                     value={lNameX}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name y axis</label>
              <input className="text_box" type="number" name="lNameY" id="SYAxis"
                     onChange={handleChange}
                     value={lNameY}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name color</label>
              <input className="text_box" type="color" name="lNameColor" id="SColor"
                     onChange={handleChange}
                     value={lNameColor}/>
            </div>

            <div className='formfield'>
              <label htmlFor='xAxis'>Last name text angle(In degree)</label>
              <input className="text_box" type="number" name="lNameAngle" id="angle"
                     onChange={handleChange} min={'-360'} max={'360'} step={'1'}
                     value={lNameAngle}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name text align</label>
              {/*<input className="text_box" type="text" name="lNameTxtAlign" id="angle" onChange={handleChange}
                       value={lNameTxtAlign}/>*/}

              <Dropdown onSelect={(key, e) => handleFontChange(key, e, "lNameTxtAlign")}
                        style={{width: "100%"}}
                        className={"fontFamily"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className={"dropdownBtn"}>
                  {lNameTxtAlign.toUpperCase() || "Font Family"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>left</Dropdown.Item>
                  <Dropdown.Item>center</Dropdown.Item>
                  <Dropdown.Item>right</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='formfield fontslist'>
              <label htmlFor='xAxis'>Last name font family</label>
              {/*<input className="text_box" type="text" name="lNamefontFamily" id="angle" onChange={handleChange}
                       value={lNamefontFamily}/>*/}

              <Dropdown onSelect={(key, e) => handleFontChange(key, e, "lNamefontFamily")}
                        style={{width: "100%"}}
                        className={"fontFamily"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className={"dropdownBtn"}>
                  <span className={"fontfamilyTxt"}>{lNamefontFamily.toUpperCase() || "Font Family"}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {FONTS?.length > 0 && FONTS.map((font, idx) => {
                    return (<Dropdown.Item key={idx}>{font}</Dropdown.Item>)
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name font size</label>
              <input className="text_box" type="number" name="lNamefontSize" id="fontSize"
                     onChange={handleChange}
                     value={lNamefontSize}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name letter spacing</label>
              <input className="text_box" type="number" name="lNameLetterSpacing" id="lNameLetterSpacing"
                     onChange={handleChange} min={'0'} step={'1'}
                     value={lNameLetterSpacing}/>
            </div>
            <div className='formfield'>
              <label htmlFor='xAxis'>Last name text max width</label>
              <input readOnly className="text_box" type="number" name="lNameTxtMaxWidth" id="lNameTxtMaxWidth"
                     onChange={handleChange}
                     value={lNameTxtMaxWidth}/>
            </div>
          </div>
        </div>
        <Button disabled={getTxtLoading} className={"designButton"}
                onClick={handleSubmit}>{getTxtLoading ? "Loading..." : "Get Text"}</Button>
        <Button disabled={isLoading} className={"designButton saveBtn"}
                onClick={handleSave}>{isLoading ? "Loading..." : "Save"}</Button>
      </div>

    </div>
  </>)
}

export default FrameDesign