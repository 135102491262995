import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
} from "react-bootstrap";
import axios from "../../../axios/axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import { ProfileT_Img, Like1_Img,Profw2_Img} from "../../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import "../../StoryDetails/storydetails.css";

function OrderDetails() {
  const { id } = useParams();
  console.log("order id", id);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);

  const getStoryDetails = async () => {
    // setLoading(prevState => ({...prevState, profile:true}));
    await axios
      .get(`/get-order-details/${id}`)
      .then((res) => {
        console.log("order details.....", res.data);
        if (res.data.success === true) {
            setProfile(res.data.data);
            setLoading(false);
          // if (res.data.data) {
          //   setProfile(res.data.data);
          //   setLoading(false);
          // } else {
          //   setProfile(null);
          //   setLoading(true);
          //   message.error("Data fetching failed")
          //   // setLoading(prevState => ({...prevState, profile:false}));
          // }
        } else {
          setProfile(null);
          setLoading(true);
          // setLoading(prevState => ({...prevState, profile:false}));
          message.error("Data fetching failed");
        }
      })
      .catch((err) => {
        console.log("Ordered list error", err);
      });
  };

  useEffect(() => {
    getStoryDetails();
  }, []);
  return (
    // <div>
    <LoadingOverlay
      //   className="t_userers_loading"
      active={loading}
      spinner={<PulseLoader color="white" size={50} />}
    >
      <div className="story_details_row dash_partts">
        <Row>
          <Col lg="12" md="12">
            <div className="s_details_left">
              <div className="s_details_left_inner">
                <div className="sdetails_left_img">
                  {console.log("profile.prodImage",profile.prodImage && profile.prodImage.imgUrl)}
                  <img src={profile.prodImage && profile.prodImage.imgUrl} />
                </div>
                <div className="s_details_right">
                  <h3>{profile.product && profile.product.name}</h3>
                  <p>{profile.product && profile.product.description}</p>
                  <div className="created_date">
                    <p>
                      <span>Date Created:</span>{" "}
                      {moment(profile.order && profile.order.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                  <div className="created_date">
                    <div className="reaction_part">
                      <p className="react_heading">
                        <span>amount:</span>
                      </p>
                      {profile.order && profile.order.amount}
                    </div>
                  </div>
                  <div className="created_date">
                    <div className="reaction_part">
                      <p className="react_heading">
                        <span>Quantity:</span>
                      </p>
                      {profile.order && profile.order.quantity}
                    </div>
                  </div>

                  <div className="created_date">
                    <p>
                      <span>user:</span>
                      {profile.order &&
                        profile.order.user_id &&
                        profile.order.user_id.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col lg="6" md="6">
                        <div className="s_details_right_parttts">
                            <Form.Group className="mb-3 storyfield_part" controlId="formBasicEmail">
                                <Form.Label>Story Name</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>
                        </div>
                    </Col> */}
        </Row>
      </div>
    </LoadingOverlay>
    // </div>
  );
}

export default OrderDetails;
