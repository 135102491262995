import React from "react";
import { Nav } from "react-bootstrap";

import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
// import 'react-pro-sidebar/dist/css/styles.css';

import { NavLink } from "react-router-dom";

import "./leftpanel.css";
import { Dash_logo_img, Admin_left_img, Icon1_img, Icon2_img, Icon3_img, Icon4_img, Icon6_img, Icon8_img, Icon9_img, Nft_Purchase_Img, email_Template_Img, notification_log_png, notify_img, offer_list_img, payout_img, faq_img, Icon11_img, } from "../../assets/images";
import chiaWallet from "../../assets/images/svg_images/chia.svg"

// import Logout from "../../assets/images/svg_images/logout.svg"
// import { LogoutIcon } from "../../svgComp";

function Leftpanel() {
  // const history = useHistory();
  // const sendTODashboard = () =>{
  //   history.push();
  // }

  let userData = JSON.parse(localStorage.getItem("AuthData"))?.userDetails

  return (
    <section className="left_panel">
      <div className="left_panel_header">
        {/* <img src={Dash_logo_img} alt="No Img" onClick={sendTODashboard} /> */}
        <NavLink exact to="/dashboard">
          <span className="dasg__imgg">
            <img alt="No Img" src={Dash_logo_img} />
          </span>
        </NavLink>
      </div>
      <div className="left_panel_admin_parts">
        <div className="admin_left">
          <div className="admin_left_box">
            <img src={Admin_left_img} alt="No Img" />
            <span className="user_online"></span>
          </div>
          <h3>Admin</h3>
        </div>
        <div className="admin_right">
          {/* <Dropdown className="drop_partts">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={Dot_img} alt="No Img" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      <div className="my_prf_inner">
        {
          userData && userData.role === "admin" ?
            <ProSidebar>
              <Menu iconShape="square">
                <Nav as="ul" className="ml-auto mr-auto nav_dash">
                  <Nav.Item as="li">
                    <NavLink exact to="/dashboard">
                      <span className="dasg__imgg">
                        <img alt="No Img" src={Icon1_img} />
                      </span>
                      <span>Dashboard</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <NavLink exact to="/hypeu-totals">
                      <span className="dasg__imgg">
                        <img alt="No Img" src={Icon1_img} />
                      </span>
                      <span>Hypeu Totals</span>
                    </NavLink>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                <NavLink to="/user" exact>
                  <span className="dasg__imgg">
                    <img src={Icon2_img} alt="No Img" />
                  </span>
                  <span>User</span>
                </NavLink>
              </Nav.Item> */}
                  <SubMenu title="Users" icon={<img src={Icon2_img} alt="No Img" />} as="li">
                    <MenuItem>
                      <NavLink exact to="/user">
                        <span>Users List</span>
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink exact to="/userlevel">
                        <span>User Level</span>
                      </NavLink>
                    </MenuItem>
                  </SubMenu>
                  <Nav.Item as="li">
                    <NavLink exact to="/storyfeeds">
                      <span className="dasg__imgg">
                        <img src={Icon3_img} alt="No Img" />
                      </span>
                      <span>Story Feeds</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/nfts">
                      <span className="dasg__imgg">
                        <img src={Icon4_img} alt="No Img" />
                      </span>
                      <span>NFTs</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/offer-list">
                      <span className="dasg__imgg">
                        <img src={offer_list_img} alt="No Img" />
                      </span>
                      <span>Make Offered</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/nftpurchase">
                      <span className="dasg__imgg">
                        <img src={Nft_Purchase_Img} alt="No Img" />
                      </span>
                      <span>NFT Purchase</span>
                    </NavLink>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                <NavLink exact to="/wallet">
                  <span className="dasg__imgg">
                    <img src={Icon5_img} alt="No Img" />
                  </span>
                  <span>Wallet</span>
                </NavLink>
              </Nav.Item> */}
                  <Nav.Item as="li">
                    <NavLink exact to="/users-payout">
                      <span className="dasg__imgg">
                        <img src={payout_img} alt="No Img" />
                      </span>
                      <span>Users Payout</span>
                    </NavLink>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                    <NavLink exact to="/badges">
                      <span className="dasg__imgg">
                        <img src={Icon6_img} alt="No Img" />
                      </span>
                      <span>Badges</span>
                    </NavLink>
                  </Nav.Item> */}

                  <Nav.Item as="li">
                    <NavLink exact to="/news-announcement">
                      <span className="dasg__imgg">
                        <img src={Icon11_img} alt="No Img" />
                      </span>
                      <span>News and Announcement</span>
                    </NavLink>
                  </Nav.Item>

                  {/* <Nav.Item as="li">
                <NavLink exact to="/fund-raisers">
                  <span className="dasg__imgg">
                    <img src={Icon6_img} alt="No Img" />
                  </span>
                  <span>Fund Raisers</span>
                </NavLink>
              </Nav.Item> */}
                  {/* <SubMenu
                title="Promo Code"
                icon={<img src={Icon4_img} alt="No Img" />}
                as="li"
              >
                  <MenuItem>
                <NavLink exact to="/promo-code-list">
                  <span>Codes List</span>
                </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink exact to="/usages-promo-code">
                    <span>Usages Codes</span>
                  </NavLink>
                </MenuItem>
              </SubMenu> */}

                  <Nav.Item as="li">
                    {/* <NavLink exact to="/hype-u-merchaindise">
              <span className="dasg__imgg">
                <img src={Icon7_img} alt="No Img" />
              </span>
              <span>Merchaindise</span>
            </NavLink> */}
                  </Nav.Item>

                  <SubMenu
                    title="Templates"
                    icon={<img src={email_Template_Img} alt="No Img" />}
                    as="li"
                  >
                    <MenuItem>
                      <NavLink exact to="/email-list">
                        <span className="dasg__imgg">
                          {/* <img src={email_Template_Img} alt="No Img" /> */}
                        </span>
                        <span>Email Template</span>
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink exact to="/pushnotification">
                        <span className="dasg__imgg">
                          {/* <img src={Notification_img} alt="No Img" /> */}
                        </span>
                        <span>Notification Template</span>
                      </NavLink>
                    </MenuItem>
                  </SubMenu>

                  {/* <Nav.Item as="li">
            <NavLink exact to="/email-list">
              <span className="dasg__imgg">
                <img src={email_Template_Img} alt="No Img" />
              </span>
              <span>Email Template</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item as="li">
            <NavLink exact to="/pushnotification">
              <span className="dasg__imgg">
                <img src={Notification_img} alt="No Img" />
              </span>
              <span>Notification Template</span>
            </NavLink>
          </Nav.Item> */}
                  {/* <Nav.Item as="li">
                    <NavLink exact to="/nft-notification">
                      <span className="dasg__imgg">
                        <img src={notify_img} alt="No Img" />
                      </span>
                      <span>NFTs Notifications</span>
                    </NavLink>
                  </Nav.Item> */}
                  <Nav.Item as="li">
                    <NavLink exact to="/notification-log">
                      <span className="dasg__imgg">
                        <img src={notification_log_png} alt="No Img" />
                      </span>
                      <span>Notifications</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/feedback">
                      <span className="dasg__imgg">
                        <img src={Icon8_img} alt="No Img" />
                      </span>
                      <span>Feedback</span>
                    </NavLink>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
            <NavLink to="/management">
              <span className="dasg__imgg">
                <img src={management_img} alt="No Img" />
              </span>
              <span>Management</span>
            </NavLink>
          </Nav.Item> */}
                  {/* <Nav.Item as="li"> */}
                  {/* </NavLink> */}
                  {/* <SubMenu
                title="Merchandise"
                icon={<img src={Icon7_img} alt="No Img" />}
                as="li"
              >
                <MenuItem>
                  <NavLink exact to="/banners">
                    <span>Banners</span>
                  </NavLink>
                </MenuItem >
                <MenuItem>
                  <NavLink exact to="/add-varieties">
                    <span>Add Varieties</span>
                  </NavLink>
                </MenuItem >
                <MenuItem>
                  <NavLink exact to="/add-attributes">
                    <span>Add Attributes</span>
                  </NavLink>
                </MenuItem >
                <MenuItem>
                  <NavLink exact to="/orders">
                    <span>Orders List</span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink exact to="/products">
                    <span>Products List</span>
                  </NavLink>
                </MenuItem>
              </SubMenu> */}
                  {/* </Nav.Item> */}
                  {/* <Nav.Item as="li">
                <NavLink exact to="/feedback">
                  <span className="dasg__imgg">
                    <img src={Icon8_img} alt="No Img" />
                  </span>
                  <span>Feedback </span>
                </NavLink>
              </Nav.Item> */}
                  {/* <Nav.Item as="li">
              </NavLink> */}
                  {/* </Nav.Item> */}
                  <Nav.Item as="li">
                    <NavLink exact to="/blockchain-wallet">
                      <span className="dasg__imgg">
                        <img src={chiaWallet} alt="No Img" height={20} width={20} />
                      </span>
                      <span>Blockchain Wallet</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/frames">
                      <span className="dasg__imgg">
                        <img src={faq_img} alt="No Img" />
                      </span>
                      <span>Frames</span>
                    </NavLink>
                  </Nav.Item>
                  <SubMenu
                    title="Settings"
                    icon={<img src={Icon9_img} alt="No Img" />}
                    as="li"
                  >
                    <MenuItem>

                      <NavLink exact to="/management">
                        <span>Management</span>
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink exact to="/setting">
                        <span>Other Settings</span>
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      {/* <NavLink exact to="/lebelsetting">
                    <span>Lebel Settings</span>
                  </NavLink> */}
                      <NavLink exact to="/level-setting">
                        <span>Level Setting</span>
                      </NavLink>
                    </MenuItem>
                  </SubMenu>
                  <Nav.Item as="li">
                    <NavLink exact to="/about">
                      <span className="dasg__imgg">
                        {/* <img src={faq_img} alt="No Img" /> */}
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" style={{ fill: "#fff" }} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg>
                      </span>
                      <span>About Us</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/privacy">
                      <span className="dasg__imgg">
                        {/* <img src={faq_img} alt="No Img" /> */}
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" style={{ fill: "white" }} aria-hidden="true" viewBox="0 0 24 24" data-testid="PrivacyTipIcon"><path d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-1 6h2v2h-2V7zm0 4h2v6h-2v-6z"></path></svg>
                      </span>
                      <span>Privacy Policy</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/terms-conditions">
                      <span className="dasg__imgg">
                        <img src={faq_img} alt="No Img" />
                      </span>
                      <span>Terms and Conditions</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/faq">
                      <span className="dasg__imgg">
                        <img src={faq_img} alt="No Img" />
                      </span>
                      <span>FAQ</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink to="/logout" className={"logout_section"}>
                      <span className="dasg__imgg">
                        {/* <img src={Logout} alt="No Img /> */}
                        <svg className="logoutIcon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LogoutRoundedIcon"><path d="M5 5h6c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h6c.55 0 1-.45 1-1s-.45-1-1-1H5V5z"></path><path d="m20.65 11.65-2.79-2.79c-.32-.32-.86-.1-.86.35V11h-7c-.55 0-1 .45-1 1s.45 1 1 1h7v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.19.2-.51.01-.7z"></path></svg>
                      </span>
                      <span className="logoutTxt">Logout</span>
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Menu>
            </ProSidebar>
            :
            <ProSidebar style={{ height: "calc(100vh - 14.6rem)" }}>
              <Menu iconShape="square">
                <Nav as="ul" className="ml-auto mr-auto nav_dash">
                  <Nav.Item as="li">
                    <NavLink exact to="/dashboard">
                      <span className="dasg__imgg">
                        <img alt="No Img" src={Icon1_img} />
                      </span>
                      <span>Dashboard</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavLink exact to="/blockchain-wallet">
                      <span className="dasg__imgg">
                        <img src={chiaWallet} alt="No Img" height={20} width={20} />
                      </span>
                      <span>Blockchain Wallet</span>
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Menu>
            </ProSidebar>
        }
      </div>
      <div className="powered_by">Powered by HYPEU © 2022</div>
    </section >
  );
}

export default Leftpanel;
