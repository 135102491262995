import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { privacy_policy_img } from '../../assets/images';
import HeaderWithLogo from '../layout/HeaderWithLogo';
import './privacy.css';
import axios from "../../axios/axios";

const Privacy = () => {
  const [managementData, setManagementData] = useState({
    heading: "",
    content: "",
    manageKey: "",
  });
  
  const getPrivacyPolicy = async () => {
    await axios
      .get(`/get-privacy-policy`)
      .then((response) => {
        const data = response.data.getPrivacyPolicyData[0];
        setManagementData({
          ...managementData,
          heading: data.heading,
          content: data.content,
          manageKey: data.key,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    getPrivacyPolicy();
  }, [])
  return (
    <React.Fragment>
    <div dangerouslySetInnerHTML={{__html: managementData.content}}></div>
    </React.Fragment>
  )
}

export default Privacy;