// import * as nftDetailsAction from "../actions/nftDetails.action";

// //  featurekey
// export const nftDetailsFeatureKey = "nftDetails-info";

// //  initial state
// let initialState = {
//     nftDetails: [],
//     errorMessage: ""
// };

// console.log("================", initialState);

// //   reducer () function  ->  it takes 2-arguments.  1. initialState  2.action
// export const reducer = (state = initialState, action) => {
//     let {type, payload} = action;
    
//     switch(type){
//         // fetching nftDetails
//         case nftDetailsAction.FETCH_NFT_DETAILS_REQUEST:
//             return{
//                 ...state,
//             };
//         case nftDetailsAction.FETCH_NFT_DETAILS_SUCCESS:
//             return{
//                 ...state,
//                 nftDetails: payload.nftEditions,
//             };
//         case nftDetailsAction.FETCH_NFT_DETAILS_FAILURE:
//             return{
//                 ...state,
//                 errorMessage: payload.error,
//             };
//         default:
//             return state;
//     }
// };


const setNftDetails = (state = [], action) =>{
    switch(action.type){
        case "SET_NFT_DETAILS": 
            return action.payload;
        default:
            return state;
    }
}

export default setNftDetails;