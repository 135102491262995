import { CopyFilled, InfoCircleFilled } from "@ant-design/icons";
import { Col, Tooltip, message } from "antd";
import React from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CurrencyFormating } from "../../helpers/currencyHelper";

const NftEdition = () => {
  let nftDetailsInfo = useSelector((state) => state.setNftDetails);

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
    message.success("Text copied successfully")
  }

  const redirectToExplorer = (nft) => {
    let walletId = nft.walletDetails.walletId.wallet_address.split("")[0]
    let url;
    if (walletId === "t") {
      if(nft.walletDetails.details.nft_id)
        url = `https://testnet10.spacescan.io/nft/${nft.walletDetails.details.nft_id}`
      else
        url = `https://testnet10.spacescan.io/address/${nft.walletDetails.walletId.wallet_address}`
      
    } else {
      if(nft.walletDetails.details.nft_id)
        url = `https://spacescan.io/nft/${nft.walletDetails.details.nft_id}`
      else
        url = `https://spacescan.io/address/${nft.walletDetails.walletId.wallet_address}`
      
    }

    window.open(url)
  }

  return (
    <React.Fragment>
      <div className="hermis_bottom fix_design">
        <Col xs={2} sm={2} md={2}></Col>
        <Col xs={4} sm={4} md={4}>
          <h4>Title</h4>
        </Col>
        <Col xs={4} sm={4} md={4}>
          <h4>Nft's Holder</h4>
        </Col>
        {/* <Col xs={4} sm={4} md={4}>
          <h4>WalletDetails</h4>
        </Col> */}
        <Col xs={4} sm={4} md={4}>
          <h4>Price</h4>
        </Col>
        <Col xs={4} sm={4} md={4}>
          <h4>Status</h4>
        </Col>
        <Col xs={2} sm={2} md={2}>
          <h4>View</h4>
        </Col>
      </div>
      {nftDetailsInfo && (
        <React.Fragment>
          {nftDetailsInfo.map((nftData, index) => {
            return (
              // <div key={index}>
              //   <Row>
              //     <Col>
              //       <div className="edition_inner_center">
              //         <div className="edition_center_left">
              //           <div className="edition_user">
              //             <img src={nftData.fileUrl} />
              //           </div>

              //           <div className="edition_user_text">
              //             {nftData.title}
              //           </div>
              //         </div>
              //       </div>
              //     </Col>
              //     <Col>
              //       <div className="edition_inner_center">
              //         <div className="edition_center_middle">
              //           {/* {nftData.creator.name} */}
              //           {nftData.nftHolder?.name}
              //         </div>
              //       </div>
              //     </Col>
              //     <Col>
              //       <Row>
              //         <div className="edition_inner_center">
              //           <div className="edition_center_right padding-lefts">
              //             ${" "}{nftData.prices.amount}
              //           </div>
              //         </div>
              //       </Row>

              //     </Col>
              //   </Row>
              // </div>
              <div key={index} className="hermis_bottom fix_design" style={{ backgroundColor: "#545f70", borderRadius: 0, marginTop: 0 }}>
                <Col xs={2} sm={2} md={2}> <div className="edition_user">
                      <img src={nftData.fileUrl} />
                    </div></Col>
                <Col xs={4} sm={4} md={4}>
                  <div className="edition_center_left">
                   

                    <div className="edition_user_text">
                      {nftData.title!=""? nftData.title : `${nftData.creator.nickName.fName} ${nftData.creator.nickName.lName}`}
                    </div>
                  </div>
                </Col>
                <Col xs={4} sm={4} md={4}>
                  <div className="edition_center_middle">
                    {nftData.nftHolder?.name}
                  </div>
                </Col>
                {/* <Col xs={4} sm={4} md={4}>
                  <div className="edition_center_middle">
                    <div>
                      <Tooltip placement="bottomLeft" style={{ marginLeft: 0 }} title={nftData.walletDetails?.walletId?.name}>
                        <span style={{ marginLeft: 0, width: "60px" }}>{nftData.walletDetails?.walletId?.name || "--"}</span>
                        {nftData.walletDetails?.walletId?.name && <span><CopyFilled onClick={() => handleCopy(nftData.walletDetails?.walletId?.name)} /></span>}
                      </Tooltip>
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <Tooltip placement="bottomLeft" title={nftData.walletDetails?.walletId?.wallet_address}>
                        <span className="text-truncate" style={{ width: "70px" }}>{nftData.walletDetails?.walletId?.wallet_address || "--"}</span>
                      </Tooltip>
                      {nftData.walletDetails?.walletId?.wallet_address && <span style={{ marginLeft: 0 }}><CopyFilled onClick={() => handleCopy(nftData.walletDetails?.walletId?.wallet_address)} /></span>}
                    </div>
                  </div>
                </Col> */}
                <Col xs={4} sm={4} md={4}>
                  <div className="edition_center_right">
                    {CurrencyFormating(nftData.prices.amount)}
                  </div>
                </Col>
                <Col xs={4} sm={4} md={4}>
                  <div className="edition_center_right">
                    {nftData.activeStatus ? "Minted" : "Not Minted"}
                  </div>
                </Col>
                <Col xs={2} sm={2} md={2}>
                  <div className="edition_center_right">
                    <InfoCircleFilled style={{ fontSize: "20px", color: nftData.walletDetails === null ? "gray" : "white", cursor: nftData.walletDetails === null ? "not-allowed" : "pointer", pointerEvents: nftData.walletDetails === null ? "none" : "all" }} onClick={() => redirectToExplorer(nftData)} />
                  </div>
                </Col>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default NftEdition;
