import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from '../../axios/axios'
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';

const getQueryValue = (key) => {
	let objUrlParams = new URLSearchParams(window.location.search);

	return objUrlParams.get(key)
}

const BlockchainWalletDetails = () => {
	let { walletId } = useParams();
	let mode = getQueryValue("mode")

	const history = useHistory()
	const [walletDetails, setWalletDetails] = useState([])
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [totalRows, setTotalRows] = useState(0)
	const [loading, setLoading] = useState(false)

	const getWalletDetails = useCallback(async () => {
		setLoading(true)
		const walletDetails = await axios.get(`/wallet-details/${walletId}?mode=${mode}&limit=${limit}&page=${page}`)

		if (walletDetails.data.success) {
			setWalletDetails(walletDetails.data.result)
			setTotalRows(walletDetails.data.pagination.totalRows)
		} else {
			setWalletDetails([])
		}
		setLoading(false)
	}, [limit, page])

	useEffect(() => {
		getWalletDetails()
	}, [getWalletDetails])

	const CustomLoader = () => (
		<div
			style={{
				padding: "15px",
				background: "#495A71",
				color: "#fff",
				width: "100%",
				fontSize: "40px",
				textAlign: "center",
				minHeight: "350px",
				lineHeight: "400px",
			}}
		>
			<div>Loading...</div>
		</div>
	);

	const EmptyDataLoader = () => (
		<div style={{
			padding: '15px',
			background: '#495A71',
			color: '#fff',
			width: '100%',
			fontSize: '40px',
			textAlign: 'center',
			minHeight: '350px',
			lineHeight: '400px'
		}}>
			<div>No data found...</div>
		</div>
	);

	const handlePerRowsChange = (newPerPage, page) => {
		setLimit(newPerPage);
		setPage(page);
	}

	const handlePageChange = (page) => {
		//console.log(page)
		setPage(page);
	}

	const walletQueues = () => {
		let allQueues = []

		walletDetails && walletDetails.map(data => {
			if(data.nftId){

			
				allQueues.push({
					name: (
						<div className="management_list_name">
							<div className="man_user" style={{ position: "relative" }}>
								<img src={data.nftId?.fileUrl} alt="Card icon"
									style={{ objectFit: "contain", borderRadius: 0, position: "absolute" }} loading={"lazy"} />
							</div>
							<div className="man_text" style={{ width: "100%" }}>{data.nftId.title}</div>
						</div>
					),
					edition: <div style={{ width: "100%" }}>{data.nftId.editions}</div>,
					walletId: <div style={{ width: "100%" }}>{data.blockchainWallet.id}</div>,
					address: <div style={{ width: "80%", color: data.attempt >= 10 && data.queueStatus !== "minted" && "#ff8e8e" }}>{data.blockchainWallet.wallet_address}</div>,
					status: <div style={{ width: "100%", color: data.attempt >= 10 && data.queueStatus !== "minted" && "#ff8e8e" }}>{data.queueStatus}</div>,
					attempt: <div style={{ width: "100%", color: data.attempt >= 10 && data.queueStatus !== "minted" && "#ff8e8e" }}>{data.attempt}</div>,
					mode: <div style={{ width: "100%" }}>{data.mode}</div>,
					startDate: <div style={{ width: "100%" }}>{moment(data.minDate).format("L")}</div>,
					endDate: <div style={{ width: "100%" }}>{moment(data.maxDate).format("L")}</div>,
				})
			}
		})
		return allQueues;
	}

	const conditionalRowStyles = [
		{
			when: row => row.attempt > 10,
			classNames: ["bg-red"]
		}
	]

	return (
		<>
			<div className="add-frame_header">
				<ArrowLeftOutlined key="arrow-left" style={{ marginRight: "10px" }} onClick={() => history.goBack()} />
				Wallet Details
			</div>
			<div style={{ marginTop: "1rem" }}>
				<div className="manage_table table-responsive framelist_table">
					<DataTable
						className="hypTable1"
						columns={columns}
						data={walletQueues()}
						responsive
						customStyles={customStyles}
						conditionalRowStyles={conditionalRowStyles}
						defaultSortField="id"
						defaultSortAsc={false}
						noDataComponent={<EmptyDataLoader />}
						progressComponent={<CustomLoader />}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						paginationPerPage={limit}
						paginationRowsPerPageOptions={[10, 20, 40]}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						progressPending={loading}
					/>
				</div>
			</div>
		</>
	)
}

const columns = [
	{
		name: "Name",
		selector: (row) => row.name,
		reorder: true,
		width: "200px",
	},
	{
		name: "Edition",
		selector: (row) => row.edition,
		reorder: true,
		width: "120px",
	},
	{
		name: "Wallet ID",
		selector: (row) => row.walletId,
		reorder: true,
		width: "120px",
	}, {
		name: "Wallet address",
		selector: (row) => row.address,
		reorder: true,
		width: "210px",
	},
	{
		name: "Queue status",
		selector: (row) => row.status,
		// reorder: true,
		width: "120px",
	},
	{
		name: "Attempt",
		selector: (row) => row.attempt,
		// reorder: true,
		width: "100px",
	},
	{
		name: "Mode",
		selector: (row) => row.mode,
		// reorder: true,
		width: "120px",
	},
	{
		name: "Start date",
		selector: (row) => row.startDate,
		// reorder: true,
		width: "180px",
	},
	{
		name: "End date",
		selector: (row) => row.endDate,
		// reorder: true,
		width: "180px",
	}
];

const customStyles = {
	rows: {
		style: {
			//minHeight: '72px',
			backgroundColor: "transparent",
			color: "#fff",
			paddingLeft: "10px", // override the row height
		},
	},
	headCells: {
		style: {
			paddingLeft: "10px", // override the cell padding for head cells
			paddingRight: "2px",
			backgroundColor: "transparent",
			fontSize: "16px",
			color: "#fff",
		},
	},
	cells: {
		style: {
			paddingLeft: "4px", // override the cell padding for data cells
			paddingRight: "2px",
			paddingTop: "5px",
			paddingBottom: "5px",
			backgroundColor: "transparent",
			fontSize: "14px",
			color: "#fff",
		},
	},
};

export default BlockchainWalletDetails