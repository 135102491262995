import React, { useEffect, useState } from "react";
import "./hypeuTotals.css";
import { Row, Col, Container, } from "react-bootstrap";
import axios from "../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyFormating } from "../../helpers/currencyHelper";
import Filter from './filter/filter'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { PulseLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';
import Item from "./cardinfo/item";
import "./cardinfo/infoblock.css"


const HypeuTotals = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [totals, setTotals] = useState({})
  const [filter, setFilter] = useState({})
  const [filterSelected, setFilterSelected] = useState({})
  const [filterString, setFilterString] = useState("")

  const token = useSelector((state) => state.setToken);

  const fetchTotals = async () => {
    setLoading(true);
    await axios
      .get("/hypeu-totals" + filterString, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setTotals(res.data.counts);

        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };
  const filterHandale = (field, d) => {

    // let date = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`
    setFilterSelected({ ...filterSelected, [field]: d })
    if (field == "start") {
      setFilterSelected((v) => { return { ...v, dateRangeErr: "" } })
      setFilter({
        ...filter, dateRange: {
          ...filter.dateRange,
          start: moment(d).format("YYYY-MM-DD")
        }
      })
    }
    else if (field == "end") {
      setFilterSelected((v) => { return { ...v, dateRangeErr: "" } })
      setFilter({
        ...filter, dateRange: {
          ...filter.dateRange,
          end: moment(d).format("YYYY-MM-DD")
        }
      })
    }
    else if (field == "date") {
      setFilterSelected((v) => { return { ...v, dateErr: "" } })
      setFilter({ ...filter, date: moment(d).format("YYYY-MM-DD") })
    }

    else if (field == "monthYear") {
      setFilterSelected((v) => { return { ...v, monthYearErr: "" } })
      setFilter({ ...filter, month: moment(d).format("MM"), year: moment(d).format("YYYY") })
    }



  }
  const isBeforeDate = (dateA, dateB) => new Date(dateA) < new Date(dateB);
  const filterSubmit = async (type) => {
    console.log("file", type)
    if (type == "dateRange") {
      if (filter?.dateRange?.start && filter?.dateRange?.end) {
        if (isBeforeDate(filter?.dateRange?.start, filter?.dateRange?.end)) {
          setFilterString(`?dateRange[start]=${filter?.dateRange?.start}&dateRange[end]=${filter?.dateRange?.end}`)
        }
        else {
          setFilterSelected({ ...filterSelected, dateRangeErr: "Start date Should be lower than End date" })

        }

      }
      else {
        setFilterSelected({ ...filterSelected, dateRangeErr: "Invalid Date Range" })

      }
    }
    else if (type == "date") {
      if (filter?.date) {

        setFilterString(`?date=${filter?.date}`)

      }
      else {
        setFilterSelected({ ...filterSelected, dateErr: "Invalid Date" })

      }
    }
    else if (type == "monthYear") {
      if (filter?.month && filter?.year) {

        setFilterString(`?month=${filter?.month}&year=${filter?.year}`)

      }
      else {
        setFilterSelected({ ...filterSelected, monthYearErr: "Invalid Month" })

      }
    }

  }
  useEffect(() => {
    fetchTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString]);
  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Hypeu Totals</h2>

          </div>

        </div>
      </div>


      <div className="dash_partts_inner">

          <LoadingOverlay
            active={loading}
            spinner={<PulseLoader color="white" size={10} />}
          >
            <Filter filterSelected={filterSelected} filterHandale={filterHandale} filterSubmit={filterSubmit} setFilterSelected={setFilterSelected} />
          </LoadingOverlay>
          <Row>
            <Col md={12}>
              <LoadingOverlay
                active={loading}
                spinner={<PulseLoader color="white" size={10} />}
              >
                <div className="info_block d-flex flex-wrap mt-5 mb-4">


                  <Item value={totals ? totals.totalUsers : 0} title={`Total Users`} className="mt-2" />
                  <Item value={totals ? totals.createdUsers : 0} title={`Created Users`} className="mt-2" />
                  <Item value={totals ? totals.loggedinUsers : 0} title={`Users Loggedin`} className="mt-2" />
                  <Item value={totals ? totals.cardsMinted : 0} title={`Cards Minted`} className="mt-2" />

                  <Item value={totals ? totals.editionMinted : 0} title={`Editions Minted`} className="mt-2" />
                  <Item value={totals ? totals.newEditionSoldCount : 0} title={`Card First Sale`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.newEditionSoldPrice : 0)} title={`Card First Sale ($)`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.newEditionUserProfPrice : 0)} title={`Card First Sale Users Share ($)`} className="mt-2" />

                  <Item value={totals ? totals.newEditionHypeuProfPrice : 0} title={`Card First Sale Hypeu Share ($)`} className="mt-2" />
                  <Item value={totals ? totals.secEditionSoldCount : 0} title={`Card Secondary Sale`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.secEditionSoldPrice : 0)} title={`Card Secondary Sale ($)`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.secEditionHypeuProfPrice : 0)} title={`Card Secondary Sale Hypeu Share ($)`} className="mt-2" />

                  <Item value={totals ? totals.EditionSoldCount : 0} title={`Total Card Sale`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.EditionSoldPrice : 0)} title={`Total Card Sale ($)`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.EditionHypeuProfPrice : 0)} title={`Total Card Sale Hypeu Share ($)`} className="mt-2" />
                  <Item value={totals ? totals.payoutCount : 0} title={`Cashout Total`} className="mt-2" />

                  <Item value={CurrencyFormating(totals ? totals.payoutCountAmount : 0)} title={`Cashout Total ($)`} className="mt-2" />
                  <Item value={CurrencyFormating(totals ? totals.stripeFeePaid : 0)} title={`Fee Paid ($)`} className="mt-2" />


                </div>
              </LoadingOverlay>
            </Col>
          </Row>











      </div>
    </div>
  );
};
export default HypeuTotals;