import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
const CountCard = ({ title, count, imgName, loading }) => {
  return (
    <LoadingOverlay
      className="story_card_count"
      active={loading}
      spinner={<PulseLoader color="white" size={10} />}
    >
      <div className="t_userers">
        <div className="tusers_heading">
          <h2>{title}</h2>
          <h4>{count}</h4>
        </div>
        <span>
          {/* <img src={Story_User2_Img} /> */}
          <img src={imgName} alt="No Img" />
        </span>
      </div>
    </LoadingOverlay>
  );
};

CountCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
};
export default CountCard;
