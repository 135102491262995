import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import {Managesearch_img} from "../../assets/images";
import { Link, useParams } from "react-router-dom";


const UserUsagesPromoCode = () => {
const [usagesPromo, setUsagesPromo] = useState([])
const [limit, setLimit] = useState(10);
const [page, setPage] = useState(1);
const [totalRows, setTotalRows] = useState(0);
const [search, setSearch] = useState("");
const [loading, setLoading] = useState({ counts: false, dtable: false });
console.log(".....usagesPromo",usagesPromo)

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);

const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);

const fetchUsersUsagesPromo = async () => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    await axios
      .get(`/get-use-users-promo-codes/?limit=${limit}&page=${page}&search=${search}`)
      .then((res) => {
        if (res.data.success === true) {
          setLoading((prevState) => ({ ...prevState, dtable: false }));
          setTotalRows(res.data.pagination.totalRows);
          setUsagesPromo(res.data.data);
        } else {
          console.log("error");
          setLoading((prevState) => ({ ...prevState, dtable: false }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getUsersUsageCode = () => {
    let promoCodeData = [];
    usagesPromo.map((item) => {
      console.log("===========-----.",item)
      promoCodeData.push({
        name: (
          <div className="management_list_name">
            <div className="man_text">{item?.userId?.name}</div>
          </div>
        ),
        orderId: (
          <div className="management_list_name">
            <div className="man_text">{item?.paymentId?.orderId}</div>
          </div>
        ),
        promoCode: (
          <div className="management_list_name">
            <div className="man_text">{item?.promoId?.code}</div>
          </div>
        ),
        appliedOnAmount: (
          <div className="management_list_name">
            <div className="man_text">{item?.appliedOnAmount}</div>
          </div>
        ),
        discountAmount: (
          <div className="management_list_name">
            <div className="man_text">{item?.discountAmount}</div>
          </div>
        ),
        paidAmount: (
          <div className="management_list_name">
            <div className="man_text">{item?.paidAmount}</div>
          </div>
        ),
      });
    });
    return promoCodeData;
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  
  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };

  useEffect(() => {
    fetchUsersUsagesPromo();
  },[page, limit, search])

  return (
    <div>
       <React.Fragment>
        <div className="user_inner_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">          
              <div className="manage_heading_search">
                <h2>Usages Promo Code List</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div>
              </div>
                    
              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                    className="hypTable1"
                    columns={columns}
                    data={getUsersUsageCode()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>

    </div>
  )
}
const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    reorder: true,
    width: "150px",
  },
  {
    name: "Promo Code",
    selector: (row) => row.promoCode,
    reorder: true,
    width: "150px",
  },
  {
    name: "Applied Amount",
    selector: (row) => row.appliedOnAmount,
    reorder: true,
    width: "150px",
  },
  {
    name: "Discount Amount",
    selector: (row) => row.discountAmount,
    reorder: true,
    width: "150px",
  },
  {
    name: "Paid Amount",
    selector: (row) => row.paidAmount,
    reorder: true,
    width: "150px",
  },
  {
    name: "Order Id",
    selector: (row) => row.orderId,
    reorder: true,
    width: "150px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: "10px",
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};
export default UserUsagesPromoCode;