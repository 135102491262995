
// import moment from "moment-timezone";

let CurrencyFormating = (price)=>{
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return USDollar.format(Number(price)).replace("$","$ ")
}

let capitalizeFirstLetter= (string)=> {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
    CurrencyFormating,
    capitalizeFirstLetter
}