import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import axios from "../../axios/axios";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { Maneye_img, Profw2_Img } from "../../assets/images";
import { CircularProgressbar } from "react-circular-progressbar";
import ProgressBar from 'react-bootstrap/ProgressBar';
import "./userLevel.css";

const UserLevel = () => {
  // const {userId} = useParams()
  // console.log("....id",userId)

  const [userLevelData, setUserLevelData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState({ counts: false, dtable: false });

  // console.log("...........//", userLevelData);
  const getLevelData = async () => {
    await axios
      .get(`/get-all-user-level/?limit=${limit}&page=${page}`)
      .then((resp) => {
        console.log("................... user-level: ", resp.data);
        setLoading((prevState) => ({ ...prevState, dtable: false }));
        setUserLevelData(resp.data.data);
        setTotalRows(resp.data.pagination.totalRows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const getAllUserLevel = () => {
    let userLevelDetails = [];
    userLevelData.map((item, i) => {
      // console.log("....item", item);
      userLevelDetails.push({
        image:(<div className="man_user">
        <img
          alt={Profw2_Img}
          src={
            item.userProfile?.thumbnailUrl
              ? item.userProfile?.thumbnailUrl
              : Profw2_Img
          }
        />
      </div>),
        name: (
          <div className="management_list_name">
            <div className="man_text" style={{ color: !item.user_id?.name && "#ff7575" }}>{item.user_id?.name ?? "DELETED USER"}</div>
          </div>
        ),
        level: <div className="man_text_userlevel">{item.level}</div>,
        common: (
          <div className="man_text_common">
            {item.mintBalance?.common ? item.mintBalance?.common : 0}
          </div>
        ),
        rare: (
          <div className="man_text_rare">
            {item.mintBalance?.rare ? item.mintBalance?.rare : 0}
          </div>
        ),
        epic: (
          <div className="man_text_epic">
            {item.mintBalance?.epic ? item.mintBalance?.epic : 0}
          </div>
        ),
        nftSaleCount: (
          <div className="man_text_nftcount">
            {item.nftSaleCount ? item.nftSaleCount : 0}
          </div>
        ),
        nftSaleEarn: (
          <div className="man_text" style={{ marginLeft: "1rem" }}>
            ${" "}{item.nftSaleEarn ? item.nftSaleEarn.toFixed(2) : 0}
          </div>
        ),
        levelPercentage: (
          <div className="man_text" >
            <div className="progress_label">
              <ProgressBar className="fixProgressBar" now={item.levelPercentage} />
              <span className='progress_new_label'>{`${item.levelPercentage}%`}</span>
            </div>
          </div>
        ),
        action: item.user_id && (
          <Link to={`UserLevelDetails/${item.user_id?.id}`} style={{ marginLeft: "0.8rem", color: "white" }} className="view_hover">
            <span style={{ fontSize: "14px" }}>View</span>
          </Link>
        ),
      });
    });
    return userLevelDetails;
  };

  useEffect(() => {
    getLevelData();
  }, [page, limit]);

  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };
  return (
    <React.Fragment>
      <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Users Level List</h2>
              </div>

              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                    className="hypTable"
                    columns={columns}
                    data={getAllUserLevel()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
const columns = [
  {
    name: "",
    selector: (row) => row.image,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    // reorder: true,
    width: "165px",
  },
  {
    name: "User Level",
    selector: (row) => row.level,
    // reorder: true,
    width: "110px",
  },
  {
    name: "Common Balance",
    selector: (row) => row.common,
    // reorder: true,
    width: "155px",
  },
  {
    name: "Rare Balance",
    selector: (row) => row.rare,
    // reorder: true,
    width: "130px",
  },
  {
    name: "Epic Balance",
    selector: (row) => row.epic,
    // reorder: true,
    width: "125px",
  },
  {
    name: "Nft Sale Count",
    selector: (row) => row.nftSaleCount,
    // reorder: true,
    width: "145px",
  },
  {
    name: "Nft Sale Earn",
    selector: (row) => row.nftSaleEarn,
    // reorder: true,
    width: "130px",
  },
  {
    name: "Level Percentage",
    selector: (row) => row.levelPercentage,
    // reorder: true,
    width: "250px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "100px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: "10px",
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "#495A71",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "15px",
      // paddingTop: "5px,
      paddingBottom: "15px",
      // paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px"
    },
  },
};

export default UserLevel;
