import React, {useState,useEffect} from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {message} from "antd";
import axios from "../../../axios/axios";
import "./addBanner.css";

const AddBanner = () => {
  const [btn, setBtn] = useState(false);
  // const [err, setErr] = useState({});
  const [formErr, setFormerr] = useState({name: "", description:"", imageUrl: ""});
  const [imageUrl, setImageUrl] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const imgValidation = () => {
    if (!imageUrl) {
      setFormerr((prevState) => ({
        ...prevState,
        imageUrl: "file is required",
      }));
    } else if (!imageUrl?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setFormerr((prevState) => ({
        ...prevState,
        imageUrl: "file not an image",
      }));
    } else {
      setFormerr((prevState) => ({ ...prevState, imageUrl: "" }));
    }
    if (!description) {
      setFormerr((prevState) => ({
        ...prevState,
        description: "description is required",
      }));
    } else {
      setFormerr((prevState) => ({ ...prevState, description: "" }));
    }
    if (!name) {
      setFormerr((prevState) => ({
        ...prevState,
        name: "name is required",
      }));
    } else {
      setFormerr((prevState) => ({ ...prevState, name: "" }));
    }
  };
  const uploadBadge = async (e) => {
    imgValidation(); 
    let error = {};
    let data = new FormData();
    if (formErr.imageUrl === "" && formErr.name === "" && formErr.description === "") {
      data.append("name", name);
      data.append("description", description);
      data.append("items[img]", imageUrl);
      // console.log(">>>>>>>>>>>>>>>>>>>",...data);
      // console.log("/////",imageUrl)

      const hideMessage = message.loading("Please wait ...", 0);
      setBtn(true);
      
      await axios.post(`/add-banner`,data)
      .then((res) => {
        console.log(res)
        if(res.data.success === true) {
          message.success("create success")
          hideMessage();
          console.log(res.data.message);
          console.log(".......", res.data);
          // setErr({});
          setBtn(false);
          setImageUrl("");
          setName("");
          setDescription("")
        } else {
          hideMessage();
          setBtn(false)
          message.error("create failed")
          res.data.errors?.map( item =>{
            error[item.param] = item.msg;
          })
          // setErr(error);
        }
        
      })
      .catch(err => {
        console.log(err);
        hideMessage();
        setBtn(false)
      })
    
    } else {
      console.log("error");
    }
  };
  return (
    <>
      <h4 className="heading_set">Add New Banner</h4>
      <div className="dash_partts add_banner_background">
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <input
                    className="text_box"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p style={{ color: "#ff4d4f" }}>{formErr.name}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <input
                    className="text_box"
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <p style={{ color: "#ff4d4f" }}>{formErr.description}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                 <div>Banner Image Upload</div>
                  <input className="custom-file-input" 
                  type="file" name="imagefile" 
                  onChange={(e) => {
                        setImageUrl(e.target.files[0]);
                      }}/> 
              </Col>
            </Row>
            <p style={{ color: "#ff4d4f" }}>{formErr.imageUrl}</p>
            <Row>
              <Col md={12} className="text-center">
                <Form.Group
                  className="mb-3 pt-5 pb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <button variant="primary" className="my_button" 
                  // disabled={btn}
                  onClick={uploadBadge}>
                    Submit
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddBanner;
