import { combineReducers } from "redux";
import setStories from "./setStories";
import setDashboardCount from "./setDashboardCount";
import setStoriesCount from "./setStoriesCount";
import setUsersCount from "./setUsersCount";
import setToken from "./setToken";
import setUsers from "./setUsers";
import setUserProfileCounts from "./setUserProfileCounts";
import setUserDetails from "./setUserDetails";
import setNftByUser from "./setNftByUser";
import setStoriesByUser from "./setStoriesByUser";
import setNftDetails from "./setNftDetails";
import  setLineChart from "./setLineChart";

const rootReducers = combineReducers({
  setStories,
  setDashboardCount,
  setStoriesCount,
  setUsersCount,
  setUserProfileCounts,
  setUserDetails,
  setToken,
  setUsers,
  setNftByUser,
  setStoriesByUser,
  setNftDetails,
  setLineChart
});

export default rootReducers;
