import React, { useState, useEffect } from "react";

import ManageProductAbout from "./ManageProductAbout";
import ManageProductVarity from "./ManageProductVarity";
import ManageAttributes from "./ManageAttributes";

import "./manageproduct.css";
import axios from "../../../axios/axios";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Form, Modal, Button, Tabs, Tab } from "react-bootstrap";

import "./manageproduct.css";
import ManageProductInventory from "./ManageProductInventory";
import {
  marchindise_about,
  marchindise_variety,
  marchindise_atributes,
  marchindise_inventory,
  marchindise_finish,
} from "../../../assets/images";
import ManageColorImages from "./ManageColorImages";
import ProductDetails from "./ProductDetails";
function ManageProduct() {
  const token = useSelector((state) => state.setToken);
  const [key, setKey] = useState("about");
  const [options, setOptions] = useState([]);
  const [product, setProduct] = useState({
    name: "",
    description: "",
    categoryId: "",
    sportId: "",
    customer_gender: "",
    variety: [],
    attributes: [],
    productId: "",
  });
  const getProductOptions = async () => {
    await axios
      .get(
        `/categorical-options`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("fundraiser res....", res.data);
        if (res.data.success === true) {
          setOptions(res.data.data);
          //   setPagination(res.data.pagination);
          //   setNfts(res.data.allFundRaisers);
          //   setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getProductOptions();
  }, []);

  let keys = Object.keys(options);
  let values = Object.values(options);
  return (
    <div>
      <div className="dash_partts">
        <Row>
          <Col lg="12" md="12" sm="12" className="ps-0 pe-0">
            <div className="setting_left_inner_partts">
              {/* <h2>Add New Product</h2> */}
              <div className="levelsetting">
                <Form>
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                    activeKey={
                      product.categoryId ||
                      product.attributes.length > -1 ||
                      product.variety.length > -1
                        ? key
                        : "about"
                    }
                    // activeKey={product.categoryId ? key : "about"}
                    onSelect={(k) => setKey(k)}
                  >
                    <Tab
                      eventKey="about"
                      title={
                        <span>
                          {" "}
                          <img
                            src={marchindise_about}
                            alt="No Img"
                          /> About{" "}
                        </span>
                      }
                    >
                      <ManageProductAbout
                        setKey={setKey}
                        setProduct={setProduct}
                      />
                    </Tab>
                    <Tab
                      eventKey="variety"
                      title={
                        <span>
                          {" "}
                          <img
                            src={marchindise_variety}
                            alt="No Img"
                          /> Variety{" "}
                        </span>
                      }
                    >
                      {product.categoryId !== "" ? (
                        <ManageProductVarity
                          values={values}
                          setKey={setKey}
                          setProduct={setProduct}
                        />
                      ) : (
                        <p>please fill product details</p>
                      )}
                    </Tab>
                    <Tab
                      eventKey="attributes"
                      title={
                        <span>
                          {" "}
                          <img src={marchindise_atributes} alt="No Img" />{" "}
                          Attributes{" "}
                        </span>
                      }
                    >
                      {product.categoryId && product.variety.length > 0 ? (
                        <ManageAttributes
                          setKey={setKey}
                          setProduct={setProduct}
                          product={product}
                          values={options && options.attributes}
                        />
                      ) : (
                        <p>please select color and size</p>
                      )}
                    </Tab>
                    <Tab
                      eventKey="inventory"
                      title={
                        <span>
                          {" "}
                          <img src={marchindise_inventory} alt="No Img" />{" "}
                          Inventory{" "}
                        </span>
                      }
                    >
                      {product.productId !== "" ? (
                        <ManageProductInventory
                          product={product}
                          setKey={setKey}
                          setProduct={setProduct}
                        />
                      ) : (
                        <p>please create product</p>
                      )}
                    </Tab>
                    <Tab
                      eventKey="finish"
                      title={
                        <span>
                          {" "}
                          <img
                            src={marchindise_finish}
                            alt="No Img"
                          /> Finish{" "}
                        </span>
                      }
                    >
                      <p>
                        {product.variety.length > 0 && (
                          <ManageColorImages
                            variety={product.variety}
                            product={product}
                            setKey={setKey}
                          />
                        )}
                      </p>
                    </Tab>
                    {/* <Tab
                      eventKey="details"
                      title={
                        <span>
                          {" "}
                          <img src={marchindise_finish} alt="No Img" /> Product
                          Details{" "}
                        </span>
                      }
                    >
                      <p>{product && <ProductDetails product={product} />}</p>
                    </Tab> */}
                  </Tabs>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ManageProduct;
