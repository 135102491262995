import React, { useState, useEffect } from 'react';
import './aboutUs.css';
import { aboutUs_img } from '../../assets/images';
import HeaderWithLogo from '../layout/HeaderWithLogo';
import { Link } from 'react-router-dom';
import axios from "../../axios/axios";


const AboutUs = () => {
  const [managementData, setManagementData] = useState({
    heading: "",
    content: "",
    manageKey: "",
  });
  
  const getAboutUs = async () => {
    await axios
      .get(`/get-about-us`)
      .then((response) => {
        const data = response.data.data;
        console.log("response data....", response.data);
        setManagementData({
          ...managementData,
          heading: data.heading,
          content: data.content,
          manageKey: data.key,
        });
      })  
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getAboutUs();
  }, [])
  
  return (
   <React.Fragment>
    {/* <HeaderWithLogo /> */}
    <div dangerouslySetInnerHTML={{__html: managementData.content}} className='testetstet'></div>
   </React.Fragment>
  )
}

export default AboutUs;