/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./userdetails.css";
import { Row, Col, } from "react-bootstrap";
import Switch from "react-switch";
import {
  // Delete_1_Img, Send_Mail_Img,
  Love_s_Img, Date_Cal_Img, No_ProfImg,
} from "../../assets/images";
import axios from "../../axios/axios";
import { setUserProfileCounts } from "../../actions/setUserProfileCounts";
import { useParams, Link } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import {CurrencyFormating} from '../../helpers/currencyHelper'
import moment from 'moment-timezone';

function Userdetails() {
  //const [userId, setUserId]= useState("");
  const { userId } = useParams();
  let dispatch = useDispatch();
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();
  let [userNfts, setUserNfts] = useState();
  let [userStories, setUserStories] = useState();
  const [badgesList, setBadgesList] = useState();
  const [loading, setLoading] = useState({ nft: false, stories: false, profile: false, });

  const getUserProfileCounts = async () => {
    await axios.get(`/get-userProfile-details-count/${userId}`).then((res) => {
      if (res.data.success === true) {
        //console.log(res.data);
        dispatch(setUserProfileCounts(res.data.counts));
      } else {
        message.error("Server error");
      }
    }).catch((err) => {
      console.log("get User Profile Counts error....", err);
    });
  };

  const getUserProfileDetails = async () => {
    setLoading((prevState) => ({ ...prevState, profile: true }));
    await axios.get(`/get-user-by-id/${userId}`).then((res) => {
      if (res.data.success === true) {
        setUser(res.data.user);
        if (res.data.user.userProfile !== null) {
          setProfile(res.data.user.userProfile);
          setLoading((prevState) => ({ ...prevState, profile: false }));
        } else {
          setProfile(null);
          setLoading((prevState) => ({ ...prevState, profile: false }));
        }
      } else {
        message.error("Server error");
      }
    }).catch((err) => {
      console.log("get User Profile details error", err);
    });
  };

  const getNftsByUser = async () => {
    setLoading((prevState) => ({ ...prevState, nft: true }));
    await axios.get(`/get-collectables-of-user/${userId}`).then((res) => {
      //console.log("get collectables of user....", res.data);
      if (res.data.success === true) {
        // dispatch(setNftByUser(res.data.nfts));
        limitNfts(res.data.nfts);
        setLoading((prevState) => ({ ...prevState, nft: false }));
      }
      setLoading((prevState) => ({ ...prevState, nft: false }));
    }).catch((err) => {
      console.log("getNftsByUser err", err);
      setLoading((prevState) => ({ ...prevState, nft: false }));
    });
  };

  const getStoriesByUser = async () => {
    setLoading((prevState) => ({ ...prevState, stories: true }));
    await axios.get(`/get-stories-by-user-id/${userId}`).then((res) => {
      //console.log("get stories by user....", res.data);
      if (res.data.success === true) {
        // dispatch(setStoriesByUser(res.data.allStories));
        limitStories(res.data.allStories);
        setLoading((prevState) => ({ ...prevState, stories: false }));
      }
    }).catch((err) => {
      console.log("get Stories By User error.....", err);
      setLoading((prevState) => ({ ...prevState, stories: false }));
    });
  };

  const getUserBadges = async (req, res) => {
    await axios.get(`/get-user-badges/${userId}`).then((res) => {
      //console.log("get user badges......", res.data);
      if (res.data.success === true) {
        //setBadges(res.data.creatorBadges);
        limitBadges(res.data.creatorBadges);
      }
    }).catch((err) => {
      console.log("get User Badges error.....", err);
    });
  };

  let counts = useSelector((state) => state.setUserProfileCounts);

  const limitNfts = (nfts) => {
    if (nfts) {
      if (nfts.length > 6) {
        setUserNfts(nfts.splice(0, 6));
      } else if (nfts.length === 0) {
        setUserNfts(null);
      } else {
        setUserNfts(nfts);
      }
    }
  };

  const limitStories = (stories) => {
    if (stories) {
      if (stories.length > 2) {
        setUserStories(stories.splice(0, 2));
      } else if (stories.length === 0) {
        setUserStories(null);
      } else {
        setUserStories(stories);
      }
    }
  };

  const limitBadges = (badges) => {
    if (badges) {
      if (badges.totalCount > 3) {
        let badgeData = {
          badges: badges.badges.splice(0, 3),
        };
        setBadgesList(badgeData);
      } else if (badges.totalCount === 0) {
        setBadgesList(null);
      } else {
        setBadgesList(badges);
      }
    }
  };

  const handleAccountStatus = async (status) => {
    setLoading((prevState) => ({ ...prevState, profile: true }));
    await axios.post(`/toggle-status/${userId}`, { status: status }).then(({ data }) => {
      if (data.success) {
        getUserProfileDetails();
        message.success(data.message)
      } else {
        console.log("error");
        setLoading((prevState) => ({ ...prevState, profile: false }));
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  useEffect(() => {
    getUserProfileCounts();
    getUserProfileDetails();
    getNftsByUser();
    getStoriesByUser();
    getUserBadges();
  }, []);

  return (
    <div className="user_details_top_partts">

      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Card Minted</h3>
                  <h2>{counts ? counts.cardsMintedCount : 0}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Editions Minted</h3>
                  <h2>{counts ? counts.editionsMinted : 0}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>New Editions Sold</h3>
                  <h2>{counts ? counts.newEditionSoldCount : 0}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>New Editions Sold ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.newEditionSoldPrice : 0)}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Secondary Sale</h3>
                  <h2>{counts ? counts.secEditionSoldCount : 0}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Secondary Sale ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.secEditionSoldPrice : 0)}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Royalty Get ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.secEditionSoldRoyalty : 0)}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Others Card Sell</h3>
                  <h2>{counts ? counts.secEditionSoldBMECount : 0}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Others Card Sell ($)</h3>
                  <h2> {CurrencyFormating(counts ? counts.secEditionSoldBMEPrice : 0)}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Card Purchased</h3>
                  <h2>{counts ? counts.cardPurchasedCount : 0}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Card Purchased ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.cardPurchasedPrice : 0)}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Total Earning</h3>
                  <h2>{CurrencyFormating(counts ? counts.totalEarning : 0)}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Total Sale ($)</h3>
                  <h2> {CurrencyFormating(counts ? counts.totalSale : 0)}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Cashout Total</h3>
                  <h2>{counts ? counts.payoutCount : 0}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Cashout Total ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.payoutAmount : 0)}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Royalty Shared ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.royltySendtoOthers : 0)}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Hypeu Share ($)</h3>
                  <h2> {CurrencyFormating(counts ? counts.hypeuFee : 0)}</h2>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Fee ($)</h3>
                  <h2>{CurrencyFormating(counts ? counts.stripeFee : 0)}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col xs={6}>
                <div className="nft_inner adjust_card">
                  <div className="nft_inner_inner adjust_card">
                    <h3>Wallet Balance</h3>
                    <h2>{CurrencyFormating(counts ? counts.walletBalanceAvailable : 0)}</h2>
                    {/* <h2>{counts ? counts.walletCoinBalance?.[0]?.balance : 0}</h2> */}
                  </div>
                </div>
            </Col>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Pending Spend Balance</h3>
                  <h2>{CurrencyFormating(counts ? counts.walletBalancePending : 0)}</h2>
                </div>
              </div>
            </Col>
            
          </Row>

          
        </Col>
        
      </Row>

      <Row>
        <Col md={6} sm={12}>
          

          <Row>
            <Col xs={6}>
              <div className="nft_inner adjust_card">
                <div className="nft_inner_inner adjust_card">
                  <h3>Followers</h3>
                  <h2>{counts ? counts.totalFollowers : 0}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="nft_bottom">
        <Row>
          <Col lg="6" md="12" sm="12">
            <div className="george_hermis">
              <LoadingOverlay
                active={loading.profile}
                spinner
                text="please wait..."
              >
                <div className="hermis_top">
                  <div className="george_hermis_user">
                    <div className="hermis_user_left">
                      <div className="hermis_user_image">
                        <img src={profile?.thumbnailUrl ? profile.thumbnailUrl : No_ProfImg} alt="" />
                        <label className="greenn_bg"></label>
                      </div>
                    </div>
                    <div className="hermis_user_right">
                      <h2>
                        {user ? user?.name : ""}{" "}
                        <span>@{user ? user?.userName : ""}</span>
                      </h2>
                      <div className="hermis_listing">
                        {/* <ul>
                          {
                            badgesList ? badgesList.badges.map((item) => (
                              <li key={item.lightBadges}>
                                <Link to="#" >
                                  <img src={item.lightBadges} width={30} height={30} alt="" />
                                </Link>
                              </li>
                            )) : (
                              <p style={{ color: "white" }}>
                                No badges were present
                              </p>
                            )
                          }
                        </ul>

                        <Link
                          to={{
                            pathname: `/user-badges/${userId}`,
                            state: {
                              pic: profile?.thumbnailUrl
                                ? profile.thumbnailUrl
                                : No_ProfImg,
                              name: user?.name,
                            },
                          }}
                          className="more_but"
                        >
                          More
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="state_parttsss mt-3">
                    <h2>{`Address: ${profile ? profile?.address?.city+"," : ""
                      } ${profile?.stateDetails?.State ?? ""}, ${profile ? profile?.address?.country : ""}`}</h2>
                    <h2>{`Email: ${user ? user.email : ""}`}</h2>
                    <h2>{`Phone No: (${user && user?.callingCode ? user?.callingCode : ""}) ${user && user?.phone ? user?.phone : ""
                      }`}</h2>
                  </div>
                  <div className="height_weight">
                    {/* <label>{`Height: ${profile ? profile.measurements.height.feet : 0
                      }’ ${profile ? profile.measurements.height.inch : 0
                      }”`}</label>
                    <label>{`Weight: ${profile ? profile.measurements.weight : 0
                      } lbs`}</label> */}
                  </div>
                </div>
              </LoadingOverlay>
              <div className="hermis_bottom">
                <div>
                  <Switch checked={user ? user.status : false} onChange={() => handleAccountStatus(user.status)} onColor="#2e7b7f" onHandleColor="#01C1C1" handleDiameter={12} uncheckedIcon={false} checkedIcon={false} height={9} width={19} className="react-switch" id="material-switch" />
                  <span> Account Active / Deactive </span>
                </div>
                {/* <div>
                  <Link to="#" title="Temporary Closed">
                    <img src={Delete_1_Img} alt="" />
                    <span>Delete Account</span>
                  </Link>
                </div>
                <div>
                  <Link href="#" title="Under Development" style={{ opacity: 0.6, cursor: "not-allowed" }}>
                    <img src={Send_Mail_Img} alt="" />
                    <span>Send Mail</span>
                  </Link>
                </div> */}
              </div>
              <LoadingOverlay active={loading.profile} spinner={<PulseLoader color="white" size={10} />} >
                <div className="bio_user">
                  <h2>Bio</h2>
                  <p>{profile ? profile.description : "No bio provided"}</p>
                </div>
              </LoadingOverlay>
            </div>
          </Col>
          <Col lg="6" md="12" sm="12">
            <LoadingOverlay
              active={loading.nft}
              spinner={<PulseLoader color="white" size={10} />}
            >
              <div className="nfts_collectable">
                <div className="delete_nft_partts">
                  <h2>NFTs Collectables</h2>
                  <h3>
                    <Link to={`/user-nft/${userId}`}>
                      {/* <img /> */}
                      More..
                    </Link>
                  </h3>
                </div>
                <div className="nft_man_partts nft_man_collectables" style={{ paddingTop: 0 }}>
                  {
                    userNfts ? userNfts.map((item, i) => (
                      
                        <div className="nft_man_inner_partts" key={i}>
                          <div className="nft_mansss">
                            <img src={item.fileUrl} alt="" />
                          </div>
                          <div className="nft_manss_heading">
                            <h3>{item.title!=''?item.title: `${item.creator.nickName.fName} ${item.creator.nickName.lName}`}</h3>
                            <label> {CurrencyFormating(`${item.prices?.amount ? item.prices?.amount : 0}`)}</label>
                            <img src={item?.nftType?.imageUrl ? item.nftType.imageUrl : No_ProfImg} alt="" width={70} height={16} />
                          </div>
                        </div>
                      
                    )) : (
                      <p style={{ color: "white" }}>No nfts were present</p>
                    )
                  }
                </div>
              </div>
            </LoadingOverlay>
            <LoadingOverlay active={loading.stories} spinner={<PulseLoader color="white" size={10} />}>
              <div className="story_partts">
                <div className="delete_nft_partts">
                  <h2>Stories</h2>
                  <h3>
                    <Link to={{ pathname: `/user-stories/${userId}`, state: { pic: profile?.thumbnailUrl ? profile.thumbnailUrl : No_ProfImg, name: user?.name, }, }}>
                      {/* <img /> */}
                      More...
                    </Link>
                  </h3>
                </div>
                {
                  userStories ? userStories.map((item, i) => (
                    <div className="football_match" key={i}>
                      <div className="football_img">
                        <img src={item.images[0].imageUrl} alt="" />
                      </div>
                      <div className="football_text">
                        <div className="football_datee">
                          <div className="love_football">
                            {/* <img src={Love_s_Img} alt="" />
                            <span style={{ fontSize: "14px" }}>{item.reactions[0].totalReacts}</span> */}
                          </div>

                          <div className="love_football">
                            <img src={Date_Cal_Img} alt="" />
                            <span style={{ fontSize: "14px" }}>{moment(item.createdAt).format("MM-DD-YYYY")}</span>
                          </div>
                        </div>
                        <div className="football_innr_text">
                          <Link to="#">{item.tournament}</Link>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  )) : (
                    <p style={{ color: "white" }}>No stories were present</p>
                  )
                }
                {/* <div className="football_match">
                    <div className="football_img">
                      <img src={Football_1_Img} />
                    </div>
                    <div className="football_text">
                      <div className="football_datee">
                        <div className="love_football">
                          <img src={Love_s_Img} />
                          <span>3,523</span>
                        </div>
  
                        <div className="love_football">
                          <img src={Date_Cal_Img} />
                          <span>3/12/22</span>
                        </div>
                      </div>
                      <div className="football_innr_text">
                        <a href="#">
                          Footbal match at our university | My best Performance
                          photo
                        </a>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod.
                        </p>
                      </div>
                    </div>
                  </div>
   */}
                {/* <div className="football_match">
                    <div className="football_img">
                      <img src={Football_1_Img} />
                    </div>
                    <div className="football_text">
                      <div className="football_datee">
                        <div className="love_football">
                          <img src={Love_s_Img} />
                          <span>3,523</span>
                        </div>
  
                        <div className="love_football">
                          <img src={Date_Cal_Img} />
                          <span>3/12/22</span>
                        </div>
                      </div>
                      <div className="football_innr_text">
                        <a href="#">
                          Footbal match at our university | My best Performance
                          photo
                        </a>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod.
                        </p>
                      </div>
                    </div>
                  </div> */}
              </div>
            </LoadingOverlay>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Userdetails;
