import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import "./header.css";
import {Search_img, Clock_img, notify_img} from "../../../assets/images";
import {Logout_Img} from "../../../assets/images";
import moment from "moment";
// import axios from "../../../axios/axios";
// import GlobalSearchResult from "../../Global Search Result/GlobalSearchResult";

const Header = () => {
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [dateTime, setDateTime] = useState(moment())

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(moment());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  /** global search function */
    // const searchSubmit = (e) => {
    //   e.preventDefault();
    //   if(keyword.trim()){
    //     <GlobalSearchResult sendData = {keyword} />
    //     history.push("/global-search")
    //     // <Link>Hello</Link>
    //     console.log("keyword....", keyword);
    //   } else {
    //     history.push("#");
    //   }
    // }

  const handleKeyDown = event => {
      // console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        // console.log("........", keyword);
        // <Link to={`/global-search/${keyword}`} />
        history.push(`/global-search/${keyword}`)
      }
    };

  let formatter = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "2-digit"
    },
    {hour: 'numeric', minute: 'numeric', second: 'numeric'}
  );

  return (
    <div className="header_partttss">
      <div className="header_texfieldt">
        <Form.Control
          type="text"
          placeholder="Search for anything here.."
          className="search_boxxx"
          // readOnly={true}
          // title="Features under development"
          style={{opacity: 0.8}}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <span className="search_imgg">
          <img src={Search_img} alt="No Img"/>
        </span>

        <div className="search_button_div">
          {/* <Link type="button" onClick={searchSubmit} className="search_button">Search</Link> */}
          {/* {
          keyword.trim() !== "" ?
          <React.Fragment>
            <Link type="button" to={`/global-search/${keyword}`} className="search_button">Search</Link>
          </React.Fragment> : <React.Fragment>
            <Link type="button" className="search_button">Search</Link>
          </React.Fragment>
        } */}
          <Link type="button" to={`/global-search/${keyword}`} className="search_button">Search</Link>
        </div>
      </div>

      <div className="header_date_and_time">
        <div className="hypeu_logout">
          <Link to="/notification-log" className="notification-design">
            <span className="notification_image">
              <img src={notify_img} alt="No Img" title="Notification"/>
            </span>
          </Link>
        </div>
        <div className="header_date">
          <span>
            <img src={Clock_img} alt="No Img"/>
          </span>{" "}
          {/*{new Date() ? moment(new Date().toLocaleString()).format("DD/MM/YYYY, h:mm:ss a") : formatter.format(new Date())}*/}
          <span style={{width: "12rem"}}>{dateTime.format("MM-DD-YYYY, h:mm:ss a")}</span>
        </div>
        <div className="hypeu_logout">
          <Link to="/logout" className="logout-design">
            <span className="dasg__imgg">
              {/* <img alt="No Img" src={Logout_Img} title="Logged out" /> */}
              <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                   style={{fill: "red", height: "32px"}} focusable="false" aria-hidden="true" viewBox="0 0 24 24"
                   data-testid="LogoutIcon"><path
                d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path></svg>
            </span>
            {/* <span>Logout</span> */}
          </Link>
        </div>
        {/* <div className="header_time">22:45:04</div> */}
      </div>
    </div>
  );
};

export default Header;
