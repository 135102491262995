import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../axios/axios';
import { Row, Col } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import moment from 'moment'

const ViewTransactions = () => {
	const { userId } = useParams();
	const history = useHistory();

	console.log("......userId...", userId);

	const [gettingWallet, setGettingWallet] = useState([]);
	const [transactions, setTransactions] = useState([])
	const [loading, setLoading] = useState({ counts: false, dtable: false });
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [totalRows, setTotalRows] = useState(0);

	const handlePageChange = (page) => {
		//console.log(page)
		setPage(page);
	  };
	const handlePerRowsChange = (newPerPage, page) => {
	console.log(newPerPage, page);
	setLimit(newPerPage);
	setPage(page);
	};

	const CustomLoader = () => (
		<div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px" }}>
			<div>Loading...</div>
		</div>
	);
	const EmptyDataLoader = () => (
		<div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px" }}>
			<div>No data found...</div>
		</div>
	);
	const getWallet = () => {
		const transactionsData = [];
		// [gettingWallet].map((item, index) => {
		transactions.map((item, index) => {
			transactionsData.push({
				transactionDate: [<div key={`${index}A`} style={{ marginLeft: "1.5rem" }}> {moment(item.createdAt).format("MM-DD-YYYY")} </div>],
				paymentMethod: [<div key={`${index}B`} className="notification_alignment">{item?.payment_method}</div>],
				price: [<div key={`${index}C`} className="notification_alignment">$ {item.price}</div>],
				paymentId: [<div key={`${index}D`} className="notification_alignment">{item.paymentId}</div>],
				type: [<div key={`${index}E`} className="notification_alignment">{item.transactionType}</div>],
				description: [<div key={`${index}F`} className="notification_alignment">{item.description}</div>]
			});
		});
		return transactionsData;
	};

	const getTransactions = async () => {
		await axios.get(`/get-card-transactions/${userId}?limit=${limit}&page=${page}`)
			.then((res) => {
				if (res.data.success === true) {
					setTransactions(res.data.cardTransactions);
					setLoading(false);
					setTotalRows(res.data.pagination.totalRows);
				}
			})
			.catch((error) => {
				console.log("Get Wallet Details Error.....", error);
				setLoading(false);
			})
	}

	useEffect(() => {
		getTransactions();
	}, [page,limit]);

	return (
		<div className="transaction-list">
			<Row>
				<Col lg="12" md="12" sm="12">
					<div className="usermaageleft">

						<div className="manage_heading_search style">
							<h2>NFT Transaction List</h2>
						</div>

						<div className="manage_table">
							<div className="table-responsive">
								{transactions.length > 0 ?
									<LoadingOverlay
										active={loading}
										spinner={<PulseLoader color="white" size={10} />}
									>
										<DataTable
											className="hypTable"
											columns={columns}
											data={getWallet()}
											responsive
											customStyles={customStyles}
											defaultSortField="id"
											defaultSortAsc={false}
											progressPending={loading.dtable}
											noDataComponent={<CustomLoader />}
											progressComponent={<EmptyDataLoader />}
											pagination
											paginationServer

											paginationTotalRows={totalRows}
											paginationPerPage={limit}
											paginationRowsPerPageOptions={[10, 30, 50]}
											onChangeRowsPerPage={handlePerRowsChange}
											onChangePage={handlePageChange}
										/>
									</LoadingOverlay>
									: <EmptyDataLoader />
								}
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	)
}

const columns = [
	{
		name: "Transaction Date",
		selector: (row) => row.transactionDate,
		reorder: true,
		width: "160px",
	},
	{
		name: "Payment Method",
		selector: row => row.paymentMethod,
		reorder: true,
		width: "180px"
	},
	{
		name: "Payment id",
		selector: row => row.paymentId,
		reorder: true,
		width: "280px"
	},
	{
		name: "Type",
		selector: row => row.type,
		reorder: true,
		width: "100px"
	},
	{
		name: "Price",
		selector: row => row.price,
		reorder: true,
		width: "100px"
	},
	{
		name: "Description",
		selector: row => row.description,
		reorder: true,
		width: "400px"
	}
];

const customStyles = {
	rows: {
		style: {
			//minHeight: '72px',
			backgroundColor: "transparent",
			color: "#fff",
			paddingLeft: "10px", // override the row height
		},
	},
	headCells: {
		style: {
			// paddingLeft: "10px",
			paddingLeft: "25px", // override the cell padding for head cells
			paddingRight: "2px",
			backgroundColor: "#495A71",
			color: "#fff",
			fontSize: "16px",
		},
	},
	cells: {
		style: {
			paddingLeft: "4px", // override the cell padding for data cells
			paddingRight: "2px",
			paddingTop: "10px",
			paddingBottom: "10px",
			backgroundColor: "transparent",
			color: "#fff",
			fontSize: "14px",
		},
	},
};

export default ViewTransactions