import React from "react";

const DetailsCard = ({ details }) => {
  return (
    <div className="nfts_collectable">
     
      <div className="headerCC Contributesr">
      <h4>User</h4>
      <h4>Sell Amount</h4>
      <h4>Nft</h4>
      </div>
      {details && (
        <React.Fragment>
          {details.map((nftData, index) => {
            return (
              <div className="edition_inner_inner_part" key={index}>
                <div className="edition_inner_center">
                  <div className="edition_center_left">
                    <div className="edition_user">
                      <img src={nftData.buyerProfile.thumbnailUrl} />
                    </div>
                  
                    <div className="edition_user_text">
                      {nftData.buyer.name}
                    </div>
                  </div>
                </div>
                <div className="edition_inner_center">
                  <div className="edition_center_middle">
                    $ {nftData.sellAmount}
                  </div>
                </div>
                <div className="edition_inner_center">
               
                  <div className="edition_center_right" style={{display:"flex"}}>
                  <div className="edition_user"> 
                      <img src={nftData.nftId.fileUrl} />
                    </div>
                  
                    <div className="edition_user_text">
                    {nftData.nftId.title}
                    </div>
                  </div>
                </div>
              </div>
            );
          
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export default DetailsCard;
