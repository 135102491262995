import StoryFeedsPart from "./StoryFeedsPart";

const StoryFeedsPage = () => {
  return (
    <div className="home_new">
      <StoryFeedsPart />
    </div>
  );
};

export default StoryFeedsPage;
