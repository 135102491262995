import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import axios from "../../../axios/axios";
import { message } from "antd";
import "./attributes.css";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Attributes = () => {
  let placeholder = "Add size";
  const [formValues, setFormValues] = useState({name: ""});
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [tags, setTags] = React.useState([]);
  const [sizeTags, setSizeTags] = React.useState([]);
console.log("sizeTags",sizeTags)
  const handleChenge = (e) => {
    const { name, value } = e.target;
    let prevFormValue = formValues;
    prevFormValue[name] = value;
    setFormValues({ ...prevFormValue });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    formValues.dataset = sizeTags.map((i) => {
      return i.text;
    });
    if (Object.keys(formError).length === 0) {
      axios
        .post("/add-merchandise-attribute", { ...formValues })
        .then(function (response) {
          if (response.data.success === true) {
            console.log(response);
            message.success("Create successfully.");
          } else {
            console.log("error");
            message.error("Create failed.");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const handleSizeDelete = (i) => {
    setSizeTags(sizeTags.filter((tag, index) => index !== i));
  };

  const handleSizeAddition = (tag) => {
    setSizeTags([...sizeTags, tag]);
    console.log(".../////", sizeTags);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    // re-render
    setTags(newTags);
  };
  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const validate = (values) => {
    const errors = {};
    //name validation
    if (!values.name) {
      errors.name = "Name is required";
    }
    //data validation
    if (!formValues.dataset) {
      errors.dataset = "Tag Field is required";
    }
    return errors;
  };
console.log(formError)
  useEffect(() => {
    setFormError(validate(formValues));
  }, [formValues,sizeTags]);
  return (
    <div>
      <h3 className="heading_set">Add New Attributes</h3>
      <div className="dash_partts background allign">
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <input
                    className="text_box"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleChenge}
                  />
                </Form.Group>
                <p style={{ color: "red" }}>{isSubmit && formError.name}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Type</Form.Label>
                  <ReactTags
                    tags={sizeTags}
                    delimiters={delimiters}
                    handleDelete={handleSizeDelete}
                    handleAddition={handleSizeAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    placeholder={placeholder}
                    autocomplete
                  />
                  <p>Type Size and Press the Enter Button or Use Comma</p>
                </Form.Group>

                <p style={{ color: "red" }}>{isSubmit && formError.dataset}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center">
                <Form.Group
                  className="mb-3 pt-5 pb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Button
                    variant="primary"
                    className="my_button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Attributes;
