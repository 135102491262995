import { useState } from "react";

import { Col, Container, Form, Row } from "react-bootstrap"
import FormInput from "./formInput"
import FormButton from "./formButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function FilterByDateRange(props) {
    let { filterSelected, filterHandale, filterSubmit } = props
    const [date, setDate] = useState(new Date());

    return (
        <Container>
            <Row>
                <Col md={12}>
                    {/* <Form> */}
                    <Row>
                        <Col md={4}>
                            <label className="form-label" for="formBasicEmail">Start Date</label>
                            {/* <input name="datepic" type="date" id="formBasicEmail" className="form-control" onChange={(e) => setDate(e.target.value)}/> */}
                            <DatePicker
                                id="formBasicEmail"
                                className="form-control"
                                type="date"
                                selected={filterSelected?.start ?? ""}
                                onChange={(e) => filterHandale("start", e)}
                                placeholderText="YYYY-MM-DD"
                                dateFormat={"yyyy-MM-dd"}
                            />

                        </Col>
                        <Col md={4}>
                            <label className="form-label" for="formBasicEmail">End Date</label>
                            {/* <FormInput type="date" name="datepic" label="End Date" value={date}
                onChange={(e) => setDate(e.target.value)}/>  */}
                            <DatePicker
                                id="formBasicEmail"
                                className="form-control"
                                type="date"
                                selected={filterSelected?.end ?? ""}
                                onChange={(e) => filterHandale("end", e)}
                                placeholderText="YYYY-MM-DD"
                                dateFormat={"yyyy-MM-dd"}
                            />
                        </Col>
                        <Col md="2">
                            <div className="mb-3">
                                <label className="form-label">&nbsp;</label>
                                <button className="form-control filter_submit btn" onClick={() => filterSubmit("dateRange")}>Filter</button>
                            </div>
                            {/* <FormButton addClass="filter_submit" onClick={() => filterSubmit("dateRange")} type="submit" name="Filter" /> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <small style={{ color: "red" }}>{filterSelected?.dateRangeErr}</small>
                        </Col>
                    </Row>
                    {/* </Form> */}
                </Col>
            </Row>
        </Container>
    )
}

export default FilterByDateRange