import "./wallet.css"
import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

const WalletHeader = (props) => {
	return (
		<div className="nft_user_top_heading frame_header">
			<h2>Blockchain Wallets</h2>
			<div className="addnew_badge_partts">
				<div className="dropdown">
					<DropdownButton title={props.mode || "Mode"} id="dropdown-button-dark-example2" variant="secondary">
						<Dropdown.Item onClick={() => props.handleClick("sandbox")}>Sandbox</Dropdown.Item>
						<Dropdown.Item onClick={() => props.handleClick("production")}>Production</Dropdown.Item>
					</DropdownButton>
				</div>
				<button className="new_badge1" onClick={props.onClick}>
					Add New Wallet
				</button>
			</div>
		</div>
	)
}

export default WalletHeader