import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import { Row, Col, Form } from "react-bootstrap";
import "./notificationLog.css";

import { Edit_img, Managesearch_img, Maneye_img } from "../../assets/images";
import NotificationLogModal from '../modals/NotificationLogModal';

const NotificationLog = () => {
  
    const [allNotificationLog, setAllNotificationLog] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState({ counts: false, dtable: false });
    
    const CustomLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>Loading...</div>
        </div>
      );
      
    const EmptyDataLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>No data found...</div>
        </div>
      );
      
      const getNotificationLog = async() => {
        setLoading((prevState) => ({ ...prevState, dtable: true }));
        await axios.get(`/get-all-notification-logs/?limit=${limit}&page=${page}&search=${search}`)
        .then((response) => {
            console.log("Notification-log data......", response.data);
            setLoading((prevState) => ({ ...prevState, dtable: false }));
            setAllNotificationLog(response.data.data);
            setTotalRows(response.data.pagination.totalRows)
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    
    useEffect(() => {
      getNotificationLog();
    }, [page, limit, search]);
      
      const getNotification = () => {
        let notificationLogData = [];
        allNotificationLog.map((item, index) => {
          notificationLogData.push({
            name: <div className="notification_alignment">{item.receiver?.name ? item.receiver?.name : "Not Found"}</div>,
            title: (
              <div className="management_list_name">
                <div className="notification_alignment">{item.title ? item.title : "Not Found"}</div>
              </div>
            ),
            content: <div className="notification_alignmenteee" dangerouslySetInnerHTML={{__html: item.content}}></div>,
            // status: <div className="notification_alignment">{item.status ? "Successfully" : "Failed"}</div>,
            // view: <NotificationLogModal sendNotificationLog={allNotificationLog} sendId={item._id} />
            // view: (
            //   <div className="three_dotss">
            //     <Link to={`/notification-log-template/${item._id}`}>
            //       <img src={Maneye_img} alt="No Img" className="manEyeStyle" />
            //     </Link>
            //   </div>
            // ),
          });
        });
        return notificationLogData;
      };
      
      
      const handlePageChange = (page) => {
        //console.log(page)
        setPage(page);
      };
      
      const handlePerRowsChange = (newPerPage, page) => {
        console.log(newPerPage, page);
        setLimit(newPerPage);
        setPage(page);
      };
  return (
    <React.Fragment>
        <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Notification Log List</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div>
              </div>
              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                  className="hypTable1"
                    columns={columns}
                    data={getNotification()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      // reorder: true,
      width: "150px",
    },
    {
        name: "Title",
        selector: (row) => row.title,
        // reorder: true,
        width: "200px",
    },
    {
      name: "Content",
      selector: (row) => row.content,
      // reorder: true,
      width: "375px",
    },  
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   // reorder: true,
    //   width: "125px",
    // },
    // {
    //   name: 'View',
    //   selector: row => row.view,
      // reorder: true,
    //   width: "150px",
    // },
  ];

  const customStyles = {
    rows: {
      style: {
        //minHeight: '72px',
        backgroundColor: "transparent",
        color: "#fff",
        paddingLeft: "10px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "25px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "16px",
      },
    },
    cells: {
      style: {
        paddingLeft: "4px", // override the cell padding for data cells
        paddingRight: "2px",
        paddingTop: "15px",
        paddingBottom: "15px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "14px",
      },
    },
  };
  
export default NotificationLog;