import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "../../axios/axios";
import { useParams, Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const UsagesPromoCodeDetails = () => {
  const { id } = useParams();
  const [promoCodeDetails, setPromoCodeDetails] = useState([]);
  console.log(".....promoCodeDetails",promoCodeDetails)
  const [loading, setLoading] = useState(true);

  const getUsagesCode = async () => {
    await axios
      .get(`/view-usages-promo-codes-by-id/${id}`)
      .then((resp) => {
        setPromoCodeDetails(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(" err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsagesCode();
  }, []);

  return (
    <div>
      <div className="nft_bottom">
        <Row>
          <Col lg="12" md="12" sm="12">
            <LoadingOverlay
              active={loading}
              spinner={<PulseLoader color="white" size={10} />}
            >
              <div className="hermis_top fix_design d-flex justify-content-between">
                <div className="d-flex">
                  {/* <div className="george_hermis_user">
                    <div className="hermis_user_left">
                      <div className="hermis_user_image">
                        <img
                          src={
                            userLevel?.userProfile?.thumbnailUrl
                              ? userLevel.userProfile.thumbnailUrl
                              : No_ProfImg
                          }
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="state_parttsss">
                    <h2>
                      <strong>Name:</strong> {promoCodeDetails?.userId?.name}
                    </h2>
                    <h2>
                      <strong>User Name:</strong> {promoCodeDetails?.userId?.userName}
                    </h2>
                    <h2>
                      <strong>Promo Name:</strong> {promoCodeDetails?.promoId?.name}
                    </h2>
                    <h2>
                      <strong>Promo Name:</strong> {promoCodeDetails?.promoId?.code}
                    </h2>
                    <h2>
                      <strong>Order Id:</strong> {promoCodeDetails?.paymentId?.orderId}
                    </h2>
                    <h2>
                      <strong>Payment Id:</strong> {promoCodeDetails?.paymentId?.paymentId}
                    </h2>
                    <h2>
                      <strong>Applied Amount:</strong> {promoCodeDetails?.appliedOnAmount}
                    </h2>
                    <h2>
                      <strong>discountAmount:</strong> {promoCodeDetails?.discountAmount}
                    </h2>
                    <h2>
                      <strong>Paid Amount:</strong> {promoCodeDetails?.paidAmount}
                    </h2>
                    </div>
                  </div>
                </div>
            </LoadingOverlay>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UsagesPromoCodeDetails;
