import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "../../axios/axios";
import {Alert, Form} from "react-bootstrap";
import { message } from "antd";
import "antd/dist/antd.css";
// import message from "../../messages";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState({ success: false, fail: false });

  const emailValidator = () => {
    const reg = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    if (email.trim() === "") {
      setErr("Email is required");
      return false;
    } else if (reg.test(email) === false) {
      setErr("Email is invalid");
      return false;
    } else {
      setErr("");
      return true;
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const sendLink = () => {
    if (emailValidator()) {
      axios
        .post("/forgot-password", { email: email })
        .then((res) => {
          console.log(res.data.success);
          if (res.data.success === true) {
            setEmail("");
            setShow({ success: true, fail: false });
            message.success("Reset password link has been mailed to you");
          } else {
            setShow({ success: false, fail: true });
            message.error("Error");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Error");
        });
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className="forgotPass"
      >
        <Modal.Header style={{ display: "block" }}>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert
            className="p-1"
            key={"danger"}
            variant={"danger"}
            show={show.fail}
          >
            Incorrect email provided
          </Alert>
          {/* <Alert className="p-1" key={"success"} variant={"success"} show={show.success}>Reset password has been mailed to you</Alert> */}
          <label className="">
            {/*<input
              type="email"
              placeholder="email"
              onBlur={emailValidator}
              value={email}
              onChange={handleChange}
            />*/}

            <Form.Control
              type="email"
              placeholder="email"
              onBlur={emailValidator}
              value={email}
              onChange={handleChange}
              className="field_part"
            />
          </label>
          <p style={{ color: "red" }}>{err}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={sendLink}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ForgotPassword;
