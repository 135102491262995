import Axios from "axios";

// console.log("axios url", process.env.REACT_APP_API_URL);
// console.log("axios url", process.env.REACT_APP_API_URL_LOCAL);
const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: `${process.env.REACT_APP_API_URL_LOCAL}`,
});

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //console.log("Hiiiiii", config);

    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "AuthToken"
    )}`;
    //console.log("Hiiiiii2", config);

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data

    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
