/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as YUP from 'yup';
import axios from '../../axios/axios';
import message from '../../messages'

export default function EditNews({ show, handleClose, getNewsAnnouncement, details }) {

    const [newstype, setnewstype] = useState("")
    const [image, setImage] = useState({})
    const [video, setVideo] = useState({})
    const [loading, setLoading] = useState(false)
    const [videoSize, setVideoSize] = useState({ width: 0, height: 0 })
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

    const FormState = YUP.object().shape({
        newsType: YUP.string().required("Select news type"),
        title: YUP.string().required("Provide news title"),
        description: YUP.string().required("Provide news description"),
        status: YUP.boolean().required("Provide news status"),
        image: YUP.mixed().required("Provide news image"),

        video: YUP.string().when("newsType", {
            is: (newsType) => "VIDEO" === newsType,
            then: schema => schema.required("Provide news video"),
            otherwise: schema => schema.notRequired()
        })
    })

    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm({ resolver: yupResolver(FormState), mode: 'all' })

    useEffect(() => {
        setValue("newsType", details?.newsType)
        setValue("title", details?.title)
        setValue("description", details?.description)
        setValue("status", details?.status)
        setValue("image", details?.imageUrl)
        setValue("video", details?.videoUrl)
        setnewstype(details?.newsType)
        setImage(details?.imageUrl)
        setVideo(details?.videoUrl)

        return () => {
            reset()
            setImage({})
            setVideo({})
        }
    }, [details])


    const updateNews = (data) => {
        const formdata = new FormData()
        formdata.append("newsType", data.newsType)
        formdata.append("title", data.title)
        formdata.append("description", data.description)
        formdata.append("image", image)
        formdata.append("imageSize", JSON.stringify(imageSize))
        if (data.newsType === "VIDEO") {
            formdata.append("video", video)
            formdata.append("videoSize", JSON.stringify(videoSize))
        }

        setLoading(true);
        axios.put(`/update-news/${details._id}`, formdata).then(({ data }) => {
            setLoading(false);
            if (data.success) {
                message.success(data.message)
                reset()
                getNewsAnnouncement()
                handleClose()
            } else {
                message.error(data.message)
            }
        }).catch((error) => {
            setLoading(false);
            message.error(error.response ? error.response.data.message : error.toString())
        })
    }

    const uploadvideo = (file) => {
        let element = document.createElement("video")
        element.onloadedmetadata = () => {
            setVideoSize({ width: element.videoWidth, height: element.videoHeight })
        }
        element.src = URL.createObjectURL(file)
        setVideo(file)
    }

    const uploadImage = (file) => {
        let element = document.createElement("img")
        element.onload = () => {
            setImageSize({ width: element.width, height: element.height })
        }
        element.src = URL.createObjectURL(file)
        setImage(file)
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Edit News</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(updateNews)}>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>News Type</Form.Label>
                        <Form.Select aria-label="Select news type" {...register("newsType", { onChange: (e) => setnewstype(e.target.value) })}>
                            <option value="">select news type</option>
                            <option value="IMAGE">Image</option>
                            <option value="VIDEO">Video</option>
                        </Form.Select>
                        {errors.newsType && (<Form.Text className="text-danger">{errors.newsType.message}</Form.Text>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>News Image</Form.Label>
                        <Form.Control type="file" placeholder="Select news image" {...register("image", { onChange: (e) => uploadImage(e.target.files[0]) })} />
                        {errors.image && (<Form.Text className="text-danger">{errors.image.message}</Form.Text>)}
                    </Form.Group>

                    {
                        (newstype === "VIDEO") && (
                            <Form.Group className="mb-3">
                                <Form.Label>News Video</Form.Label>
                                <Form.Control type="file" placeholder="Select news video" {...register("video", { onChange: (e) => uploadvideo(e.target.files[0]) })} />
                                {errors.video && (<Form.Text className="text-danger">{errors.video.message}</Form.Text>)}
                            </Form.Group>
                        )
                    }

                    <Form.Group className="mb-3">
                        <Form.Label>News Title</Form.Label>
                        <Form.Control type="text" placeholder="Enter news title" {...register("title")} />
                        {errors.title && (<Form.Text className="text-danger">{errors.title.message}</Form.Text>)}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>News Description</Form.Label>
                        <Form.Control type="text" as={"textarea"} placeholder="Enter news description" {...register("description")} rows={15} />
                        {errors.description && (<Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>News Image</Form.Label>
                                <div className="man_text"><img src={details.imageUrl} alt="" width={100} /></div>
                            </Form.Group>
                        </Col>
                        {
                            (details.videoUrl !== "") && (
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>News Video</Form.Label>
                                        <div className="man_text"><video src={details.videoUrl} width={100} autoPlay muted controls /></div>
                                    </Form.Group>
                                </Col>
                            )
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit'> Submit </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
