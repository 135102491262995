import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
const Card = ({ title, count, loading }) => {
  return (
    <div className="created_inner_prf">
      <LoadingOverlay
        active={loading}
        spinner={<PulseLoader color="white" size={10} />}
      >
        <div className="created_inner_inner_prf">
          <h2>{title}</h2>
          <label>{count}</label>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default Card;
