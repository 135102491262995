/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import { Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "./NewAnnouncement.css";
import { Delete_img, Edit_img, Managesearch_img, Threedots_img } from "../../assets/images";
import Switch from "react-switch";
import message from '../../messages'
import AddNews from "./AddNews";
import EditNews from "./EditNews";
import ViewVideo from "./ViewVideo";

export default function NewAnnouncement() {

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  let [loading, setLoading] = useState({ counts: false, dtable: false });
  const [newsannouncment, setnewsannouncment] = useState([])
  const [pagination, setpagination] = useState({ limit: 10, page: 1, totalpage: 0 });

  const [show, setshow] = useState({ addshow: false, editshow: false, reviewshow: false })
  const [details, setdetails] = useState(null)

  const getNewsAnnouncement = async () => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    await axios.get(`/get-all-news/`, { params: { limit, page, search } }).then(({ data }) => {
      setLoading(prevState => ({ ...prevState, dtable: false }));
      if (data.success) {
        usersPayoutData(data.news);
        setpagination(data.pagination);
      } else {
        message.error(data.message)
      }
    }).catch((err) => {
      setLoading(prevState => ({ ...prevState, dtable: false }));
      console.log("news-error", err);
    });
  };

  useEffect(() => {
    getNewsAnnouncement();
  }, [page, limit, search]);

  const handleStatusChange = (id) => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    axios.patch(`/toogle-news/${id}`).then(({ data }) => {
      if (data.success) {
        message.success(data.message)
        getNewsAnnouncement()
      } else {
        setLoading(prevState => ({ ...prevState, dtable: false }));
      }
    }).catch((err) => {
      setLoading(prevState => ({ ...prevState, dtable: false }));
      console.log("Users-Payout-error", err);
    });
  }

  const handleDelete = (id) => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    axios.delete(`/delete-news/${id}`).then(({ data }) => {
      if (data.success) {
        message.success(data.message)
        getNewsAnnouncement()
      } else {
        setLoading(prevState => ({ ...prevState, dtable: false }));
      }
    }).catch((err) => {
      setLoading(prevState => ({ ...prevState, dtable: false }));
      console.log("Users-Payout-error", err);
    });
  }

  const usersPayoutData = (news) => {
    setnewsannouncment(news.map((item, index) => {
      return {
        id: index,
        image: <div className="man_text" style={{ cursor: (item.newsType === "VIDEO") && "pointer" }} onClick={() => { (item.newsType === "VIDEO") && setshow({ reviewshow: true }); setdetails(item); }}><img src={item.imageUrl} alt="" width={100} /></div>,
        newsType: <div className="man_text">{item.newsType}</div>,
        title: <div className="man_text">{item.title}</div>,
        description: <div className="man_text">{item.description.length>=100? item.description.substring(0,100)+" ..." : item.description}</div>,
        status: <Switch checked={item.status} uncheckedIcon={item.status} onChange={() => handleStatusChange(item._id)} onColor="#2e7b7f" onHandleColor="#01C1C1" handleDiameter={12} height={9} width={19} className="react-switch" id="material-switch" />,
        action: (
          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt="No Img" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="DropdownMenu">
                <Link to={"#"} onClick={() => { setshow({ editshow: true }); setdetails(item); }}>
                  <img alt="No Img" src={Edit_img} />
                  <span className="userActionAlign">Edit</span>
                </Link>
                <Link to="#" onClick={() => handleDelete(item._id)}>
                  <img src={Delete_img} alt="" />
                  <span className="userActionAlign">Delete</span>
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      }
    }))
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
    setPage(page);
  };

  const CustomLoader = () => (
    <div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px", }}>
      <div>Loading...</div>
    </div>
  );

  const EmptyDataLoader = () => (
    <div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px", }}>
      <div>No data found...</div>
    </div>
  );

  return (
    <>
      <div className="user_inner_partts email_partts news_announcment">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>News and Announcement List</h2>
                <div className="managment_section">
                  <div className="manage_texfieldt">
                    <Form.Control type="text" placeholder="Search here.." className="manage_search_boxxx" onChange={(e) => setSearch(e.target.value)} />
                    <span className="search_imgg_manage">
                      <img alt="No Img" src={Managesearch_img} />
                    </span>
                  </div>
                  <Button onClick={() => setshow({ addshow: true })}> Add New </Button>
                </div>
              </div>

              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                    className="hypTable1"
                    columns={columns}
                    data={newsannouncment}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={pagination.totalpage}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {
        show.addshow && (
          <AddNews show={show.addshow} handleClose={() => setshow({ ...show, addshow: false })} getNewsAnnouncement={getNewsAnnouncement} />
        )
      }
      {
        show.editshow && (
          <EditNews show={show.editshow} handleClose={() => { setshow({ ...show, editshow: false }); setdetails(null); }} getNewsAnnouncement={getNewsAnnouncement} details={details} />
        )
      }
      {
        show.reviewshow && (
          <ViewVideo show={show.reviewshow} handleClose={() => { setshow({ ...show, reviewshow: false }); setdetails(null); }} details={details} />
        )
      }
    </>
  );
}


const columns = [
  {
    name: "Image / Video",
    selector: (row) => row.image,
    // reorder: true,
    width: "150px",
  },
  {
    name: "News Type",
    selector: (row) => row.newsType,
    // reorder: true,
    width: "150px",
  },
  {
    name: "Title",
    selector: (row) => row.title,
    // reorder: true,
    width: "135px",
  },
  {
    name: "Description",
    selector: (row) => row.description,
    // reorder: true,
    width: "300px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    // reorder: true,
    width: "90px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "100px"
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      // paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "17px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
      minWidth: "none",
      maxWidth: "none !important",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      //   paddingTop: "5px",
      paddingTop: "25px",
      //   paddingBottom: "5px",
      paddingBottom: "25px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
      minWidth: "none",
      maxWidth: "none !important",
    },
  },
};
