import React from 'react'

function Item(props) {
  return (
    <div className={`item_info ${props.className}`}>
        <div className='info_value'>{props.value}</div>
        <span className='info_title'>{props.title}</span>
    </div>
  )
}

export default Item