import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-switch";
import ProgressBar from "react-bootstrap/ProgressBar";
import Badge from "react-bootstrap/Badge";

import "./fundrisedetails.css";

import {
  Delete_1_Img,
  Send_Mail_Img,
  Love_s_Img,
  Date_Cal_Img,
  No_ProfImg,
} from "../../assets/images";
import Card from "../Nft/Card";
import NftEdition from "../NftDetails/NftEdition";
import DetailsCard from "./DetailsCard";
import moment from "moment";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FundRaiserResumeDate from "../modals/FundRaiserResumeDate";
const FundRaiserDetails = () => {
  const { fr_id } = useParams();
  const [frDetails, setfrDetails] = useState({});
  const [userNfts, setUserNfts] = useState([]);
  const [contributers, setContributers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();

  // let first_day = "";
  // let last_day = "";

  const leftDay = () => {
    let first = moment(frDetails?.createdAt).format("YYYY/MM/DD");
    // let last_day = moment(frDetails?.expireDate).format("YYYY/MM/DD");
    let current = moment().startOf("day");
    let days = moment.duration(first.diff(current)).asDays();
    // const time_difference = first_day.getTime() - last_day.getTime();

    // console.log("first day: ", first_day + " " + "last day", last_day + "  " + "time difference", time_difference);

    // console.log("Number of days: ", moment.duration(first_day.diff(last_day)).asWeeks());

    console.log("Number of days: ", days);
  };

  useEffect(() => {
    getFrsById();
    getSelectedNfts();
    getContributers();
    // leftDay();
  }, []);
  const getFrsById = async () => {
    // setLoading((prevState) => ({ ...prevState, nft: true }));
    await axios
      .get(`/fund-raiser-details/${fr_id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.success === true) {
          setfrDetails(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getNftsByUser err", err);
        setLoading(false);
      });
  };
  const getSelectedNfts = async () => {
    // setLoading((prevState) => ({ ...prevState, nft: true }));
    await axios
      .get(`/fund-raiser-nfts/${fr_id}`)
      .then((res) => {
        console.log("nfts", res.data);
        if (res.data.success === true) {
          setUserNfts(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getNftsByUser err", err);
        setLoading(false);
      });
  };
  const getContributers = async () => {
    // setLoading((prevState) => ({ ...prevState, nft: true }));
    await axios
      .get(`/fund-raiser-contibuters/${fr_id}`)
      .then((res) => {
        console.log("contri", res.data);
        if (res.data.success === true) {
          setContributers(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("getNftsByUser err", err);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="nft_bottom right_panel_container">
        <Row>
          <Col lg="6" md="12" sm="12" style={{ paddingTop: "40px" }}>
            <div className="george_hermis">
              <LoadingOverlay active={loading} spinner text="please wait...">
                <div className="hermis_top">
                  <div className="george_hermis_user">
                    <div className="hermis_user_left">
                      <div className="hermis_user_image">
                        <img
                          src={
                            frDetails.raiserProfile
                              ? frDetails.raiserProfile.thumbnailUrl
                              : No_ProfImg
                          }
                          alt=""
                        />
                        <label className="greenn_bg"></label>
                      </div>
                    </div>
                    <div className="hermis_user_right">
                      <h4>{frDetails.userId ? frDetails.userId.name : ""} </h4>
                      <h4>
                        <span>
                          @{frDetails.userId ? frDetails.userId.email : ""}
                        </span>
                      </h4>
                      <h4>
                        <span>{frDetails.reason}</span>
                      </h4>
                      <div>
                        <ProgressBar
                          now={frDetails.percentEarned}
                          label={`${frDetails.percentEarned}%`}
                        />
                      </div>
                      <h4 style={{ marginTop: "10px" }}>
                        <span>
                          <i>Status</i>:{" "}
                          {frDetails.status == "closed" ? (
                            <Badge bg="danger">{frDetails.status}</Badge>
                          ) : (
                            <>
                              {frDetails.status == "completed" ? (
                                <Badge bg="primary">{frDetails.status}</Badge>
                              ) : (
                                <Badge bg="success">{frDetails.status}</Badge>
                              )}
                            </>
                          )}
                        </span>
                      </h4>
                      
                      {/* below is the comment of  "Reset Fund Raisers Date"  */}
                      {/* <div style={{display: "flex", direction: "flex-column", color: "white"}}>
                        <span>Resume Date: &nbsp;</span>
                        <FundRaiserResumeDate  />
                      </div> */}
                    </div>
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </Col>
          {/* <Card
                    loading={loading}
                    title="Days Left"
                    count={frDetails.daysLeft}
                  />
                  <Card
                    loading={loading}
                    title="Earned"
                    count={frDetails.earned}
                  />
                  <Card
                    loading={loading}
                    title="Earning Left"
                    count={frDetails.earningLeft}
                  />
                  <Card
                    loading={loading}
                    title="Days Left"
                    count={frDetails.daysLeft}
                  /> */}
          <Col lg="6" md="12" sm="12" style={{ paddingTop: "40px" }}>
            <Row>
              <Col lg="6" md="12" sm="12">
                <LoadingOverlay
                  active={loading}
                  spinner={<PulseLoader color="white" size={10} />}
                >
                  <div>
                    <div className="active_inner_list frlist">
                      <h2>Target Amount</h2>
                      <div className="active_image_text">
                        <div className="active_image">
                          {/* <img src={imgSrc} alt="No Img" /> */}
                        </div>
                        <div className="active_text">$ {frDetails.amount}</div>
                      </div>
                    </div>
                  </div>
                </LoadingOverlay>
              </Col>

              <Col lg="6" md="12" sm="12">
                <LoadingOverlay
                  active={loading}
                  spinner={<PulseLoader color="white" size={10} />}
                >
                  <div>
                    <div className="active_inner_list frlist">
                      <h2>Earned</h2>
                      <div className="active_image_text">
                        <div className="active_image">
                          {/* <img src={imgSrc} alt="No Img" /> */}
                        </div>
                        <div className="active_text">$ {frDetails.earned}</div>
                      </div>
                    </div>
                  </div>
                </LoadingOverlay>
              </Col>
              <Col lg="6" md="12" sm="12" style={{ marginTop: "10px" }}>
                <LoadingOverlay
                  active={loading}
                  spinner={<PulseLoader color="white" size={10} />}
                >
                  <div>
                    <div className="active_inner_list frlist">
                      <h2>Earning Left</h2>
                      <div className="active_image_text">
                        <div className="active_image">
                          {/* <img src={imgSrc} alt="No Img" /> */}
                        </div>
                        <div className="active_text">
                          $ {frDetails.earningLeft}
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingOverlay>
              </Col>
              <Col lg="6" md="12" sm="12" style={{ marginTop: "10px" }}>
                <LoadingOverlay
                  active={loading}
                  spinner={<PulseLoader color="white" size={10} />}
                >
                  <div>
                    <div className="active_inner_list frlist">
                      <h2>Days Left</h2>
                      <div className="active_image_text">
                        <div className="active_image">
                          {/* <img src={imgSrc} alt="No Img" /> */}
                        </div>
                        <div className="active_text">{frDetails.daysLeft}</div>
                      </div>
                    </div>
                  </div>
                </LoadingOverlay>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ paddingTop: "40px" }}>
          <Col>
            <LoadingOverlay
              active={loading}
              spinner={<PulseLoader color="white" size={10} />}
            >
              <div className="nfts_collectable">
                <div className="delete_nft_partts">
                  <h2>Selected NFTS</h2>
                </div>
                <div className="nft_man_partts" style={{ paddingTop: "5px" }}>
                  {userNfts ? (
                    userNfts.map((item, i) => {
                      return (
                        <>
                          <div
                            className="nft_man_inner_partts"
                            style={{ opacity: item.isSold ? "0.6" : "1" }}
                          >
                            <div className="nft_mansss">
                              <img src={item.nftId.fileUrl} />
                            </div>
                            <div className="nft_manss_heading">
                              <h3>{item.nftId.title}</h3>
                              <label>Nft Price:</label>
                              <label> {`$ ${item.price}`}</label>
                              <label> Sold Amount:</label>
                              <label> {`$ ${item.sellAmount}`}</label>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p style={{ color: "white" }}>No nfts were present</p>
                  )}
                </div>
              </div>
            </LoadingOverlay>
          </Col>
          <Col>
            <DetailsCard details={contributers} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FundRaiserDetails;
