import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import axios from "../../axios/axios";
import moment from "moment";
import { message } from "antd";

import { Row, Col, Form, Dropdown } from "react-bootstrap";
import "./promoCode.css";

import { Delete_img, Edit_img,level_setting,Managesearch_img, Maneye_img, Threedots_img } from "../../assets/images";

const PromoCode = () => {
    const history = useHistory();
    
    const [promoCode, setPromoCode] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    
    const CustomLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>Loading...</div>
        </div>
      );
      
    const EmptyDataLoader = () => (
        <div
          style={{
            padding: "15px",
            background: "#495A71",
            color: "#fff",
            width: "100%",
            fontSize: "40px",
            textAlign: "center",
            minHeight: "350px",
            lineHeight: "400px",
          }}
        >
          <div>No data found...</div>
        </div>
      );
      
      const getPromoCode = async() => {
        await axios.get(`/get-all-promo-codes/?limit=${limit}&page=${page}`)
        .then((response) => {
          console.log("Promo_code data....", response.data);
            setPromoCode(response.data.data);
            setTotalRows(response.data.pagination.totalRows)
            setLoading(false)
        })
        .catch((error) => {
            console.log(error);
        })
    }
    
    useEffect(() => {
      getPromoCode();
    }, [page, limit]);
    
    const deletePromo = (promoId) => {
        console.log("Promo_Code is deleted.. with id..", promoId);
        
        axios
      .delete(`/delete-promo-codes/${promoId}`)
      .then((res) => {
        if (res.data.success === true) {
            getPromoCode();
          message.success("Promo Code deleted successfully");
        } else {
          message.error("Promo Code deletion failed");
        }
      })
      .catch((err) => {
        console.log("deletePromoCode err", err);
      });
    }
      
      const getPromoData = () => {
        let promoData = [];
        promoCode.map((item, i) => {
          item.code !== "" && promoData.push({
            id: item._id,
            promo_name: (
              <div className="management_list_name">
                <div className="man_text">{item.name}</div>
              </div>
            ),
            promo_code: <div className="man_username">{item.code}</div>,
            start_date: <div className="man_username">{moment(item.start_date).format("DD/MM/YYYY")}</div>,
            expire_date: <div className="man_username">{moment(item.expire_date).format("DD/MM/YYYY")}</div>,
            discount_type: <div className="man_username">{item.discount?.type}</div>,
            status: <div className="man_username">{item.status === true ? "Active" : "Inactive"}</div>,
            action: (
                <div className="three_dotss">
                  <Dropdown className="drop_partts">
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <img src={Threedots_img} alt="No Img" />
                    </Dropdown.Toggle>
      
                    <Dropdown.Menu>
                      {/* <Link to={`/userdetails/${item.id}`}>
                        <img alt="No Img" src={Maneye_img} />
                        <span className="userActionAlign">View</span>
                      </Link> */}
                      <Link to={`/edit-promo-code/${item._id}`}>
                        <img alt="No Img" src={Edit_img} />
                        <span className="userActionAlign">Edit</span>
                      </Link>
                      <Dropdown.Item onClick={() => deletePromo(item._id)}>
                        <img alt="No Img" src={Delete_img} />
                        <span className="userActionAlign">Delete</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ),
              
          });
        });
        return promoData;
      };
      
      
      const handlePageChange = (page) => {
        setLoading(true)
        setPage(page);
      };
      
      const handlePerRowsChange = (newPerPage, page) => {
        setLoading(true)
        console.log(newPerPage, page);
        setLimit(newPerPage);
        setPage(page);
      };
      
      const gotoAddPromo = () => {
        history.push('/add-new-promo-code')
      }
      
  return (
    <React.Fragment>
        <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
            
            {/* <div className="emailModel">
                <EmailTemplateModel />
            </div> */}
            
              <div className="manage_heading_search">
                <h2>Promo Codes List</h2>
                <button className="add_new_promo" onClick={gotoAddPromo}>
                    {/* <Link to={`/add-new-promo-code`} className="link_style">
                        Add New Promo Code
                    </Link> */}
                    Add New Promo Code
                </button>
              </div>
                
              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                    className="hypTable1"
                    columns={columns}
                    data={getPromoData()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<CustomLoader />}
                    progressPending={loading}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const columns = [
    {
      name: "Name",
      selector: (row) => row.promo_name,
      reorder: true,
      width: "200px",
    },
    {
      name: "Code",
      selector: (row) => row.promo_code,
      reorder: true,
      width: "200px",
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      reorder: true,
      width: "150px",
    },
    {
      name: "Expire Date",
      selector: (row) => row.expire_date,
      reorder: true,
      width: "150px",
    },
    {
      name: "Discount Type",
      selector: (row) => row.discount_type,
      reorder: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      reorder: true,
      width: "100px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      reorder: true,
      width: "100px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        //minHeight: '72px',
        backgroundColor: "transparent",
        color: "#fff",
        paddingLeft: "10px", // override the row height
      },
    },
    headCells: {
      style: {
        // paddingLeft: "10px",
        paddingLeft: "15px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "transparent",
        color: "#fff",
      },
    },
    cells: {
      style: {
        paddingLeft: "4px", // override the cell padding for data cells
        paddingRight: "2px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "transparent",
        color: "#fff",
      },
    },
  };
  
export default PromoCode;