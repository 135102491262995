import React, { useEffect, useState } from "react";

import { Row, Col, Dropdown, Button, Form } from "react-bootstrap";
import { findIndex } from "lodash";
import "./manageproduct.css";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import axios from "../../../axios/axios";
import { useHistory } from "react-router-dom";
import InputControl from "../InputControl";
import { message } from "antd";

const ManageColorImages = ({ variety, product, setKey }) => {
  const token = useSelector((state) => state.setToken);
  const [selectedImage, setSelectedImage] = useState([]);
  const [isActive, setActive] = useState([]);
  
  const history = useHistory();

  // let colors=variety.filter((var)=>var.type=="color");
  let colors = variety.filter((tag, index) => tag.type == "color");
  const uploadImage = async (color, file) => {
    console.log(color, file);
    let formData = new FormData();
    formData.append("productId", product.productId);
    formData.append("color", color);
    formData.append("items[img]", file);

    await axios
      .post(
        `/upload-product-image`,
        formData,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("image res....", res.data);
        if (res.data.success === true) {
          setActive(color);
          message.success("Image Uploaded");
          // setOptions(res.data.data);
          //   setPagination(res.data.pagination);
          //   setNfts(res.data.allFundRaisers);
          //   setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const data = () => {
    // console.log("inventory", inventory);
    let allInventorycolors = [];
    colors.length > 0 &&
      colors[0].dataset.map((inv, index) => {
        // let id = inv._id;
        console.log("inventory", inv);
        // console.log("inventory[id].quantity", inventory[index]);
        allInventorycolors.push({
          sl: index + 1,

          color: (
            <div
              style={{
                "background-color": inv,
                width: "20px",
                height: "20px",
                "border-radius": "50px",
                margin: "0 auto",
              }}
            ></div>
          ),

          image: (
            <label className="custom-file-upload">
              {/* <Button onClick={showState}></Button> */}
              {selectedImage != null && isActive.includes(inv) ? (
                <div>
                  done
                  <br />
                  {/* <button onClick={() => setSelectedImage(null)}>Remove</button> */}
                </div>
              ) : (
                <>
                  {" "}
                  <input
                    type="file"
                    onChange={(event) =>
                      uploadImage(inv, event.target.files[0])
                    }
                    // onChange={(event) => {
                    //   console.log(event.target.files[0]);
                    //   setActive(index + 1);
                    //   setSelectedImage({
                    //     id: index + 1,
                    //     file: event.target.files[0],
                    //   });
                    // }}
                  />
                  Choose file
                </>
              )}
            </label>
          ),
        });
      });
    return allInventorycolors;
  };
  const saveProd = async () => {
    let productId = product.productId;
    console.log("productId", productId);
    await axios
      .post(
        `/confirm-product-inventory`,
        { productId },

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("image confr res....", res.data);
        if (res.data.success === true) {
          message.success("Product Created successfully");

          setKey("details");
          // setOptions(res.data.data);
          //   setPagination(res.data.pagination);
          //   setNfts(res.data.allFundRaisers);
          //   setLoading(false);
          history.push('/products')
        } else {
          message.error("Product Created failed");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div>
      {console.log("inventory list", colors)}
      <div className="dash_partts">
        <Row className="justify-content-center">
          <Col lg="12" md="12" sm="12" className="ps-0 pe-0">
            <DataTable
              className="hypTable1"
              columns={columns}
              data={data()}
              pagination
              responsive
              //   progressPending={loading}
              customStyles={customStyles}
              noDataComponent={<EmptyDataLoader />}
              progressComponent={<CustomLoader />}
            />
          </Col>
          <Col>
            <Button onClick={saveProd}>Save</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
const columns = [
  {
    name: "Sl.",
    selector: (row) => row.sl,
  },

  {
    name: "Color",
    selector: (row) => row.color,
  },

  {
    name: "Image",
    selector: (row) => row.image,
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};
export default ManageColorImages;
