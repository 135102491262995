import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "./nftNotification.css";
import {
  Managesearch_img,
  Maneye_img,
  Threedots_img,
  Edit_img,
  Profw2_Img,
} from "../../assets/images";
import { Link } from "react-router-dom";
import { message } from "antd";

function NftNotification() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [allNftNotification, setAllNftNotification] = useState([]);
  let [loading, setLoading] = useState({ counts: false, dtable: false });

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const getNftNotification = async () => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    await axios
      .get(
        `/get-nft-notification/?limit=${limit}&page=${page}&search=${search}`
      )
      .then((res) => {
        console.log("NFTs-Notification data......", res.data);
        setLoading((prevState) => ({ ...prevState, dtable: false }));
        setAllNftNotification(res.data.getNftNotification);
        setTotalRows(res.data.pagination.totalRows);
      })
      .catch((err) => {
        console.log("NFTs-notification-error", err);
      });
  };

  useEffect(() => {
    getNftNotification();
  }, [page, limit, search]);

  const getNftsNotification = () => {
    let nftNotificationDetails = [];
    allNftNotification.map((item, i) => {
      nftNotificationDetails.push({
        // id: item.id,
        image: ( <div className="man_user">
        <img
          alt={Profw2_Img}
          src={item?.nftId?.fileUrl ? item?.nftId?.fileUrl : Profw2_Img}
        />
      </div>),
        nfts_name: (
          <div className="management_list_name">
           
            <div className="man_text">{item.nftId?.title}</div>
          </div>
        ),
        holder: <div className="man_usernameee">{item?.nftId?.nftHolder?.name}</div>,
        subscriber: <div className="man_usernameee">{item.userId?.name}</div>,
        status: <div className="man_usernameee" style={{marginLeft: "1.5rem"}}>{item.nftId?.forSale ? "true" : "false"}</div>,
        action: (
          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt="No Img" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to={`#`}>
                  <img alt="No Img" src={Maneye_img} />
                  View
                </Link>
                <Link to={`#`}>
                  <img alt="No Img" src={Edit_img} />
                  Edit
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      });
    });
    return nftNotificationDetails;
  };

  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    // console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };

  return (
    <React.Fragment>
      <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>NFTs Notification</h2>
                <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div>
              </div>

              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                  className="hypTable1"
                    columns={columns}
                    data={getNftsNotification()}
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    noDataComponent={<EmptyDataLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const columns = [
  {
    name: "",
    selector: (row) => row.image,
    // reorder: true,
    width: "100px",
  },
  {
    name: "NFTs Name",
    selector: (row) => row.nfts_name,
    // reorder: true,
    width: "250px",
  },
  {
    name: "Holder",
    selector: (row) => row.holder,
    // reorder: true,
    width: "225px",
  },
  {
    name: "Subscriber",
    selector: (row) => row.subscriber,
    // reorder: true,
    width: "225px",
  },
  {
    name: "Sale Status",
    selector: (row) => row.status,
    // reorder: true,
    width: "175px",
  },
];
const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "17px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "15px",
      paddingBottom: "15px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
    },
  },
};

export default NftNotification;
