import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import {
  Kevin_Img,
  Choice_Img,
  Kevin_Common_Img,
  Purcase_Arrow_Img,
  Profw2_Img,
  No_ProfImg
} from "../../assets/images";
import "./nftpurchasedetails.css";

import moment from "moment-timezone";
import { useLocation, useParams } from "react-router-dom";
import axios from "../../axios/axios";
import {datteTimerender} from "../../helpers/timeHelper";
import { useEffect } from "react";
import { CurrencyFormating } from "../../helpers/currencyHelper";

function NftPurchaseDetails() {
  const { state } = useLocation();
  // const {purchaseId} = useParams();

  const [reacts, setReacts] = useState([]);
  const [commission, setCommission] = useState({});

  const nftDetails = state.data;
  console.log(".....nftDetails check....", nftDetails);
  // return
  
  const getNftReactions = async() =>{
    axios.get(`get-nft-reactions/${nftDetails?.nft._id}`)
    .then((res)=>{
        console.log("............get reactions", res.data);
        if(res.data.success === true){
          setReacts(res.data.reacts);
        } else {
          console.log("error")
        }
    })
    .catch(err =>{
      console.log("getNftReactions err", err)
    })
  }

  const getCommission = () =>{
    console.log(".........get commission", nftDetails.payment.commission);
    setCommission(nftDetails.payment.commission)
  }

  useEffect(()=>{
    // getNftPurchaseDetails();
    getNftReactions();
    getCommission();
  },[nftDetails]);
  
  return (
    <div>
      <div className="kevin_bio nft_purchase_detailssss">
        <div className="kevin_inner_bio_parttss">
          <div className="kevin_inner_bio">
            <div className="kevin_inner_image">
              <img src={nftDetails?.nft?.fileUrl} />
            </div>
            <div className="kevin_inner_text">
              <div className="kevin_inner_title">
                <p>
                  <span className="minted">
                    Minted:{" "}
                    {moment(nftDetails.nft.createdAt).format("MM-DD-YYYY")}
                  </span>{" "}
                  
                  {nftDetails.nft.team!="" && (
                  <><span className="devider_minted">|</span>{" "}<span className="minted">{nftDetails.nft.team}</span></>
                  )}

                  {nftDetails.nft.position!="" && (<><span className="devider_minted">|</span>{" "}<span className="minted">{nftDetails.nft.position}</span></>)}
                </p>
                <div className="choice_sign"> 
                    <span className="kevin_number">{nftDetails.payment.firstHandTransacction? "First Sale" : "Secondary Sale"}</span>
              </div>
              </div>

              <div className="kevin_inner_heading">
                <div>
                  {/* <h3>Current Holder {" "}: {" "} <span>{profile.title}</span> </h3> */}
                  <h3>Title {" "}: {" "} <span>{nftDetails.nft.title != ""? nftDetails.nft.title: `${nftDetails.creatorDetails.nickName.fName} ${nftDetails.creatorDetails.nickName.lName}`}</span> </h3>
                </div>
                <div>
                  {/* <h3>Current Holder {" "}: {" "} <span>{profile.title}</span> </h3> */}
                  {/* <h5 style={{ color: "white" }}>Current Holder {" "}: {" "} <span>{profile.nftHolder?.name}</span> </h5> */}
                </div>
                <div>
                  <h6 style={{ color: "rgba(255, 255, 255, 0.5)" }}>Current Holder {" "}: {" "} <span>{nftDetails?.creatorDetails?.name}</span><p><small>@{nftDetails?.creatorDetails?.userName}</small></p></h6>
                </div>
              </div>


          
              <div className="kevin_common">
                <img src={nftDetails.nft.nftType.imageUrl} />
              </div>
            </div>
          </div>
          <div className="seller_details">
            <div className="seller_inner_details">
              <Row>
                <Col lg="6" md="6" sm="6">
                  <div className="seller_iner_heading">
                    <h3>Seller info</h3>
                  </div>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <div className="seller_iner_heading">
                    <h3>Buyer info</h3>
                  </div>
                </Col>
              </Row>
              <div className="seller_inner_inner">
                <div className="seller_inner_inner_left">
                  <div className="seller_inner_foot">
                    <div className="seller_user_image">
                      <img src={nftDetails.sellerProfile.thumbnailUrl? nftDetails.sellerProfile.thumbnailUrl : No_ProfImg} />
                    </div>
                    <div className="seller_user_textts">
                      <h3>{nftDetails.sellerDetails.name}</h3>
                      <h5>@{nftDetails.sellerDetails.userName}</h5>
                    </div>
                  </div>
                  <div className="seller_inner_arrow">
                    <img src={Purcase_Arrow_Img} />
                  </div>
                </div>
                <div className="seller_inner_inner_left">
                  <div className="seller_inner_foot jehn">
                    <div className="seller_user_image" style={{marginLeft: '2rem'}}>
                      {/* <img src={nftDetails.buyerProfile.thumbnailUrl ? nftDetails.buyerProfile.thumbnailUrl : No_ProfImg} /> */}
                    </div>
                    <div className="seller_user_textts">
                      <h3>{nftDetails.buyerDetails.name}</h3>
                      <h5>@{nftDetails.buyerDetails.userName}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seller_info">
          <div className="seller_inner_info">
            <label>Buying date</label>
            <h3>{moment(nftDetails.createdAt).format("MM-DD-YYYY")}</h3>
          </div>

          <div className="seller_inner_info">
            <label>Buying time</label>
            {/* <h3>{moment(nftDetails.createdAt).tz("Asia/kolkata").format("HH:MM:SS")}pm</h3> */}
            <h3>{datteTimerender(nftDetails.createdAt)}</h3>
          </div>
          <div className="seller_inner_info">
            <label>Price</label>
            <h3>
              {CurrencyFormating(nftDetails.price)} 
            </h3>
          </div>
          <div className="seller_inner_info">
            <label>Payment Method</label>
            <h3>{nftDetails.payment.payment_method}</h3>
          </div>
          {
            nftDetails.payment.payment_method === "WALLET" ?
            <React.Fragment>
              <div className="seller_inner_info">
                <label>Wallet Id</label>
                <h3 style={{maxWidth: '12rem'}}>{nftDetails.payment.wallet}</h3>
              </div>
            </React.Fragment> : nftDetails.payment.payment_method === "CARD" ?
            <React.Fragment>
              <div className="seller_inner_info">
                <label>Charge Id</label>
                <h3 style={{maxWidth: '12rem'}}>{nftDetails.payment.charge_id}</h3>
              </div>
            </React.Fragment> : ""
          }
          <div className="seller_inner_info">
            <label> Minter earning {`(${commission?.mintor?.percentage} %)`}</label>
            <h3>{CurrencyFormating(commission?.mintor?.amount)} </h3>
          </div>

          <div className="seller_inner_info">
            <label> Seller earning {`(${commission?.seller?.percentage} %)`}</label>
            <h3>{CurrencyFormating(commission?.seller?.amount)} </h3>
          </div>
          <div className="seller_inner_info">
            <label>HYPEU earning {`(${commission?.hypeu?.percentage} %)`}</label>
            <h3>{CurrencyFormating(commission?.hypeu?.amount)} </h3>
          </div>
          {nftDetails.payment.fee?.amount!=0 && (
            <div className="seller_inner_info">
            <label>Stripe Fee</label>
            <h3>{CurrencyFormating(nftDetails.payment.fee?.amount)} </h3>
          </div>
          )}
        </div>
        
        <div className="align-table-button">
          {
            nftDetails?.payment?.payment_method === "WALLET" && nftDetails?.payment?.transferIds && nftDetails?.payment?.transferIds.length > 0 ?
            <React.Fragment>
              <table className="transaction_table_border">
                  <thead style={{borderBottom: '1px solid white'}}>
                    <div className="table_head_center">
                      <tr>
                        <th colSpan="2">Transfer Id</th>
                      </tr>
                    </div>
                  </thead>
                  <tbody>
                  
                  {/* {
                    nftDetails?.payment?.transferIds.map((item, index) =>{
                      console.log("details of transaction id...", item)
                      <tr>
                      <span>Id - {index + 1} : &nbsp;</span>
                      <th scope="row">{item}</th>
                    </tr>
                    })
                  } */}
                      
                  {
                    nftDetails?.payment?.payment_method === "WALLET" && nftDetails?.payment?.transferIds && nftDetails?.payment?.transferIds.length > 0 ? 
                    nftDetails?.payment?.transferIds.map((data, index) => {
                      return(
                        <React.Fragment>
                          <div className="transaction_table_style">
                            <tr>
                              <strong style={{fontSize: '18px'}}>Id - {index + 1} : &nbsp;</strong>
                              <th scope="row">{data}</th>
                            </tr>
                          </div>
                        </React.Fragment>
                      )}) : ""
                  }
                  </tbody>
              </table>
            </React.Fragment> : ""
          }
          
          {
            nftDetails?.payment?.payment_method === "CARD" &&
            <React.Fragment>
              <div className="seller_inner_info download_invoice">
                <div className="download_in">
                  <a href={nftDetails.payment.invoiceUrl} target="_blank">Download invoice</a>
                </div>
              </div>
            </React.Fragment>
          }
        </div>
        
      </div>
    </div>
  );
}

export default NftPurchaseDetails;

