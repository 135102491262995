import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "../../axios/axios";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from "react-spinners";
import "./viewWallet.css";
import moment from "moment";

const ViewWallet = () => {
  const { walletId } = useParams();
  const history = useHistory();

  console.log("......userId...", walletId);

  const [gettingWallet, setGettingWallet] = useState([]);
  const [walletDetails, setWalletDetails] = useState("");
  const [loading, setLoading] = useState({ counts: false, dtable: false });

  const CustomLoader = () => (
    <div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px" }}>
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div style={{ padding: "15px", background: "#495A71", color: "#fff", width: "100%", fontSize: "40px", textAlign: "center", minHeight: "350px", lineHeight: "400px" }}>
      <div>No data found...</div>
    </div>
  );

  const getWalletDetails = async () => {
    await axios.get(`/get-make-an-offers/${walletId}`)
      .then((res) => {
        if (res.data.success === true) {
          console.log("Wallet Detailss.....", res.data);
          setGettingWallet(res.data.data);
          setWalletDetails(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Get Wallet Details Error.....", error);
        setLoading(false);
      })
  }

  useEffect(() => {
    getWalletDetails();
  }, []);

  // console.log("getting wallet details...", gettingWallet)

  const getWallet = () => {
    const walletData = [];
    // [gettingWallet].map((item, index) => {
    gettingWallet.map((item, index) => {
      walletData.push({
        transactionDate: [<div key={index} style={{ marginLeft: "1.5rem" }}> {moment(item.createdAt).format("MM-DD-YYYY")} </div>],
        nfts_purchaser: [<div key={`${index}B`} className="notification_alignment">{item.userId?.name}</div>],
        // debit: [<div className="man_text" style={{color: '#00FFFF'}}>{item.balance}</div>],
        // debit: <div>
        // {
        //   item.amount > 0 ? <div className="man_text" style={{color: '#2ED47A', marginLeft: '1rem'}}>{item.amount}</div> : item.balance > 0 ?
        //   <div className="man_text" style={{color: '#00FFFF', marginLeft: '1rem'}}>{item.balance}</div> : ''
        // }
        // </div>,
        debit: <div key={`${index}C`}>
          {
            item.type === "CREDIT" ? <div  className="man_text" style={{ color: '#2ED47A', marginLeft: '4rem' }}>$ {item.balance.toFixed(2)}</div> : item.type === "DEBIT" ?
              <div className="man_text" style={{ color: '#ff5c5c', marginLeft: '4rem' }}>{`${item?.nftPurchasepayment?.payment_method === "CARD" ? "$ " : ""} $ ${item.balance.toFixed(2)}`}</div> : ''
          }
        </div>,
        debit_for: [<div key={`${index}D`} className="notification_alignment" style={{ marginLeft: "1rem", color: "#ff5c5c" }}>{item.debitFor || "--"}</div>],
        credit_for: [<div key={`${index}E`} className="man_text" style={{ color: '#2ED47A', marginLeft: '1rem' }}>{item.creditFor || "--"}</div>],
        // amount: [<div className="man_text" style={{marginLeft: '2rem'}}>{item.amount > 0 ? '$ ': ''}{item.amount !== 0 ? item.amount : ''}</div>],
        type: [<div key={`${index}F`} className="notification_alignment" style={{ color: `${item.type === "CREDIT" ? "#2ed47a" : "#ff5c5c"}` }}>{item.type}</div>],
        description: [<div key={`${index}G`} className="notification_alignment">{item.description.replace("_","")}</div>],
      });
    });
    return walletData;
  };


  return (
    <React.Fragment>
      <div className="user_inner_partts email_partts">
        <React.Fragment>
          <Row>
            <Col md={4} sm={5}>
              <div className="nft_inner adjust_card">
                <LoadingOverlay active={loading} spinner={<PulseLoader color="white" size={10} />}>
                  <div className="nft_inner_inner adjust_card">
                    <h3>Main Balance</h3>
                    <h2>${" "}{gettingWallet[0]?.walletId?.balance || 0}</h2>
                  </div>
                </LoadingOverlay>
              </div>
            </Col>
            <Col md={4} sm={7}>
              <div className="nft_inner adjust_card">
                <LoadingOverlay active={loading} spinner={<PulseLoader color="white" size={10} />}>
                  <div className="nft_inner_inner adjust_card">
                    <h3>Debit Pending Wallet Balance</h3>
                    <h2>${" "}{gettingWallet[0]?.walletId?.debitPending || 0}</h2>
                  </div>
                </LoadingOverlay>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="nft_inner adjust_card">
                <LoadingOverlay active={loading} spinner={<PulseLoader color="white" size={10} />}>
                  <div className="nft_inner_inner adjust_card">
                    <h3>Credit Pending Wallet Balance</h3>
                    <h2>${" "}{gettingWallet[0]?.walletId?.creditPending || 0}</h2>
                  </div>
                </LoadingOverlay>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="usermaageleft">

                <div className="manage_heading_search style">
                  <h2>Wallet Transaction List</h2>
                </div>

                <div className="manage_table">
                  <div className="table-responsive">
                    {walletDetails.data?.length > 0 ?
                      <LoadingOverlay
                        active={loading}
                        spinner={<PulseLoader color="white" size={10} />}
                      >
                        <DataTable
                          className="hypTable"
                          columns={columns}
                          data={getWallet()}
                          responsive
                          customStyles={customStyles}
                          defaultSortField="id"
                          defaultSortAsc={false}
                          progressPending={loading.dtable}
                          noDataComponent={<CustomLoader />}
                          progressComponent={<EmptyDataLoader />}
                        />
                      </LoadingOverlay> : <EmptyDataLoader />}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      </div>
    </React.Fragment>
  )
}

const columns = [
  // {
  //   name: "Nfts Purchaser",
  //   selector: (row) => row.nfts_purchaser,
  //   // reorder: true,
  //   width: "150px",
  // },
  {
    name: "Transaction Date",
    selector: (row) => row.transactionDate,
    // reorder: true,
    width: "160px",
  },
  {
    name: "Debit/Credit Balance",
    selector: (row) => row.debit,
    // reorder: true,
    width: "200px",
  },
  {
    name: "Debit For",
    selector: (row) => row.debit_for,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Credit For",
    selector: (row) => row.credit_for,
    reorder: true,
    width: "125px",
  },
  // {
  //   name: "Amount",
  //   selector: (row) => row.amount,
  // reorder: true,
  //   width: "125px",
  // },
  {
    name: "Type",
    selector: (row) => row.type,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Description",
    selector: (row) => row.description,
    // reorder: true,
    width: "300px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: "10px",
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
    },
  },
};

export default ViewWallet;