import React, { useState, useEffect } from "react";

import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "../../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import "./manageproduct.css";

function ManageProductAbout({ setKey, setProduct }) {
  const token = useSelector((state) => state.setToken);
  const [options, setOptions] = useState([]);
  let [prodDT, setProdDT] = useState({
    name: "",
    category: "",
    categoryId: "",
    sportsId: "",
    desc: "",
    sports: "",
    gender: "",
  });
  
  const [aboutProductErr, setAboutProductErr] = useState({});
  
  const validate = () => {
    let isValidate = [];
    
    // Product Name Error
    if(prodDT.name === ""){
      setAboutProductErr((prevState) => ({...prevState, nameErr: "Product Name is Required"}));
      isValidate.push(false);
    }else{
      setAboutProductErr((prevState) => ({...prevState, nameErr: ""}));
      isValidate.push(true);
    }
    // Product Description Error
    if(prodDT.desc === ""){
      setAboutProductErr((prevState) => ({...prevState, descriptionErr: "Product Description is Required"}));
      isValidate.push(false);
    }else{
      setAboutProductErr((prevState) => ({...prevState, descriptionErr: ""}));
      isValidate.push(true);
    }
    // Category Error
    if(prodDT.category === ""){
      setAboutProductErr((prevState) => ({...prevState, categoryErr: "Category is Required"}));
      isValidate.push(false);
    }else if(prodDT.category === "Select"){
      setAboutProductErr((prevState) => ({...prevState, categoryErr: "Category is Required"}));
      isValidate.push(false);
    }else{
      setAboutProductErr((prevState) => ({...prevState, categoryErr: ""}));
      isValidate.push(true);
    }
    // Gender Error
    if(prodDT.gender === ""){
      setAboutProductErr((prevState) => ({...prevState, genderErr: "Gender is Required"}));
      isValidate.push(false);
    }else{
      setAboutProductErr((prevState) => ({...prevState, genderErr: ""}));
      isValidate.push(true);
    }
    // Sports Error
    if(prodDT.sports === ""){
      setAboutProductErr((prevState) => ({...prevState, sportsErr: "Sports is Required"}));
      isValidate.push(false);
    }else if(prodDT.sports === "Select"){
      setAboutProductErr((prevState) => ({...prevState, sportsErr: "Sports is Required"}));
      isValidate.push(false);
    }else{
      setAboutProductErr((prevState) => ({...prevState, sportsErr: ""}));
      isValidate.push(true);
    }
    
    if(isValidate.includes(false))
    {
        return false
    }else
    {
        return true;
    }
  }
  
  const clearError = () => {
    setAboutProductErr({
        nameErr: "", descriptionErr: "", categoryErr: "", genderErr: "", sportsErr: ""
    })
}
  
  const getProductOptions = async () => {
    await axios
      .get(
        `categorical-options`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log("fundraiser res....", res.data);
        if (res.data.success === true) {
          setOptions(res.data.data);
          //   setPagination(res.data.pagination);
          //   setNfts(res.data.allFundRaisers);
          //   setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const showState = (e) => {
    e.preventDefault();
    
    let valid = validate();
    // console.log("prodDT", prodDT);
    // setProduct(prodDT);
    if(valid){
      console.log(".............Inside validation", prodDT);
      setProduct((prevState) => ({
        ...prevState,
        name: prodDT.name,
        categoryId: prodDT.categoryId,
        description: prodDT.desc,
  
        sportId: prodDT.sportsId,
        customer_gender: prodDT.gender,
      }));
      setKey("variety");
    }else{
      console.log("product About.... else part error......")
    }
  };

  useEffect(() => {
    clearError();
    getProductOptions();
  }, []);
  let keys = Object.keys(options);
  let values = Object.values(options);
  console.log("keys", keys);
  console.log("keys values", values);
  return (
    <div>
      <div className="dash_partts">
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    className="text_box"
                    type="text"
                    placeholder="Product Name"
                    value={prodDT.name}
                    onChange={(e) => {
                      setProdDT((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                      if(!e.target.value){
                        aboutProductErr.nameErr = "Product Name is Required"
                      }else{
                        aboutProductErr.nameErr = "";
                      }
                      setAboutProductErr(aboutProductErr);
                    }}
                  />
                  <p className="errorMessage">{aboutProductErr.nameErr}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product Description</Form.Label>
                  <input
                    className="text_box"
                    type="text"
                    placeholder="Product Name"
                    value={prodDT.desc}
                    onChange={(e) =>{
                      setProdDT((prevState) => ({
                        ...prevState,
                        desc: e.target.value,
                      }))
                      if(!e.target.value){
                        aboutProductErr.descriptionErr = "Product Description is Required"
                      }else{
                        aboutProductErr.descriptionErr = "";
                      }
                      setAboutProductErr(aboutProductErr);
                    }}
                  />
                  <p className="errorMessage">{aboutProductErr.descriptionErr}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Categories</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    // value={prodDT.category}
                    onChange={(e) =>{
                      setProdDT((prevState) => ({
                        ...prevState,
                        category: e.target.value,
                        categoryId: e.target.value,
                      }))
                      if(!e.target.value){
                        aboutProductErr.categoryErr = "Category is Required"
                      }else{
                        aboutProductErr.categoryErr = "";
                      }
                      setAboutProductErr(aboutProductErr);
                    }}
                  >
                    <option>Select</option>
                    {values.length > 0 &&
                      values[1].map((value, idx) => {
                        return (
                          <option key={idx} value={value._id}>
                            {" "}
                            {value.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <p className="errorMessage">{aboutProductErr.categoryErr}</p>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    name="gender"
                    // value={prodDT.gender}
                    onChange={(e) =>{
                      setProdDT((prevState) => ({
                        ...prevState,
                        gender: e.target.value,
                      }))
                      if(!e.target.value){
                        aboutProductErr.genderErr = "Gender is Required"
                      }else{
                        aboutProductErr.genderErr = "";
                      }
                      setAboutProductErr(aboutProductErr);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="trans">Trans</option>
                    {/* {values.length > 0 &&
                      values[1].map((value, idx) => {
                        return <option key={idx}> {value.name}</option>;
                      })} */}
                  </Form.Select>
                  <p className="errorMessage">{aboutProductErr.genderErr}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Sports</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    // value={prodDT.sports}
                    onChange={(e) => {
                      console.log("event", e);

                      setProdDT((prevState) => ({
                        ...prevState,
                        sports: e.target.value,
                        sportsId: e.target.value,
                      }))
                      if(!e.target.value){
                        aboutProductErr.sportsErr = "Sports is Required"
                      }else{
                        aboutProductErr.sportsErr = "";
                      }
                      setAboutProductErr(aboutProductErr);
                    }}
                  >
                    <option>Select</option>
                    {values.length > 0 &&
                      values[0].map((value, idx) => {
                        return (
                          <option key={value._id} value={value._id}>
                            {" "}
                            {value.name}
                          </option>
                        );
                      })}
                    {/* <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option> */}
                  </Form.Select>
                  <p className="errorMessage">{aboutProductErr.sportsErr}</p>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Color</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    value={prodDT.color}
                    onChange={(e) =>
                      setProdDT((prevState) => ({
                        ...prevState,
                        color: e.target.value,
                      }))
                    }
                  >
                    <option>Select</option>
                    {values.length > 0 &&
                      values[2].color &&
                      values[2].color[0].datasets.map((color, idx) => {
                        // console.log("size", values[0].size);
                        return <option key={idx}>{color}</option>;
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <Form.Label>{keys[2]}</Form.Label> */}
            {/* <Form.Label>Size</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    value={prodDT.size}
                    onChange={(e) =>
                      setProdDT((prevState) => ({
                        ...prevState,
                        size: e.target.value,
                      }))
                    }
                  >
                    <option>Select</option>

                    {values.length > 0 &&
                      values[2].size &&
                      values[2].size[0].datasets.map((size, idx) => {
                        // console.log("size", values[0].size);
                        return <option key={idx}>{size}</option>;
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col md={12} className="text-center">
                <Form.Group
                  className="mb-3 pt-5 pb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Button
                    variant="primary"
                    // type="Save"
                    className="my_button"
                    onClick={showState}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ManageProductAbout;
