import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
} from "react-bootstrap";
import axios from "../../axios/axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import { ProfileT_Img, Like1_Img } from "../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import "./storydetails.css";

function StoryDetailsPage() {
  const { id } = useParams();

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);

  const getStoryDetails = async () => {
    // setLoading(prevState => ({...prevState, profile:true}));
    await axios
      .get(`/get-story-by-id/${id}`)
      .then((res) => {
        if (res.data.success === true) {
          console.log("story details", res.data);

          if (res.data.story !== null) {
            setProfile(res.data.story);
            setLoading(false);
          } else {
            setProfile(null);
            // setLoading(prevState => ({...prevState, profile:false}));
          }
        } else {
          message.error("Server error");
        }
      })
      .catch((err) => {
        console.log("story details err", err);
      });
  };

  useEffect(() => {
    getStoryDetails();
  }, []);
  return (<>
    <div>
      <LoadingOverlay
        //   className="t_userers_loading"
        active={loading}
        spinner={<PulseLoader color="white" size={50} />}
      >
        <div className="story_details_row dash_partts">
          <Row>
            <Col lg="12" md="12">
              <div className="s_details_left">
                <div className="s_details_left_inner">
                  <div className="sdetails_left_img">
                    {profile?.images?.length > 0 && (
                    <img src={profile.images[0]?.imageUrl} />
                  )}
                  </div>
                  <div className="s_details_right">
                    <h3>{profile.name}</h3>
                    <p>{profile.description}</p>
                    <div className="created_date">
                      <p>
                        <span>Date Created:</span>{" "}
                        {moment(profile.createdAt).format("MM-DD-YYYY")}
                      </p>
                    </div>
                    {/* <div className="created_date">
                    <div className="reaction_part">
                      <p className="react_heading">
                        <span>Reaction:</span>
                      </p>
                      <div className="like_dislike">
                        <div className="like_part">
                          <div className="inner_like">
                            <img src={Like1_Img} />
                          </div>
                          <span>  {profile.reacts?.find(i => i.reactName="like")?.totalReacts}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div className="created_date">
                      {profile.tournament != "" && (
                        <p>
                          <span>Tournament:</span>
                          {profile.tournament}
                        </p>
                      )}
                    </div>
                    <div className="created_date another_font">
                      <p>
                        <span>Creator:</span>
                        {profile.userId && profile.userId.name}
                      </p>
                    </div>
                    <div className="created_date another_font">
                      <p>
                        <span>Nick Name:</span>
                        {profile.userId && `${profile.userId.nickName.fName} ${profile.userId.nickName.lName}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col lg="6" md="6">
                        <div className="s_details_right_parttts">
                            <Form.Group className="mb-3 storyfield_part" controlId="formBasicEmail">
                                <Form.Label>Story Name</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>
                        </div>
                    </Col> */}
          </Row>
        </div>
      </LoadingOverlay>
    </div>
    <div className="dash_partts">
      <div className="nft_collect_body">
        <LoadingOverlay
          active={loading}
          spinner={<PulseLoader color="white" />}
        >
          <div className="nft_collect_inner_body nft_collect_inner_body_story">
            {profile?.images?.map((item, index) => {
              return (

                <div className="nft_collct_inner_body_part" key={index}>
                  <div className="nft_collect_inner_partts">
                    <div className="nft_collect_top_img">
                      <img src={item.imageUrl} alt="No Img" />
                    </div>

                  </div>
                </div>

              );
            })}
          </div>
        </LoadingOverlay>
      </div>
    </div>
  </>);
}

export default StoryDetailsPage;
