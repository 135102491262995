import React from "react";
import Papaparse from "papaparse";
import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "../../axios/axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const fields = ["Sl No.", "Name", "Username", "Email", "Phone No.", "Status"];
const badgesFields = ["Sl No", "name", "Divisions Badges", "Locations Badges", "Sports Badges"];
const storyFields = ["Sl No.", "Name", "Status", "Creacted Date", "React"];
const nftFields = ["Sl No.", "Name of NFTs", "NFTs Author", "NFTs price ($)", "Edition", "Status"];
const nftPurchase = ["Sl No.", "NFT Title", "NFT Price ($)", "Seller Name", "Buyer Name", "Invoice No."];
const merchandiseOrderFields = ["SL No.", "Name", "Ordered_Id", "Amount", "Quantity"];
const merchandiseFields = ["Sl No", "name", "Product Id", "Description", "Gender"];
const fundRaisersFields = ["Sl No.", "Author", "Reasons", "Created", "Expiry", "Target Amount ($)", "Status"];

const hideCsvpaths = ["/", "/dashboard"];

const ReportDownload = (props) => {
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();

  const chartDashExp = async () => {
    setLoading(true);
    let input = window.document.getElementById("dashPDF");
    // html2canvas((input), { logging: true, letterRendering: 1, allowTaint: false, crossOrigin: true}).then((canvas) => {
    // await html2canvas((input), { backgroundColor: "none", logging: true, letterRendering: 1, useCORS: true}).then((canvas) => {
    await html2canvas(input, { logging: true, letterRendering: 1, allowTaint: true, useCORS: true }).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      let pdf = new jsPDF("landscape", "pt");
      pdf.addImage(image, "png", 17, 17, 810, 560);
      pdf.save("HYPEU_DASHBOARD_CHARTS.pdf");
      setLoading(false);
      // html2canvas(input).then((canvas) => {
      //   const image = canvas.toDataURL("image/png", 1.0);
      //   let pdf = new jsPDF("landscape", "pt");
      //   pdf.addImage(image, "png", 17, 17, 810, 560);
      //   pdf.save("HYPEU_DASHBOARD_CHARTS.pdf");
      //   setLoading(false);
    });

    //   let input = window.document.getElementById("dashboardCount");
    //   // const pdf = new jsPDF(4, "pt");
    //   html2canvas(input).then((canvas) => {
    //     const image = canvas.toDataURL("image/png", 1.0);
    //     let pdf = new jsPDF("landscape", "pt");
    //     pdf.addImage(image, "png", 15, 15, 800, 130);
    //     // let input1 = window.document.getElementById("dashPDF");
    //     // html2canvas(input1).then((canvas) => {
    //     //   const image = canvas.toDataURL("image/png", 1.0);
    //     //   pdf.addImage(image, "png", 17, 17, 810, 160);
    //     // })
    //     // pdf.save("HYPEU_DASHBOARD_CHARTS.pdf");
    //     // setLoading(false);
    //     dashCount(pdf);
    //   });
  };

  // const dashCount = (pdf) => {
  //   let input1 = window.document.getElementById("dashPDF");
  //   html2canvas(input1).then((canvas) => {
  //     const image = canvas.toDataURL("image/png", 1.0);
  //     pdf.addImage(image, "png", 17, 17, 810, 160);
  //   })
  //   pdf.save("HYPEU_DASHBOARD_CHARTS.pdf");
  //     setLoading(false);
  // }

  const ExpUserCsv = () => {
    setLoading(true);
    axios
      .get(
        `/get-users/?limit=${props.totalUsercount}&search=${props.totalSearch}`
      )
      .then((resp) => {
        let csv = Papaparse.unparse({
          data: resp.data.users,
          fields: props.Csvfields,
        });
        let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "HYPEU_USERS_LIST.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "HYPEU_USERS_LIST.csv");
        tempLink.click();
        setLoading(false);
      });
  };

  const merchandiseProductCsv = () => {
    setLoading(true);
    axios
      .get(
        `/all-products/?limit=${props.totalProductCount}`
      )
      .then((resp) => {
        let csv = Papaparse.unparse({
          data: resp.data.data,
          fields: props.Csvfields,
        });
        let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "products.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "products.csv");
        tempLink.click();
        setLoading(false);
      });
  };

  const merchandiseProductPdf = () => {
    setLoading(true);
    axios
      .get(`/all-products/?limit=${props.totalProductCount}`)
      .then((resp) => {

        const fieldsRows = [];
        resp.data.data.map((val, ind) => {
          const merchandiseData = [
            ind + 1,
            val.name,
            val.product_id,
            val.description,
            val.customer_gender,
          ];
          fieldsRows.push(merchandiseData);
        });
        let doc = jsPDF();
        doc.autoTable(merchandiseFields, fieldsRows, {
          theme: "grid",
          margin: [10, 10, 40, 10],
        });
        doc.save("HYPEU_PRODUCTS.pdf");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const MerchandiseOrderCsv = () => {
    setLoading(true);
    axios
      .get(
        `/get-orders/?limit=${props.totalOrderCount}`
      )
      .then((resp) => {
        // let csv = Papaparse.unparse({
        //   data: resp.data.allOrders,
        //   fields: props.Csvfields,
        // });
        let csv = Papaparse.unparse(
          resp.data.allOrders.map((val, index) => {
            return {
              "Order Id": val.orderId,
              "Name": val.user_id?.name,
              "Amount": val.amount,
              "Quantity": val.quantity,
            }
          })
        );
        let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "HYPEU_MERCHANDISE_ORDER.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "HYPEU_MERCHANDISE_ORDER.csv");
        tempLink.click();
        setLoading(false);
      });
  };

  const merchandiseOrderPdf = () => {
    setLoading(true);
    axios
      .get(`/get-orders/?limit=${props.totalOrderCount}`)
      .then((resp) => {

        const fieldsRows = [];
        resp.data.allOrders.map((data, ind) => {
          const merchandiseOrderData = [
            ind + 1,
            data.user_id?.name,
            data.orderId,
            data.amount,
            data.quantity,
          ];
          fieldsRows.push(merchandiseOrderData);
        });
        let doc = jsPDF();
        doc.autoTable(merchandiseOrderFields, fieldsRows, {
          theme: "grid",
          margin: [10, 10, 40, 10],
        });
        doc.save("HYPEU_MERCHANDISE_ORDER.pdf");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const ExpStoryCsv = () => {
    setLoading(true);
    axios.get("/all-story-count").then((resp) => {
      let csv = Papaparse.unparse({
        data: resp.data.detailStories,
        fields: props.Csvfields,
      });
      let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, "HYPEU_STORY.csv");
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "HYPEU_STORY.csv");
      tempLink.click();
      setLoading(false);
    });
  };

  const ExpNftCsv = () => {
    setLoading(true);
    axios.get(`/all-nfts/?limit=${props.totalNftCount}`).then((res) => {

      let csv = Papaparse.unparse(
        res.data.allNfts.map((val, index) => {
          return {
            "Title": val.title,
            "Position": val.position,
            "Team": val.team,
            "NFTs Author": val.creator?.name,
            "NFTs Amount": val.prices?.amount,
            "Editions": val.editions,
          }
        })
      );

      let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, "HYPEU_NFT.csv");
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "HYPEU_NFT.csv");
      tempLink.click();
      setLoading(false);
    });
  };

  const fundRaisersCsv = () => {
    setLoading(true);
    axios
      .get(`/fund-raisers/?limit=${props.totalFundRaisersCount}`)
      .then((resp) => {
        let csv = Papaparse.unparse(
          resp.data.allFundRaisers.map((val, index) => {
            return {
              "Name": val.userId?.name,
              "Reason": val.reason,
              "Created": val.createdAt,
              "Expiry": val.expireDate,
              "Amount": val.amount,
              "Status": val.status,
            }
          })
        );

        let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "fundraisers.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "fundraisers.csv");
        tempLink.click();
        setLoading(false);
      });
  };

  const ExpNftPurchaseCsv = () => {
    setLoading(true);
    axios
      .get(
        `/get-nft-purchase-details/?limit=${props.totalNftPurchasedCount}&search=${props.totalSearch}`
      )
      .then((res) => {
        // let csv = Papaparse.unparse({
        //   data: res.data.NftPurchased,
        //   fields: props.Csvfields,
        // });
        let csv = Papaparse.unparse(
          res.data.NftPurchased.map((val, index) => {
            return {
              "title": val?.nft?.title,
              "paymentId": val?.payment?.paymentId,
              "price": val?.price,
              "sellerDetails_Name": val?.sellerDetails?.name,
              "buyerDetails_Name": val?.buyerDetails?.name,
              "mode": val?.mode
            }
          })
        );
        let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "HYPEU_NFTPURCHASE.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "HYPEU_NFTPURCHASE.csv");
        tempLink.click();
        setLoading(false);
      });
  };

  const expBadgesExpCsv = () => {
    setLoading(true);
    axios.get(`/get-badges/?limit=${props.totalRows}`).then((resp) => {
      // console.log(".......////badgData",resp.data.userDetails)
      let csv = Papaparse.unparse({
        data: resp.data.userDetails,
        fields: props.Csvfields,
      });
      let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, "HYPEU_BADGES.csv");
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "HYPEU_BADGES.csv");
      tempLink.click();
      setLoading(false);
    });
  };

  const expBadgesExpPdf = () => {
    setLoading(true);
    axios.get(`/get-badges/?limit=${props.totalRows}`).then((resp) => {
      const fieldsRows = [];

      resp.data.userDetails.map((val, ind) => {
        return fieldsRows.push([ind + 1, val?.name, val?.divisionsBadges, val?.locationsBadges, val?.sportsBadges,]);
      });

      let doc = jsPDF();
      doc.autoTable(badgesFields, fieldsRows, { theme: "grid", margin: [10, 10, 40, 10], });
      doc.save("HYPEU_BADGES.pdf");
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.log("err", err);
    });
  };

  const csvExp = () => {
    if (props.type === "user") {
      ExpUserCsv();
    } else if (props.type === "story") {
      ExpStoryCsv();
    } else if (props.type === "nft") {
      ExpNftCsv();
    } else if (props.type === "badges") {
      expBadgesExpCsv();
    } else if (props.type === "nftPurchase") {
      ExpNftPurchaseCsv();
    } else if (props.type === "merchandiseProduct") {
      merchandiseProductCsv();
    } else if (props.type === "fundRaisers") {
      fundRaisersCsv();
    } else if (props.type === "merchandiseOrder") {
      MerchandiseOrderCsv();
    }
  };

  const pdfExp = () => {
    if (props.type === "user") {
      pdfExpUser();
    } else if (props.type === "story") {
      pdfExpStory();
    } else if (props.type === "badges") {
      expBadgesExpPdf();
    } else if (props.type === "nft") {
      pdfExpNft();
    } else if (props.type === "nftPurchase") {
      pdfExpNftPurchase();
    } else if (props.type === "merchandiseOrder") {
      merchandiseOrderPdf();
    } else if (props.type === "merchandiseProduct") {
      merchandiseProductPdf();
    } else if (props.type === "fundRaisers") {
      expFundRaisersPdf();
    } else {
      chartDashExp();
    }
  };

  const pdfExpUser = () => {
    setLoading(true);
    let input = window.document.getElementById("userPDF");
    const pdf = new jsPDF(4, "pt");
    html2canvas(input).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      pdf.addImage(image, "png", 34, 17, 380, 110); // dimension of image 1.Margin-left 2.Margin-top 3.Length 4.Height
      let input1 = window.document.getElementById("userPDFChart");
      html2canvas(input1).then((canvas) => {
        const image = canvas.toDataURL("image/png", 1.0);
        pdf.addImage(image, "png", 430, -2, 130, 130);
        addUserTable(pdf);
      });
    });
  };

  const addUserTable = (pdf) => {
    axios
      .get(
        `/get-users/?limit=${props.totalUsercount}&search=${props.totalSearch}`
      )
      .then((resp) => {
        const fieldsRows = [];
        resp.data.users.map((val, ind) => {
          const userData = [
            ind + 1,
            val.name,
            val.userName,
            val.email,
            val.phone,
            val.status === true ? "Active" : "Inactive",
          ];
          fieldsRows.push(userData);
        });
        pdf.autoTable(fields, fieldsRows, {
          theme: "grid",
          margin: [150, 10, 40, 10],
        });
        pdf.save("HYPEU_USERS_LIST.pdf");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const pdfExpStory = () => {
    setLoading(true);
    let input = window.document.getElementById("storyPDF");
    const pdf = new jsPDF(4, "pt");
    html2canvas(input).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      pdf.addImage(image, "png", 30, 17, 300, 80);
      addStoryTable(pdf);
    });
  };

  const addStoryTable = (pdf) => {
    console.log(props.totalStory)
    axios.get(`/get-stories/?limit=${props.totalStory}&page=1`).then((resp) => {
      const fieldsRows = [];
      resp.data.allStories.map((val, ind) => {
        return fieldsRows.push([ind + 1, val.name, val.status === true ? "Active" : "Inactive", val.createdAt, val.reacts?.find(i => i.reactName = "like")?.totalReacts]);
      });

      pdf.autoTable(storyFields, fieldsRows, { theme: "grid", margin: [150, 10, 40, 10], });

      pdf.save("HYPEU_STORY.pdf");

      setLoading(false);

    }).catch((err) => {
      console.log("err", err);
    });
  };

  const pdfExpNft = () => {
    setLoading(true);
    let input = window.document.getElementById("nftPDF");
    const pdf = new jsPDF(4, "pt");
    html2canvas(input).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      pdf.addImage(image, "png", 34, 17, 510, 115); // dimension of image 1.Margin-left 2.Margin-top 3.Length 4.Height
      addNftTable(pdf);
    });
  };

  const addNftTable = (pdf) => {
    axios.get(`/all-nfts/?limit=${props.totalNftCount}`).then((res) => {
      const fieldsRows = [];
      res.data.allNfts.map((val, ind) => {
        return fieldsRows.push([ind + 1, val?.title, val?.creator?.name, val?.prices?.amount, val?.editions, val?.activeStatus]);
      });

      pdf.autoTable(nftFields, fieldsRows, { theme: "grid", margin: [150, 10, 40, 10], });
      pdf.save("HYPEU_NFT.pdf");
      setLoading(false);

    }).catch((err) => {
      console.log("err", err);
    });
  };

  const expFundRaisersPdf = () => {
    setLoading(true);
    let input = window.document.getElementById("fundRaisersPdf");
    const pdf = new jsPDF(4, "pt");
    html2canvas(input).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      pdf.addImage(image, "png", 34, 17, 510, 115); // dimension of image 1.Margin-left 2.Margin-top 3.Length 4.Height
      fundRaisersTable(pdf);
    });
  };

  const fundRaisersTable = (pdf) => {
    axios
      .get(`/fund-raisers/?limit=${props.totalFundRaisersCount}`)
      .then((res) => {
        const fundRaisersRow = [];
        res.data.allFundRaisers.map((val, ind) => {
          const fundRaiserData = [
            ind + 1,
            val.userId?.name,
            val.reason,
            val.createdAt,
            val.expireDate,
            val.amount,
            val.status
          ];
          fundRaisersRow.push(fundRaiserData);
        });
        pdf.autoTable(fundRaisersFields, fundRaisersRow, {
          theme: "grid",
          margin: [150, 10, 40, 10],
        });
        pdf.save("fundraisers.pdf");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const pdfExpNftPurchase = () => {
    setLoading(true);
    let input = window.document.getElementById("nftPurchasePDF");
    const pdf = new jsPDF(4, "pt");
    html2canvas(input).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      pdf.addImage(image, "png", 34, 17, 510, 110); // dimension of image 1.Margin-left 2.Margin-top 3.Length 4.Height
      NftPurchaseTable(pdf);
    });
  };
  const NftPurchaseTable = (pdf) => {
    axios
      .get(`/get-nft-purchase-details/?limit=${props.totalNftPurchasedCount}&search=${props.totalSearch}`)
      .then((res) => {
        const fieldsRows = [];
        res.data.NftPurchased.map((val, ind) => {
          const nftPurchaseData = [
            ind + 1,
            val.nft.title,
            val.price,
            val.sellerDetails.name,
            val.buyerDetails.name,
            val.payment.paymentId
          ];
          fieldsRows.push(nftPurchaseData);
        });
        pdf.autoTable(nftPurchase, fieldsRows, {
          theme: "grid",
          margin: [150, 10, 40, 10],
        });
        pdf.save("HYPEU_NFTPURCHASE.pdf");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="download_report">
        <LoadingOverlay
          className="download_loading"
          active={loading}
          spinner={
            <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
          }
        >
          <Dropdown className="drop_partts121">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              Download Report
            </Dropdown.Toggle>

            <Dropdown.Menu className="default-padding" style={{ textAlign: "center" }}>
              <Dropdown.Item onClick={() => pdfExp()}>
                Download pdf
              </Dropdown.Item>
              {hideCsvpaths.includes(location.pathname) ? null : (
                <Dropdown.Item onClick={() => csvExp()}>
                  Download csv
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </LoadingOverlay>
      </div>
    </>
  );
};

export default ReportDownload;
