import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../axios/axios';
import DataTable from 'react-data-table-component';
import "./globalSearch.css";
import { Noimg_Img } from '../../assets/images';
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import { BounceLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const GlobalSearchResult = () => {
    const history = useHistory();
    const {search} = useParams();
    console.log("params data...", search);
    
    const [searchData, setSearchData] = useState([]);
    const [limit, setLimit] = useState(4);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    
    const getData = async () => {
        setLoading(true);
        await axios.get(`/get-search-details/?limit=${limit}&page=${page}&search=${search}`)
        .then((res) => {
            console.log("search result data....", res.data);
            // console.log("users data search results....", res.data.data.users);
            if (res.data.success === true) {
                setTotalRows(res.data.pagination);
                setSearchData(res.data.data);
                setLoading(false);
            } else {
                console.log("error");
                setLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }
    useEffect(() => {
        getData();
    }, [page, limit, search]);
    
    const getUsersSearch = () => {
        if(searchData?.users){
            let usersSearchDetails = [];
            searchData?.users?.map((item, index) => {
                usersSearchDetails.push({
                    name: <div className="man_text adjust">{item.name}</div>,
                    username: <div className="man_text adjust">{item.userName}</div>,
                    email: <div className="man_text adjust">{item.email}</div>,
                });
            });
            return usersSearchDetails;
        }
    };
    const getUsersLevelSearch = () => {
        if(searchData?.usersLevel){
            let usersLevelSearch = [];
            searchData?.usersLevel?.map((item, index) => {
                usersLevelSearch.push({
                    name: <div className="man_text">{item.user_id?.name}</div>,
                    level: <div className="man_text">{item.level}</div>,
                    nftSaleCount: <div className="man_text">{item.nftSaleCount}</div>,
                    nftSaleEarn: <div className="man_text">{item.nftSaleEarn}</div>,
                    levelPercentage: <div className="man_text">{item.levelPercentage} %</div>,
                });
            });
            return usersLevelSearch;
        }
    };
    const getStorySearch = () => {
        if(searchData?.allStories){
            let storySearchDetails = [];
            searchData?.allStories?.map((item, index) => {
                storySearchDetails.push({
                    name: <div className="man_text">{item.name}</div>,
                    tournament: <div className="man_text">{item.tournament}</div>,
                    description: <div className="man_text">{item.description}</div>,
                });
            });
            return storySearchDetails;
        }
    };
    const getNftSearch = () => {
        if(searchData?.nftDetails){
            let nftSearchDetails = [];
            searchData?.nftDetails?.map((item, index) => {
                nftSearchDetails.push({
                    name: <div className="man_text">{item.title}</div>,
                    team: <div className="man_text">{item.team}</div>,
                    position: <div className="man_text">{item.position}</div>,
                    description: <div className="man_text">{item.description}</div>,
                });
            });
            return nftSearchDetails;
        }
    };
    const getMakeOfferSearch = () => {
        if(searchData?.makeOffers){
            let makeOfferDetails = [];
            searchData?.makeOffers?.map((item, index) => {
                makeOfferDetails.push({
                    name: <div className="man_text adjust">{item.nft?.title}</div>,
                    offerPrice: <div className="man_text adjust">{item.offerPrice}</div>,
                    status: <div className="man_text adjust">{item.status}</div>,
                });
            });
            return makeOfferDetails;
        }
    };
    const getNftPurchaseSearch = () => {
        if(searchData?.nftPurchaseDetails){
            let nftPurchaseData = [];
            searchData?.nftPurchaseDetails?.map((item, index) => {
                nftPurchaseData.push({
                    // title: <div className="man_text adjust">{item.nft?.title}</div>,
                    title:  [
                        <div className="management_list_name nft_title">
                            <div className="man_user"><img src={item.nft?.fileUrl} alt="nft" /></div>
                            <div className="man_text">{item.nft?.title}</div>
                        </div>
                        ],
                    price: <div className="man_text">{item.price}</div>,
                    payment_method: <div className="man_text" style={{marginLeft: "1.2rem"}}>{item.payment?.payment_method}</div>,
                    description: <div className="man_text adjust">{item.description}</div>,
                });
            });
            return nftPurchaseData;
        }
    };
    const getPayoutSearch = () => {
        if(searchData?.getPayout){
            let usersPayoutDetails = [];
            searchData?.getPayout?.map((item, index) => {
                usersPayoutDetails.push({
                    name: <div className="man_text">{item.user_id?.name}</div>,
                    bank_name: <div className="man_text">{item.bankId?.bank_name}</div>,
                    amount: <div className="man_text" style={{marginLeft: "1rem"}}>{item.amount}</div>,
                    status: <div className="man_text">{item.status}</div>,
                });
            });
            return usersPayoutDetails;
        }
    };
    const getFundRaisersSearch = () => {
        if(searchData?.fundRaisers){
            let fundRaiserDetails = [];
            searchData?.fundRaisers?.map((item, index) => {
                fundRaiserDetails.push({
                    name: <div className="man_text">{item.userId?.name}</div>,
                    amount: <div className="man_text">{item.amount}</div>,
                    percentEarned: <div className="man_text" style={{marginLeft: "2rem"}}>{item.percentEarned} %</div>,
                    reason: <div className="man_text">{item.reason}</div>,
                    status: <div className="man_text">{item.status}</div>,
                });
            });
            return fundRaiserDetails;
        }
    };
    const getPromoCodeSearch = () => {
        if(searchData?.promoCode){
            let promoCodeDetails = [];
            searchData?.promoCode?.map((item, index) => {
                promoCodeDetails.push({
                    name: <div className="man_text">{item.name}</div>,
                    code: <div className="man_text">{item.code}</div>,
                    maxUse: <div className="man_text" style={{marginLeft: "2rem"}}>{item.maxUse}</div>,
                    perUserMaxUse: <div className="man_text">{item.perUserMaxUse}</div>,
                    status: <div className="man_text">{item.status === true ? "Active" : "Inactive"}</div>,
                });
            });
            return promoCodeDetails;
        }
    };
    const getUsagePromoCodeDetails = () => {
        if(searchData?.getUsagePromoCode){
            let promoCodeDetails = [];
            searchData?.getUsagePromoCode?.map((item, index) => {
                promoCodeDetails.push({
                    code: <div className="man_text">{item.promoId?.code}</div>,
                    appliedOnAmount: <div className="man_text">{item.appliedOnAmount}</div>,
                    discountAmount: <div className="man_text" style={{marginLeft: "2rem"}}>{item.discountAmount}</div>,
                    paidAmount: <div className="man_text">{item.paidAmount}</div>,
                });
            });
            return promoCodeDetails;
        }
    };
    const notificationLogs = () => {
        if(searchData?.getNotificationLogs){
            let getNotificationLogDetails = [];
            searchData?.getNotificationLogs?.map((item, index) => {
                getNotificationLogDetails.push({
                    name: <div className="man_text">{item.receiver?.name}</div>,
                    title: <div className="man_text">{item.title}</div>,
                    content: <div className="man_text" dangerouslySetInnerHTML={{__html: item.content}}></div>,
                });
            });
            return getNotificationLogDetails;
        }
    };
    const getFeedback = () => {
        if(searchData?.feedback){
            let getFeedbackDetails = [];
            searchData?.feedback?.map((item, index) => {
                getFeedbackDetails.push({
                    name: <div className="man_text">{item.userId?.name}</div>,
                    rating: (
                        <div className="management_list_name">
                          <div className="man_text">
                            <ReactStars
                              count= {item?.rating ? item?.rating : 0}
                              color= "#FFD700"
                              // activeColor= "white"
                              edit= {false}   // "stars" is selected b'z of  edit: true
                              size= {20}
                              isHalf= {true}   // for fraction value
                            />
                          </div>
                        </div>
                      ),
                    content: <div className="man_text">{item.content === "" ? "NO COMMENTED..." : item.content}</div>,
                });
            });
            return getFeedbackDetails;
        }
    };
    const get_mrd_banner = () => {
        if(searchData?.mrd_Banner){
            let getMerchandBanner = [];
            searchData?.mrd_Banner?.map((item, index) => {
                getMerchandBanner.push({
                    // name: <div className="man_text">{item.name}</div>,
                    name: (
                        <div className="management_list_name">
                          <div className="man_user story_user">
                            <img
                              alt={Noimg_Img}
                              src={item.imageUrl ? item.imageUrl : Noimg_Img}
                            />
                          </div>
                          <div className="man_text story_text">
                            <h4>{item.name}</h4>
                          </div>
                        </div>
                      ),
                    createdAt: <div className="man_text">{moment(item.createdAt).format("DD/MM/YYYY")}</div>,
                    active: <div className="man_text">{item.active === true ? "Active" : "Inactive"}</div>,
                });
            });
            return getMerchandBanner;
        }
    };
    const get_mrd_products = () => {
        if(searchData?.mrd_Products){
            let mrdProductDetails = [];
            searchData?.mrd_Products?.map((item, index) => {
                mrdProductDetails.push({
                    name: <div className="man_text">{item.name}</div>,
                    customer_gender: <div className="man_text">{item.customer_gender}</div>,
                    forSale: <div className="man_text">{item.forSale}</div>,
                    description: <div className="man_text">{item.description}</div>,
                });
            });
            return mrdProductDetails;
        }
    };
    const get_mrd_orders = () => {
        if(searchData?.mrd_Orders){
            let mrdOrdersDetails = [];
            searchData?.mrd_Orders?.map((item, index) => {
                mrdOrdersDetails.push({
                    name: <div className="man_text">{item.user_id?.name}</div>,
                    email: <div className="man_text">{item.user_id?.email}</div>,
                    quantity: <div className="man_text">{item.quantity}</div>,
                    amount: <div className="man_text">{item.amount}</div>,
                });
            });
            return mrdOrdersDetails;
        }
    };
    
    // const handlePageChange = (page) => {
    //     // console.log("handlePageChange", page);
    //     setLoading(true);
    //     setPage(page);
    // }
    // const handlePerRowsChange = (newPerPage, page) => {
    //     setLoading(true);
    //     // console.log("handlePerRowsChange newPage and page", newPerPage, page);
    //     setLimit(newPerPage);
    //     setPage(page);
    // }
    
    const CustomLoader = () => (
        <div style={{ padding: '15px', background: '#495A71', color:'#fff', width:'100%', fontSize:'40px', textAlign:'center', minHeight:'350px', lineHeight:'400px' }}>
        		<div>Loading...</div>
        	</div>
    );
    const EmptyDataLoader = () => (
        <div style={{ padding: '15px', background: '#495A71', color:'#fff', width:'100%', fontSize:'40px', textAlign:'center', minHeight:'350px', lineHeight:'400px' }}>
        		<div>No data found...</div>
        	</div>
    );
    
  return (
    <React.Fragment>
    {
        searchData?.users?.length !== 0 || searchData?.allStories?.length !== 0 || searchData?.nftDetails?.length !== 0 || searchData?.usersLevel?.length !== 0 || searchData?.makeOffers?.length !== 0 || 
        searchData?.nftPurchaseDetails?.length !== 0 || searchData?.getPayout?.length !== 0 || searchData?.fundRaisers?.length !== 0 || searchData?.promoCode?.length !== 0 || searchData?.getUsagePromoCode?.length !== 0 || 
        searchData?.getNotificationLogs?.length !== 0 || searchData?.feedback?.length || searchData?.mrd_Banner?.length !== 0 || searchData?.mrd_Products?.length !== 0  || searchData?.mrd_Orders?.length !== 0 ?
        <React.Fragment>
            
        <div className="dash_partts">
            <div className="dash_part_heading">
                <div className="dash_part_heading_left">
                    <h2>Search Results</h2>
                </div>
            </div>
        </div>
        
        <LoadingOverlay
        className="download_loading"
        active={loading}
        spinner={ <PulseLoader color="white" size={30} style={{ opacity: "40%" }} /> }
        // spinner={<BounceLoader color="white" />}
        >
            {
                searchData?.users?.length !== 0 && searchData?.users !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Users List</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={usersColumn}
                                    data={getUsersSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    // pagination
                                    // paginationServer
                                    // paginationTotalRows={totalRows?.usersCount_TotalRows}
                                    // paginationPerPage={limit}
                                    // paginationRowsPerPageOptions={[10, 20, 40]}
                                    // onChangeRowsPerPage={handlePerRowsChange}
                                    // onChangePage={handlePageChange}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/user')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.usersLevel?.length !== 0 && searchData?.usersLevel !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Users Level</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={usersLevelColumn}
                                    data={getUsersLevelSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/userlevel')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.allStories?.length !== 0 && searchData?.allStories !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Stories</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={storyColumn}
                                    data={getStorySearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/storyfeeds')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.nftDetails?.length !== 0 && searchData?.nftDetails !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>NFTs Details</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={nftColumn}
                                    data={getNftSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/nfts')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.makeOffers?.length !== 0 && searchData?.makeOffers !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Nfts Offered</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={makeOffersColumn}
                                    data={getMakeOfferSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/offer-list')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.nftPurchaseDetails?.length !== 0 && searchData?.nftPurchaseDetails !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>NFTs Purchase</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={makePurchaseColumn}
                                    data={getNftPurchaseSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/nftpurchase')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.getPayout?.length !== 0 && searchData?.getPayout !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>User's Payout</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={payoutColumn}
                                    data={getPayoutSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/users-payout')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.fundRaisers?.length !== 0 && searchData?.fundRaisers !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Fund Raisers</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={fundRaisersColumn}
                                    data={getFundRaisersSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/fund-raisers')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.promoCode?.length !== 0 && searchData?.promoCode !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Promo Codes</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={promoCodeColumn}
                                    data={getPromoCodeSearch()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/promo-code-list')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.getUsagePromoCode?.length !== 0 && searchData?.getUsagePromoCode !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Usages Promo Code</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={usagePromo}
                                    data={getUsagePromoCodeDetails()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/usages-promo-code')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.getNotificationLogs?.length !== 0 && searchData?.getNotificationLogs !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Notification Logs</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={notificationLog}
                                    data={notificationLogs()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/notification-log')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.feedback?.length !== 0 && searchData?.feedback !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Feedback Details</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={feedbackColumn}
                                    data={getFeedback()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/feedback')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.mrd_Banner?.length !== 0 && searchData?.mrd_Banner !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Banners List</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={mrd_Banner_Column}
                                    data={get_mrd_banner()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/banners')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.mrd_Products?.length !== 0 && searchData?.mrd_Products !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Products List</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={mrd_Product_Column}
                                    data={get_mrd_products()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/products')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
            {
                searchData?.mrd_Orders?.length !== 0 && searchData?.mrd_Orders !== undefined ? <React.Fragment>
                    <div className="user_management_list">
                        <div className='manage_heading_search'>
                            <h2>Orders List</h2>
                        </div>
                        <div className="manage_table">
                            <div className="table-responsive">
                                <DataTable
                                    className="hypTable"
                                    columns={mrd_Orders_Column}
                                    data={get_mrd_orders()}
                                    responsive
                                    customStyles={customStyles}
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    noDataComponent={<EmptyDataLoader/>}
                                    progressComponent={<CustomLoader />}
                                    progressPending={loading}
                                />
                            </div>
                        </div>
                        <div className="more_button_div">
                            <button onClick={() => history.push('/orders')} className="more_button">More...</button>
                        </div>
                    </div>
                </React.Fragment> : ""
            }
        </LoadingOverlay>
        
        </React.Fragment> :
        <React.Fragment>
            <h2 className="text-center text-white mt-5">Sorry! There is No Match. Try Again...</h2>
        </React.Fragment>
    }
    </React.Fragment>
  )
}

export default GlobalSearchResult;

const usersColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "250px",
      },
      {
        name: "Username",
        selector: (row) => row.username,
        // reorder: true,
        width: "250px",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        // reorder: true,
        width: "250px",
      },
    ];
const storyColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Tournament",
        selector: (row) => row.tournament,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Description",
        selector: (row) => row.description,
        // reorder: true,
        width: "350px",
        },
    ];
const nftColumn = [
    {
        name: "Name of NFTs",
        selector: (row) => row.name,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Team",
        selector: (row) => row.team,
        // reorder: true,
        width: "150px",
        },
        {
        name: "Position",
        selector: (row) => row.position,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Description",
        selector: (row) => row.description,
        // reorder: true,
        width: "250px",
        },
    ];
const usersLevelColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Level",
        selector: (row) => row.level,
        // reorder: true,
        width: "100px",
        },
        {
        name: "NFT Sale Count",
        selector: (row) => row.nftSaleCount,
        // reorder: true,
        width: "150px",
        },
        {
        name: "NFT Sale Earn",
        selector: (row) => row.nftSaleEarn,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Level Percent",
        selector: (row) => row.levelPercentage,
        // reorder: true,
        width: "250px",
        },
    ];
const makeOffersColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Offer Price",
        selector: (row) => row.offerPrice,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Status",
        selector: (row) => row.status,
        // reorder: true,
        width: "250px",
        },
    ];
const makePurchaseColumn = [
    {
        name: "NFT Title",
        selector: (row) => row.title,
        // reorder: true,
        width: "225px",
        },
        {
        name: "Price",
        selector: (row) => row.price,
        // reorder: true,
        width: "150px",
        },
        {
        name: "Payment Method",
        selector: (row) => row.payment_method,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Description",
        selector: (row) => row.description,
        // reorder: true,
        width: "300px",
        },
    ];
const payoutColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Bank Name",
        selector: (row) => row.bank_name,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Amount",
        selector: (row) => row.amount,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Status",
        selector: (row) => row.status,
        // reorder: true,
        width: "250px",
        },
    ];
const fundRaisersColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "150px",
        },
        {
        name: "Amount",
        selector: (row) => row.amount,
        // reorder: true,
        width: "150px",
        },
        {
        name: "Earned Percent",
        selector: (row) => row.percentEarned,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Reason",
        selector: (row) => row.reason,
        // reorder: true,
        width: "200px",
        },
        {
        name: "status",
        selector: (row) => row.status,
        // reorder: true,
        width: "200px",
        },
    ];
const promoCodeColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Promo Code",
        selector: (row) => row.code,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Maximum Use",
        selector: (row) => row.maxUse,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Per User Max Use",
        selector: (row) => row.perUserMaxUse,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Status",
        selector: (row) => row.status,
        // reorder: true,
        width: "150px",
        },
    ];
const usagePromo = [
    {
        name: "Promo Code",
        selector: (row) => row.code,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Discount Applied On",
        selector: (row) => row.appliedOnAmount,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Discount Amount",
        selector: (row) => row.discountAmount,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Paid Amount",
        selector: (row) => row.paidAmount,
        // reorder: true,
        width: "250px",
        },
    ];
const notificationLog = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Title",
        selector: (row) => row.title,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Content",
        selector: (row) => row.content,
        // reorder: true,
        width: "300px",
        },
    ];
const feedbackColumn = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Rating",
        selector: (row) => row.rating,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Comment",
        selector: (row) => row.content,
        // reorder: true,
        width: "300px",
        },
    ];
const mrd_Banner_Column = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "300px",
        },
        {
        name: "Date Created",
        selector: (row) => row.createdAt,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Status",
        selector: (row) => row.active,
        // reorder: true,
        width: "250px",
        },
    ];
const mrd_Product_Column = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Customer Gender",
        selector: (row) => row.customer_gender,
        // reorder: true,
        width: "200px",
        },
        {
        name: "For Sale",
        selector: (row) => row.forSale,
        // reorder: true,
        width: "250px",
        },
        {
        name: "Description",
        selector: (row) => row.description,
        // reorder: true,
        width: "300px",
        },
    ];
const mrd_Orders_Column = [
    {
        name: "Name",
        selector: (row) => row.name,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Email",
        selector: (row) => row.email,
        // reorder: true,
        width: "200px",
        },
        {
        name: "Quantity",
        selector: (row) => row.quantity,
        // reorder: true,
        width: "150px",
        },
        {
        name: "Amount",
        selector: (row) => row.amount,
        // reorder: true,
        width: "200px",
        },
    ];
    
const customStyles = {
    rows: {
        style: {
            //minHeight: '72px',
            backgroundColor: 'transparent',
            color:'#fff',
            paddingLeft: '10px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '1.5rem', // override the cell padding for head cells
            paddingRight: '2px',
            backgroundColor: 'transparent',
            color:'#fff',
            fontSize: "17px",
        },
    },
    cells: {
        style: {
            paddingLeft: '5px', // override the cell padding for data cells
            paddingRight: '2px',
            // paddingTop: '5px',
            // paddingBottom: '5px',
            backgroundColor: 'transparent',
            color:'#fff',
            fontSize: "14px",
        },
    },
};