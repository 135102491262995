import React, {useEffect, useState} from "react";

import { useParams,useLocation } from "react-router-dom";
import {
  Badges_1_Img,
  Badges_2_Img,
  Badges_3_Img,
  Badges_4_Img,
  Badges_5_Img,
  Badges_6_Img,
  Badges_7_Img,
  Badges_8_Img,
  Badges_9_Img,
  Badges_10_Img,
  Badges_11_Img,
  Badges_12_Img,
  Badges_13_Img,
  Badges_14_Img,
  Badges_15_Img,
  Badges_16_Img,
  Badges_17_Img,
  Badges_18_Img,
  Badges_19_Img,
  Badges_20_Img,
  Badges_21_Img,
  Badges_22_Img,
  Badges_23_Img,
  Division_1_Img,
  Profw2_Img,
} from "../../assets/images";
import axios from "../../axios/axios";
import "./userbadges.css";
import {BounceLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';

function UserBadges() {

    const { userId } = useParams();
    const location = useLocation();

    const { pic, name } = location.state;

    const [badges, setBadges] = useState([]);
    const [loading, setLoading] = useState(false)

    const getBadges = async() => {
        setLoading(true)
        console.log(userId);
        axios.get(`/get-badges-by-userId/${userId}`)
        .then( res => {
            console.log(res);
            if(res.data.success === true) {
                setBadges(res.data.badges)
                setLoading(false)
            } else {
                console.log("error");
                setLoading(false);
            }
        })
        .catch( err =>{
            console.log(err)
        })
    }

    useEffect(()=>{
        getBadges();
    },[])
    
    return (
        <div >
        <div className="dash_partts">
        <div className="dash_part_heading user_badge_heading">
          <div className="dash_part_heading_left">
            <h2>Badges</h2>
          </div>
          {/* <div className="nft_drop">
            <Dropdown className="drop_partts121">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                Download Report
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          <div className="ind_user_badges">
                <div className="user_image_badge">
                    <img src={pic} />
                </div>
                <h2>{name}</h2>
          </div>
        </div>
        
        <div className="badge_listss">
            <div className="badge_listing_inner_partts">
                <div className="badges_list_heading">
                    <h3>Sport Badges</h3>
                    {/* <div className="addnew_badge_partts">
                        <a href="#" className="new_badge1">Add New Badge</a>
                    </div> */}
                </div>
                <LoadingOverlay active={loading}>
                <div className="badges_inner_lists">
                    <ul>
                        {badges?.sportsBadges?.map((item,i)=>{
                            return(
                                <>
                                    <li>
                                        <div className="badge_listtting"><img src={item.lightBadges} /></div>
                                    </li>
                                </>
                            )
                        })
                        }
                    </ul>
                </div>
                </LoadingOverlay>
            </div>

            
            <div className="badge_listing_inner_partts">
                <div className="badges_list_heading">
                    <h3>Division Badges</h3>
                    {/* <div className="addnew_badge_partts">
                        <a href="#" className="new_badge1">Add New Badge</a>
                    </div> */}
                </div>
                <LoadingOverlay active={loading}>
                <div className="badges_inner_lists">
                    <ul>
                    {badges?.divisionBadges?.map((item,i)=>{
                            return(
                                <>
                                    <li>
                                        <div className="badge_listtting"><img src={item.lightBadges} /></div>
                                    </li>
                                </>
                            )
                        })
                    }
                    </ul>
                </div>
                </LoadingOverlay>
            </div>


            <div className="badge_listing_inner_partts">
                <div className="badges_list_heading">
                    <h3>State Badges</h3>
                    {/* <div className="addnew_badge_partts">
                        <a href="#" className="new_badge1">Add New Badge</a>
                    </div> */}
                </div>
                <LoadingOverlay active={loading}>
                <div className="badges_inner_lists">
                    <ul>
                    {badges?.stateBadges?.map((item,i)=>{
                            return(
                                <>
                                    <li>
                                        <div className="badge_listtting"><img src={item.lightBadges} /></div>
                                    </li>
                                </>
                            )
                        })
                    }
                    </ul>
                </div>
                </LoadingOverlay>
            </div>



            {/* <div className="badge_listing_inner_partts">
                <div className="badges_list_heading">
                    <h3>Other Badges</h3>
                </div>
                <div className="badges_inner_lists">
                    <ul>
                    <li><div className="badge_listtting"><img src={Badges_1_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_2_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_3_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_4_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_5_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_6_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_7_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_8_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_9_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_10_Img} /></div></li>

                    <li><div className="badge_listtting"><img src={Badges_11_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_12_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_13_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_14_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_15_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_16_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_17_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_18_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_19_Img} /></div></li>
                     <li><div className="badge_listtting"><img src={Badges_20_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_21_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_22_Img} /></div></li>
                    <li><div className="badge_listtting"><img src={Badges_23_Img} /></div></li>
                    </ul>
                </div>
            </div> */}
        </div>
        
        </div>
    </div>
        );
    };   
export default UserBadges;