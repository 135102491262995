// import React, { useState } from "react";
// import { Row, Col, Form, Modal, Button, Tabs, Tab } from "react-bootstrap";
// import "./varietys.css";
// import { ChromePicker } from "react-color";
// import { useEffect } from "react";
// import axios from "../../../axios/axios";
// import { WithContext as ReactTags } from "react-tag-input";
// import { message } from "antd";

// const KeyCodes = {
//   comma: 188,
//   enter: 13,
// };
// const delimiters = [KeyCodes.comma, KeyCodes.enter];

// const Variety = () => {
//   let placeholder = "Tag Input";

//   // const [color, setColor] = useState("#ffff");
//   const [color, setColor] = useState("");
//   const [showColorPicker, setShowColorPicker] = useState();

//   const initialValue = { desc: "", varietyId: "" };
//   const [formValues, setFormValues] = useState(initialValue);
//   const [formError, setFormError] = useState({});
//   const [isSubmit, setIsSubmit] = useState(false);

//   const [tags, setTags] = React.useState([]);
//   const [sizeTags, setSizeTags] = React.useState([]);
//   const [varietyData, setVarietysData] = useState([]);
//   const [varData, setVarData] = useState("")
//   console.log("././././././././",varData?.key)
  
//   const handleSizeDelete = (i) => {
//     setSizeTags(sizeTags.filter((tag, index) => index !== i));
//   };

//   const handleSizeAddition = (tag) => {
//     setSizeTags([...sizeTags, tag]);
//     // console.log(".../////", sizeTags);
//   };

//   const handleDrag = (tag, currPos, newPos) => {
//     const newTags = tags.slice();
//     newTags.splice(currPos, 1);
//     newTags.splice(newPos, 0, tag);
//     // re-render
//     setTags(newTags);
//   };

//   const handleTagClick = (index) => {
//     console.log("The tag at index " + index + " was clicked");
//   };
//   console.log("::::::::::::::", varietyData);
//   const getVarietysData = async () => {
//     await axios
//       .get(`/get-merchandise-varietys-groups`)
//       .then((res) => {
//         if (res.data.success === true) {
//           setVarietysData(res.data.data);
//         }
//       })
//       .catch((err) => {
//         console.log("err", err);
//       });
//   };

//   const handleChenge = (e) => {
//     const { name, value } = e.target;
//     let prevFormValue = formValues;
//     prevFormValue[name] = value;
//     setFormValues({ ...prevFormValue });
//     let varity = varietyData.find(varity=>varity._id == value)
//     setVarData(varity)
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setIsSubmit(true);
//     formValues.datasets = sizeTags.map((i) => {
//        return i.text
//       }) 
//     if (Object.keys(formError).length === 0) {
//       axios
//         .post("/add-merchandise-variety", { ...formValues })
//         .then(function (response) {
//           if (response.data.success === true) {
//             console.log(response);
//             message.success("Create successfully.");
//           } else {
//             console.log("error");
//             message.error("Create failed.");
//           }
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//     }
//   };

//   const validate = (val) => {
//     let errors = {};

//     if (!val.desc) {
//       errors.desc = "Name is required";
//     }
//     if (!val.varietyId) {
//       errors.varietyId = "Type is required";
//     }
//     if (!formValues.datasets) {
//       errors.datasets = "Tag Field is required";
//     }
//     return errors;
//   };

//   useEffect(() => {
//     getVarietysData();
//     setFormError(validate(formValues));
//   }, [formValues]);

//   return (
//     <div>
//       <h3 className="heading_set">Add New Varieties</h3>
//       <div className="dash_partts background allign">
//         <Row className="justify-content-center">
//           <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
//             <Row>
//               <Col md={12}>
//                 <Form.Group
//                   className="mb-3"
//                   controlId="exampleForm.ControlInput1"
//                 >
//                   <Form.Label>Name</Form.Label>
//                   <input
//                     className="text_box"
//                     type="text"
//                     placeholder="Name"
//                     name="desc"
//                     // value={formValues.desc}
//                     onChange={handleChenge}
//                   />
//                 </Form.Group>
//                 <p style={{ color: "red" }}>{isSubmit && formError.desc}</p>
//               </Col>
//             </Row>
//             <hr />
//             <Row>
//               <Col md={12}>
//                 <Form.Group
//                   className="mb-3"
//                   controlId="exampleForm.ControlInput1"
//                 >
//                   <Form.Label>Type</Form.Label>
//                   <Form.Select
//                     aria-label="Default select example"
//                     className="text_box"
//                     name="varietyId"
//                     onChange={handleChenge}
//                   >
//                     <option value={formValues.varietyId}>Select</option>
//                     {varietyData &&
//                       varietyData.map((val, idx) => {
//                         // console.log("........./", val.name);
//                         return (
//                           <option value={val._id} key={idx}>
//                             {val.name}
//                           </option>
//                         );
//                       })}
//                   </Form.Select>
//                 </Form.Group>
//                 <p style={{ color: "red" }}>
//                   {isSubmit && formError.varietyId}
//                 </p>
//               </Col>
//             </Row>
//             {formValues.varietyId == "63244f527fbe40441f765689" && (
//               <div>
//                 <Form.Label>Size</Form.Label>
//                 <ReactTags
//                   tags={sizeTags}
//                   delimiters={delimiters}
//                   handleDelete={handleSizeDelete}
//                   handleAddition={handleSizeAddition}
//                   handleDrag={handleDrag}
//                   handleTagClick={handleTagClick}
//                   inputFieldPosition="bottom"
//                   placeholder={placeholder}
//                   autocomplete
//                 />
//                 <p>Type Size and Press the Enter Button or Use Comma</p>
//                  <p style={{ color: "red" }}>{isSubmit && formError.datasets}</p>
//               </div>
//             )}
//             {formValues.varietyId == "6324500b7fbe40441f76568e" && (
//               <div>
//                 <Form.Label>Color</Form.Label>
//                 <ReactTags
//                   tags={sizeTags}
//                   delimiters={delimiters}
//                   handleDelete={handleSizeDelete}
//                   handleAddition={handleSizeAddition}
//                   handleDrag={handleDrag}
//                   handleTagClick={handleTagClick}
//                   inputFieldPosition="bottom"
//                   placeholder={placeholder}
//                   autocomplete
//                 />
                
//                 <p style={{ color: "red" }}>{isSubmit && formError.datasets}</p>
//                 <Button
//                   onClick={() =>
//                     setShowColorPicker((showColorPicker) => !showColorPicker)
//                   }
//                 >
//                   {showColorPicker ? "Close" : "Pick Color"}
//                 </Button>
//                 {showColorPicker && (
//                   <ChromePicker
//                     color={color}
//                     onChange={(updateColor) => {
//                       setColor(updateColor);
//                     }}
//                   />
//                 )}
//                 <p>Copy Hex Code and Drop the Tag Input Field then Press Enter</p>
//               </div>
//             )}

//             <Row>
//               <Col md={12} className="text-center">
//                 <Form.Group
//                   className="mb-3 pt-5 pb-2"
//                   controlId="exampleForm.ControlInput1"
//                 >
//                   <Button
//                     variant="primary"
//                     className="my_button"
//                     onClick={handleSubmit}
//                   >
//                     Submit
//                   </Button>
//                 </Form.Group>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default Variety;


import React, { useState } from "react";
import { Row, Col, Form, Modal, Button, Tabs, Tab } from "react-bootstrap";
import "./varietys.css";
import { ChromePicker } from "react-color";
import { useEffect } from "react";
import axios from "../../../axios/axios";
import { WithContext as ReactTags } from "react-tag-input";
import { message } from "antd";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Variety = () => {
  let placeholder = "Tag Input";

  // const [color, setColor] = useState("#ffff");
  const [color, setColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState();

  // const initialValue = { desc: "", varietyId: "" };
  // const [formValues, setFormValues] = useState(initialValue);
  
  const [varityData, setVarityData] = useState({
    desc: "",
    varietyId: "",
  })
  
  // const [formError, setFormError] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);

  const [tags, setTags] = React.useState([]);
  const [sizeTags, setSizeTags] = React.useState([]);
  const [getVarietyData, setGetVarietysData] = useState([]);
  // const [varData, setVarData] = useState("")
  // console.log("././././././././",varData?.key)
  
  // error validation
  const [varityErr, setVarityErr] = useState({});
  
  const varityValidation = () => {
    let isValid=[];
    
    if(varityData.desc === ""){
      setVarityErr((prevState) => ({...prevState, nameErr: "Field is Required"}));
      isValid.push(false);
    }else{
      setVarityErr((prevState) => ({...prevState, nameErr: ""}));
      isValid.push(true);
    }
    
    if(varityData.varietyId === ""){
      setVarityErr((prevState) => ({...prevState, typeErr: "Type is Required"}));
      isValid.push(false);
    }else{
      setVarityErr((prevState) => ({...prevState, typeErr: ""}));
      isValid.push(true);
    }
    if(sizeTags.length === 0){
      setVarityErr((prevState) => ({...prevState, tagErr: "Type Tag Field is Required"}));
      isValid.push(false);
    }else{
      setVarityErr((prevState) => ({...prevState, tagErr: ""}));
      isValid.push(true);
    }
    
    if(isValid.includes(false))
      {
          return false
      }
      else
      {
          return true;
      }
  }
  
  console.log("varity dataset.....", varityData.datasets);
  
  const clearError = () => {
    setVarityErr({
      nameErr: "",
      typeErr: "",
      tagErr: "",
    })
  }
  const clearData = () => {
    setVarityData({
      desc: "",
      varietyId: "",
    })
  }

  const handleSizeDelete = (i) => {
    setSizeTags(sizeTags.filter((tag, index) => index !== i));
  };

  const handleSizeAddition = (tag) => {
    setSizeTags([...sizeTags, tag]);
    // setVarityData({...varityData, datasets: tag})
    // console.log(".../////", sizeTags);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  
  // console.log("::::::::::::::", getVarietyData);
  
  const getVarietysData = async () => {
    await axios
      .get(`/get-merchandise-varietys-groups`)
      .then((res) => {
        console.log("get varity data.....", res.data);
        if (res.data.success === true) {
          setGetVarietysData(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChenge = (e) => {
  //   const { name, value } = e.target;
  //   let prevFormValue = formValues;
  //   prevFormValue[name] = value;
  //   setFormValues({ ...prevFormValue });
  //   let varity = getVarietyData.find(varity=>varity._id == value)
  //   setVarData(varity)
  };
  
    
  // let valid = varityValidation();
  // if(valid){
  //   varityData.datasets.textremove();
  // }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = varityValidation();
    console.log("validation data...", valid);
    
    // setIsSubmit(true);
    varityData.datasets = sizeTags.map((i) => {
      console.log("data of i....", i);
       return i.text
      }) 
    // if (Object.keys(formError).length === 0) {
      
    if(valid){
      axios
        .post("/add-merchandise-variety", { ...varityData })
        .then(function (response) {
          if (response.data.success === true) {
            console.log(response);
            message.success("Create successfully.");
            clearData();
            setSizeTags([]);
          } else {
            console.log("error");
            message.error("Create failed.");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    // }
  };

  // const validate = (val) => {
  //   let errors = {};

  //   if (!val.desc) {
  //     errors.desc = "Name is required";
  //   }
  //   if (!val.varietyId) {
  //     errors.varietyId = "Type is required";
  //   }
  //   if (!formValues.datasets) {
  //     errors.datasets = "Tag Field is required";
  //   }
  //   return errors;
  // };

  useEffect(() => {
    getVarietysData();
    clearError();
    // setFormError(validate(formValues));
  }, []);

  return (
    <div>
      <h3 className="heading_set">Add New Varieties</h3>
      <div className="dash_partts background allign">
        <Row className="justify-content-center">
          <Col lg="8" md="10" sm="12" className="ps-0 pe-0">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <input
                    className="text_box"
                    type="text"
                    placeholder="Name"
                    name="desc"
                    value={varityData.desc}
                    onChange={(e) => {
                      setVarityData({
                          ...varityData,
                          desc: e.target.value
                      })
                      if(!e.target.value){
                        varityErr.nameErr = "Name is Required"
                      }else{
                        varityErr.nameErr = "";
                      }
                      setVarityErr(varityErr);
                  }}
                  />
                </Form.Group>
                {/* <p style={{ color: "red" }}>{isSubmit && formError.desc}</p> */}
                <p style={{ color: "red" }}>{varityErr.nameErr}</p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className="text_box"
                    name="varietyId"
                    value={varityData.varietyId}
                    // onChange={handleChenge}
                    onChange={(e) => {
                      setVarityData({
                          ...varityData,
                          varietyId: e.target.value
                      })
                      if(!e.target.value){
                        varityErr.typeErr = "Type is Required"
                      }else{
                        varityErr.typeErr = "";
                      }
                      setVarityErr(varityErr);
                  }}
                  >
                    <option value="">Select</option>
                    {getVarietyData &&
                      getVarietyData.map((val, idx) => {
                        return (
                          <option value={val._id} key={idx}>
                            {val.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
                {console.log("........varity id", varityData.varietyId)}
                {/* <p style={{ color: "red" }}>
                  {isSubmit && formError.varietyId}
                </p> */}
                <p style={{ color: "red" }}>{varityErr.typeErr}</p>
              </Col>
            </Row>
            {varityData.varietyId === "63244f527fbe40441f765689" && (
              <div>
                <Form.Label>Size</Form.Label>
                <ReactTags
                  tags={sizeTags}
                  value={varityData.datasets}
                  delimiters={delimiters}
                  handleDelete={handleSizeDelete}
                  handleAddition={handleSizeAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  placeholder={placeholder}
                  autocomplete
                />
                <p>Type Size and Press the Enter Button or Use Comma</p>
                 {/* <p style={{ color: "red" }}>{isSubmit && formError.datasets}</p> */}
                 <p style={{ color: "red" }}>{varityErr.tagErr}</p>
                 </div>
            )}
            {varityData.varietyId === "6324500b7fbe40441f76568e" && (
              <div>
                <Form.Label>Color</Form.Label>
                <ReactTags
                  tags={sizeTags}
                  value={varityData.datasets}
                  delimiters={delimiters}
                  handleDelete={handleSizeDelete}
                  handleAddition={handleSizeAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  placeholder={placeholder}
                  autocomplete
                />
                
                {/* p style={{ color: "red" }}>{isSubmit && formError.datasets}</p>  */}
                <p style={{ color: "red" }}>{varityErr.tagErr}</p>
                <Button
                  onClick={() =>
                    setShowColorPicker((showColorPicker) => !showColorPicker)
                  }
                >
                  {showColorPicker ? "Close" : "Pick Color"}
                </Button>
                
                {showColorPicker && (
                  <ChromePicker
                    color={color}
                    onChange={(updateColor) => {
                      setColor(updateColor);
                    }}
                  />
                )}
                <p>Copy Hex Code and Paste in Input Tag Field then Press Enter</p>
              </div>
            )}

            <Row>
              <Col md={12} className="text-center">
                <Form.Group
                  className="mb-3 pt-5 pb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Button
                    variant="primary"
                    className="my_button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Variety;