import React, { useState, useEffect } from "react";
import axios from "../../axios/axios";
import message from "../../messages";
import {
    Row,
    Col,
    Form,
    Modal,
    Button,
} from "react-bootstrap";

import "./setting.css";
import SettingTable from "./SettingTable";
import SettingModal from "../modals/SettingModal";
import { BulbFilled } from "@ant-design/icons";

function Setting() {
    const [isToggled, setIsToggled] = useState(false);
    const [isActive, setActive] = useState(false);

    const [sandGetDetail, setSandGetDetail] = useState("");
    const [prodGetDetail, setProdGetDetail] = useState({})
    const [mode, setMode] = useState("");

    const getSandDetails = async () => {
        await axios.get(`get-mode-details`)
            .then((res) => {
                console.log("get mode details of setting....", res.data);
                // setSandGetDetail(res.data.details);
                setMode(res.data.details.mode);
                setIsToggled(res.data.details.mode === "production" ? true : false)
                setActive(res.data.details.mode === "production" ? true : false)
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const getSettingsDetails = async () => {
        await axios.get(`/get-settings`)
            .then((res) => {
                let result = res.data.details

                const sandbox = result.filter(res => res.mode === "sandbox")
                const production = result.filter(res => res.mode === "production")

                setSandGetDetail(sandbox[0]);
                setProdGetDetail(production[0])
            })
            .catch((error) => {
                console.log(error);
            })
    };

    useEffect(() => {
        getSettingsDetails()
    }, [])

    const changeMode = async () => {
        axios.post(`/change-mode`)
            .then((res) => {
                setMode(res.data.settingsDetails.mode);
                setSandGetDetail(res.data.settingsDetails)
            })
            .catch((error) => {
                console.log(error);
            })
    };

    useEffect(() => {
        getSandDetails();
    }, []);


    const onToggle = () => {
        setIsToggled(!isToggled);
        setActive(!isActive)
        changeMode()
    }

    const handleToggle = () => {
        // setActive(!isActive);
    };

    return (
        <div>
            <SettingModal />
            <div className="dash_partts">
                <Row>
                    <Col lg="2" md="12" sm="12">
                        <div className="setting_left_inner_partts">
                            <div className="setting_left_partts fix_design">
                                <Form.Label>Mail Login</Form.Label>
                                {/* <Form.Label>SMS Gateway</Form.Label> */}
                                <Form.Label>Stripe</Form.Label>
                                <Form.Label style={{ marginBottom: "8rem", marginTop: "280px" }}>Others</Form.Label>
                                <Form.Label style={{ marginBottom: "12.5rem", marginTop: "250px" }}>Emails</Form.Label>
                            </div>
                        </div>
                    </Col>
                    <Col lg="10" md="12" sm="12">
                        <div className="setting_right_partts">
                            <div className="sandbox_row">
                                <div className="sand_left">
                                    <div className="sand_box_partts">
                                        <h2>Sandbox</h2>
                                        <div className={isActive ? "setting_right_partts_inner settingsBlock" : "setting_active"} >
                                            <SettingTable sendData={sandGetDetail} getSettings={getSettingsDetails} />
                                        </div>
                                    </div>
                                </div>
                                <div className="sand_middle">
                                    <div className="toggle_switch">
                                        <label className="toggle-switch">
                                            <input type="checkbox" checked={isToggled} onChange={(evt) => onToggle(evt)} onClick={handleToggle} />
                                            <span className="switch" />
                                        </label>
                                    </div>
                                </div>
                                <div className="sand_right">
                                    <div className="prd_partts">
                                        <h2>Producton</h2>
                                        <div className={isActive ? "setting_active" : "setting_right_partts_inner settingsBlock"}>

                                            {/* { isToggled ? <SettingTable sendDate={sandGetDetail} /> : <SettingTable />}  */}

                                            <SettingTable sendData={prodGetDetail} getSettings={getSettingsDetails} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="note-container">
                <p style={{ fontSize: "17px" }}>
                    <div className="d-flex align-items-center">
                        <BulbFilled style={{ color: "#785ca0", fontSize: "25px" }} />
                        <span className="text-center" style={{ fontSize: "25px" }}>Requirement:</span>
                    </div>
                    <ul className="mt-2">
                        <li>
                            By switching application mode, Blockchain mode will be changed automatically.
                        </li>
                        <li>
                            If blockchain mode is not changed automatically, then use <strong>chia configure --testnet true</strong> to convert from PRODUCTION to SANDBOX and <strong>chia configure --testnet false</strong> to convert from SANDBOX to PRODUCTION.
                        </li>
                    </ul> </p>
            </div>
        </div>
    );
}

export default Setting;