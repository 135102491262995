import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import "./removeAccount.css";
import { Logo_img } from "../../assets/images";

import axios from "../../axios/axios";
import message from "../../messages";

const RemoveAccount = () => {
  const [creds, setCreds] = useState({ email: "", otp: "" });
  const [credsError, setCredsError] = useState({
    emailerr: "",
    otpErr: "",
  });
  const [otpGenerated,setOtpGenerated] = useState(false);
  const [loading,setLoading] = useState(false);
  

  const emailValidatior = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (creds.email.trim() === "") {
      setCredsError({ emailerr: "Email is required" });
      return false;
    } else if (reg.test(creds.email) === false) {
      setCredsError({ emailerr: "Email is invalid" });
      return false;
    } else {
      setCredsError({ emailerr: "" });
      return true;
    }
  };


  const handleChange = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };
  

  const handleLogin = async (e) => {
    e.preventDefault();
      if (emailValidatior() && !otpGenerated)
      {
        setLoading(true)
        

      
        await axios
        .post("/remove-account-otp", {email:creds.email})
        .then((res) => {
          console.log("res", res.data);
          if (res.data.success === true) {
            setOtpGenerated(true)
           
            message.success('Otp send successfully')
            
          } else {
            setOtpGenerated(false)
            message.error(res.data.message)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err);
        });
      }
      else if(otpGenerated)
      {
        setLoading(true)
        await axios
        .post("/remove-account", {email:creds.email,otp:creds.otp})
        .then((res) => {
          console.log("res", res.data);
          if (res.data.success === true) {
           
            message.success(res.data.message)
            setTimeout(()=>{
              window.location.reload()
            },4000)
            
            
          } else {
            message.error(res.data.message)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err);
        });
      }
  };

  return (
    <>
      
      <div className="login_box">
        <div className="login_left">
          <div className="login_left_inner">
            <div>
              <div className="login_logo text-center">
                <img src={Logo_img} alt="img" />
              </div>
              <div className="login_text">
             
              </div>
            </div>
          </div>
        </div>
        <div className="login_right">
          <div className="login_into_dashboard">
            <h2>Remove Account</h2>
            {/* <Alert key={"danger"} variant={"danger"} show={show.alert}>
              Wrong Credentials
            </Alert> */}
            <div className="login__right_form">
              <Form>
                {!otpGenerated && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <div className="field_img">
                      <Form.Control
                        name="email"
                        type="email"
                        value={creds.email}
                        //onChange={(e)=>setCreds({...creds, email:e.target.value})}
                        onChange={handleChange}
                        onBlur={emailValidatior}
                        className="field_part"
                      />
                    </div>
                    <p style={{ color: "red" }}>{credsError.emailerr}</p>
                  </Form.Group>
                )}


                {otpGenerated && (
                  <Form.Group className="mb-3" controlId="formBasicOtp">
                      <Form.Label>OTP</Form.Label>
                      <div className="field_img">
                        <Form.Control
                          name="otp"
                          type="text"
                          value={creds.otp}
                          //onChange={(e)=>setCreds({...creds, email:e.target.value})}
                          onChange={handleChange}
                          className="field_part"
                        />
                      </div>
                      <p style={{ color: "red" }}>{credsError.otperr}</p>
                    </Form.Group>
                  )}

                <Button
                  variant="primary"
                  type="submit"
                  className="dash_login_but"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {otpGenerated? 'Remove Account':'Send OTP'}
                  
                </Button>
               
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RemoveAccount;
