import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../axios/axios";
import message from "../../messages";
import Switch from "react-switch";
import {
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";
import "./promoCode.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Delete_img, Edit_img,level_setting,Managesearch_img, Maneye_img, Threedots_img } from "../../assets/images";


const AddPromoCode = () => {
    const history = useHistory();

    const [promoCode, setPromoCode] = useState({
        code:"",
        discount: {
            type: "",
            figures: {
                minAppliedAmount: "",
                maxDiscountPrice: "",
                discountAmount: "",
            }
        },
        start_date: new Date(),
        expire_date: new Date(),
        maxUse: "",
        name: "",
        perUserMaxUse: "",
        restrictPerUser: "",
        status: true,
    });
    
    const [promoErr, setPromoErr] = useState({});
    
    const promoCodeValidation = () => {
        let isValid=[];
        let format = /[ `!@#$%^&* ()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        
        // promoCode.name
        if(promoCode.name === ""){
            setPromoErr((prevState) => ({...prevState, nameErr: "Promo Name is Required"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, nameErr: ""}));
            isValid.push(true);
        }
        // promoCode.code
        if(promoCode.code === ""){
            setPromoErr((prevState) => ({...prevState, codeErr: "Promo Code is Required"}));
            isValid.push(false);
        }else if (format.test(`${promoCode.code}`)){
            setPromoErr((prevState) => ({...prevState, codeErr: "Special Character is not allowed"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, codeErr: ""}));
            isValid.push(true);
        }
        // promoCode.discount.type
        if(promoCode.discount.type === ""){
            setPromoErr((prevState) => ({...prevState, discountTypeErr: "Discount Type is Required"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, discountTypeErr: ""}));
            isValid.push(true);
        }
        // promoCode.discount.figures.minAppliedAmount
        if(promoCode.discount.figures.minAppliedAmount === ""){
            setPromoErr((prevState) => ({...prevState, minAppliedAmountErr: "Min Discount is Required"}));
            isValid.push(false);
        }else if(promoCode.discount.figures.minAppliedAmount < 0){
            setPromoErr((prevState) => ({...prevState, minAppliedAmountErr: "Invalid input"}));
            isValid.push(false);
        }else if(promoCode.discount.figures.minAppliedAmount < 10){
            setPromoErr((prevState) => ({...prevState, minAppliedAmountErr: "Min Discount at least 10"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, minAppliedAmountErr: ""}));
            isValid.push(true);
        }
        // promoCode.discount.figures.maxDiscountPrice
        if(promoCode.discount.type === "percantage"){
            if(promoCode.discount.figures.maxDiscountPrice === ""){
                setPromoErr((prevState) => ({...prevState, maxDiscountPriceErr: "Max Discount is Required"}));
                isValid.push(false);
            }else if(promoCode.discount.figures.maxDiscountPrice < 0){
                setPromoErr((prevState) => ({...prevState, maxDiscountPriceErr: "Invalid input"}));
                isValid.push(false);
            }else if(promoCode.discount.figures.maxDiscountPrice < 10){
                setPromoErr((prevState) => ({...prevState, maxDiscountPriceErr: "Max Discount at least 10"}));
                isValid.push(false);
            }else {
                setPromoErr((prevState) => ({...prevState, maxDiscountPriceErr: ""}));
                isValid.push(true);
            }
        }
        // promoCode.discount.figures.discountAmount
        if(promoCode.discount.figures.discountAmount === ""){
            setPromoErr((prevState) => ({...prevState, discountAmountErr: "Discount is Required"}));
            isValid.push(false);
        }else if(promoCode.discount.figures.discountAmount < 0){
            setPromoErr((prevState) => ({...prevState, discountAmountErr: "Invalid input"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, discountAmountErr: ""}));
            isValid.push(true);
        }
        // promoCode.maxUse
        if(promoCode.maxUse === ""){
            setPromoErr((prevState) => ({...prevState, maxUseErr: "promo max use is required"}));
            isValid.push(false);
        }else if(promoCode.maxUse < 1){
            setPromoErr((prevState) => ({...prevState, maxUseErr: "Invalid input"}));
            isValid.push(false);
        }else if(promoCode.maxUse < 50){
            setPromoErr((prevState) => ({...prevState, maxUseErr: "Minimum Max Use Need 50"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, maxUseErr: ""}));
            isValid.push(true);
        }
        // promoCode.perUserMaxUse
        if(promoCode.restrictPerUser === "true"){
            if(promoCode.perUserMaxUse === ""){
                setPromoErr((prevState) => ({...prevState, perUserMaxUseErr: "per user max use is required"}));
                isValid.push(false);
            }else if(promoCode.perUserMaxUse < 1){
                setPromoErr((prevState) => ({...prevState, perUserMaxUseErr: "Invalid input"}));
                isValid.push(false);
            }else {
                setPromoErr((prevState) => ({...prevState, perUserMaxUseErr: ""}));
                isValid.push(true);
            }
        }
        // promoCode.restrictPerUser
        if(promoCode.restrictPerUser === ""){
            setPromoErr((prevState) => ({...prevState, restrictPerUserErr: "promo use restriction is required"}));
            isValid.push(false);
        }else if(promoCode.restrictPerUser < 0){
            setPromoErr((prevState) => ({...prevState, restrictPerUserErr: "Invalid input"}));
            isValid.push(false);
        }else {
            setPromoErr((prevState) => ({...prevState, restrictPerUserErr: ""}));
            isValid.push(true);
        }
        
        if(isValid.includes(false))
        {
            return false
        }
        else
        {
            return true;
        }
    }
    
    const clearError = () => {
        setPromoErr({
            nameErr: "", codeErr: "", discountTypeErr: "", minAppliedAmountErr: "", discountAmountErr: "", maxUseErr: "",
            restrictPerUserErr: "", perUserMaxUseErr: "", userRestrictionType: "", statusErr: ""
        })
    }
    
    useEffect(() => {
        clearError();
    }, []);
      
      const addNewPromoCode = async (e) => {
        e.preventDefault();
        let valid = promoCodeValidation();
        
        if(valid){
            // console.log("Added data....", promoCode);
            let dataurl = `/add-promo-codes`
            await axios.post(dataurl, promoCode)
            .then((res) => {
                if(res.data.success === true) {
                    // console.log("level-promoCode response...", res.data);
                    message.success("Update successfully.");
                    history.push('/promo-code-list');
                } else {
                    console.log("error");
                    message.error("Update fail.");
                }
            })
            .catch((error) => {
                console.log("level-promoCode update error", error);
            });
        }

        console.log("Else part of validation... Error...",);
      };
      
      const setStartDate = (startDate) => {
        setPromoCode({
            ...promoCode,
            start_date: startDate
        })
      }
      const setExpireDate = (expireDate) => {
        setPromoCode({
            ...promoCode,
            expire_date: expireDate
        })
      }
      
    return (
        <React.Fragment>
            <div className="dash_partts">
                <Row>
                    <Col lg="12" md="12" sm="12" className="ps-0 pe-0">
                    <div className="setting_left_inner_partts">
                    <h2>Add Promo Code</h2>
                        <div className="levelsetting">
                            <h5>Promo Code</h5>
                            
                        <Form>
                            <Row>
                                <Col md={3}></Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Promo Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="text_box"
                                            placeholder="Promo_Name"
                                            name="name"
                                            onChange={(e) => {
                                                setPromoCode({
                                                    ...promoCode,
                                                    name: e.target.value
                                                })
                                                if(!e.target.value){
                                                    promoErr.nameErr = "Promo Name is Required"
                                                }else{
                                                    promoErr.nameErr = "";
                                                }
                                                setPromoErr(promoErr);
                                            }}
                                        />
                                        <p className="errorMessage">{promoErr.nameErr}</p>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Promo Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="text_box"
                                                placeholder="Promo_Code"
                                                name="code"
                                                onChange={(e) => {
                                                setPromoCode({
                                                    ...promoCode,
                                                    code: e.target.value
                                                })
                                                if(!e.target.value){
                                                    promoErr.codeErr = "Promo Code is Required"
                                                }else{
                                                    promoErr.codeErr = "";
                                                }
                                                setPromoErr(promoErr);
                                            }}
                                            />
                                            <p className="errorMessage">{promoErr.codeErr}</p>
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                                
                            <div className="seperate_section">
                                <h5>Promo Valid</h5>
                                <Row className="mail-first-row">
                                    <Col md={3}></Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Start Date</Form.Label>
                                            <DatePicker
                                                className="text_box"
                                                selected={promoCode.start_date}
                                                onChange={setStartDate}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="Set Time"
                                                dateFormat="d MMMM yyyy, h:mm aa"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Expire Date</Form.Label>
                                                <DatePicker
                                                    className="text_box"
                                                    type="date"
                                                    selected={promoCode.expire_date}
                                                    onChange={setExpireDate}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={10}
                                                    timeCaption="Set Time"
                                                    dateFormat="d MMMM yyyy, h:mm aa"
                                                    minDate={promoCode.start_date !== "" && new Date(promoCode.start_date) }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                                
                            <Row className="mb-2 pt-3 pb-3">
                                <Col md={12} className="text-center main_head">Discount</Col>
                                <div className="mb-3"></div>
                            <Row className="mail-first-row">
                                <Col md={3}>
                                <Form.Label>Discount Type</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    className="text_box"
                                    name="type"
                                    onChange={(e) => {
                                        setPromoCode({
                                            ...promoCode,
                                            discount: {
                                                ...promoCode?.discount,
                                                type: e.target.value
                                            }
                                        })
                                        if(!e.target.value){
                                            promoErr.discountTypeErr = "Discount Type is Required"
                                        }else{
                                            promoErr.discountTypeErr = "";
                                        }
                                        setPromoErr(promoErr);
                                    }}
                                >
                                    <option value="">Select</option>
                                    <option value="amount">Amount</option>
                                    <option value="percantage">Percentage (%)</option>
                                </Form.Select>
                                <p className="errorMessage">{promoErr.discountTypeErr}</p>
                                </Col>
                                {
                                    promoCode.discount.type === "amount" ?
                                    <React.Fragment>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Minimum Amount</Form.Label>
                                                <Form.Control
                                                type="number"
                                                className="text_box percentageInput"
                                                placeholder="minimum_amount"
                                                name="minAppliedAmount"
                                                onChange={(e) => {
                                                    let {value} = e.target;
                                                    setPromoCode({
                                                        ...promoCode,
                                                        discount: {
                                                            ...promoCode?.discount,
                                                            figures: {
                                                                ...promoCode.discount.figures,
                                                                minAppliedAmount: value
                                                            }
                                                        }
                                                    })
                                                    if(!e.target.value){
                                                        promoErr.minAppliedAmountErr = "Min Discount is Required"
                                                    }else if(e.target.value < 0){
                                                        promoErr.minAppliedAmountErr = "Min Discount should greater then 10"
                                                    }else if(e.target.value < 10){
                                                        promoErr.minAppliedAmountErr = "Min Discount at least 10"
                                                    }else{
                                                        promoErr.minAppliedAmountErr = "";
                                                    }
                                                    setPromoErr(promoErr);
                                                }}
                                            />
                                            <p className="errorMessage">{promoErr.minAppliedAmountErr}</p>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control
                                                type="number"
                                                className="text_box"
                                                placeholder="discount"
                                                name="discountAmount"
                                                onChange={(e) => {
                                                    let {value} = e.target;
                                                    setPromoCode({
                                                        ...promoCode,
                                                        discount: {
                                                            ...promoCode?.discount,
                                                            figures: {
                                                                ...promoCode.discount.figures,
                                                                discountAmount: value
                                                            }
                                                        }
                                                    })
                                                    if(!e.target.value){
                                                        promoErr.discountAmountErr = "Discount is Required"
                                                    }else if(e.target.value < 0){
                                                        promoErr.discountAmountErr = "Invalid input"
                                                    }else{
                                                        promoErr.discountAmountErr = "";
                                                    }
                                                    setPromoErr(promoErr);
                                                }}
                                                />
                                                <p className="errorMessage">{promoErr.discountAmountErr}</p>
                                            </Form.Group>
                                        </Col>
                                    </React.Fragment> : promoCode.discount.type === "percantage" ?
                                    <React.Fragment>
                                        <Col md={3}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Minimum Amount</Form.Label>
                                            <Form.Control
                                            type="number"
                                            className="text_box percentageInput"
                                            placeholder="minimum_amount"
                                            name="minAppliedAmount"
                                            onChange={(e) => {
                                                let {value} = e.target;
                                                setPromoCode({
                                                    ...promoCode,
                                                    discount: {
                                                        ...promoCode?.discount,
                                                        figures: {
                                                            ...promoCode.discount.figures,
                                                            minAppliedAmount: value
                                                        }
                                                    }
                                                })
                                                if(!e.target.value){
                                                    promoErr.minAppliedAmountErr = "Min Discount is Required"
                                                }else if(e.target.value < 10){
                                                        promoErr.minAppliedAmountErr = "Min Discount at least 10"
                                                }else{
                                                    promoErr.minAppliedAmountErr = "";
                                                }
                                                setPromoErr(promoErr);
                                            }}
                                        />
                                        <p className="errorMessage">{promoErr.minAppliedAmountErr}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Maximum Amount</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    className="text_box percentageInput"
                                                    placeholder="maximum_amount"
                                                    name="maxAppliedAmount"
                                                    onChange={(e) => {
                                                         let {value} = e.target;
                                                        setPromoCode({
                                                            ...promoCode,
                                                            discount: {
                                                                ...promoCode?.discount,
                                                                figures: {
                                                                    ...promoCode?.discount?.figures,
                                                                    maxDiscountPrice: value
                                                                }
                                                                
                                                            }
                                                        })
                                                        if(!e.target.value){
                                                            promoErr.maxDiscountPriceErr = "Min Discount is Required"
                                                        }else if(e.target.value < 0){
                                                            promoErr.maxDiscountPriceErr = "Invalid input"
                                                        }else if(e.target.value < 10){
                                                            promoErr.maxDiscountPriceErr = "Max Discount at least 10"
                                                        }else{
                                                            promoErr.maxDiscountPriceErr = "";
                                                        }
                                                        setPromoErr(promoErr);
                                                    }}
                                                />
                                                <p className="errorMessage">{promoErr.maxDiscountPriceErr}</p>
                                            </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control
                                                type="number"
                                                className="text_box"
                                                placeholder="discount"
                                                name="discountAmount"
                                                onChange={(e) => {
                                                    let {value} = e.target;
                                                    setPromoCode({
                                                        ...promoCode,
                                                        discount: {
                                                            ...promoCode?.discount,
                                                            figures: {
                                                                ...promoCode.discount.figures,
                                                                discountAmount: value
                                                            }
                                                        }
                                                    })
                                                    if(!e.target.value){
                                                        promoErr.discountAmountErr = "Discount is Required"
                                                    }else if(e.target.value < 0){
                                                        promoErr.discountAmountErr = "Invalid input"
                                                    }else{
                                                        promoErr.discountAmountErr = "";
                                                    }
                                                    setPromoErr(promoErr);
                                                }}
                                                />
                                                <p className="errorMessage">{promoErr.discountAmountErr}</p>
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </React.Fragment> : ""
                                }
                                
                                </Row>
                            </Row>
                            
                            <div className="seperate_section">
                                <h5>Restricted Per User</h5>
                                <Row>
                                    <Col md={3}></Col>
                                    <Col md={9}>
                                        <Row>
                                            <Col md={4}>
                                                <br/>
                                                <label className="main_head">Maximum Use</label>
                                            </Col>
                                            <Col md={5}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <br />
                                                    <Form.Control 
                                                        type="number" 
                                                        placeholder="maximum_use"
                                                        className="text_box"
                                                        name="maxUse"
                                                        onChange={(e) => {
                                                            setPromoCode({
                                                                ...promoCode,
                                                                maxUse: e.target.value
                                                            })
                                                            if(!e.target.value){
                                                                promoErr.maxUseErr = "promo max use is required"
                                                            }else if(e.target.value < 1){
                                                                promoErr.maxUseErr = "Invalid input"
                                                            }else if(e.target.value < 50){
                                                                promoErr.maxUseErr = "Minimum Max Use Need 50"
                                                            }else{
                                                                promoErr.maxUseErr = "";
                                                            }
                                                            setPromoErr(promoErr);
                                                        }}
                                                    />
                                                    <p className="errorMessage">{promoErr.maxUseErr}</p>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Restricted Per User</Form.Label>
                                                        <Form.Select aria-label="Default select example"
                                                            className="text_box"
                                                            name="restrictPerUser"
                                                            onChange={(e) => {
                                                                setPromoCode({
                                                                    ...promoCode,
                                                                    restrictPerUser: e.target.value
                                                                })
                                                                if(!e.target.value){
                                                                    promoErr.restrictPerUserErr = "promo use restriction type is required"
                                                                }else{
                                                                    promoErr.restrictPerUserErr = "";
                                                                }
                                                                setPromoErr(promoErr);
                                                            }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="true">Yes</option>
                                                            <option value="false">No</option>
                                                        </Form.Select>
                                                        <p className="errorMessage">{promoErr.restrictPerUserErr}</p>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                            {
                                                promoCode.restrictPerUser === "true" ? 
                                                <React.Fragment> <div></div> </React.Fragment> : promoCode.restrictPerUser === "false" ?
                                                <React.Fragment>
                                                    <Col md={5}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <br />
                                                            <h5 className="promo_restriction">Promo code use no restriction</h5>
                                                        </Form.Group>
                                                    </Col>
                                                </React.Fragment> : ""
                                            }
                                        </Row>
                                        <Row>
                                        {
                                            promoCode.restrictPerUser === "true" ?
                                            <React.Fragment>
                                                <Col md={4}>
                                                    <br/>
                                                    <label className="main_head">Per User Max Use</label>
                                                </Col>
                                            </React.Fragment> : ""
                                        }
                                        <Col md={5}>
                                            <Form.Group className="mb-3" controlId="per_user_max_use">
                                            <br />
                                            {
                                                promoCode.restrictPerUser === "true" ? 
                                                <React.Fragment>
                                                    <Form.Control 
                                                        type="number" 
                                                        placeholder="per_user_max_use"
                                                        className="text_box"
                                                        name="perUserMaxUse"
                                                        onChange={(e) => {
                                                            setPromoCode({
                                                                ...promoCode,
                                                                perUserMaxUse: e.target.value
                                                            })
                                                            if(!e.target.value){
                                                                promoErr.perUserMaxUseErr = "per user max use is required"
                                                            }else if(e.target.value < 1){
                                                                promoErr.perUserMaxUseErr = "Invalid input"
                                                            }else{
                                                                promoErr.perUserMaxUseErr = "";
                                                            }
                                                            setPromoErr(promoErr);
                                                        }}
                                                    />
                                                    <p className="errorMessage">{promoErr.perUserMaxUseErr}</p>
                                                </React.Fragment> : ""
                                            }
                                            </Form.Group>
                                        </Col>
                                        </Row>
                                        
                                    </Col>
                                </Row>
                            </div>

                            <Form.Group className="mb-3 pt-5 pb-2" controlId="exampleForm.ControlInput1">
                                <Button variant="primary" type="submit" className="my_button" onClick={addNewPromoCode}>
                                    Submit
                                </Button>
                            </Form.Group>
                                
                        </Form>
                        </div>
                    </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
        );
    }
    
export default AddPromoCode;