import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";

import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { Maneye_img, Profw2_Img } from '../../assets/images';
import ReactStars from "react-rating-stars-component";
import CommentModel from '../modals/CommentModel';
import "./feedback.css";


const FeedBack = () => {
  const [allFeedBacks, setAllFeedBacks] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [sortDirection, setSortD] = useState("");
  let [loading, setLoading] = useState({ counts: false, dtable: false });
  const max_word = 5;

  // console.log(".........../.././././././/,,,,//,/,",allFeedBacks)

  const getFeedBacksData = async () => {
    setLoading({ dtable: true })
    await axios.get(`/get-all-feedback/?limit=${limit}&page=${page}&sort=${sort}&direction=${sortDirection}`)
      .then((resp) => {
        console.log("feedback data.....", resp.data);
        setAllFeedBacks(resp.data.getFeedBacks);
        setTotalRows(resp.data.pagination.totalRows);
        setLoading({ dtable: false })
      })
      .catch((error) => {
        setLoading({ dtable: false })
        console.log(error);
      })
  }

  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const getAllFeedBacksData = () => {
    let feedData = []
    allFeedBacks.map((data, i) => {

      // select word (5/10/...) a/c to max_word from  content
      let comments = data.content.split(" ");
      let len = Math.min(max_word, comments.length);
      let myComments = ""
      for (let i = 0; i < len; i++) {
        myComments += comments[i] + " ";
      }
      console.log("Contents....", myComments);

      feedData.push({
        image:(<div className="man_user">
        <img
          alt={Profw2_Img}
          src={data.userId?.userProfile?.thumbnailUrl ? data.userId?.userProfile?.thumbnailUrl : Profw2_Img}
        />
      </div>),
        name: (
          <div className="management_list_name">
            
            <div className="man_text" style={{ color: !data?.userId?.name && "#ff7575" }}>{data.userId?.name ?? "DELETED USER"}</div>
          </div>
        ),

        ratings: (
          <div className="management_list_name">
            <div className="notification_alignment">
              <ReactStars
                count={data?.rating ? data?.rating : 0}
                color="#FFD700"
                // activeColor= "white"
                edit={false}   // "stars" is selected b'z of  edit: true
                size={20}
                isHalf={true}   // for fraction value
              />
            </div>
          </div>
        ),
        // content: <div className="man_text">{comments.length > max_word ? data.content.substring(0, max_word) : data.content}</div>,
        content: <div className="notification_alignment">{myComments}</div>,
        view: <div style={{ marginLeft: "2rem", color: "white" }} className="view_hover">
          <CommentModel sendComment={data.content} className="man" />
        </div>,
      })
    })
    return feedData
  };

  useEffect(() => {
    getFeedBacksData();
  }, [page, limit, sort, sortDirection]);


  const handlePageChange = (page) => {
    //console.log(page)
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLimit(newPerPage);
    setPage(page);
  };

  /**
 * column(for sort) => for single entity and columns(display header) => multiple entity in 'DataTable component'
 */
  const handleSort = async (column, sortDirection) => {
    /// reach out to some API and get new data using or sortField and sortDirection
    // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}

    setSort(column.sortField);
    setSortD(sortDirection)
  };
  return (
    <React.Fragment>
      <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Feedback List</h2>
              </div>

              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                    className="hypTable1"
                    columns={columns}
                    data={getAllFeedBacksData()}
                    responsive
                    customStyles={customStyles}
                    onSort={handleSort}
                    noDataComponent={<CustomLoader />}
                    pagination
                    // selectableRows
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={loading.dtable}
                    progressComponent={<EmptyDataLoader />}
                    sortServer
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
const columns = [
  {
    name: "",
    selector: (row) => row.image,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    // reorder: true,
    width: "250px",
  },
  {
    name: "Rating",
    selector: (row) => row.ratings,
    sortable: true,
    // reorder: true,
    sortField: 'rating',
    width: "200px",
  },
  {
    name: "Comment",
    selector: (row) => row.content,
    // reorder: true,
    width: "250px",
  },
  {
    name: "View Full Comment",
    selector: (row) => row.view,
    // reorder: true,
    width: "200px"
  },
]

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: "10px",
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "20px",
      paddingBottom: "20px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "14px",
    },
  },
};

export default FeedBack;