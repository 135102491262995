import React, { useEffect, useState } from 'react';
import "./faq.css";
import "../AboutUs/aboutUs.css"
import Accordion from 'react-bootstrap/Accordion';
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import axios from "../../axios/axios";
import { message } from "antd";
import { Delete_img, Edit_img, Noimg_Img } from '../../assets/images';
import { Form, Modal } from 'react-bootstrap';

const FaqList = () => {
    const [loading, setLoading] = useState(false);
    const [faqData, setFaqData] = useState({});
    const [faqId, setFaqId] = useState("");
    const [show, setShow] = useState(false);
    let count = 0;

    const getFaqList = async () => {
        setLoading(true);
        await axios
            .get("/get-faqs")
            .then((res) => {
                console.log("get faq list response...", res);
                if (res.data.success === true) {
                    setFaqData(res.data)
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getFaqList();
    }, []);
    const deleteFaq = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        console.log("Delete data...  /delete-faq/{faqId}");
        console.log("Deleted faq id... ", id);
        await axios
            .delete(`/delete-faq/${id}`)
            .then((res) => {
                if (res.data.success === true) {
                    getFaqList();
                    message.error("FAQ Deleted successfully");
                    setLoading(false);
                    setShow(false);
                } else {
                    message.error("FAQ deletion failed");
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setLoading(false);
            })
    }

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
    };

    return (
        <React.Fragment>
            <Modal show={show} onHide={handleClose} size="md">
                <div className="mail_container">
                    <Modal.Header closeButton onClick={handleClose}>
                        <Modal.Title className="modal-title w-100 text-center">
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <p className="alert_p">Do you want to delete the faq's records?</p>
                            </Form.Group>

                            <div className="deleteLoader d-flex justify-content-end">
                                <LoadingOverlay
                                    spinner={
                                        <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
                                    }
                                >
                                    <div className='email-button-container'>
                                        <div className="mx-3">
                                            <button type="submit" className="alert-button ml-0" onClick={(e) => deleteFaq(e, faqId)}>Delete</button>
                                        </div>
                                        <div>
                                            <button className="notification_update_button email-button" onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>

            <div className="hypeu_nav">
                <div className="container_fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-3 col-md-2 col-12">
                            <div className="header_logo">
                                <img src="images/logo.png" className="img-fluid" />
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-4 col-12">
                    <div className="header_search">
                    <form className="form-inline">
                        <input
                        className="form-control mr-sm-2"
                        type="search"
                        placeholder="Search for anything here.."
                        aria-label="Search"
                        />
                        <button className="btn my-2 my-sm-0" type="submit">
                        <img src="images/search.png" alt="search icon" />
                        </button>
                    </form>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="header_date_and_time">
                    <div className="header_logout">
                        <a href="http://35.87.237.165:3000/logout" className="logout_logo">
                        <img src="images/out.png" className="img-fluid" alt="logout icon" />
                        </a>
                    </div>
                    </div>
                </div> */}
                    </div>
                </div>
            </div>

            <div className="user_inner_partts email_partts" style={{ padding: "0 0 30px" }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">

                        {/* <div className="manage_heading_search mb-4">
                            <h2>FAQ List</h2>
                        </div> */}

                        <div className="hypeu_about_banner">
                            <h1>Faq List</h1>
                        </div>

                        <div className='container' style={{ marginTop: "30px" }}>
                            <Accordion defaultActiveKey="0" className="faq_cntn">
                                {
                                    faqData?.faqs && faqData?.faqs?.map((item, ind) => {
                                        count += 1;
                                        return (
                                            <React.Fragment>
                                                <Accordion.Item eventKey={count} style={{ backgroundColor: "transparent" }} keys={ind}>
                                                    <Accordion.Header>
                                                        <span> {item.question} </span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </React.Fragment>
                                        )
                                    })
                                }

                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hypeu_footer">
                <div className="container_fluid">
                    <div className="ft_cntn d-flex">
                        <p>© information goes here. All rights reserved.</p>
                        <ul className="ul_style">
                            <a href="/about">About Us</a>
                            <a href="/privacy">Privacy Policy</a>
                            <a href="/terms-conditions">Terms & Conditions</a>
                            <a href="/faq-list">FAQ</a>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FaqList;