import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { No_ProfImg } from "../../assets/images";
import axios from "../../axios/axios";
import { useParams } from "react-router-dom";
import { CurrencyFormating } from "../../helpers/currencyHelper";

const NftOffers = () => {
  const { nftId, NFT_identity } = useParams();
  const [acceptedOffer, setAcceptedOffer] = useState([]);
  let [loading, setLoading] = useState({ counts: false, dtable: false });

  const acceptOfferList = async () => {
    setLoading((prevState) => ({ ...prevState, dtable: true }));
    await axios
      .get(`/get-nft-offers/${NFT_identity}`)
      .then((res) => {
        console.log("accepted offer list......", res.data);
        setLoading((prevState) => ({ ...prevState, dtable: false }));
        setAcceptedOffer(res.data.data);
      })
      .catch((err) => {
        console.log("Offer-List error", err);
      });
  };
  useEffect(() => {
    acceptOfferList();
  }, []);

  return (
    <React.Fragment>
      <div className="hermis_bottom fix_design">
        {/* <Row> */}
        <Col xs={2} sm={2} md={2}></Col>
        <Col xs={4} sm={4} md={4}>
          <h4>Offeror</h4>
        </Col>
        <Col xs={3} sm={3} md={3}>
          <h4>Offered ($)</h4>
        </Col>
        <Col xs={2} sm={2} md={2}>
          <h4>Status</h4>
        </Col>
        {/* </Row> */}

      </div>
      {acceptedOffer && (
        <React.Fragment>
          {acceptedOffer.map((nftData, index) => {
            return (
              <div key={index} className="edition_inner_inner_part" >
                <Col xs={2} sm={2} md={2}>
                  <div className="man_user">
                    <img src={nftData?.offeredBy?.userProfile?.thumbnailUrl ? nftData?.offeredBy?.userProfile?.thumbnailUrl : No_ProfImg} />
                  </div>
                </Col>
                <Col xs={4} sm={4} md={4}>
                  <div className="management_list_name nft_offers">
                    {/* <div className="management_list_name"> */}

                    <div className="man_text width">
                      {nftData?.offeredBy?.name}
                    </div>
                    {/* </div> */}
                  </div>
                </Col>

                <Col xs={3} sm={3} md={3}>
                  <div className="edition_inner_center">
                    <div className="edition_center_right adjust">
                    {CurrencyFormating(nftData?.offerPrice)}
                      {/* ${" "}{nftData?.offerPrice} */}
                    </div>
                  </div>
                </Col>

                <Col xs={2} sm={2} md={2}>
                  <div className="edition_inner_center">
                    <div className="edition_center_right adjust">
                      {nftData?.status?.toLowerCase()}
                    </div>
                  </div>
                </Col>

              </div>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default NftOffers;
