/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CountCard from "../subComponents/CountCard";
import ReportDownload from "../subComponents/ReportDownload";
import { Dropdown, Form, Modal } from "react-bootstrap";
// import { find } from "lodash";
import "./storyfeeds.css";

import {
  // Story_User_Img,
  Story_User2_Img,
  // Story_Man1_Img,
  Threedots_img,
  // Edit_img,
  Delete_img,
  Maneye_img,
  Like1_Img,
  // Dislike_Img,
  // Story_Man2_Img,
  // Story_Man3_Img,
  // Story_Man4_Img,
  Noimg_Img,
} from "../../assets/images";
import axios from "../../axios/axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStories } from "../../actions/setStories";
import { setStoriesCount } from "../../actions/setStoriesCount";
// import ListTable from "../subComponents/ListTable";
import DataTable from "react-data-table-component";
import moment from "moment";
// import { options } from "../Dashboard/DashBoardPart";
// import { parseZone } from "moment";
import DescriptionModal from "../modals/DescriptionModal";
import { message } from "antd";
// import StoryConfirmModal from "../modals/StoryConfirmModal";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const StoryFeedsPart = () => {
  const [page, setPage] = useState("1");
  const [pageNew, setPageNew] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalPage: 7,
    totalRows: 0,
  });
  const [count, setCount] = useState("0");
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(true);
  const stories = useSelector((state) => state.setStories);
  const storiesCount = useSelector((state) => state.setStoriesCount);
  const [show, setShow] = useState(false);
  const [story_id, setStory_id] = useState("");
  // console.log("stories storyfeed parts", stories);
  let dispatch = useDispatch();

  const token = useSelector((state) => state.setToken);
  const fetchStories = async () => {
    // console.log("page row chng", pageNew, pagination.limit);
    await axios.get(`/get-stories/?limit=${limit}&page=${pageNew}`, { headers: { Authorization: token, }, }).then((res) => {
      console.log("stories res...", res.data);
      if (res.data.success === true) {
        setPagination(res.data.pagination);
        dispatch(setStories(res.data.allStories));
        setLoading(false);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };
  const fetchStoriesCount = async () => {
    await axios.get("/all-story-count", { headers: { Authorization: token, }, }).then((res) => {
      // console.log("stories count res", res.data);
      if (res.data.success === true) {
        dispatch(setStoriesCount(res.data.allStories));
        setCount(res.data);
        setLoadingCount(false);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  useEffect(() => {
    fetchStories();
    fetchStoriesCount();
  }, [pageNew, loading, limit, loadingCount]);

  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);

    setLimit(newPerPage);

    setPageNew(page);
  };

  const deleteStory = async (e, id) => {
    // console.log("delete story=", id);
    e.preventDefault();
    let data = { id };
    await axios
      .post("/delete-story", data, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // setLoading(true);
        console.log("stories deleted", res.data);
        if (res.data.success === true) {
          fetchStories();
          fetchStoriesCount();
          // handleClose();
          message.success("Story deleted successfully");
          setShow(false);
        } else {
          message.error("Story deletion failed");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getUsersStories = () => {
    let userStories = [];
    stories.map((item, i) => {
      userStories.push({
        id: i,
        image:(<div className="man_user story_user">
              <img alt={Noimg_Img} src={item.images[0].thumbnailUrl ?? Noimg_Img} />
            </div>),
        title: (
          <div key={i+'A'} className="management_list_name" style={{whiteSpace:"nowrap !important"}}>
            
            <div className="man_text story_text">
              {item.name} <br />
            </div>
          </div>
        ),
        creator: [
          <div  key={i+'A'}  className="man_email">
            <b>{`${item.creator.name}`}</b>
            <p><small> {`@${item.creator.userName}`}</small></p>
            
          </div>,
        ],
        datecreated: [
          <div  key={i+'B'}  className="man_email">
            {" "}
            {moment(item.createdAt).format("MM-DD-YYYY")}
          </div>,
        ],
        descripton: (<>
          
          
            {
              item.description.length > 60 ?
                <React.Fragment>
                  <div className="title-_description">
                    {item?.description?.substring(0, 60)}
                    <DescriptionModal sendDescription={item?.description} />
                  </div>
                </React.Fragment>
                : <React.Fragment>
                  <div className="title-_description">
                    {item?.description}
                  </div>
                </React.Fragment>
            }
          </>),
        // );
        // }),
        action: [
          <div  key={i+'D'}  className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt={Noimg_Img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item href="#/action-1">
                  <img src={Edit_img} /> Edit
                </Dropdown.Item> */}
                <Link to={`/storydetails/${item._id}`}>
                  <img src={Maneye_img} alt={Noimg_Img} />
                  View
                </Link>
                <Dropdown.Item
                  href="#"
                  onClick={() => { setShow(true); handleShow(item._id) }}
                // onClick={() =>{setShow(true); setStory_id(item._id)}}
                >
                  {/* <Link to={'/storyfeeds'} onClick={() =>{ setShow(true); handleShow(item._id)}}> */}
                  {/* <StoryConfirmModal sendId={item._id} /> */}
                  <img src={Delete_img} alt={Noimg_Img} /> Delete
                  {/* </Link> */}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>,
        ],
      });
    });
    return userStories;
  };
  const changePage = (newPage) => {
    // console.log("newPage", newPage);
    setLoading(true);
    setPageNew(newPage);
    // // if (pageNew + 1 < pagination.totalPage) {
    // //   setPageNew(pageNew + 1);
    // // } else if (pageNew + 1 == pagination.totalPage) {
    // //   setPageNew(pagination.totalPage);
    // // } else {
    // //   setPageNew(pagination.totalPage);
    // // }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const handleShow = (id) => {
    console.log("display current id...", id);
    // setShow(true);
    setStory_id(id);
  }

  // const storyDelete = () => {
  //   console.log("yes, delete function is working....");
  // }

  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );
  return (
    <React.Fragment>

      {/* <Modal show={show} onHide={() => setShow(false)} size="md"> */}
      <Modal show={show} onHide={handleClose} size="md">
        <div className="mail_container">
          <Modal.Header closeButton onClick={handleClose}>
            <Modal.Title className="modal-title w-100 text-center">
              Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                {/* <Form.Label>Alert</Form.Label> */}
                <p className="alert_p">Do you want to delete the Story? </p>
              </Form.Group>

              <div className="deleteLoader d-flex justify-content-end">
                <LoadingOverlay
                  active={loading}
                  spinner={
                    <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
                  }
                >
                  <div className='email-button-container'>
                    <div className="mx-3">
                      <button type="submit" className="alert-button ml-0" onClick={(e) => deleteStory(e, story_id)}>Delete</button>
                    </div>
                    <div>
                      {/* <button type="submit" className="notification_update_button email-button" onClick={updateEmailDetail}>Update</button> */}
                      <button className="notification_update_button email-button" onClick={handleClose}>Cancel</button>
                    </div>
                  </div>
                </LoadingOverlay>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      {/* <Leftpanel panelName="dashboard"/> */}
      {/* <div className="right_panel"> */}
      {/* <HeaderPart /> */}
      <div className="user_inner_partts">
        <div className="total_users_report">
          <div className="total_users_left">
            <h2>Story Feeds</h2>
            <div className="total_and_new_user" id="storyPDF">
              {/* <div className="t_userers">
                <div className="tusers_heading">
                  <h2>My Story</h2>
                  <h4>500</h4>
                </div>
                <span>
                  <img src={Story_User_Img} />
                </span>
              </div> */}

              <CountCard
                loading={loadingCount}
                count={storiesCount}
                title="All Stories"
                imgName={Story_User2_Img}
              />

              {/* <div className="t_userers">
                  <div className="tusers_heading">
                    <h2>All Story</h2>
                    <h4>6000</h4>
                  </div>
                  <span>
                    <img src={Story_User2_Img} />
                  </span>
                </div> */}
            </div>
          </div>
          <div className="total_users_right">
            <ReportDownload listData={stories} totalStory={pagination.totalRows} Csvfields={["userId", "name", "tournament", "description", "status",]} type="story" />
          </div>
        </div>
      </div>

      <div className="user_management_list">
        <h3>Story List</h3>
        <div className="manage_table manage_table_1">
          <DataTable
            className="hypTable1"
            columns={columns}
            //data={data45}
            data={getUsersStories()}
            pagination
            responsive
            customStyles={customStyles}
            defaultSortField="id"
            defaultSortAsc={false}
            paginationDefaultPage={page}
            paginationServer
            paginationTotalRows={pagination.totalRows}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={changePage}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            noDataComponent={<EmptyDataLoader />}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const columns = [
  {
    name: "",
    selector: (row) => row.image,
    // reorder: true,
    width: "80px",
    // paddingLeft: "80px"
  },
  {
    name: "Title",
    selector: (row) => row.title,
    // reorder: true,
    width: "300px",
    // paddingLeft: "80px"
  },
  {
    name: "Creator",
    selector: (row) => row.creator,
    // reorder: true,
    width: "100px",
  },
  {
    name: "Description",
    selector: (row) => row.descripton,
    // reorder: true,
    width: "200px",
  },
  {
    name: "Date Created",
    selector: (row) => row.datecreated,
    // reorder: true,
    width: "150px",
  },
  {
    name: "Action",
    selector: (row) => row.action,
    // reorder: true,
    width: "150px",
  },
];

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      // marginLeft: "8.5rem",
      color: "#fff",
      fontSize: "17px",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      color: "#fff",
      fontSize: "15px",
    },
  },
};

export default StoryFeedsPart;
