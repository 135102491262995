import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import axios from "../../axios/axios";
import "./setting.css";
import message from "../../messages";

const SettingTable = (props) => {

  const [settingData, setSettingData] = useState({});
  const [settingError, setSettingError] = useState({
    hostErr: "",
    portErr: "",
    userNameErr: "",
    passwordErr: "",
    nameErr: "",
    accountIdErr: "",
    authTokenErr: "",
    sidErr: "",
    privateKeyErr: "",
    skErr: "",
    webhookErr: "",
    removalAiKeyErr: "",
    allowToAddCardErr: "",
    contactMailIdErr: "",
    reactivateAccountMailIdErr: "",
    feedbackMailIdErr: "",
    makeOfferExpiryErr:""
  })
  const validation = () => {
    let isValid = [];

    //////// host
    if (settingData?.mailLogin?.host === "") {
      setSettingError((prevState) => ({ ...prevState, hostErr: "Host is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, hostErr: "" }));
      isValid.push(true);
    }
    //////// port
    if (settingData?.mailLogin?.port === "") {
      setSettingError((prevState) => ({ ...prevState, portErr: "Port is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, portErr: "" }));
      isValid.push(true);
    }
    //////// User-name
    if (settingData?.mailLogin?.auth?.user === "") {
      setSettingError((prevState) => ({ ...prevState, userNameErr: "User name is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, userNameErr: "" }));
      isValid.push(true);
    }
    //////// Password
    if (settingData?.mailLogin?.auth?.pass === "") {
      setSettingError((prevState) => ({ ...prevState, passwordErr: "password is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, passwordErr: "" }));
      isValid.push(true);
    }
    //////// Name
    if (settingData?.smsGateway?.name === "") {
      setSettingError((prevState) => ({ ...prevState, nameErr: "Name is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, nameErr: "" }));
      isValid.push(true);
    }
    //////// Account-id
    if (settingData?.smsGateway?.accountSid === "") {
      setSettingError((prevState) => ({ ...prevState, accountIdErr: "Account ID is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, accountIdErr: "" }));
      isValid.push(true);
    }
    //////// Auth-Token
    if (settingData?.smsGateway?.authToken === "") {
      setSettingError((prevState) => ({ ...prevState, authTokenErr: "Auth-Token is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, authTokenErr: "" }));
      isValid.push(true);
    }
    //////// SID
    if (settingData?.smsGateway?.sid === "") {
      setSettingError((prevState) => ({ ...prevState, sidErr: "SID is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, sidErr: "" }));
      isValid.push(true);
    }
    //////// Private Key
    if (settingData?.stripe?.pk === "") {
      setSettingError((prevState) => ({ ...prevState, privateKeyErr: "Private Key is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, privateKeyErr: "" }));
      isValid.push(true);
    }
    //////// SK
    if (settingData?.stripe?.sk === "") {
      setSettingError((prevState) => ({ ...prevState, skErr: "SK is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, skErr: "" }));
      isValid.push(true);
    }
    //////// Webhook Secret
    if (settingData?.stripe?.webhookSecret === "") {
      setSettingError((prevState) => ({ ...prevState, webhookErr: "Webhook Secret  is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, webhookErr: "" }));
      isValid.push(true);
    }

    //////// Removal Ai Key
    if (settingData?.removalAiKey === "") {
      setSettingError((prevState) => ({ ...prevState, removalAiKeyErr: "Removal ai key is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, removalAiKeyErr: "" }));
      isValid.push(true);
    }

    if (settingData?.makeOfferExpiry === "") {
      setSettingError((prevState) => ({ ...prevState, makeOfferExpiryErr: "Offer expiry hours is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, makeOfferExpiryErr: "" }));
      isValid.push(true);
    }

    if (settingData?.minimumPayoutBalance === "") {
      setSettingError((prevState) => ({ ...prevState, minimumPayoutBalanceErr: "Minimum cashout value is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, minimumPayoutBalanceErr: "" }));
      isValid.push(true);
    }

    if (settingData?.mailIds?.contactMailId === "") {
      setSettingError((prevState) => ({ ...prevState, contactMailIdErr: "Contact mail id is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, contactMailIdErr: "" }));
      isValid.push(true)
    }

    if (settingData?.mailIds?.feedbackMailId === "") {
      setSettingError((prevState) => ({ ...prevState, feedbackMailIdErr: "Feedback mail id is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, feedbackMailIdErr: "" }));
      isValid.push(true)
    }

    if (settingData?.mailIds?.reactivateAccountMailId === "") {
      setSettingError((prevState) => ({ ...prevState, reactivateAccountMailIdErr: "Reactivate mail id is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, reactivateAccountMailIdErr: "" }));
      isValid.push(true)
    }

    //////// allow add card
    if (settingData?.allowAddCard === "") {
      setSettingError((prevState) => ({ ...prevState, allowToAddCardErr: "Removal ai key is required" }));
      isValid.push(false);
    } else {
      setSettingError((prevState) => ({ ...prevState, allowToAddCardErr: "" }));
      isValid.push(true);
    }

    /////////////////////////

    if (isValid.includes(false)) {
      return false
    } else {
      return true;
    }
  };

  const getProps = () => {
    setSettingData(props.sendData);
  };

  useEffect(() => {
    getProps();
  }, [props.sendData])

  console.log("Setting data...", settingData ? settingData : "");

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = validation();
    console.log("... valid...", valid);

    if (valid) {
      let dataurl = `/edit-sandbox/${props.sendData._id}`;
      axios.put(dataurl, settingData)
        .then((resp) => {
          if (resp.data.success === true) {
            console.log(".....response of update ", resp)
            message.success("Update successfully");
            props.getSettings()
          } else {
            console.log("error");
            message.error("Update fail.");
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // console.log("Updated data...", settingData);
  };

  useEffect(() => { validation(); }, [settingData])


  return (
    <React.Fragment>
      <div className="mail_login">
        <div className="host_partts">


            <div className="columnss">
              <Form.Label>Host</Form.Label>
              <Form.Control
                type="text"
                placeholder="Host"
                className="sand_text_box"
                name="host"
                value={settingData?.mailLogin?.host}
                onChange={(evt) => {
                  let { value } = evt.target;
                  let { mailLogin } = settingData
                  // props.sendData?.mailLogin?.host = value;
                  mailLogin.host = value;
                  setSettingData({
                    ...settingData,
                    mailLogin
                  })
                  // validation();
                }}
              />
              {/* <p style={{ color: "red" }}>{settingError.hostErr}</p> */}
              <p className="errorMessage">{settingError.hostErr}</p>
            </div>

            <div className="columnss">
              <Form.Label>Port</Form.Label>
              <Form.Control
                type="text"
                placeholder="Port"
                className="sand_text_box"
                name="port"
                value={settingData?.mailLogin?.port}
                onChange={(evt) => {
                  let { value } = evt.target;
                  let { mailLogin } = settingData
                  mailLogin.port = value;
                  setSettingData({
                    ...settingData,
                    mailLogin
                  })
                  // validation();
                }}
              />
              <p className="errorMessage">{settingError.portErr}</p>
            </div>


            <div className="host_partts">
              <div className="columnss">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Name"
                  className="sand_text_box"
                  name="user"
                  value={settingData?.mailLogin?.auth?.user}
                  onChange={(evt) => {
                    let { value } = evt.target;
                    let auth = settingData?.mailLogin?.auth
                    if (auth?.user) {
                      auth.user = value;
                      setSettingData({
                        ...settingData,
                        auth
                      })
                      // validation();
                    }
                  }}
                />
                <p className="errorMessage">{settingError.userNameErr}</p>
              </div>

              <div className="columnss">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="sand_text_box"
                  name="pass"
                  value={settingData?.mailLogin?.auth?.pass}
                  onChange={(evt) => {
                    let { value } = evt.target;
                    let auth = settingData?.mailLogin?.auth
                    if (auth) {
                      auth.pass = value;
                      setSettingData({
                        ...settingData,
                        auth
                      })
                    }
                    // validation();
                  }}
                />
                <p className="errorMessage">{settingError.passwordErr}</p>
              </div>
            </div>
        </div>
      </div>

      
      <div className="mail_login">
        {/* <div className="host_partts">
          <div className="columnss">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              className="sand_text_box"
              name="name"
              value={settingData?.smsGateway?.name}
              onChange={(evt) => {
                let { value } = evt.target;
                let { smsGateway } = settingData
                smsGateway.name = value;
                setSettingData({
                  ...settingData,
                  smsGateway
                })
                // validation();
              }}
            />
            <p className="errorMessage">{settingError.nameErr}</p>
          </div>
          <div className="columnss">
            <Form.Label>Accounts ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Accounts ID"
              className="sand_text_box"
              name="accountSid"
              value={settingData?.smsGateway?.accountSid}
              onChange={(evt) => {
                let { value } = evt.target;
                let { smsGateway } = settingData
                smsGateway.accountSid = value;
                setSettingData({
                  ...settingData,
                  smsGateway
                })
                // validation();
              }}
            />
            <p className="errorMessage">{settingError.accountIdErr}</p>
          </div>
        </div>
        <div className="host_partts">
          <div className="columnss">
            <Form.Label>Auth Token</Form.Label>
            <Form.Control
              type="text"
              placeholder="Auth Token"
              className="sand_text_box"
              name="authToken"
              value={settingData?.smsGateway?.authToken}
              onChange={(evt) => {
                let { value } = evt.target;
                let { smsGateway } = settingData
                smsGateway.authToken = value;
                setSettingData({
                  ...settingData,
                  smsGateway
                })
                // validation();
              }}
            />
            <p className="errorMessage">{settingError.authTokenErr}</p>
          </div>

          <div className="columnss">
            <Form.Label>SID</Form.Label>
            <Form.Control
              type="text"
              placeholder="SID"
              className="sand_text_box"
              name="sid"
              value={settingData?.smsGateway?.sid}
              onChange={(evt) => {
                let { value } = evt.target;
                let { smsGateway } = settingData
                smsGateway.sid = value;
                setSettingData({
                  ...settingData,
                  smsGateway
                })
                // validation();
              }}
            />
            <p className="errorMessage">{settingError.sidErr}</p>
          </div>
        </div> */}
      </div>
      <div className="mail_login stripe">
        <div>
          <Form.Label>Private Key</Form.Label>
          <Form.Control
            type="text"
            placeholder="Private Key"
            className="sand_text_box"
            name="pk"
            value={settingData?.stripe?.pk}
            onChange={(evt) => {
              let { value } = evt.target;
              let { stripe } = settingData
              stripe.pk = value;
              setSettingData({
                ...settingData,
                stripe
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.privateKeyErr}</p>
        </div>
        <div>
          <Form.Label>SK</Form.Label>
          <Form.Control
            type="text"
            placeholder="SK"
            className="sand_text_box"
            name="sk"
            value={settingData?.stripe?.sk}
            onChange={(evt) => {
              let { value } = evt.target;
              let { stripe } = settingData
              stripe.sk = value;
              setSettingData({
                ...settingData,
                stripe
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.skErr}</p>
        </div>

        <div>
          <Form.Label>Webhook Secret</Form.Label>
          <Form.Control
            type="text"
            placeholder="webhookSecret"
            className="sand_text_box"
            name="webhookSecret"
            value={settingData?.stripe?.webhookSecret}
            onChange={(evt) => {
              let { value } = evt.target;
              let { stripe } = settingData
              stripe.webhookSecret = value;
              setSettingData({
                ...settingData,
                stripe
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.webhookErr}</p>
        </div>

        <div>
          <Form.Label>Stripe Fee</Form.Label>
          <div className="host_partts">
          <div className="columnss">
          <Form.Control
            type="text"
            placeholder="Stripe Fee Percentage"
            className="sand_text_box"
            name="stripeCharges.percentage"
            value={`${settingData?.stripeCharges?.percentage} %`}
            readOnly={true}
          />
          </div>
          <div className="columnss">
           <Form.Control
            type="text"
            placeholder="Stripe Fee Fixed"
            className="sand_text_box"
            name="stripeCharges.fixed"
            value={settingData?.stripeCharges?.fixed}
            readOnly={true}
          />
          </div>
          </div>
          {/* <p className="errorMessage">{settingError.webhookErr}</p> */}
        </div>
      </div>

      <div className="mail_login stripe">
        <div>
          <Form.Label>Removal Ai Key</Form.Label>
          <Form.Control
            type="text"
            placeholder="Removal ai key"
            className="sand_text_box"
            name="removalAiKey"
            value={settingData?.removalAiKey}
            onChange={(evt) => {
              let { value } = evt.target;
              let { removalAiKey } = settingData
              removalAiKey = value
              setSettingData({
                ...settingData,
                removalAiKey: value
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.removalAiKeyErr}</p>
        </div>
      </div>

      <div className="mail_login stripe">
        <div>
          <Form.Label>Offer Expiry (Hours)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Offer Expiry"
            className="sand_text_box"
            name="makeOfferExpiry"
            value={settingData?.makeOfferExpiry}
            onChange={(evt) => {
              let { value } = evt.target;
              let { makeOfferExpiry } = settingData
              makeOfferExpiry = value
              setSettingData({
                ...settingData,
                makeOfferExpiry: value
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.makeOfferExpiryErr}</p>
        </div>
      </div>

      <div className="mail_login stripe">
        <div>
          <Form.Label>Minimum Cashout ($)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Minimum Cashout ($)"
            className="sand_text_box"
            name="minimumPayoutBalance"
            value={settingData?.minimumPayoutBalance}
            onChange={(evt) => {
              let { value } = evt.target;
              let { minimumPayoutBalance } = settingData
              minimumPayoutBalance = value
              setSettingData({
                ...settingData,
                minimumPayoutBalance: value
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.minimumPayoutBalanceErr}</p>
        </div>
      </div>

      <div className="mail_login stripe">
        <div>
          <Form.Label>Contact email id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Contact email id"
            className="sand_text_box"
            name="contactEmail"
            value={settingData?.mailIds?.contactMailId}
            onChange={(evt) => {
              let { value } = evt.target;
              let { contactMailId } = settingData
              contactMailId = value
              setSettingData({
                ...settingData,
                mailIds: { ...settingData.mailIds, contactMailId: value }
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.contactMailIdErr}</p>
        </div>
      </div>
      <div className="mail_login stripe">
        <div>
          <Form.Label>Feedback mail id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Feedback mail id"
            className="sand_text_box"
            name="feedbackMailId"
            value={settingData?.mailIds?.feedbackMailId}
            onChange={(evt) => {
              let { value } = evt.target;
              let { feedbackMailId } = settingData
              feedbackMailId = value
              setSettingData({
                ...settingData,
                mailIds: { ...settingData.mailIds, feedbackMailId: value }
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.feedbackMailIdErr}</p>
        </div>
      </div>
      <div className="mail_login stripe">
        <div>
          <Form.Label>Reactive mail id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Reactive mail id"
            className="sand_text_box"
            name="reactivateAccountMailId"
            value={settingData?.mailIds?.reactivateAccountMailId}
            onChange={(evt) => {
              let { value } = evt.target;
              let { reactivateAccountMailId } = settingData
              reactivateAccountMailId = value
              setSettingData({
                ...settingData,
                mailIds: { ...settingData.mailIds, reactivateAccountMailId: value }
              })
              // validation();
            }}
          />
          <p className="errorMessage">{settingError.reactivateAccountMailIdErr}</p>
        </div>
      </div>

      <div className="mail_login stripe">
        <div>
          <div className="allowAddCard" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Form.Check
              type="switch"
              name="allowToAddCard"
              checked={settingData?.allowToAddCard}
              onChange={(evt) => {
                let { checked } = evt.target;
                let { allowToAddCard } = settingData
                allowToAddCard = checked
                setSettingData({
                  ...settingData,
                  allowToAddCard
                })
                // validation();
              }}
            />
            <Form.Label>Allow add card</Form.Label>
          </div>
          <p className="errorMessage">{settingError.allowToAddCard}</p>
        </div>
      </div>
      <div className="text-center">
        <button type="Submit" className="email-button" onClick={handleSubmit}>
          {/* <button type="Submit" className="email-button"> */}
          Save
        </button>
      </div>
    </React.Fragment>
  );
};

export default SettingTable;
