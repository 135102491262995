import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { Row, Col, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import "./makeOffered.css";
import {
  Managesearch_img,
  Maneye_img,
  No_ProfImg,
  Profw2_Img,
} from "../../assets/images";
import { Link } from "react-router-dom";
import { CurrencyFormating } from "../../helpers/currencyHelper";

function MakeOffered() {
  const [offerList, setOfferList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  let [loading, setLoading] = useState(true);


  const CustomLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>Loading...</div>
    </div>
  );
  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );
  const getOfferList = async () => {
    await axios
      .get(`/get-nft-make-offers/?limit=${limit}&page=${page}`)
      .then((res) => {
        console.log("response......", res);
        if (res.data.success === true) {
          console.log("List of all offers......", res.data);
          setOfferList(res.data.data);
          setTotalRows(res.data.pagination.totalRows);
          // setLoading(prevState => ({...prevState, dtable:false}));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Offer-List error", err);
      });
  };
  useEffect(() => {
    getOfferList();
  }, [page, limit, loading]);

  const getOfferListData = () => {
    let offerDetails = [];
    offerList.map((item, i) => {
      offerDetails.push({
        image: (

          <div className="man_user story_user division_image">
            <img
              alt={Profw2_Img}
              src={item.nft?.fileUrl ? item.nft?.fileUrl : No_ProfImg}
            />
          </div>
        ),
        name: (
          <div className="management_list_name">

            <div className="man_text story_text" style={{ marginLeft: "15px" }}>{item.nft?.title !="" ? item.nft?.title : `${item.nft.creator.nickName.fName} ${item.nft.creator.nickName.lName}`}</div>
          </div>
        ),
        // nft_creator: <div className="man_email">{item.nft_creator}</div>,
        // nft_holder: <div className="offer_adjust">{item?.nft?.nftHolder?.name}</div>,
        offered_person: <div className="offer_adjust">{item?.offeredBy?.name}</div>,
        receiver_person: <div className="offer_adjust">{item?.receiver?.name}</div>,
        offered_price: <div className="offer_price">{CurrencyFormating(item?.offerPrice)}</div>,
        offered_date: <div className="offer_adjust">{moment(item.createdAt).format("MM-DD-YYYY")}</div>,
        status: <div className="offer_adjust">{item?.status}</div>,
        accepted_by: <div className="offer_adjust">{item?.acceptedBy?.name ?? "--"}
        {item?.acceptedBy?.userName && (<p><small>@{item?.acceptedBy?.userName}</small></p>)}
        </div>,
        // description: <div className="man_ph">{item.description}</div>,
        // view: (
        //   <div className="three_dotss">
        //     <Link to="/offer-details">
        //       <img src={Maneye_img} alt="No Img" className="manEyeStyle" />
        //     </Link>
        //   </div>
        // ),
      });
    });
    return offerDetails;
  };

  const handlePageChange = (page) => {
    console.log(page);
    setLoading(true);
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page);
    setLoading(true)
    setLimit(newPerPage);
    setPage(page);
  };

  return (
    <React.Fragment>
      <div className="user_inner_partts email_partts">
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="usermaageleft">
              <div className="manage_heading_search">
                <h2>Nfts Offered List</h2>
                {/* <div className="manage_texfieldt">
                  <Form.Control
                    type="text"
                    placeholder="Search here.."
                    className="manage_search_boxxx"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="search_imgg_manage">
                    <img alt="No Img" src={Managesearch_img} />
                  </span>
                </div> */}
              </div>

              <div className="manage_table">
                <div className="table-responsive">
                  <DataTable
                    className="hypTable1"
                    columns={columns}
                    data={getOfferListData()}
                    pagination
                    responsive
                    customStyles={customStyles}
                    defaultSortField="id"
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 40]}
                    defaultSortAsc={false}
                    progressPending={loading}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={<EmptyDataLoader />}
                    progressComponent={<CustomLoader />}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const columns = [
  {
    name: "",
    selector: (row) => row.image,
    reorder: true,
    width: "50px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    reorder: true,
    width: "170px",
  },
  // {
  //   name: "Creator",
  //   selector: (row) => row.nft_creator,
  //   reorder: true,
  //   width: "150px",
  // },
  // {
  //   name: "Holder",
  //   selector: (row) => row.nft_holder,
  //   reorder: true,
  //   width: "145px",
  // },
  {
    name: "Sender",
    selector: (row) => row.offered_person,
    reorder: true,
    width: "145px",
  },
  // {
  //   name: "Receiver",
  //   selector: (row) => row.receiver_person,
  //   reorder: true,
  //   width: "145px",
  // },
  {
    name: "Price $",
    selector: (row) => row.offered_price,
    reorder: true,
    width: "100px",
  },
  {
    name: "Date",
    selector: (row) => row.offered_date,
    reorder: true,
    width: "125px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    reorder: true,
    width: "125px",
  },
  {
    name: "Accepted By",
    selector: (row) => row.accepted_by,
    reorder: true,
    // width: "100px",
    marginLeft: "40px"
  },
  // {
  //   name: "Description",
  //   selector: (row) => row.description,
  //   reorder: true,
  //   width: "250px",
  // },
  // {
  //   name: "View",
  //   selector: (row) => row.view,
  //   reorder: true,
  //   width: "175px",
  // },
];
const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "17px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "16px",
      fontWeight: 600,
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      //   paddingTop: "5px",
      paddingTop: "25px",
      //   paddingBottom: "5px",
      paddingBottom: "25px",
      backgroundColor: "transparent",
      color: "#fff",
    },
  },
};

export default MakeOffered;
